import axios from 'axios';
import qs from 'qs'
import configs from './configs'
import router from '../router'
let devUrl = ''
process.env.NODE_ENV === 'development' ? devUrl = configs.apiAddress : devUrl = configs.apiAddress;
const http = axios.create({
    baseURL: devUrl,
    timeout: 20000,
});

// 请求拦截器
http.interceptors.request.use(config => {
    // 可以在这里添加例如token等配置
    // config.headers['Authorization'] = 'Bearer yourToken';
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

    if (config.url !== '/Home/Register/loadPics') {
        let lang = localStorage.getItem('lang');
        if(lang == 'zh'){
			lang = 'zh-cn'
		}else if(lang == 'jp'){
			lang = 'ja'
		}else if(lang == 'ko'){
			lang = 'ko'
		}else if(lang == 'tc'){
			lang = 'zh-tw'
		}else if(lang == 'en'){
			lang = 'en-us'
		} else if(lang == 'ar'){
			lang = 'ar'
		}else if(lang == 'de'){
			lang = 'de'
		}else if(lang == 'es'){
			lang = 'es'
		}else if(lang == 'fa'){
			lang = 'fa'
		} else if(lang == 'fr'){
			lang = 'fr'
		}else if(lang == 'hi'){
			lang = 'hi'
		}else if(lang == 'pl'){
			lang = 'pl'
		}else if(lang == 'pt'){
			lang = 'pt'
		}else if(lang == 'ru'){
			lang = 'ru'
		}else if(lang == 'tr'){
			lang = 'tr'
		}else if(lang == 'ur'){
			lang = 'ur'
		}  
        if(config.url !== '/home/Google/googleCallback'){
            config.data.token = window.sessionStorage.getItem('tk')
        }
        config.data.lang = lang
        config.data = qs.stringify(config.data)
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// 响应拦截器
http.interceptors.response.use(response => {
    // 对响应数据做处理
    if(response.data.code ==412){
        localStorage.removeItem('tk');
        
        router.push({
            path:'/LoginLayout'
        });
    }else{
        return response;
    }
    
    
}, error => {
    
    // 处理响应错误
    return Promise.reject(error);
});

export default http;
