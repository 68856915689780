export default {
    public:{
        zhd:"лучший",
        qsjyh:"Пользователи по всему миру",
        gtdxz:'общий выбор',
        szhbjyytz:'Возможности и проблемы цифровой валюты',
        xsddcf:'Возможности и проблемы благосостояния в новую эпоху',
        yzsjypt:"Универсальная торговая платформа",
        mskljyzh:'Откройте торговый счет и начните торговать прямо сейчас',
        jyhg:"Соблюдение требований бизнеса",
    },

    header: {
    hello: 'Привет, мир!',
    changeLanguage: 'переключить язык',
    Login: 'Авторизоваться',
    Register: 'зарегистрироваться',
    Logout: 'Выйти',
    shouye: 'первая страница',
    bibijiaoyisuo: 'Торговля криптовалютой',
    heyuejiaoyisuo: 'контрактная торговля',
    licaichanpin: 'Пул для майнинга',
    rengou: 'Подписка',
    ruanjianxiazai: 'Загрузка программного обеспечения',
    zhongwen: 'китайский',
    yingwen: 'В',
    zichan: 'ресурсы',
    dingdan: 'Заказ',
    heyuedingdan: 'контрактный заказ',
    bibidingdan: 'Порядок монет',
    anquanzhongxin: 'Центр безопасности',
    fanyongyaoqing: 'Приглашение комиссии',
    tuichudenglu: 'Выйти',
    gupiaojiaoyi: 'торговля акциями',
    waihuijiaoyi: 'Торговля на Форексе',
    qiquanjiaoyi:'торговля опционами',
    gupiaodingdan: 'заказ на склад',
    waihuidingdan: 'Форекс ордера',
    qiquandingdan: 'порядок опционов',
    jiaoyidingdan:'торговый приказ',
    daolizhongxin:'Агентский центр',
    huodong:'Активность'
    },
    home: {
    huanyinglaidao: 'добро пожаловать в',
    pingtaimingcheng: 'здесь',
    mengxiangqihang: 'Мечты отплыли',
    yijiankaiqishuzihualvcheng: 'Начните свое цифровое путешествие одним щелчком мыши',
    liaojiegengduo: 'узнать больше',
    chongzhi: 'пополнить',
    jiaoyi: 'торговля',
    remenbang: 'Популярный список',
    zhangfubang: 'Список гейнеров',
    diefubang: 'Список проигравших',
    chengjiaoliang: 'Объем',
    zanwushuju: 'Данных пока нет',
    jiayidui: 'торговая пара',
    zuixinjiage: 'последняя цена',
    zhangdie24h: '24 часа взлетов и падений',
    caozuo: 'действовать',
    jiaoyi: 'торговля',
    gengduo: 'Более',
    suishisuidikaiqijiaoyi: 'Начните торговать в любое время и в любом месте',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'Будь то приложение или Интернет, вы можете быстро начать транзакцию',
    saomaxiazaiApp: 'Отсканируйте QR-код, чтобы загрузить приложение.',
    iosheAndroid: 'iOS и Андроид',
    ninkexinrenqieanquandejiamibijiaoyisuo: 'Ваша надежная и безопасная биржа криптовалют',
    tishi1: 'Резервный сертификат',
    xiangqing1: 'Мы обещаем хранить все активы пользователей как минимум в соотношении 1:1.',
    tishi2: 'Лучшие ставки транзакций',
    xiangqing2: 'Льготные ставки транзакций, конкурентоспособные VIP-права и услуги самого высокого качества.',
    tishi3: 'Безопасность, на которую вы можете рассчитывать',
    xiangqing3: 'Наши сложные меры безопасности и фонды SAFU защищают ваши цифровые активы от всех рисков.',
    tishi4: 'Круглосуточная служба поддержки клиентов 24*7 часов',
    xiangqing4: '24*7 часов, режим постоянной работы, ответьте на ваши соответствующие запросы как можно скорее',
    tishi5: 'Стабильный и безопасный',
    xiangqing5: 'Ведущая мировая биржа обеспечивает защиту ваших активов на банковском уровне.',
    tishi6: 'Часто задаваемые вопросы',
    xiangqing6: 'Ознакомьтесь с часто задаваемыми вопросами для получения подробного описания конкретных функций.',
    fanhui:"возвращаться",
    shishihangqing:'Котировки в реальном времени',
    },
    footer: {
    bangzhuzhongxin: 'Справочный центр',
    dianhuazixun: 'Консультация по телефону',
    dianhuahegongzuoshijian: '1670-9756 (10:00~19:00 по будням/закрыто по выходным и праздникам)',
    liaotianzixun: '1:1 консультация в чате',
    keketuozixun: 'Кокоток Консалтинг',
    zaixianbangzhu: 'Онлайн-помощь',
    h24kaifang: '(открыто 24 часа)',
    lixianbangzhu: 'Офлайн-помощь',
    gongsidizhi: '1F, Yongsan Purgio Summit, Ханган-гиль, 69, Юнсан-гу, Сеул',
    gongzuoshijian: '(10:00~18:00 по будням, закрыто по выходным и праздникам)',
    shangshizixun: 'Консультация по листингу',
    hezuozixun: 'Консультация по сотрудничеству',
    gongsi: 'компания',
    lianxiwomwn: 'Связаться с нами',
    zhidao: 'гид',
    yonghuzhichi: 'Поддержка пользователей',
    zhanghuzhinan: 'Руководство по счету: пополнение/вывод средств',
    ruanjianxiazai: 'Загрузка программного обеспечения',
    zhengce: 'политика',
    fuwuyuyinsitiaokuan: 'Условия обслуживания и конфиденциальность',
    },
    login: {
    yonghudenglu: 'Вход пользователя',
    huanyingdenglu: 'Добро пожаловать, чтобы войти',
    xiangmumingcheng: 'BCFX',
    guanfangwangzhan: 'официальный сайт',
    youxiang: 'Почта',
    qingshuruyouxiang: 'Пожалуйста, введите свой адрес электронной почты',
    mima: 'пароль',
    qingshurumima: 'Пожалуйста, введите пароль',
    wangjimima: 'забыл пароль?',
    denglu: 'Авторизоваться',
    haimeiyouzhanghao: 'У вас еще нет учетной записи? идти',
    zhuce: 'зарегистрироваться',
    dengluchenggong: 'Вход успешен',
    denglushibai: 'Ошибка входа',
    qingshuruyonghuminghemima: 'Пожалуйста, введите имя пользователя и пароль',
    mimachangdubunengxiaoyuliuwei: 'Длина пароля не может быть менее 6 символов.',
    youxiangrenzheng: 'Проверка электронной почты',
    youxiangdizhi: 'Адрес электронной почты',
    qingshuruyouxiangdizhi: 'Пожалуйста, введите адрес электронной почты',
    tuxingyanzhengma: 'Графический код проверки',
    qingshurutuxingyanzhengma: 'Пожалуйста, введите графический проверочный код',
    yanzhengma: 'Проверочный код',
    qingshuruyouxiangyanzhengma: 'Пожалуйста, введите код подтверждения электронной почты',
    huoquyouxiangyanzhengma: 'Получить код подтверждения электронной почты',
    queding: 'Конечно',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Не удалось получить графический проверочный код, запросите еще раз.',
    qingxianwanchengyouxiangrenzheng: 'Пожалуйста, сначала завершите проверку электронной почты',
    huoquyouxiangyanzhengmashibai: 'Не удалось получить код подтверждения электронной почты,',
    houchongxinfasong: 'Отправить повторно после S',
    youxiangyanzhengchenggong: 'Проверка электронной почты прошла успешно',
    yanzhengmacuowu: 'Ошибка кода подтверждения',
    xiugaidenglumima: 'Изменить пароль для входа',
    xinmima: 'Новый пароль',
    quedingxinmima: 'Подтвердите новый пароль',
    qingshuruxinmima: 'Пожалуйста, введите новый пароль',
    qingzaicishuruxinmima: 'Пожалуйста, введите новый пароль еще раз',
    liangcimimashurubuyizhi: 'Два ввода пароля несовместимы',
    mimaxiugaichenggong: 'Пароль успешно изменен',
    xiugaimimashibai: 'Не удалось изменить пароль',
    },
    trade: {
    hangqingheyue: 'договор',
    hangqingzuixinjia: 'последняя цена',
    hangqingzhangdiefu: 'Увеличение или уменьшение',
    hangqingzuigao24h: 'максимум 24 часа',
    hangqingzuidi24h: 'минимум 24 часа',
    hangqingchengjiaoliang24h: 'Объем 24 часа',
    hangqingchengjiabishu: 'Объем',
    zhanghuquanyi: 'Права аккаунта',
    zhanghukeyongshouyi: 'Доступные преимущества',
    hangqingweituoliebiao: 'Список заказов',
    hangqingjiage: 'цена',
    hangqingshuliang: 'количество',
    hangqingzuixinchengjiao: 'последняя транзакция',
    hangqingjiage1: 'цена',
    hangqingshuliang1: 'количество',
    hangqingshijian: 'время',
    dingdanchiyoucangwei: 'Удерживать позицию',
    dingdandangqianweituo: 'Текущий заказ',
    dingdanchengjiaojilu: 'Запись транзакции',
    dingdantiaojiandan: 'условный заказ',
    chicangheyue: 'договор',
    chicangkaicangjunjia: 'Цена позиции',
    chicangfudongyingkui: 'Прибыль и убытки',
    chicanggangganbeishu: 'Использовать',
    chicangchicangliang: 'Должность(я)',
    chicangduokong: 'Длинный/Короткий',
    chicangduo: 'много',
    chicangkong: 'нулевой',
    chicangzhiyingjiage: 'Цена тейк-профита',
    chicangzhisunjiage: 'Цена стоп-лосса',
    chicangqiangpingyugujiage: 'Ориентировочная цена ликвидации',
    chicangcaozuo: 'действовать',
    chicangzhiyingzhisun: 'Тейк-профит и стоп-лосс',
    chicangpingcang: 'Закрыть',
    chicangshouxufei: 'плата за обработку',
    weituoheyue: 'договор',
    weituoshuliang: 'Количество заказа',
    weituojiage: 'Цена комиссии',
    weituofangxiang: 'направление',
    weituogangganbeishu: 'Используйте несколько',
    weituochengjiaoshuliang: 'Сумма транзакции',
    weituoshouxufei: 'плата за обработку',
    weituochengjiaojunjia: 'Средняя цена',
    weituoshouyi: 'доход',
    weituozhuangtai: 'состояние',
    weituochengjiaoshijian: 'Время транзакции',
    weituocaozuo: 'действовать',
    weituochedan: 'Отменить заказ',
    weituotijiaoshibai: 'Отправка не удалась',
    weituozhilingtijiao: 'Подача инструкций',
    weituoyitijiao: 'Поданный',
    weituobufenchengjiao: 'Частичная сделка',
    weituobufenchengjiaoyichedan: 'Некоторые заказы отменены',
    weituowanquanchengjiao: 'Завершить сделку',
    weituowanquanchedan: 'Отменить заказ полностью',
    weituochedanzhong: 'Отмена заказа',
    weituokaiduo: 'Открыто долго',
    weituopingkong: 'плоское небо',
    weituokaikong: 'Открыть короткую позицию',
    weituopingduo: 'Пиндуо',
    chengjiaoheyue: 'договор',
    chengjiaofangxiang: 'направление',
    chengjiaoshuliang: 'Сумма транзакции',
    chengjiaojiage: 'цена сделки',
    chengjiaoshijian: 'Время транзакции',
    chengjiaopingcangyingkui: 'Прибыль и убытки',
    chengjiaoshouxufei: 'Комиссия за транзакцию',
    chengjiaokaiduo: 'Открыто долго',
    chengjiaopingkong: 'плоское небо',
    chengjiaokaikong: 'Открыть короткую позицию',
    chengjiaopingduo: 'Пиндуо',
    tiaojianzengjiatiaojian: 'Добавить условия',
    tiaojianzantingquanbu: 'приостановить все',
    tiaojianqidongquanbu: 'Начать все',
    tiaojianshanchuquanbu: 'Удалить все',
    tiaojianheyue: 'договор',
    tiaojianweituoshuliang: 'Количество заказа',
    tiaojianfangxiang: 'направление',
    tiaojiantiaojianleixing: 'тип условия',
    tiaojianjiageleixing: 'тип цены',
    tiaojianbijiaofuhao: 'Символ сравнения',
    tiaojiantiaojian: 'состояние',
    tiaojianzhuangtai: 'состояние',
    tiaojianchuangjianshijian: 'время создания',
    tiaojiancaozuo: 'действовать',
    tiaojianxiugai: 'Исправлять',
    tiaojianqidong: 'запускать',
    tiaojianzanting: 'пауза',
    tiaojianshanchu: 'удалить',
    tiaojianlijixiadan: 'Заказать сейчас',
    tiaojiankaiduo: 'Открыто долго',
    tiaojianpingkong: 'плоское небо',
    tiaojiankaikong: 'Открыть короткую позицию',
    tiaojianpingduo: 'Пиндуо',
    tiaojianjiagetiaojian: 'ценовые условия',
    tiaojianshijiantiaojian: 'условие времени',
    tiaojianzuixinjia: 'последняя цена',
    tiaojianByijia: 'купить одну цену',
    tiaojianSyijia: 'Продать одну цену',
    tiaojianzanting: 'пауза',
    tiaojianyunxing: 'бегать',
    tiaojianyiwancheng: 'Завершенный',
    tiaojianshibai: 'неудача',
    tiaojianyishanchu: 'Удалено',
    tiaojianshoudongchufawancheng: 'Ручной триггер завершен',
    tiaojianshoudongchufashibai: 'Ручной триггер не удался',
    xiadankaicang: 'Открыть',
    xiadanpingcang: 'Закрыть',
    xiadanweituoleixing: 'Тип',
    xiadanqingxuanze: 'Пожалуйста, выберите',
    xiadanshijia: 'рыночная цена',
    xiadanxianjia: 'ценовой предел',
    xiadanjiage: 'цена',
    xiadanqingshurujiage: 'Пожалуйста, введите цену',
    xiadanshuliang: 'количество',
    xiadanqingshurushuliang: 'Пожалуйста, введите количество',
    xiadanzhang: 'открыть',
    xiadankekaishuliang: 'Доступное количество',
    xiadanqingxianxuanzechicangdan: 'Пожалуйста, сначала выберите порядок позиций',
    xiadankepingshuliang: 'Количество, которое можно выровнять',
    xiadansuoxubaozhengjin: 'Требуемая маржа:',
    xiadanshijiazhiyingzhisunyushe: 'Предварительная настройка тейк-профита и стоп-лосса по рыночной цене',
    xiadanyujiqiangpingjiage: 'Ориентировочная цена ликвидации:',
    xiadanqiangpingjiagetishi: 'Ожидаемая цена ликвидации динамически рассчитывается на основе общей суммы активов. Эта цена предназначена только для справки и не будет использоваться при окончательной ликвидации.',
    xiadanzuoduo: 'идти долго',
    xiadanzuokong: 'короткий',
    xiadanpingcang: 'Закрыть',
    zhanghuheyuezichan: 'Контрактные активы',
    zhanghuchongbi: 'пополнить',
    zhanghutibi: 'Вывод монет',
    zhanghuhuazhuan: 'передача',
    zhanghuzhanghuquanyi: 'Права аккаунта',
    zhanghufudongyingkui: 'плавающая прибыль и убыток',
    zhanghucangweibaozhengjin: 'Маржа позиции',
    zhanghudongjiebaozhengjin: 'Заморозить маржу',
    zhanghukeyongquanyi: 'Доступные преимущества',
    zhanghubaozhengjinshiyonglv: 'Коэффициент использования маржи',
    hangqingheyuexinxi: 'Информация о контракте',
    hangqingheyuemingcheng: 'Название контракта',
    hangqingjijiahuobi: 'Валюта котировки',
    hangqingheyuedaxiao: 'Размер контракта',
    hangqingzuixiaojiagebodong: 'минимальное колебание цен',
    dialogleverRateganggantiaozheng: 'Регулировка кредитного плеча',
    dialogleverRatequeding: 'Конечно',
    dialogtransferzichanhuazhuan: 'Передача активов',
    dialogtransferbizhong: 'Валюта',
    dialogtransfercong: 'от',
    dialogtransferdao: 'приезжать',
    dialogtransfershuliang: 'количество',
    dialogtransferqingshuruhuazhuanshuliang: 'Пожалуйста, введите сумму перевода',
    dialogtransferquanbu: 'все',
    dialogtransferkeyong: 'Доступный',
    dialogtransferhuazhuan: 'передача',
    dialogtransferjichuzhanghu: 'Базовый аккаунт',
    dialogtransferheyuezhanghu: 'Контрактный счет',
    dialogfitlosszhiyingzhisun: 'Тейк-профит и стоп-лосс',
    dialogfitlossheyu: 'договор',
    dialogfitlossduokong: 'Длинный/Короткий',
    dialogfitlosschichangjunjia: 'средняя цена позиции',
    dialogfitlossshuliang: 'количество',
    dialogfitlosszuixinjia: 'последняя цена',
    dialogfitlosszhiyingdianshu: 'Тейк-профит пунктов',
    dialogfitlosszhisundianshu: 'Стоп-лосс точки',
    dialogfitlossdianshu: 'счет',
    dialogfitlosszhiyingjiage: 'Цена тейк-профита',
    dialogfitlosszhisunjiage: 'Цена стоп-лосса',
    dialogfitlossjiage: 'цена',
    dialogfitlossyuqishouyi: 'ожидаемая доходность',
    dialogfitlossyuqikuisun: 'ожидаемая потеря',
    dialogfitlosstishi1: 'Совет: Минимальное колебание текущего контракта составляет',
    dialogfitlosstishi2: ', пожалуйста, установите цену на 0, чтобы отменить тейк-профит и стоп-лосс.',
    dialogfitlossqueding: 'Конечно',
    dialogfitlossduo: 'много',
    dialogfitlosskong: 'нулевой',
    dialogconditionzengjiayuntiaojiandan: 'Добавить таблицу состояния облаков',
    dialogconditiontiaojianleixing: 'Тип условия:',
    dialogconditionjiagetiaojiandan: 'Условия цен',
    dialogconditionshijiantiaojiandan: 'Таблица условий времени',
    dialogconditionjiageleixing: 'Тип цены:',
    dialogconditionzuixinjia: 'последняя цена',
    dialogconditionByijia: 'купить одну цену',
    dialogconditionSyijia: 'Продать одну цену',
    dialogconditiontiaojianshezhi: 'Настройки условий:',
    dialogconditionjiageshezhi: 'Настройки цен:',
    dialogconditionqingshurujiage: 'Пожалуйста, введите цену',
    dialogconditionshijianshezhi: 'Настройка времени:',
    dialogconditionjiaoyileixing: 'Тип транзакции:',
    dialogconditionkaiduo: 'Открыто долго',
    dialogconditionpingkong: 'плоское небо',
    dialogconditionkaikong: 'Открыть короткую позицию',
    dialogconditionpingduo: 'Пиндуо',
    dialogconditiondingdanshuliang: 'Количество заказа:',
    dialogconditionqingshurudingdanshuliang: 'Пожалуйста, введите количество заказа',
    dialogconditiondingdanshixiao: 'Время заказа:',
    dialogconditionyongjiuyouxia: 'Действует постоянно',
    dialogconditiontijiao: 'представлять на рассмотрение',
    dialogAutoProfitLosszhiyingzhisun: 'Тейк-профит и стоп-лосс',
    dialogAutoProfitLossmingcheng: 'имя',
    dialogAutoProfitLosszhiyingdian: 'Точка фиксации прибыли',
    dialogAutoProfitLosszhisundian: 'Точка стоп-лосса',
    dialogAutoProfitLosschongzhi: 'перезагрузить',
    dialogAutoProfitLoss: 'представлять на рассмотрение',
    dialogCloseOrderpingcang: 'Закрыть',
    dialogCloseOrderweituoleixing: 'Тип делегата',
    dialogCloseOrderqingxuanze: 'Пожалуйста, выберите',
    dialogCloseOrdershijia: 'рыночная цена',
    dialogCloseOrderxianjia: 'ценовой предел',
    dialogCloseOrderjiage: 'цена',
    dialogCloseOrderqingshurujiage: 'Пожалуйста, введите цену',
    dialogCloseOrdershuliang: 'количество',
    dialogCloseOrderqingshurushuliang: 'Пожалуйста, введите количество',
    dialogCloseOrderzhang: 'открыть',
    dialogCloseOrderkepingshuliang: 'Количество, которое можно выровнять',
    dialogCloseOrderqueding: 'Конечно',
    jsMessageResgaiheyuegangganxiugaichenggong: 'Кредитное плечо контракта было успешно изменено',
    jsMessageResgaiheuyegangganxiugaishibai: 'Модификация кредитного плеча контракта не удалась',
    jsMessageReskaicangchenggong: 'Позиция открыта успешно',
    jsMessageReskaicangshibai: 'Открытие позиции не удалось:',
    jsMessageReskaicang1: 'Открытие позиции запрещено',
    jsMessageReskaicang2: 'Контракт не существует',
    jsMessageReskaicang3: 'Количество открытых позиций превышает верхний предел',
    jsMessageReskaicang4: 'Цена 0',
    jsMessageReskaicang5: 'Недостаточно маржи для открытия позиции',
    jsMessageRespingcangchenggong: 'Позиция успешно закрыта',
    jsMessageRespingcangshibai: 'Не удалось закрыть:',
    jsMessageRespingcang1: 'Контракт не существует',
    jsMessageRespingcang2: 'Квитанция о позиции не существует',
    jsMessageRespingcang3: 'Недостаточная сумма',
    jsMessageReszijinhuazhuanchenggong: 'Перевод средств успешен',
    jsMessageReszijinhuazhuanshibai: 'Перевести средства не удалось',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'Стоп-профит и стоп-лосс позиционного ордера установлены успешно.',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'Не удалось настроить стоп-профит и стоп-лосс для позиционных ордеров:',
    jsMessageResweituodanchedanchenggong: 'Заказ успешно отменен',
    jsMessageResweituodanchedanshibai: 'Отменить заказ не удалось',
    jsMessageRestiaojiandantianjiachenggong: 'Условный заказ успешно добавлен',
    jsMessageResheyuebucunzai: 'Контракт не существует',
    jsMessageResjiageshezhicuowu: 'Ошибка установки цены',
    jsMessageResshijianshezhicuowu: 'Ошибка установки времени',
    jsMessageResneibucuowu: 'Внутренняя ошибка',
    jsMessageRestiaojiandantianjiashibai: 'Не удалось добавить условный порядок:',
    jsMessageReszhiyingzhisunshezhichenggong: 'Тейк-профит и стоп-лосс успешно установлены',
    jsconfirmquedingchedanma: 'Вы уверены, что хотите отменить заказ?',
    jsconfirmtishi: 'намекать',
    jsconfirmqueding: 'Конечно',
    jsconfirmquxiao: 'Отмена',
    jsMessageqingxiandenglu: 'Пожалуйста, сначала войдите в систему!',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'Этот контракт имеет открытые позиции, и кредитное плечо не может быть изменено.',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'Этот контракт имеет мандат, и кредитное плечо не может быть изменено.',
    jsMessageqingshuruzhengquedeshuliang: 'Пожалуйста, введите правильное количество!',
    jsMessageqingshuruzhengquedejiage: 'Пожалуйста, введите правильную цену!',
    jsMessageqingxianxuanzechicang: 'Пожалуйста, сначала выберите позицию!',
    jsMessageqingshuruhuazhuanshuliang: 'Пожалуйста, введите сумму перевода!',
    jsMessageqingxianxuanzeheyue: 'Пожалуйста, сначала выберите договор!',
    jsMessageqingshurutiaojiandanshuliang: 'Пожалуйста, введите условный объем заказа!',
    jsMessageqingshurutiaojiandandejiage: 'Пожалуйста, введите стоимость условного заказа!',
    jsMessageqingshurutiaojiandanzhixingshijian: 'Пожалуйста, укажите срок исполнения условного ордера!',
    emptytext: 'Данных пока нет',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'Тейк-профит и стоп-лосс установлены неправильно.',
    jsMessageReschicangdanbucunzai: 'Квитанция о позиции не существует',
    zxkcje:'Минимальная сумма открытия'
    },
    commisson:{
    dlzx:'Агентский центр',
    sjzl:'Обзор данных',
    quanbu:"все",
    jinri:'сегодня',
    zuori:'вчера',
    ri:'день',
    wdfy:'Моя скидка',
    wdsjfy:'Моя скидка третьего уровня',
    dengji:'оценка',
    yqrs:'Количество приглашенных',
    tdrs:'Размер команды',
    yqxq:'Детали приглашения',
    yhm:"имя пользователя",
    cjsj:'время создания',
    fyxq:'Подробности о скидках',
    zyj:'общая комиссия',
    dryj:'Комиссия за один день',
    fyje:'Сумма скидки',
    
    },
    assets: {
    zongzichan: 'общие активы',
    chongbi: 'пополнить',
    tibi: 'Вывод монет',
    huazhuan: 'передача',
    fabizhanghu: 'Базовый аккаунт',
    heyuezhanghu: 'Контрактный счет',
    jiayiliushui: 'поток транзакций',
    dialogzichanhuazhuan: 'Передача активов',
    cong: 'от',
    dao: 'приезжать',
    shuliang: 'количество',
    qingshuruhuazhuanshuliang: 'Пожалуйста, введите сумму перевода',
    quanbu: 'все',
    keyong: 'Доступный',
    huanzhuan: 'передача',
    zijinhuazhuanchenggong: 'Перевод средств успешен',
    zijinhuazhuanshibai: 'Перевести средства не удалось',
    bibizhanghu: 'Монетный счет',
    qiquanzhanghu:'опционный счет',
    zczl:'Обзор активов',
    zzczh:'Общая скидка на активы',
    ztzc:"Активы в пути",
    djzc:'Заморозить активы',
    zyzc:'Заложенное имущество',
    cwzc:'Позиция активов',
    jyzh:'торговый счет',
    bizhong:'Валюта',
    dongjie:'заморозить',
    lirun:"выгода",
    hyzh:"Контрактный счет",
    },
    basicsAccountRecord: {
    fanhui: 'возвращаться',
    fabizhanghuliushui: 'Поток торгового счета',
    fabijiaoyiliushuileixing: 'Тип потока транзакций',
    qingxuanze: 'Пожалуйста, выберите',
    chaxunriqi: 'Дата запроса',
    liushuileixing: 'Тип трубопровода',
    shijian: 'время',
    biandongjine: 'Изменить сумму',
    yue: 'Баланс',
    quanbu: 'все',
    Sellbi: 'продать монеты',
    Buybi: 'купить монеты',
    tibi: 'Вывод монет',
    chongbi: 'пополнить',
    fabizhuanheyue: 'Базовый трансферный контракт',
    heyuezhuanfabi: 'Контракт на базу',
    zhuanzhang: 'передача',
    fabihuazhuandaoheyue: 'Базовый переход на контракт',
    heyuehuazhuandaofabi: 'Передача контракта на базу',
    goumaijijin: 'Купить пул для майнинга',
    jijinshuhui: 'Погашение майнинг-пула',
    jijinshouyi: 'Доход от погашения майнинг-пула',
    fabizhuanqihuo: 'Базовая конвертация во фьючерсы',
    qihuozhuanfabi: 'Трансфертная база фьючерса',
    fabizhuanganggu: 'Базовый перевод в акции Гонконга',
    gangguzhuanfabi: 'Основы перемещения акций в Гонконге',
    fabizhuanbibi: 'Основная валюта перевода',
    bibizhuanfabi: 'Основы перевода валюты в валюту',
    goumaiSGCB: 'Купить ICO',
    chushouSGCB: 'Продажа ICO',
    huoqushibai: 'Не удалось получить',
    zanwushuju: 'Данных пока нет',
    },
    contractAccountRecord: {
    fanhui: 'возвращаться',
    heyuezhanghuliushui: 'Поток контрактного счета',
    heyuejiaoyiliushuileixing: 'Тип потока транзакций контракта',
    qingxuanze: 'Пожалуйста, выберите',
    chaxunriqi: 'Дата запроса',
    liushuileixing: 'Тип трубопровода',
    shijian: 'время',
    biandongjine: 'Изменить сумму',
    yue: 'Баланс',
    quanbu: 'все',
    kaicangshouxufei: 'Плата за открытие',
    pingcangshouxufei: 'Плата за закрытие',
    pingcangyingkui: 'Прибыль и убыток при закрытии позиции',
    fabizhuanheyue: 'Базовый трансферный контракт',
    heyuezhuanfabi: 'Контракт на базу',
    zhuanzhang: 'передача',
    kaicangyongjin: 'Комиссия по открытию',
    pingcangyongjin: 'Закрытие комиссии',
    huoqushibai: 'Не удалось получить',
    zanwushuju: 'Данных пока нет',
    },
    recharge: {
    zanwushuju: 'Данных пока нет',
    shijian: 'время',
    leixing: 'тип',
    shuliang: 'количество',
    zhuangtai: 'состояние',
    caozuo: 'действовать',
    chakanxiangqing: 'проверьте детали',
    huoqushibai: 'Не удалось получить',
    dialogtitle: 'Подробности',
    chongbi: 'пополнить',
    fanhui: 'возвращаться',
    lianmingcheng: 'Название сети',
    fuzhilianjie: 'Копировать адрес',
    tishixinxi1: '• Не вносите активы, отличные от TRC_USDT или ERC_USDT, по указанному выше адресу, иначе активы не будут получены.',
    tishixinxi2: '• После пополнения баланса на указанный выше адрес требуется подтверждение всего узла сети. Счет будет получен после 1 подтверждения сети, а валюту можно будет вывести после 2 подтверждений сети.',
    tishixinxi3: '• Минимальная сумма депозита: 50 долларов США. Депозиты меньше минимальной суммы не зачисляются и не подлежат возврату.',
    tishixinxi4: '• Ваш адрес пополнения счета не будет часто меняться и может быть пополнен повторно, в случае каких-либо изменений мы постараемся уведомить вас об этом посредством объявлений на веб-сайте или по электронной почте;',
    tishixinxi5: '• Убедитесь, что ваш компьютер и браузер защищены, чтобы предотвратить подделку или утечку информации.',
    putongchongbi: 'Обычный депозит',
    jiaoyichenggong: 'Транзакция успешна',
    jiaoyishibai: 'Транзакция не удалась',
    OMNIweihuzhong: 'Цепь OMNI находится на техническом обслуживании, выберите TRC20',
    },
    withdraw: {
    fanhui: 'возвращаться',
    zanwushuju: 'Данных пока нет',
    tibi: 'Вывод монет',
    huoqushibai: 'Не удалось получить',
    OMNIweihuzhong: 'Цепь OMNI находится на техническом обслуживании, выберите TRC20',
    shijian: 'время',
    leixing: 'тип',
    shuliang: 'количество',
    zhuangtai: 'состояние',
    caozuo: 'действовать',
    chakanxiangqing: 'проверьте детали',
    putongtibi: 'Обычный вывод',
    dialogtitle: 'Подробности',
    dialogtitlewithdraw: 'Вывод монет',
    houchongxinfasong: 'Отправить повторно после S',
    huoquyouxiangyanzhengma: 'Получить код подтверждения электронной почты',
    lianmingcheng: 'Название сети',
    tibidizhi: 'Адрес вывода',
    qingshurutibidizhi: 'Пожалуйста, введите адрес вывода',
    tibishuliang: 'Сумма выведенных монет',
    shouxufei: 'плата за обработку',
    qingshurutibishuliang: 'Пожалуйста, введите сумму вывода',
    quanbu: 'все',
    keyong: 'Доступный',
    daozhangshuliang: 'Количество прибытия',
    zuixiaotibishuliang: 'Минимальная сумма вывода составляет:',
    tishixinxi: 'Чтобы обеспечить безопасность средств, в случае изменения политики безопасности вашей учетной записи или изменения вашего пароля мы проведем ручную проверку вывода средств. Наберитесь терпения и подождите, пока сотрудники свяжутся с вами по телефону или электронной почте. Убедитесь, что ваш компьютер и браузер защищены, чтобы предотвратить подделку или утечку данных.',
    tuxingyanzhengma: 'Графический код проверки',
    qingshurutuxingyanzhengma: 'Пожалуйста, введите графический проверочный код',
    youxiangyanzhengma: 'Код подтверждения электронной почты',
    queding: 'Конечно',
    tijiaozhong: 'Отправка',
    yijujue: 'Отклоненный',
    yitongguo: 'Прошедший',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Не удалось получить графический проверочный код, запросите еще раз.',
    huoquyouxiangyanzhengmashibai: 'Не удалось получить код подтверждения электронной почты,',
    qingxianwanchengyouxiangrenzheng: 'Пожалуйста, сначала завершите проверку электронной почты',
    huoquyouxiangshibaiqingchongxinqingqiu: 'Не удалось получить адрес электронной почты, запросите еще раз',
    qingshuruyouxiangyanzhengma: 'Пожалуйста, введите код подтверждения электронной почты',
    youxiangyanzhengmacuowu: 'Код подтверждения электронной почты неверен,',
    qxsmrz:'Пожалуйста, сначала подтвердите свое настоящее имя',
    tishi:'намекать',
    queding:'Конечно'
    },
    contractTransactionRecord: {
    fanhui: 'возвращаться',
    zanwushuju: 'Данных пока нет',
    heyuedingdan: 'контрактный заказ',
    qingxuanze: 'Пожалуйста, выберите',
    quanbu: 'все',
    kaiduo: 'Открыто долго',
    pingkong: 'плоское небо',
    kaikong: 'Открыть короткую позицию',
    pingduo: 'Пиндуо',
    xianjiaweituo: 'лимитный ордер',
    shijiaweituo: 'рыночный ордер',
    suoyouzhuangtai: 'Все статусы',
    tijiaoshibai: 'Отправка не удалась',
    zhunbeitijiao: 'готов представить',
    yitijiao: 'Поданный',
    bufentijiao: 'Частичная сделка',
    bufentijiaoyichedan: 'Некоторые заказы отменены',
    quanbuchengjiao: 'Все транзакции',
    yichedan: 'Заказ отменен',
    huoqushibai: 'Не удалось получить',
    heyuedaima: 'Код контракта',
    ganggan: 'рычаг',
    xiadanshijian: 'Время заказа',
    chengjiaoshijian: 'Время транзакции',
    fangxiang: 'направление',
    baojialeixing: 'Тип котировки',
    weituoliang: 'Размер комиссии (штук)',
    weituojia: 'Цена ордера (USDT)',
    chengjiaoliang: 'Объем торгов (билеты)',
    chengjiaojunjia: 'Средняя цена сделки (USDT)',
    yingkui: 'Прибыль и убыток (USDT)',
    shouxufei: 'Плата за обработку (USDT)',
    zhuangtai: 'состояние',
    gupiaodingdan: 'заказ на склад',
    waihuidingdan: 'Форекс ордера',
    gupiaodaima: 'Код акции',
    waihuidaima: 'Форекс код',
    },
    kLine: {
    zhibiao: 'индекс',
    shezhi: 'Настройка',
    quanping: 'Полный экран',
    tuichuquanping: 'Выйти из полноэкранного режима',
    jishuzhibiao: 'Технические индикаторы',
    futuzhibiao: 'Индикатор дополнительного изображения',
    zhutuzhibiao: 'Основной индикатор графика',
    huifumoren: 'Восстановить настройки по умолчанию',
    lazhutuleixing: 'Типы свечей',
    qingxuanze: 'Пожалуйста, выберите',
    quanshixin: 'Все твердое',
    quankongxin: 'Полностью полый',
    zhangkongxin: 'восходящая пустота',
    diekongxin: 'провалиться',
    mianjitu: 'диаграмма областей',
    jiagezhouleixing: 'Тип ценовой оси',
    xianxingzhou: 'ось линии',
    baifenbizhou: 'Процентная ось',
    duishuzhou: 'Логарифмическая ось',
    zuigaojiaxianshi: 'Дисплей самой высокой цены',
    zuidijiaxianshi: 'Дисплей самой низкой цены',
    zuixinjiaxianshi: 'Отображение последней цены',
    zhibiaozuixinzhixianshi: 'Отображается последнее значение индикатора',
    daozhizuobiao: "инвертированные координаты",
    wanggexianxianshi: 'Отображение линий сетки',
    hfcg:'Восстановление успешно'
    },
    SecurityCenter: {
    fanhui: 'возвращаться',
    anquanzhongxin: 'Центр безопасности',
    qingshurunicheng: 'Пожалуйста, введите псевдоним',
    nichengxiugaishibai: 'Изменение псевдонима не удалось',
    huoquyonghuxinxishibai: 'Не удалось получить информацию о пользователе.',
    xiugaidenglumima: 'Изменить пароль для входа',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Не удалось получить графический проверочный код, запросите еще раз.',
    qingshurujiumima: 'Пожалуйста, введите старый пароль',
    qingshuruxinmima: 'Пожалуйста, введите новый пароль',
    qingzaicishuruxinmima: 'Пожалуйста, введите новый пароль еще раз',
    qingshurutuxingyanzhengma: 'Пожалуйста, введите графический проверочный код',
    qingshuruyanzhengma: 'Пожалуйста, введите проверочный код',
    huoquzhuceshoujihaoshibai: 'Не удалось получить зарегистрированный номер мобильного телефона',
    qingxianwanchengshoujirenzheng: 'Пожалуйста, сначала завершите аутентификацию на мобильном телефоне.',
    huoqushoujiyanzhengmashibai: 'Не удалось получить код подтверждения мобильного телефона.',
    huoqushoujiyanzhengma: 'Получить код подтверждения мобильного телефона',
    houchongxinfasong: 'Отправить повторно после S',
    mimacuowu: 'Неправильный пароль',
    liangcixinmimashurubuyizhiqingjiancha: 'Два введенных новых пароля несовместимы, проверьте',
    xinmimaheyuanmimabunengyiyang: 'Новый пароль не может совпадать с исходным паролем.',
    qingshurushoujiyanzhengma: 'Пожалуйста, введите код подтверждения мобильного телефона',
    yanzhengmacuowu: 'Ошибка кода подтверждения',
    denglumimaxiugaichenggong: 'Пароль для входа успешно изменен',
    denglumimaxiugaishibai: 'Изменение пароля для входа не удалось',
    xiugaizijinmima: 'Сменить пароль фонда',
    qingshuruzijinmima: 'Пожалуйста, введите пароль фонда',
    qingzaicishuruzijinmima: 'Пожалуйста, введите пароль фонда еще раз',
    liangcizijinmimabuyizhiqingjiancha: 'Два пароля фонда несовместимы, пожалуйста, проверьте',
    shezhizijinmimachenggong: 'Пароль фонда успешно установлен.',
    shezhizijinmimashibai: 'Не удалось установить пароль фонда.',
    xiugaizijinmimachenggong: 'Пароль фонда успешно изменен',
    xiugaizijinmimashibai: 'Не удалось изменить пароль фонда.',
    youxiangrenzheng: 'Аутентификация по электронной почте',
    huoquyouxiangyanzhengma: 'Получить код подтверждения электронной почты',
    qingshuruyouxiangdizhi: 'Пожалуйста, введите адрес электронной почты',
    huoquyouxiangyanzhengmashibai: 'Не удалось получить код подтверждения электронной почты.',
    qingshuruyouxiangyanzhengma: 'Пожалуйста, введите код подтверждения электронной почты',
    youxiangyanzhengchenggong: 'Проверка электронной почты прошла успешно',
    weirenzheng: 'Не сертифицирован',
    queding: 'Конечно',
    anquanshezhiguanli: 'Управление настройками безопасности',
    denglumima: 'Пароль для входа',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'Установив пароль для входа, вы сможете войти в систему напрямую, используя пароль своей учетной записи.',
    xiugai: 'Исправлять',
    zijinmima: 'Пароль фонда',
    yongyubaohuzijinanquan: 'Используется для защиты сохранности средств.',
    shezhi: 'настраивать',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'Используется для проверки безопасности при входе в систему, снятии монет, получении паролей и изменении настроек безопасности.',
    yirenzheng: 'Проверенный',
    renzheng: 'Сертификация',
    shimingrenzheng: 'Аутентификация по настоящему имени',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'Используется для аутентификации безопасности при покупке, продаже, снятии, внесении и переводе монет.',
    daishenhe: 'Ожидает рассмотрения',
    yibohui: 'Уволен',
    yuandenglumima: 'Исходный пароль для входа',
    xinmima: 'Новый пароль',
    quedingxinmima: 'Подтвердите новый пароль',
    tuxingyanzhengma: 'Графический код проверки',
    yanzhengma: 'Проверочный код',
    zijinmima: 'Пароль фонда',
    querenzijinmima: 'Подтвердите пароль фонда',
    youxiangdizhi: 'Адрес электронной почты',
    qingxianwanchengyouxiangrenzheng: 'Пожалуйста, сначала завершите проверку электронной почты',
    xiugaitouxiangchenggong: 'Изменен аватар успешно',
    xiugaitouxiangshibai: 'Не удалось изменить аватар',
    shangchuantouxiangzhinengshijpggeshi: 'Загружать картинки аватарки можно только в формате JPG!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'Размер загружаемого изображения аватара не может превышать 2 МБ!',
    },
    verification: {
    fanhui: 'возвращаться',
    shimingrenzheng: 'Аутентификация по настоящему имени',
    qingshuruxingming: 'Пожалуйста, введите имя',
    qingshuruzhengjianhaoma: 'Пожалуйста, введите свой идентификационный номер',
    huoquyonghuxinxishibai: 'Не удалось получить информацию о пользователе.',
    wenjiangeshicuowu: 'Ошибка формата файла',
    wenjianbunengdayu5M: 'Размер файла не может превышать 5 М.',
    qingtianxiexingming: 'Пожалуйста, введите свое имя',
    qingtianxiezhengjianhaoma: 'Пожалуйста, введите идентификационный номер',
    qingshangchuanzhengjianzhengmian: 'Пожалуйста, загрузите лицевую сторону вашего удостоверения личности',
    qingshangchuanzhengjianfanmian: 'Пожалуйста, загрузите обратную сторону вашего удостоверения личности',
    tijiaochenggong: 'Отправка успешна',
    kaiqishenfenrenzheng: 'Включите аутентификацию личности',
    bohuiyuanyin: 'Причина отказа',
    qingtianxiejiashizhizhaogongminkahuzhao: 'Пожалуйста, заполните данные своих водительских прав/карты гражданина/паспорта.',
    xingming: 'Имя',
    zhengjianhaoma: 'идентификационный номер',
    qingshangchuanjiashizhizhaogongminkahuzhao: 'Пожалуйста, загрузите данные водительского удостоверения/карты гражданина/паспорта.',
    shangchuanzhengjianzhengmian: 'Загрузите лицевую сторону удостоверения личности',
    huotuozhuafangru: 'Или перетащите',
    shangchuanzhengjianfanmian: 'Загрузите обратную сторону сертификата',
    tijiao: 'представлять на рассмотрение',
    },
    invite: {
    yaoqinghaoyoudejiangli: 'Приглашайте друзей и получайте награды',
    yongjiukede: 'постоянно доступен',
    jiaoyifanyong: 'Торговая скидка',
    fasongyaoqinggeihaoyou: 'Отправить приглашение друзьям',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'Пригласите друзей зарегистрироваться BCFX, поделившись ссылкой',
    haoyouzhuce: 'Регистрация друга',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'Друг принимает приглашение, завершает регистрацию и проводит транзакции.',
    huodexiangyingbilijiangli: 'Получите соответствующие пропорциональные вознаграждения',
    qingsonghuodejiaoyishouxufeijianglifuli: 'Легко получайте бонусы за комиссию за транзакцию',
    wodezhuanshufenxiangfangshi: 'Мой эксклюзивный способ поделиться',
    yaoqinglianjie: 'Ссылка для приглашения',
    jiazaizhong: 'загрузка...',
    fuzhilianjie: 'Скопировать ссылку',
    yaoqingma: 'Пригласительный код',
    fuzhiyaoqingma: 'Скопировать код приглашения',
    wodeyaoqing: 'мое приглашение',
    yaoqingrenshu: 'Количество приглашенных',
    huodejiangli: 'Получите вознаграждение',
    huodongxize: 'Подробности мероприятия',
    tips1: 'Отношения между пользователями платформы имеют три уровня, например: пользователи ABCD составляют три уровня пользователей, а A — самый высокий уровень!',
    tips2: 'Отношения интересов между уровнями',
    tips201: '。',
    tips3: 'Денежное вознаграждение: Клиент A может получить 40% комиссии за транзакцию по контракту Клиента B + 20% комиссии за транзакцию по контракту Клиента C + 10% комиссии за транзакцию по контракту Клиента D',
    tips4: 'Например: у клиента A есть 10 клиентов первого уровня B, 10 клиентов второго уровня C и 10 клиентов третьего уровня D. Если каждый контракт клиента торгуется на сумму 100 000 долларов США.',
    tips5: 'Тогда доход, который может получить клиент А,',
    tips6: '100000*1,2%*40%*10+100000*1,2%*20%*10+100000*1,2%*10%*10=денежное вознаграждение в размере 8400 долларов США, которое можно вывести в режиме реального времени.',
    tips7: 'Если деятельность будет скорректирована, она будет подлежать обновлениям платформы BCFX, и окончательное право на интерпретацию будет принадлежать BCFX.',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Не удалось получить графический проверочный код, запросите еще раз.',
    qingshuruyouxiang: 'Пожалуйста, введите свой адрес электронной почты',
    qingshurutuxingyanzhengma: 'Пожалуйста, введите графический проверочный код',
    qingshuruyanzhengma: 'Пожалуйста, введите проверочный код',
    qingshurunindemima: 'Пожалуйста, введите свой пароль',
    qingzaicishurunindemima: 'Пожалуйста, введите свой пароль еще раз',
    qingshuruzhifumima: 'Пожалуйста, введите платежный пароль',
    xuantian: 'Необязательный',
    bitian: 'Необходимый',
    yonghuzhuce: 'Регистрация пользователя',
    huanyingdenglu: 'Добро пожаловать, чтобы войти',
    guanfangwangzhan: 'официальный сайт',
    xiangmumingcheng: 'BCFX',
    youxiang: 'Почта',
    tuxingyanzhengma: 'Графический код проверки',
    yanzhengma: 'Проверочный код',
    huoquyanzhengma: 'Проверочный код',
    shezhimima: 'Установить пароль',
    quedingmima: 'Подтвердите пароль',
    zhifumima: 'Пароль платежа',
    yaoqingma: 'Пригласительный код',
    zhuce: 'зарегистрироваться',
    yiyouzhanghao: 'У вас уже есть аккаунт?',
    denglu: 'Авторизоваться',
    qingshuruyouxiangdizhi: 'Пожалуйста, введите адрес электронной почты',
    huoqushibai: 'Не удалось получить',
    houchongxinfasong: 'Отправить повторно после S',
    liangcishurudemimabuyizhi: 'Пароли, введенные дважды, несовместимы',
    zhucechenggong: 'Регистрация прошла успешно',
    zhuceshibai: 'Регистрация не удалась',
    },
    treasure:{
    jichubizhong: 'базовая валюта',
    shouyibizhong: 'Валюта дохода',
    wakuangtianshu: 'Дни горного дела',
    shouyitianshu: 'Дни заработка',
    suodingtianshu: 'Блокировка дней',
    yichanshengshouyi: 'Полученный доход',
    yuqishouyi: 'ожидаемая доходность',
    kaishishijian: 'время начала',
    jieshushijian: 'время окончания',
    qingshuruninyaosuodingdebizhongshuliang: 'Пожалуйста, введите сумму USDT в валюте, которую вы хотите заблокировать.',
    huoqushibai: 'Не удалось получить',
    meiyitian: 'каждый 1 день',
    dakai: 'Открыть',
    yuanyuzhoukuangchi: 'Пул для майнинга Метавселенной',
    jilu: 'Записывать',
    kuangchixiangqing: 'Подробности о майнинг-пуле',
    suo: 'Замок',
    yitianchanchu: 'вывод за 1 день',
    zuixiaosuodingshijian: 'Минимальное время блокировки',
    nindeUSDT: 'Ваш доллар США',
    tian: 'небо',
    quanbu: 'все',
    suoUSDTbingkaishishengchan: 'Заблокируйте USDT и начните производство',
    ninquedingyaosuoUSDTkaishishengchanma: 'Вы уверены, что хотите заблокировать USDT и начать производство?',
    tishi: 'намекать',
    queding: 'Конечно',
    quxiao: 'Отмена',
    kaishishengchan: 'Запускаем производство!',
    yiquxiao: 'Отменено',
    },
    subscription:{
    rengouliebiao:'Список подписки',
    jijiangdaolai:'Вскоре',
    jinxingzhong:'в ходе выполнения',
    quanbu:'все',
    keshiyonghuobi:"Доступные валюты",
    zongshuliang:'общее количество',
    meiyouxianzhi:'без ограничений',
    shengyushuliang:'Оставшееся количество',
    kaishishijian:'время начала',
    zhuangtai:'состояние',
    jilu:'Записывать',
    zanwujilu:"Пока нет записей",
    leixing:'тип',
    shuliang:'количество',
    goumaijine:'Сумма покупки',
    shijian:'время',
    goumai:'Купить',
    duichu:'Из ниоткуда',
    jiangli:'награда',
    tibi:'Вывод монет',
    huidaomulu:'Вернуться к оглавлению',
    dangqianchiyou:'в настоящее время проводится',
    keyong:'Доступный',
    keduihuan:'погашаемый',
    shishihangqing:'Котировки в реальном времени',
    yue:'Баланс',
    biaoti:'заголовок',
    fashoushuliang:'Количество на продажу',
    jindu:"расписание",
    goumaishuliang:'Количество покупки',
    ge:'индивидуальный',
    woyiyuedu:'я прочитал',
    daibigoumaixieyi:'Соглашение о покупке токена',
    qingshurugoumaijine:'Пожалуйста, введите сумму покупки',
    shifoyaogoumai:'Вы хотите купить',
    tishi:'намекать',
    queding:'Конечно',
    quxiao:'Отмена',
    yjs:'закончился',
    ywc:'Завершенный',
    sgjg:'Стоимость подписки',
    jssj:"время окончания",
    zqsl:"Выигрышное количество",
    zqje:"Сумма выигрыша",
    tian:"небо",
    shi:'час',
    fen:'точка',
    miao:'Второй',
    shengou:'Подписка',
    xmxq:'Детали проекта',
    },
    }