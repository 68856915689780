export default {
    public:{
        zhd:"to, co najlepsze",
        qsjyh:"Użytkownicy na całym świecie",
        gtdxz:'wspólny wybór',
        szhbjyytz:'Szanse i wyzwania związane z walutą cyfrową',
        xsddcf:'Szanse i wyzwania związane z bogactwem w nowej erze',
        yzsjypt:"Kompleksowa platforma handlowa",
        mskljyzh:'Otwórz konto handlowe i rozpocznij handel już teraz',
        jyhg:"Zgodność biznesowa",
    },

    header: {
    hello: 'Witaj świecie!',
    changeLanguage: 'zmień język',
    Login: 'Zaloguj się',
    Register: 'rejestr',
    Logout: 'Wyloguj się',
    shouye: 'strona tytułowa',
    bibijiaoyisuo: 'Handel kryptowalutami',
    heyuejiaoyisuo: 'handel kontraktowy',
    licaichanpin: 'Basen górniczy',
    rengou: 'Prenumerata',
    ruanjianxiazai: 'Pobieranie oprogramowania',
    zhongwen: 'chiński',
    yingwen: 'W',
    zichan: 'aktywa',
    dingdan: 'Zamówienie',
    heyuedingdan: 'zlecenie kontraktowe',
    bibidingdan: 'Kolejność monet',
    anquanzhongxin: 'Centrum bezpieczeństwa',
    fanyongyaoqing: 'Zaproszenie Komisji',
    tuichudenglu: 'Wyloguj się',
    gupiaojiaoyi: 'handel akcjami',
    waihuijiaoyi: 'Handel na rynku Forex',
    qiquanjiaoyi:'handel opcjami',
    gupiaodingdan: 'zamówienie na magazynie',
    waihuidingdan: 'Zamówienia na rynku Forex',
    qiquandingdan: 'kolejność opcji',
    jiaoyidingdan:'zlecenie handlowe',
    daolizhongxin:'Centrum Agencji',
    huodong:'Działalność'
    },
    home: {
    huanyinglaidao: 'witaj w',
    pingtaimingcheng: 'Tutaj',
    mengxiangqihang: 'Marzenia odpłynęły',
    yijiankaiqishuzihualvcheng: 'Rozpocznij swoją cyfrową podróż jednym kliknięciem',
    liaojiegengduo: 'dowiedz się więcej',
    chongzhi: 'uzupełniać',
    jiaoyi: 'handel',
    remenbang: 'Popularna lista',
    zhangfubang: 'Lista zdobywców',
    diefubang: 'Lista przegranych',
    chengjiaoliang: 'Tom',
    zanwushuju: 'Brak danych',
    jiayidui: 'para handlowa',
    zuixinjiage: 'ostatnia cena',
    zhangdie24h: '24 godziny wzlotów i upadków',
    caozuo: 'działać',
    jiaoyi: 'handel',
    gengduo: 'Więcej',
    suishisuidikaiqijiaoyi: 'Rozpocznij handel w dowolnym miejscu i czasie',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'Niezależnie od tego, czy jest to aplikacja, czy internet, możesz szybko rozpocząć transakcję',
    saomaxiazaiApp: 'Zeskanuj kod QR, aby pobrać aplikację',
    iosheAndroid: 'iOS i Androida',
    ninkexinrenqieanquandejiamibijiaoyisuo: 'Twoja zaufana i bezpieczna giełda kryptowalut',
    tishi1: 'Certyfikat rezerwowy',
    xiangqing1: 'Obiecujemy zachować wszystkie zasoby użytkownika co najmniej w stosunku 1:1',
    tishi2: 'Najlepsze stawki transakcyjne',
    xiangqing2: 'Preferencyjne stawki transakcyjne, konkurencyjne prawa VIP i ciesz się usługami najwyższej jakości',
    tishi3: 'Bezpieczeństwo, na które możesz liczyć',
    xiangqing3: 'Nasze wyrafinowane środki bezpieczeństwa i fundusze SAFU chronią Twoje zasoby cyfrowe przed wszelkimi zagrożeniami',
    tishi4: 'Całodobowa obsługa klienta',
    xiangqing4: '24*7 godzin, tryb pracy w pełnym wymiarze godzin, odpowiadaj na odpowiednie pytania tak szybko, jak to możliwe',
    tishi5: 'Stabilny i bezpieczny',
    xiangqing5: 'Wiodąca na świecie giełda zapewnia ochronę Twoich aktywów na poziomie banku',
    tishi6: 'Często zadawane pytania',
    xiangqing6: 'Sprawdź często zadawane pytania, aby uzyskać szczegółowe opisy konkretnych funkcji',
    fanhui:"powrót",
    shishihangqing:'Cytaty w czasie rzeczywistym',
    },
    footer: {
    bangzhuzhongxin: 'Centrum pomocy',
    dianhuazixun: 'Konsultacja telefoniczna',
    dianhuahegongzuoshijian: '1670-9756 (10:00~19:00 w dni powszednie/nieczynne w weekendy i święta)',
    liaotianzixun: 'Konsultacje na czacie 1:1',
    keketuozixun: 'Konsulting Cocotok',
    zaixianbangzhu: 'Pomoc internetowa',
    h24kaifang: '(otwarte całą dobę)',
    lixianbangzhu: 'Pomoc offline',
    gongsidizhi: '1F, Yongsan Purgio Summit, 69 Hangang-gil, Yongsan-gu, Seul',
    gongzuoshijian: '(10:00~18:00 w dni powszednie / nieczynne w weekendy i święta)',
    shangshizixun: 'Konsultacje dotyczące aukcji',
    hezuozixun: 'Konsultacje współpracy',
    gongsi: 'firma',
    lianxiwomwn: 'Skontaktuj się z nami',
    zhidao: 'przewodnik',
    yonghuzhichi: 'Wsparcie użytkownika',
    zhanghuzhinan: 'Przewodnik po koncie – wpłata/wypłata',
    ruanjianxiazai: 'Pobieranie oprogramowania',
    zhengce: 'polityka',
    fuwuyuyinsitiaokuan: 'Warunki świadczenia usług i prywatność',
    },
    login: {
    yonghudenglu: 'Logowanie użytkownika',
    huanyingdenglu: 'Zapraszamy do logowania',
    xiangmumingcheng: 'BCFX',
    guanfangwangzhan: 'oficjalna strona internetowa',
    youxiang: 'Poczta',
    qingshuruyouxiang: 'Proszę wpisać swój adres e-mail',
    mima: 'hasło',
    qingshurumima: 'Proszę wprowadzić hasło',
    wangjimima: 'zapomnieć hasła?',
    denglu: 'Zaloguj się',
    haimeiyouzhanghao: 'Nie masz jeszcze konta? Iść',
    zhuce: 'rejestr',
    dengluchenggong: 'Logowanie powiodło się',
    denglushibai: 'Logowanie nie powiodło się',
    qingshuruyonghuminghemima: 'Proszę wprowadzić nazwę użytkownika i hasło',
    mimachangdubunengxiaoyuliuwei: 'Długość hasła nie może być krótsza niż 6 znaków',
    youxiangrenzheng: 'Weryfikacja e-mailowa',
    youxiangdizhi: 'Adres e-mail',
    qingshuruyouxiangdizhi: 'Proszę podać adres e-mail',
    tuxingyanzhengma: 'Graficzny kod weryfikacyjny',
    qingshurutuxingyanzhengma: 'Proszę wprowadzić graficzny kod weryfikacyjny',
    yanzhengma: 'Kod weryfikacyjny',
    qingshuruyouxiangyanzhengma: 'Wprowadź kod weryfikacyjny adresu e-mail',
    huoquyouxiangyanzhengma: 'Uzyskaj kod weryfikacyjny e-mail',
    queding: 'Jasne',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Nie udało się uzyskać graficznego kodu weryfikacyjnego. Poproś o ponowny wniosek',
    qingxianwanchengyouxiangrenzheng: 'Najpierw dokończ weryfikację e-mailową',
    huoquyouxiangyanzhengmashibai: 'Nie udało się uzyskać kodu weryfikacyjnego e-mailem,',
    houchongxinfasong: 'Wyślij ponownie po S',
    youxiangyanzhengchenggong: 'Weryfikacja e-mailowa powiodła się',
    yanzhengmacuowu: 'Błąd kodu weryfikacyjnego',
    xiugaidenglumima: 'Zmień hasło logowania',
    xinmima: 'Nowe hasło',
    quedingxinmima: 'Potwierdź nowe hasło',
    qingshuruxinmima: 'Proszę wprowadzić nowe hasło',
    qingzaicishuruxinmima: 'Proszę wprowadzić nowe hasło ponownie',
    liangcimimashurubuyizhi: 'Obydwa wprowadzone hasła są niespójne',
    mimaxiugaichenggong: 'Hasło zostało pomyślnie zmienione',
    xiugaimimashibai: 'Nie udało się zmienić hasła',
    },
    trade: {
    hangqingheyue: 'umowa',
    hangqingzuixinjia: 'ostatnia cena',
    hangqingzhangdiefu: 'Zwiększ lub zmniejsz',
    hangqingzuigao24h: 'Najwyżej 24 godziny',
    hangqingzuidi24h: 'Minimum 24 godziny',
    hangqingchengjiaoliang24h: 'Wolumen 24 godziny',
    hangqingchengjiabishu: 'Liczba transakcji',
    zhanghuquanyi: 'Prawa do konta',
    zhanghukeyongshouyi: 'Dostępne korzyści',
    hangqingweituoliebiao: 'Lista zamówień',
    hangqingjiage: 'cena',
    hangqingshuliang: 'ilość',
    hangqingzuixinchengjiao: 'ostatnia transakcja',
    hangqingjiage1: 'cena',
    hangqingshuliang1: 'ilość',
    hangqingshijian: 'czas',
    dingdanchiyoucangwei: 'Utrzymaj pozycję',
    dingdandangqianweituo: 'Aktualne zamówienie',
    dingdanchengjiaojilu: 'Zapis transakcji',
    dingdantiaojiandan: 'zamówienie warunkowe',
    chicangheyue: 'umowa',
    chicangkaicangjunjia: 'Cena pozycji',
chicangfudongyingkui: 'Zysk i strata',
chicanggangganbeishu: 'Wpływ',
    chicangchicangliang: 'Stanowisko (-a)',
    chicangduokong: 'Długie/krótkie',
    chicangduo: 'wiele',
    chicangkong: 'nieważny',
    chicangzhiyingjiage: 'Weź cenę zysku',
    chicangzhisunjiage: 'Cena zatrzymania straty',
    chicangqiangpingyugujiage: 'Szacunkowa cena likwidacji',
    chicangcaozuo: 'działać',
    chicangzhiyingzhisun: 'Zrealizuj zysk i zatrzymaj stratę',
    chicangpingcang: 'Zamknięta',
    chicangshouxufei: 'opłata manipulacyjna',
    weituoheyue: 'umowa',
    weituoshuliang: 'Ilość zamówienia',
    weituojiage: 'Cena prowizyjna',
    weituofangxiang: 'kierunek',
    weituogangganbeishu: 'Wykorzystaj wiele',
    weituochengjiaoshuliang: 'Ilość transakcji',
    weituoshouxufei: 'opłata manipulacyjna',
    weituochengjiaojunjia: 'Średnia cena',
    weituoshouyi: 'dochód',
    weituozhuangtai: 'państwo',
    weituochengjiaoshijian: 'Czas transakcji',
    weituocaozuo: 'działać',
    weituochedan: 'Anuluj zamówienie',
    weituotijiaoshibai: 'Przesyłanie nie powiodło się',
    weituozhilingtijiao: 'Złożenie instrukcji',
    weituoyitijiao: 'Złożony',
    weituobufenchengjiao: 'Częściowa umowa',
    weituobufenchengjiaoyichedan: 'Niektóre zamówienia zostały anulowane',
    weituowanquanchengjiao: 'Kompletna transakcja',
    weituowanquanchedan: 'Całkowicie anuluj zamówienie',
    weituochedanzhong: 'Anulowanie zamówienia',
    weituokaiduo: 'Otwórz długo',
    weituopingkong: 'płaskie niebo',
    weituokaikong: 'Otwórz krótko',
    weituopingduo: 'Pinduo',
    chengjiaoheyue: 'umowa',
    chengjiaofangxiang: 'kierunek',
    chengjiaoshuliang: 'Ilość transakcji',
    chengjiaojiage: 'cena transakcyjna',
    chengjiaoshijian: 'Czas transakcji',
    chengjiaopingcangyingkui: 'Zysk i strata',
    chengjiaoshouxufei: 'Opłata transakcyjna',
    chengjiaokaiduo: 'Otwórz długo',
    chengjiaopingkong: 'płaskie niebo',
    chengjiaokaikong: 'Otwórz krótko',
    chengjiaopingduo: 'Pinduo',
    tiaojianzengjiatiaojian: 'Dodaj warunki',
    tiaojianzantingquanbu: 'wstrzymaj wszystko',
    tiaojianqidongquanbu: 'Zacznij wszystko',
    tiaojianshanchuquanbu: 'Usuń wszystko',
    tiaojianheyue: 'umowa',
    tiaojianweituoshuliang: 'Ilość zamówienia',
    tiaojianfangxiang: 'kierunek',
    tiaojiantiaojianleixing: 'typ warunku',
    tiaojianjiageleixing: 'rodzaj ceny',
    tiaojianbijiaofuhao: 'Symbol porównania',
    tiaojiantiaojian: 'stan',
    tiaojianzhuangtai: 'państwo',
    tiaojianchuangjianshijian: 'czas stworzenia',
    tiaojiancaozuo: 'działać',
    tiaojianxiugai: 'Poprawiać',
    tiaojianqidong: 'rozpocząć',
    tiaojianzanting: 'pauza',
    tiaojianshanchu: 'usuwać',
    tiaojianlijixiadan: 'Zamów teraz',
    tiaojiankaiduo: 'Otwórz długo',
    tiaojianpingkong: 'płaskie niebo',
    tiaojiankaikong: 'Otwórz krótko',
    tiaojianpingduo: 'Pinduo',
    tiaojianjiagetiaojian: 'warunki cenowe',
    tiaojianshijiantiaojian: 'warunek czasowy',
    tiaojianzuixinjia: 'ostatnia cena',
    tiaojianByijia: 'kup jedną cenę',
    tiaojianSyijia: 'Sprzedaj jedną cenę',
    tiaojianzanting: 'pauza',
    tiaojianyunxing: 'uruchomić',
    tiaojianyiwancheng: 'Zakończony',
    tiaojianshibai: 'ponieść porażkę',
    tiaojianyishanchu: 'Usunięto',
    tiaojianshoudongchufawancheng: 'Ręczne wyzwalanie zakończone',
    tiaojianshoudongchufashibai: 'Ręczne wyzwalanie nie powiodło się',
    xiadankaicang: 'Otwórz ',
    xiadanpingcang: 'Zamknięta',
    xiadanweituoleixing: 'Typ',
    xiadanqingxuanze: 'Proszę wybrać',
    xiadanshijia: 'cena rynkowa',
    xiadanxianjia: 'limit cenowy',
    xiadanjiage: 'cena',
    xiadanqingshurujiage: 'Proszę wpisać cenę',
    xiadanshuliang: 'ilość',
    xiadanqingshurushuliang: 'Proszę wpisać ilość',
    xiadanzhang: 'Otwarte',
    xiadankekaishuliang: 'Dostępna ilość',
    xiadanqingxianxuanzechicangdan: 'Najpierw wybierz kolejność pozycji',
    xiadankepingshuliang: 'Ilość, którą można wyrównać',
    xiadansuoxubaozhengjin: 'Wymagany margines:',
    xiadanshijiazhiyingzhisunyushe: 'Cena rynkowa pozwala przyjąć zysk i ustawić stop loss',
    xiadanyujiqiangpingjiage: 'Szacunkowa cena likwidacji:',
    xiadanqiangpingjiagetishi: 'Oczekiwana cena likwidacji jest obliczana dynamicznie na podstawie sumy aktywów. Cena ta ma charakter wyłącznie poglądowy i nie będzie wykorzystywana do ostatecznej likwidacji.',
    xiadanzuoduo: 'Idź długo',
    xiadanzuokong: 'krótki',
    xiadanpingcang: 'Zamknięta',
    zhanghuheyuezichan: 'Aktywa kontraktowe',
    zhanghuchongbi: 'Wpłacaj monety',
    zhanghutibi: 'Wycofaj monety',
    zhanghuhuazhuan: 'przenosić ',
    zhanghuzhanghuquanyi: 'Prawa do konta',
    zhanghufudongyingkui: 'zmienne zyski i straty',
    zhanghucangweibaozhengjin: 'Margines pozycji',
    zhanghudongjiebaozhengjin: 'Zamroź margines',
    zhanghukeyongquanyi: 'Dostępne korzyści',
    zhanghubaozhengjinshiyonglv: 'Stopień wykorzystania marży',
    hangqingheyuexinxi: 'Informacje o umowie',
    hangqingheyuemingcheng: 'Nazwa kontraktu',
    hangqingjijiahuobi: 'Waluta notowań',
    hangqingheyuedaxiao: 'Wielkość kontraktu',
    hangqingzuixiaojiagebodong: 'minimalne wahania cen',
    dialogleverRateganggantiaozheng: 'Regulacja dźwigni',
    dialogleverRatequeding: 'Jasne',
    dialogtransferzichanhuazhuan: 'Transfer aktywów',
    dialogtransferbizhong: 'Waluta',
    dialogtransfercong: 'z',
    dialogtransferdao: 'przyjechać',
    dialogtransfershuliang: 'ilość',
    dialogtransferqingshuruhuazhuanshuliang: 'Proszę wpisać kwotę przelewu',
    dialogtransferquanbu: 'Wszystko',
    dialogtransferkeyong: 'Dostępny',
    dialogtransferhuazhuan: 'przenosić ',
    dialogtransferjichuzhanghu: 'Konto podstawowe',
    dialogtransferheyuezhanghu: 'Konto kontraktowe',
    dialogfitlosszhiyingzhisun: 'Zrealizuj zysk i zatrzymaj stratę',
    dialogfitlossheyu: 'umowa',
    dialogfitlossduokong: 'Długie/krótkie',
    dialogfitlosschichangjunjia: 'średnia cena pozycji',
    dialogfitlossshuliang: 'ilość',
    dialogfitlosszuixinjia: 'ostatnia cena',
    dialogfitlosszhiyingdianshu: 'Zbieraj punkty zysku',
    dialogfitlosszhisundianshu: 'Zatrzymaj punkty straty',
    dialogfitlossdianshu: 'wynik',
    dialogfitlosszhiyingjiage: 'Weź cenę zysku',
    dialogfitlosszhisunjiage: 'Cena zatrzymania straty',
    dialogfitlossjiage: 'cena',
    dialogfitlossyuqishouyi: 'oczekiwany zwrot',
    dialogfitlossyuqikuisun: 'oczekiwana strata',
    dialogfitlosstishi1: 'Wskazówka: Minimalna zmienność aktualnej umowy wynosi',
    dialogfitlosstishi2: ', ustaw cenę na 0, aby anulować realizację zysku i zatrzymać stratę.',
    dialogfitlossqueding: 'Jasne',
    dialogfitlossduo: 'wiele',
    dialogfitlosskong: 'nieważny',
    dialogconditionzengjiayuntiaojiandan: 'Dodaj arkusz stanu chmury',
    dialogconditiontiaojianleixing: 'Typ warunku:',
    dialogconditionjiagetiaojiandan: 'Arkusz warunków cenowych',
    dialogconditionshijiantiaojiandan: 'Arkusz warunków czasowych',
    dialogconditionjiageleixing: 'Rodzaj ceny:',
    dialogconditionzuixinjia: 'ostatnia cena',
    dialogconditionByijia: 'kup jedną cenę',
    dialogconditionSyijia: 'Sprzedaj jedną cenę',
    dialogconditiontiaojianshezhi: 'Ustawienia warunku:',
    dialogconditionjiageshezhi: 'Ustawienia cen:',
    dialogconditionqingshurujiage: 'Proszę wpisać cenę',
    dialogconditionshijianshezhi: 'Ustawienie czasu:',
    dialogconditionjiaoyileixing: 'Typ transakcji:',
    dialogconditionkaiduo: 'Otwórz długo',
    dialogconditionpingkong: 'płaskie niebo',
    dialogconditionkaikong: 'Otwórz krótko',
    dialogconditionpingduo: 'Pinduo',
    dialogconditiondingdanshuliang: 'Ilość zamówienia:',
    dialogconditionqingshurudingdanshuliang: 'Proszę wpisać ilość zamówienia',
    dialogconditiondingdanshixiao: 'Czas zamówienia:',
    dialogconditionyongjiuyouxia: 'Obowiązuje na stałe',
    dialogconditiontijiao: 'składać',
    dialogAutoProfitLosszhiyingzhisun: 'Zrealizuj zysk i zatrzymaj stratę',
    dialogAutoProfitLossmingcheng: 'nazwa',
    dialogAutoProfitLosszhiyingdian: 'Weź punkt zysku',
    dialogAutoProfitLosszhisundian: 'Punkt zatrzymania straty',
    dialogAutoProfitLosschongzhi: 'nastawić',
    dialogAutoProfitLoss: 'składać',
    dialogCloseOrderpingcang: 'Zamknięta',
    dialogCloseOrderweituoleixing: 'Typ delegata',
    dialogCloseOrderqingxuanze: 'Proszę wybrać',
    dialogCloseOrdershijia: 'cena rynkowa',
    dialogCloseOrderxianjia: 'limit cenowy',
    dialogCloseOrderjiage: 'cena',
    dialogCloseOrderqingshurujiage: 'Proszę wpisać cenę',
    dialogCloseOrdershuliang: 'ilość',
    dialogCloseOrderqingshurushuliang: 'Proszę wpisać ilość',
    dialogCloseOrderzhang: 'Otwarte',
    dialogCloseOrderkepingshuliang: 'Ilość, którą można wyrównać',
    dialogCloseOrderqueding: 'Jasne',
    jsMessageResgaiheyuegangganxiugaichenggong: 'Dźwignia kontraktu została pomyślnie zmodyfikowana',
    jsMessageResgaiheuyegangganxiugaishibai: 'Modyfikacja dźwigni kontraktu nie powiodła się',
    jsMessageReskaicangchenggong: 'Pozycja otwarta pomyślnie',
    jsMessageReskaicangshibai: 'Otwarcie pozycji nie powiodło się:',
    jsMessageReskaicang1: 'Otwieranie pozycji jest zabronione',
    jsMessageReskaicang2: 'Umowa nie istnieje',
    jsMessageReskaicang3: 'Liczba otwartych pozycji przekracza górną granicę',
    jsMessageReskaicang4: 'Cena wynosi 0',
    jsMessageReskaicang5: 'Niewystarczający depozyt zabezpieczający do otwarcia pozycji',
    jsMessageRespingcangchenggong: 'Pozycja została pomyślnie zamknięta',
    jsMessageRespingcangshibai: 'Nie udało się zamknąć pozycji:',
    jsMessageRespingcang1: 'Umowa nie istnieje',
    jsMessageRespingcang2: 'Potwierdzenie pozycji nie istnieje',
    jsMessageRespingcang3: 'Niewystarczająca ilość',
    jsMessageReszijinhuazhuanchenggong: 'Transfer środków udany',
    jsMessageReszijinhuazhuanshibai: 'Transfer środków nie powiódł się',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'Zlecenie pozycjonowania Stop Profit i Stop Loss ustawione pomyślnie',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'Ustawienia stop-profit i stop-loss dla zleceń pozycji nie powiodły się:',
    jsMessageResweituodanchedanchenggong: 'Zamówienie anulowane pomyślnie',
    jsMessageResweituodanchedanshibai: 'Anulowanie zamówienia nie powiodło się',
    jsMessageRestiaojiandantianjiachenggong: 'Zamówienie warunkowe dodano pomyślnie',
    jsMessageResheyuebucunzai: 'Umowa nie istnieje',
    jsMessageResjiageshezhicuowu: 'Błąd w ustalaniu ceny',
    jsMessageResshijianshezhicuowu: 'Błąd ustawienia czasu',
    jsMessageResneibucuowu: 'Błąd wewnętrzny',
    jsMessageRestiaojiandantianjiashibai: 'Nie udało się dodać zamówienia warunkowego:',
    jsMessageReszhiyingzhisunshezhichenggong: 'Pomyślnie ustaw zysk i stop loss',
    jsconfirmquedingchedanma: 'Czy na pewno chcesz anulować zamówienie?',
    jsconfirmtishi: 'wskazówka',
    jsconfirmqueding: 'Jasne',
    jsconfirmquxiao: 'Anulować',
    jsMessageqingxiandenglu: 'Najpierw zaloguj się!',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'Ten kontrakt ma otwarte pozycje i dźwignia nie może być modyfikowana.',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'Niniejsza umowa ma mandat i dźwignia nie może być modyfikowana.',
    jsMessageqingshuruzhengquedeshuliang: 'Proszę podać poprawną ilość!',
    jsMessageqingshuruzhengquedejiage: 'Proszę podać poprawną cenę!',
    jsMessageqingxianxuanzechicang: 'Najpierw wybierz pozycję!',
    jsMessageqingshuruhuazhuanshuliang: 'Proszę podać kwotę przelewu!',
    jsMessageqingxianxuanzeheyue: 'Najpierw wybierz umowę!',
    jsMessageqingshurutiaojiandanshuliang: 'Proszę podać warunkową ilość zamówienia!',
    jsMessageqingshurutiaojiandandejiage: 'Proszę podać cenę zamówienia warunkowego!',
    jsMessageqingshurutiaojiandanzhixingshijian: 'Proszę podać termin realizacji zamówienia warunkowego!',
    emptytext: 'Brak danych',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'Pipsy Take Profit i Stop Loss są ustawione nieprawidłowo',
    jsMessageReschicangdanbucunzai: 'Potwierdzenie pozycji nie istnieje',
    zxkcje:'Minimalna kwota otwarcia',
    },
    commisson:{
    dlzx:'Centrum Agencji',
    sjzl:'Przegląd danych',
    quanbu:"Wszystko",
    jinri:'Dzisiaj',
    zuori:'Wczoraj',
    ri:'dzień',
    wdfy:'Mój rabat',
    wdsjfy:'Mój rabat trzeciego stopnia',
    dengji:'stopień',
    yqrs:'Liczba zaproszonych osób',
    tdrs:'Rozmiar zespołu',
    yqxq:'Szczegóły zaproszenia',
    yhm:"nazwa użytkownika",
    cjsj:'czas stworzenia',
    fyxq:'Szczegóły rabatu',
    zyj:'całkowita prowizja',
    dryj:'Prowizja jednodniowa',
    fyje:'Kwota rabatu',
    
    },
    assets: {
    zongzichan: 'suma aktywów',
    chongbi: 'Wpłacaj monety',
    tibi: 'Wycofaj monety',
    huazhuan: 'przenosić ',
    fabizhanghu: 'Konto podstawowe',
    heyuezhanghu: 'Konto kontraktowe',
    jiayiliushui: 'przepływ transakcji',
    dialogzichanhuazhuan: 'Transfer aktywów',
    cong: 'z',
    dao: 'przyjechać',
    shuliang: 'ilość',
    qingshuruhuazhuanshuliang: 'Proszę wpisać kwotę przelewu',
    quanbu: 'Wszystko',
    keyong: 'Dostępny',
    huanzhuan: 'przenosić ',
    zijinhuazhuanchenggong: 'Transfer środków udany',
    zijinhuazhuanshibai: 'Transfer środków nie powiódł się',
    bibizhanghu: 'Konto monetowe',
    qiquanzhanghu:'konto opcji',
    zczl:'Przegląd zasobów',
    zzczh:'Dyskonto ogółem aktywów',
    ztzc:"Aktywa w transporcie",
    djzc:'Zamroź aktywa',
    zyzc:'Zastawiony majątek',
    cwzc:'Pozycjonuj aktywa',
    jyzh:'konto handlowe',
    bizhong:'Waluta',
    dongjie:'zamrażać',
    lirun:"zysk",
    hyzh:"Konto kontraktowe",
    },
    basicsAccountRecord: {
    fanhui: 'powrót',
    fabizhanghuliushui: 'Przepływ konta handlowego',
    fabijiaoyiliushuileixing: 'Typ przepływu transakcji',
    qingxuanze: 'Proszę wybrać',
    chaxunriqi: 'Data zapytania',
    liushuileixing: 'Typ rurociągu',
    shijian: 'czas',
    biandongjine: 'Zmień kwotę',
    yue: 'Balansować',
    quanbu: 'Wszystko',
    Sellbi: 'sprzedawać monety',
    Buybi: 'kup monety',
    tibi: 'Wycofaj monety',
    chongbi: 'Wpłacaj monety',
    fabizhuanheyue: 'Podstawowa umowa transferowa',
    heyuezhuanfabi: 'Umowa do podstawy',
    zhuanzhang: 'przenosić ',
    fabihuazhuandaoheyue: 'Podstawowy transfer do kontraktu',
    heyuehuazhuandaofabi: 'Przeniesienie kontraktu do bazy',
    goumaijijin: 'Kup basen wydobywczy',
    jijinshuhui: 'Odkupienie basenu wydobywczego',
    jijinshouyi: 'Dochód z umorzenia puli wydobywczej',
    fabizhuanqihuo: 'Podstawowa konwersja na kontrakty futures',
    qihuozhuanfabi: 'Podstawa transferu kontraktów futures',
    fabizhuanganggu: 'Podstawowy transfer na akcje Hongkongu',
    gangguzhuanfabi: 'Podstawy transferu akcji w Hongkongu',
    fabizhuanbibi: 'Podstawowa waluta przelewu',
    bibizhuanfabi: 'Podstawy przelewu waluty na walutę',
    goumaiSGCB: 'Kup ICO',
    chushouSGCB: 'Sprzedam ICO',
    huoqushibai: 'Nie udało się uzyskać',
    zanwushuju: 'Brak danych',
    },
    contractAccountRecord: {
    fanhui: 'powrót',
    heyuezhanghuliushui: 'Przepływ konta kontraktowego',
    heyuejiaoyiliushuileixing: 'Typ przepływu transakcji kontraktowej',
    qingxuanze: 'Proszę wybrać',
    chaxunriqi: 'Data zapytania',
    liushuileixing: 'Typ rurociągu',
    shijian: 'czas',
    biandongjine: 'Zmień kwotę',
    yue: 'Balansować',
    quanbu: 'Wszystko',
    kaicangshouxufei: 'Opłata za otwarcie',
    pingcangshouxufei: 'Opłata za zamknięcie',
    pingcangyingkui: 'Zyski i straty z zamknięcia pozycji',
    fabizhuanheyue: 'Podstawowa umowa transferowa',
    heyuezhuanfabi: 'Umowa do podstawy',
    zhuanzhang: 'przenosić ',
    kaicangyongjin: 'Prowizja za otwarcie',
    pingcangyongjin: 'Komisja zamykająca',
    huoqushibai: 'Nie udało się uzyskać',
    zanwushuju: 'Brak danych',
    },
    recharge: {
    zanwushuju: 'Brak danych',
    shijian: 'czas',
    leixing: 'typ',
    shuliang: 'ilość',
    zhuangtai: 'państwo',
    caozuo: 'działać',
    chakanxiangqing: 'sprawdź szczegóły',
    huoqushibai: 'Nie udało się uzyskać',
    dialogtitle: 'Bliższe dane',
    chongbi: 'Wpłacaj monety',
    fanhui: 'powrót',
    lianmingcheng: 'Nazwa łańcucha',
    fuzhilianjie: 'Skopiuj adres',
    tishixinxi1: '• Proszę nie wpłacać żadnych aktywów innych niż TRC_USDT lub ERC_USDT na powyższy adres, w przeciwnym razie aktywa nie zostaną odzyskane',
    tishixinxi2: '• Po doładowaniu na powyższy adres wymagane jest potwierdzenie całego węzła sieci. Konto zostanie odebrane po 1 potwierdzeniu sieci, a wypłata waluty będzie możliwa po 2 potwierdzeniach sieci.',
    tishixinxi3: '• Minimalna kwota depozytu: 50USDT, depozyty mniejsze niż kwota minimalna nie zostaną zaksięgowane i nie podlegają zwrotowi',
    tishixinxi4: '• Twój adres doładowania nie będzie się często zmieniał i może być doładowywany wielokrotnie. W przypadku jakiejkolwiek zmiany postaramy się powiadomić Cię za pośrednictwem ogłoszeń na stronie internetowej lub e-maili;',
    tishixinxi5: '• Upewnij się, że Twój komputer i przeglądarka są bezpieczne, aby zapobiec manipulowaniu lub wyciekowi informacji.',
    putongchongbi: 'Zwykły depozyt',
    jiaoyichenggong: 'Transakcja udana',
    jiaoyishibai: 'Transakcja nie powiodła się',
    OMNIweihuzhong: 'Łańcuch OMNI jest w trakcie konserwacji, wybierz TRC20',
    },
    withdraw: {
    fanhui: 'powrót',
    zanwushuju: 'Brak danych',
    tibi: 'Wycofaj monety',
    huoqushibai: 'Nie udało się uzyskać',
    OMNIweihuzhong: 'Łańcuch OMNI jest w trakcie konserwacji, wybierz TRC20',
    shijian: 'czas',
    leixing: 'typ',
    shuliang: 'ilość',
    zhuangtai: 'państwo',
    caozuo: 'działać',
    chakanxiangqing: 'sprawdź szczegóły',
    putongtibi: 'Zwykłe wycofanie',
    dialogtitle: 'Bliższe dane',
    dialogtitlewithdraw: 'Wycofaj monety',
    houchongxinfasong: 'Wyślij ponownie po S',
    huoquyouxiangyanzhengma: 'Uzyskaj kod weryfikacyjny e-mail',
    lianmingcheng: 'Nazwa łańcucha',
    tibidizhi: 'Adres wypłaty',
    qingshurutibidizhi: 'Proszę podać adres wypłaty',
    tibishuliang: 'Ilość wycofanych monet',
    shouxufei: 'opłata manipulacyjna',
    qingshurutibishuliang: 'Proszę wpisać kwotę wypłaty',
    quanbu: 'Wszystko',
    keyong: 'Dostępny',
    daozhangshuliang: 'Ilość przybycia',
    zuixiaotibishuliang: 'Minimalna kwota wypłaty wynosi:',
    tishixinxi: 'Aby zapewnić bezpieczeństwo środków, w przypadku zmiany polityki bezpieczeństwa Twojego konta lub modyfikacji hasła przeprowadzimy ręczną weryfikację wypłaty. Prosimy o cierpliwość i oczekiwanie, aż obsługa skontaktuje się z Tobą telefonicznie lub e-mailem. Upewnij się, że Twój komputer i przeglądarka są bezpieczne, aby zapobiec manipulacji lub wyciekowi danych.',
    tuxingyanzhengma: 'Graficzny kod weryfikacyjny',
    qingshurutuxingyanzhengma: 'Proszę wprowadzić graficzny kod weryfikacyjny',
    youxiangyanzhengma: 'Kod weryfikacyjny e-mail',
    queding: 'Jasne',
    tijiaozhong: 'Przedkładający',
    yijujue: 'Odrzucony',
    yitongguo: 'Przeszedł',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Nie udało się uzyskać graficznego kodu weryfikacyjnego. Poproś o ponowny wniosek',
    huoquyouxiangyanzhengmashibai: 'Nie udało się uzyskać kodu weryfikacyjnego e-mailem,',
    qingxianwanchengyouxiangrenzheng: 'Najpierw dokończ weryfikację e-mailową',
    huoquyouxiangshibaiqingchongxinqingqiu: 'Nie udało się uzyskać adresu e-mail. Zapytaj ponownie',
    qingshuruyouxiangyanzhengma: 'Wprowadź kod weryfikacyjny adresu e-mail',
    youxiangyanzhengmacuowu: 'Kod weryfikacyjny e-mail jest błędny,',
    qxsmrz:'Najpierw sprawdź swoje prawdziwe imię i nazwisko',
    tishi:'wskazówka',
    queding:'Jasne'
    },
    contractTransactionRecord: {
    fanhui: 'powrót',
    zanwushuju: 'Brak danych',
    heyuedingdan: 'zlecenie kontraktowe',
    qingxuanze: 'Proszę wybrać',
    quanbu: 'Wszystko',
    kaiduo: 'Otwórz długo',
    pingkong: 'płaskie niebo',
    kaikong: 'Otwórz krótko',
    pingduo: 'Pinduo',
    xianjiaweituo: 'zamówienie limitowane',
    shijiaweituo: 'porządek rynkowy',
    suoyouzhuangtai: 'Wszystkie stany',
    tijiaoshibai: 'Przesyłanie nie powiodło się',
    zhunbeitijiao: 'gotowy do przesłania',
    yitijiao: 'Złożony',
    bufentijiao: 'Częściowa umowa',
    bufentijiaoyichedan: 'Niektóre zamówienia zostały anulowane',
    quanbuchengjiao: 'Wszystkie operacje',
    yichedan: 'Zamówienie anulowane',
    huoqushibai: 'Nie udało się uzyskać',
    heyuedaima: 'Kod umowy',
    ganggan: 'dźwignia',
    xiadanshijian: 'Czas zamówienia',
    chengjiaoshijian: 'Czas transakcji',
    fangxiang: 'kierunek',
    baojialeixing: 'Typ cytatu',
    weituoliang: 'Wysokość prowizji (sztuki)',
    weituojia: 'Cena zamówienia (USDT)',
    chengjiaoliang: 'Wolumen obrotu (bilety)',
    chengjiaojunjia: 'Średnia cena transakcyjna (USDT)',
    yingkui: 'Zyski i straty (USDT)',
    shouxufei: 'Opłata manipulacyjna (USDT)',
    zhuangtai: 'państwo',
    gupiaodingdan: 'zamówienie na magazynie',
    waihuidingdan: 'Zamówienia na rynku Forex',
    gupiaodaima: 'Kod magazynowy',
    waihuidaima: 'Kod Forex',
    },
    kLine: {
    zhibiao: 'indeks',
    shezhi: 'organizować coś',
    quanping: 'pełny ekran',
    tuichuquanping: 'Wyjdź z pełnego ekranu',
    jishuzhibiao: 'Wskaźniki techniczne',
    futuzhibiao: 'Wskaźnik obrazu podrzędnego',
    zhutuzhibiao: 'Główny wskaźnik wykresu',
    huifumoren: 'Przywróć domyślne',
    lazhutuleixing: 'Rodzaje świeczników',
    qingxuanze: 'Proszę wybrać',
    quanshixin: 'Wszystko solidne',
    quankongxin: 'Całkowicie pusty',
    zhangkongxin: 'wznosząca się pustka',
    diekongxin: 'zapaść w pustkę',
    mianjitu: 'wykres obszarowy',
    jiagezhouleixing: 'Typ osi ceny',
    xianxingzhou: 'oś liniowa',
    baifenbizhou: 'Oś procentowa',
    duishuzhou: 'Oś logarytmiczna',
    zuigaojiaxianshi: 'Wyświetlanie najwyższej ceny',
    zuidijiaxianshi: 'Wyświetlanie najniższej ceny',
    zuixinjiaxianshi: 'Najnowszy wyświetlacz cen',
    zhibiaozuixinzhixianshi: 'Wyświetlana jest ostatnia wartość wskaźnika',
    daozhizuobiao: "odwrócone współrzędne",
    wanggexianxianshi: 'Wyświetlanie linii siatki',
    hfcg:'Odzyskiwanie powiodło się',
    },
    SecurityCenter: {
    fanhui: 'powrót',
    anquanzhongxin: 'Centrum bezpieczeństwa',
    qingshurunicheng: 'Proszę wpisać pseudonim',
    nichengxiugaishibai: 'Modyfikacja pseudonimu nie powiodła się',
    huoquyonghuxinxishibai: 'Nie udało się uzyskać informacji o użytkowniku',
    xiugaidenglumima: 'Zmień hasło logowania',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Nie udało się uzyskać graficznego kodu weryfikacyjnego. Poproś o ponowny wniosek',
    qingshurujiumima: 'Proszę wprowadzić stare hasło',
    qingshuruxinmima: 'Proszę wprowadzić nowe hasło',
    qingzaicishuruxinmima: 'Proszę wprowadzić nowe hasło ponownie',
    qingshurutuxingyanzhengma: 'Proszę wprowadzić graficzny kod weryfikacyjny',
    qingshuruyanzhengma: 'Proszę wprowadzić kod weryfikacyjny',
    huoquzhuceshoujihaoshibai: 'Nie udało się uzyskać zarejestrowanego numeru telefonu komórkowego',
    qingxianwanchengshoujirenzheng: 'Najpierw wykonaj uwierzytelnianie za pomocą telefonu komórkowego',
    huoqushoujiyanzhengmashibai: 'Nie udało się uzyskać kodu weryfikacyjnego telefonu komórkowego',
    huoqushoujiyanzhengma: 'Uzyskaj kod weryfikacyjny telefonu komórkowego',
    houchongxinfasong: 'Wyślij ponownie po S',
    mimacuowu: 'Błędne hasło',
    liangcixinmimashurubuyizhiqingjiancha: 'Dwa nowe wprowadzone hasła są niespójne. Sprawdź',
    xinmimaheyuanmimabunengyiyang: 'Nowe hasło nie może być takie samo jak hasło oryginalne',
    qingshurushoujiyanzhengma: 'Proszę wprowadzić kod weryfikacyjny telefonu komórkowego',
    yanzhengmacuowu: 'Błąd kodu weryfikacyjnego',
    denglumimaxiugaichenggong: 'Hasło logowania zostało pomyślnie zmienione',
    denglumimaxiugaishibai: 'Modyfikacja hasła logowania nie powiodła się',
    xiugaizijinmima: 'Zmień hasło funduszu',
    qingshuruzijinmima: 'Proszę podać hasło funduszu',
    qingzaicishuruzijinmima: 'Wprowadź ponownie hasło funduszu',
    liangcizijinmimabuyizhiqingjiancha: 'Obydwa hasła funduszy są niespójne. Sprawdź',
    shezhizijinmimachenggong: 'Ustaw hasło funduszu pomyślnie',
    shezhizijinmimashibai: 'Nie udało się ustawić hasła funduszu',
    xiugaizijinmimachenggong: 'Hasło funduszu zostało pomyślnie zmodyfikowane',
    xiugaizijinmimashibai: 'Nie udało się zmienić hasła funduszu',
    youxiangrenzheng: 'Uwierzytelnianie e-mailem',
    huoquyouxiangyanzhengma: 'Uzyskaj kod weryfikacyjny e-mail',
    qingshuruyouxiangdizhi: 'Proszę podać adres e-mail',
    huoquyouxiangyanzhengmashibai: 'Nie udało się uzyskać kodu weryfikacyjnego e-mailem',
    qingshuruyouxiangyanzhengma: 'Wprowadź kod weryfikacyjny adresu e-mail',
    youxiangyanzhengchenggong: 'Weryfikacja e-mailowa powiodła się',
    weirenzheng: 'Brak certyfikatu',
    queding: 'Jasne',
    anquanshezhiguanli: 'Zarządzanie ustawieniami zabezpieczeń',
    denglumima: 'Hasło logowania',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'Ustawiając hasło logowania, będziesz mógł zalogować się bezpośrednio przy użyciu hasła do konta',
    xiugai: 'Poprawiać',
    zijinmima: 'Hasło funduszu',
    yongyubaohuzijinanquan: 'Służy do ochrony bezpieczeństwa funduszy',
    shezhi: 'organizować coś',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'Służy do weryfikacji bezpieczeństwa podczas logowania, wypłacania monet, odzyskiwania haseł i modyfikowania ustawień zabezpieczeń.',
    yirenzheng: 'Atestowany',
    renzheng: 'Orzecznictwo',
    shimingrenzheng: 'Uwierzytelnianie prawdziwego imienia',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'Służy do uwierzytelniania bezpieczeństwa podczas kupowania, sprzedawania, wycofywania, deponowania i przesyłania monet.',
    daishenhe: 'Oczekuje na recenzję',
    yibohui: 'Zwolniony',
    yuandenglumima: 'Oryginalne hasło logowania',
    xinmima: 'Nowe hasło',
    quedingxinmima: 'Potwierdź nowe hasło',
    tuxingyanzhengma: 'Graficzny kod weryfikacyjny',
    yanzhengma: 'Kod weryfikacyjny',
    zijinmima: 'Hasło funduszu',
    querenzijinmima: 'Potwierdź hasło funduszu',
    youxiangdizhi: 'Adres e-mail',
    qingxianwanchengyouxiangrenzheng: 'Najpierw dokończ weryfikację e-mailową',
    xiugaitouxiangchenggong: 'Pomyślnie zmodyfikowano awatar',
    xiugaitouxiangshibai: 'Nie udało się zmodyfikować awatara',
    shangchuantouxiangzhinengshijpggeshi: 'Przesyłaj zdjęcia awatarów tylko w formacie JPG!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'Rozmiar przesłanego obrazu awatara nie może przekraczać 2MB!',
    },
    verification: {
    fanhui: 'powrót',
    shimingrenzheng: 'Uwierzytelnianie prawdziwego imienia',
    qingshuruxingming: 'Proszę wpisać nazwę',
    qingshuruzhengjianhaoma: 'Proszę podać swój numer identyfikacyjny',
    huoquyonghuxinxishibai: 'Nie udało się uzyskać informacji o użytkowniku',
    wenjiangeshicuowu: 'Błąd formatu pliku',
    wenjianbunengdayu5M: 'Plik nie może być większy niż 5M',
    qingtianxiexingming: 'Proszę wpisać swoje imię i nazwisko',
    qingtianxiezhengjianhaoma: 'Proszę wpisać numer identyfikacyjny',
    qingshangchuanzhengjianzhengmian: 'Prześlij przednią stronę swojego dokumentu tożsamości',
    qingshangchuanzhengjianfanmian: 'Prześlij drugą stronę swojego dokumentu tożsamości',
    tijiaochenggong: 'Przesyłanie powiodło się',
    kaiqishenfenrenzheng: 'Włącz uwierzytelnianie tożsamości',
    bohuiyuanyin: 'Powód odrzucenia',
    qingtianxiejiashizhizhaogongminkahuzhao: 'Proszę podać dane dotyczące prawa jazdy/karty obywatelskiej/paszportu',
    xingming: 'Nazwa',
    zhengjianhaoma: 'Numer identyfikacyjny',
    qingshangchuanjiashizhizhaogongminkahuzhao: 'Prześlij dane dotyczące prawa jazdy/karty obywatelskiej/paszportu',
    shangchuanzhengjianzhengmian: 'Prześlij przód identyfikatora',
    huotuozhuafangru: 'Lub przeciągnij i upuść',
    shangchuanzhengjianfanmian: 'Prześlij drugą stronę certyfikatu',
    tijiao: 'składać',
    },
    invite: {
    yaoqinghaoyoudejiangli: 'Zaproś znajomych i zdobądź nagrody',
    yongjiukede: 'stale dostępne',
    jiaoyifanyong: 'Rabat handlowy',
    fasongyaoqinggeihaoyou: 'Wyślij zaproszenie do znajomych',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'Zaproś znajomych do rejestracji BCFX udostępniając link',
    haoyouzhuce: 'Rejestracja znajomego',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'Znajomy przyjmuje zaproszenie, dokonuje rejestracji i przeprowadza transakcje',
    huodexiangyingbilijiangli: 'Otrzymuj odpowiednie proporcjonalne nagrody',
    qingsonghuodejiaoyishouxufeijianglifuli: 'Z łatwością uzyskaj dodatkowe korzyści z tytułu opłat transakcyjnych',
    wodezhuanshufenxiangfangshi: 'Mój ekskluzywny sposób dzielenia się',
    yaoqinglianjie: 'Link z zaproszeniem',
    jiazaizhong: 'załadunek...',
    fuzhilianjie: 'Skopiuj link',
    yaoqingma: 'Kod zaproszenia',
    fuzhiyaoqingma: 'Skopiuj kod zaproszenia',
    wodeyaoqing: 'moje zaproszenie',
    yaoqingrenshu: 'Liczba zaproszonych osób',
    huodejiangli: 'Zdobądź nagrodę',
    huodongxize: 'Szczegóły wydarzenia',
    tips1: 'Relacja pomiędzy użytkownikami platformy przebiega na trzech poziomach, np.: użytkownicy ABCD stanowią trzy poziomy użytkowników, a A to najwyższy poziom!',
    tips2: 'Relacje interesów pomiędzy poziomami',
    tips201: '。',
    tips3: 'Nagroda pieniężna: Klient A może otrzymać 40% opłaty za transakcję kontraktową Klienta B + 20% opłaty transakcyjnej Klienta C + 10% opłaty transakcyjnej Klienta D',
    tips4: 'Na przykład: Klient A ma 10 klientów pierwszego poziomu B, 10 klientów drugiego poziomu C i 10 klientów trzeciego poziomu D. Jeśli każdy kontrakt klienta jest wart 100 000 USDT',
    tips5: 'Następnie zwrot, jaki może uzyskać klient A',
    tips6: '100000*1,2%*40%*10+100000*1,2%*20%*10+100000*1,2%*10%*10=8400 USDT nagroda pieniężna, którą można wypłacić w czasie rzeczywistym',
    tips7: 'W przypadku korekty działalność będzie podlegała aktualizacjom platformy BCFX, a ostateczne prawo interpretacji należy do BCFX.',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Nie udało się uzyskać graficznego kodu weryfikacyjnego. Poproś o ponowny wniosek',
    qingshuruyouxiang: 'Proszę wpisać swój adres e-mail',
    qingshurutuxingyanzhengma: 'Proszę wprowadzić graficzny kod weryfikacyjny',
    qingshuruyanzhengma: 'Proszę wprowadzić kod weryfikacyjny',
    qingshurunindemima: 'Proszę wpisać swoje hasło',
    qingzaicishurunindemima: 'Wprowadź hasło ponownie',
    qingshuruzhifumima: 'Proszę podać hasło płatności',
    xuantian: 'Fakultatywny',
    bitian: 'Wymagany',
    yonghuzhuce: 'Rejestracja użytkownika',
    huanyingdenglu: 'Zapraszamy do logowania',
    guanfangwangzhan: 'oficjalna strona internetowa',
    xiangmumingcheng: 'BCFX',
    youxiang: 'Poczta',
    tuxingyanzhengma: 'Graficzny kod weryfikacyjny',
    yanzhengma: 'Kod weryfikacyjny',
    huoquyanzhengma: 'Kod weryfikacyjny',
    shezhimima: 'Ustaw hasło',
    quedingmima: 'Potwierdź hasło',
    zhifumima: 'Hasło płatności',
    yaoqingma: 'Kod zaproszenia',
    zhuce: 'rejestr',
    yiyouzhanghao: 'Masz już konto?',
    denglu: 'Zaloguj się',
    qingshuruyouxiangdizhi: 'Proszę podać adres e-mail',
    huoqushibai: 'Nie udało się uzyskać',
    houchongxinfasong: 'Wyślij ponownie po S',
    liangcishurudemimabuyizhi: 'Hasła wprowadzone dwukrotnie są niespójne',
    zhucechenggong: 'Rejestracja przebiegła pomyślnie',
    zhuceshibai: 'Rejestracja nie powiodła się',
    },
    treasure:{
    jichubizhong: 'waluta bazowa',
    shouyibizhong: 'Waluta przychodów',
    wakuangtianshu: 'Dni górnicze',
    shouyitianshu: 'Dni zarabiania',
    suodingtianshu: 'Dni blokady',
    yichanshengshouyi: 'Wygenerowany przychód',
    yuqishouyi: 'oczekiwany zwrot',
    kaishishijian: 'czas rozpoczęcia',
    jieshushijian: 'czas końca',
    qingshuruninyaosuodingdebizhongshuliang: 'Wprowadź kwotę USDT w walucie, którą chcesz zablokować',
    huoqushibai: 'Nie udało się uzyskać',
    meiyitian: 'co 1 dzień',
    dakai: 'Otwarte',
    yuanyuzhoukuangchi: 'Basen wydobywczy Metaverse',
    jilu: 'Nagrywać',
    kuangchixiangqing: 'Szczegóły basenu wydobywczego',
    suo: 'Zamek',
    yitianchanchu: 'Wyjście 1 dzień',
    zuixiaosuodingshijian: 'Minimalny czas blokady',
    nindeUSDT: 'Twój USDT',
    tian: 'niebo',
    quanbu: 'Wszystko',
    suoUSDTbingkaishishengchan: 'Zablokuj USDT i rozpocznij produkcję',
    ninquedingyaosuoUSDTkaishishengchanma: 'Czy na pewno chcesz zablokować USDT i rozpocząć produkcję?',
    tishi: 'wskazówka',
    queding: 'Jasne',
    quxiao: 'Anulować',
    kaishishengchan: 'Rozpocznij produkcję!',
    yiquxiao: 'Odwołany',
    },
    subscription:{
    rengouliebiao:'Lista subskrypcji',
    jijiangdaolai:'Już wkrótce',
    jinxingzhong:'w toku',
    quanbu:'Wszystko',
    keshiyonghuobi:"Dostępne waluty",
    zongshuliang:'całkowita ilość',
    meiyouxianzhi:'bez limitu',
    shengyushuliang:'Pozostała ilość',
    kaishishijian:'czas rozpoczęcia',
    zhuangtai:'państwo',
    jilu:'Nagrywać',
    zanwujilu:"Nie ma jeszcze żadnego rekordu",
    leixing:'typ',
    shuliang:'ilość',
    goumaijine:'Kwota zakupu',
    shijian:'czas',
    goumai:'Kupić',
    duichu:'Znikąd',
    jiangli:'nagroda',
    tibi:'Wycofaj monety',
    huidaomulu:'Wróć do spisu treści',
    dangqianchiyou:'obecnie przetrzymywany',
    keyong:'Dostępny',
    keduihuan:'Odkupny',
    shishihangqing:'Cytaty w czasie rzeczywistym',
    yue:'Balansować',
    biaoti:'tytuł',
    fashoushuliang:'Ilość na sprzedaż',
    jindu:"harmonogram",
    goumaishuliang:'Ilość zakupu',
    ge:'indywidualne',
    woyiyuedu:'przeczytałem',
    daibigoumaixieyi:'Umowa zakupu tokena',
    qingshurugoumaijine:'Proszę wpisać kwotę zakupu',
    shifoyaogoumai:'Czy chcesz kupić',
    tishi:'wskazówka',
    queding:'Jasne',
    quxiao:'Anulować',
    yjs:'zakończył się',
    ywc:'Zakończony',
    sgjg:'Cena subskrypcji',
    jssj:"czas końca",
    zqsl:"Zwycięska ilość",
    zqje:"Zwycięska kwota",
    tian:"niebo",
    shi:'godzina',
    fen:'punkt',
    miao:'Drugi',
    shengou:'Prenumerata',
    xmxq:'Szczegóły projektu',
    },
    }
    
    