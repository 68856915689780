<template>
    <div class="home">
        <div class="hbanner">
            <div class="hbanner_content">
                <div class="fz-40" style="line-height: 50px;"> {{ i18n('huanyinglaidao') }}
                    <span> {{ i18n('pingtaimingcheng') }} </span>
                </div>
                <div class="fz-40" style="line-height: 50px;"> {{ i18n('mengxiangqihang') }} </div>
                <div class="fz-24 mt-8"> {{ i18n('yijiankaiqishuzihualvcheng') }} </div>
                <div class="fz-14 mt-24" >
                    <!-- <div style=" color: #333;"> {{ i18n('liaojiegengduo') }}
                        <i class="el-icon-arrow-right"></i>
                    </div> -->
                </div>
                <div class="but-card flex-betw gap-20" >
                    <div class="but-card-button" style="background-color: #FCD535;" @click="goPage('recharge')"> {{ i18n('chongzhi') }} </div>
                    <div class="but-card-button" @click="goPage('trade')" style="margin-left: 20px;"> {{ i18n('jiaoyi') }} </div>
                </div>
            </div>

            <!-- <div class="hbanner_img_card">
                <img :src="require('@/assets/images/crypto-trading-app.png')" alt="">
            </div> -->
        </div>

        <div style="display: flex;justify-content: space-between;width:1200px;margin:0 auto">
            <div style="width:50%;display: flex;align-items: center;margin-top:64px">
                <div>
                    <div style="color:#F0B90B;font-weight:500;font-size:26px">{{$t("public")['zhd']}}</div>
                    <div style="font-weight:500;font-size:36px;margin-top:20px">{{$t("public")['qsjyh']}}</div>
                    <div style="font-weight:500;font-size:36px;margin-top:10px">{{$t("public")['gtdxz']}}</div>
                    <el-button v-if="!isLoggedIn"
                    @click="goPage('register')"
                    size="mini"
                    type="primary"
                    style="background-color: #FCD535;border:none;color:#333;width: 108px;margin-top:30px;padding:10px 0"
                    > {{ $t('register')['zhuce'] }} </el-button> 
                    <el-button v-if="isLoggedIn"
                    @click="goPage('trade')"
                    size="mini"
                    type="primary"
                    style="background-color: #FCD535;border:none;color:#333;width: 108px;margin-top:30px;padding:10px 0"
                    > {{ i18n('jiaoyi') }} </el-button>
                </div>
            </div>
            <div class="selcon container" style="width:50%">
                <el-tabs 
                    class="home-tabs"
                    v-model="activeName" 
                    @tab-click="tabClick">
                    <el-tab-pane :label="i18n('remenbang')" name="first"></el-tab-pane>
                    <el-tab-pane :label="i18n('zhangfubang')" name="second"></el-tab-pane>
                    <el-tab-pane :label="i18n('diefubang')" name="third"></el-tab-pane>
                    <el-tab-pane :label="i18n('chengjiaoliang')" name="fourth"></el-tab-pane>
                </el-tabs>
    
                <el-table
                    :data="eltableData.slice(0, limit)"
                    stripe
                    type='index'
                    style="width: 100%;"
                    class="home-table"
                    :empty-text="i18n('zanwushuju')"
                    :cell-style="{'border-color':'#fff'}"
                    :header-cell-style="{background:'#fff', padding: '0', height:'45px', 'border-color':'#fff', color: '#000'}"
                    :row-style="{background:'#fff'}">
                    <el-table-column
                        :label="i18n('jiayidui')"
                        min-width="200">
                        <template slot-scope="scope">
                            <div style="display: flex; align-items: center;">
                                <el-image
                                    style="width: 30px; height: 30px"
                                    :src="apiAd+'/codelogo/'+contractCodeUsdt(scope.row.code)+'-USDT.png'"></el-image>  
                                <div style="color: #000; margin-left: 5px;"> {{ scope.row.code }} </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="i18n('zuixinjiage')"
                        min-width="100">
                        <template slot-scope="scope">
                            <span style="color: #000;"> 
                                {{ scope.row.price  | newPriceFormat(scope.row.float_num)}} </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="i18n('zhangdie24h')"
                        min-width="100">
                        <template slot-scope="scope">
                            <span :class="scope.row.price > scope.row.open ? 'color_up': 'color_down'"> 
                                {{ scope.row.price | filter_classUpOrDown(scope.row.open,'rate') }} </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="i18n('caozuo')"
                        align="right"
                        min-width="80">
                        <template slot-scope="scope">
                            <div style="flex:1; text-align: right;">
                                <el-button
                                    size="mini"
                                    type="primary"
                                    style="background-color: #FCD535;border:none;color:#333;width: 68px;"
                                    @click="handleTrade(scope.row.code)"> {{ i18n('jiaoyi') }} </el-button> 
                            </div>
                            
                        </template>
                    </el-table-column>
                </el-table>
    
                <div class="text-c mt-24" v-if="limit==6" style="cursor: pointer; font-size: 14px;" @click="loadMore(1)">
                    {{ i18n('gengduo') }}
                    <i class="el-icon-arrow-right" ></i>
                </div>
                <!-- <div class="text-c mt-24" v-if="limit>6" style="cursor: pointer; font-size: 14px;" @click="loadMore(2)">
                    收起
                    <i class="el-icon-arrow-up" ></i>
                </div> -->
            </div>
        </div>
        <div style="display:flex;width:1200px;margin:0 auto;margin-top:70px">
            <div style="width:50%">
                <img src="@/assets/images/index_center1.png" style="width:100%;height:350px" alt="">
            </div>
            <div style="width:50%;background:#FFFFFF;display:flex;align-items: center;">
                <div style="margin-left:50px">
                    <div class="fz-40 fw-500 " style="line-height: 58px;"> {{$t("public")['szhbjyytz']}}</div>
			        <div class="fz-22 mt-8 " style="line-height: 35px;"> {{$t("public")['xsddcf']}}</div>
                    <el-button 
                    @click="goPage('trade')"
                    type="primary"
                    size="mini"
                    style="background-color: #FCD535;border:none;color:#333;width: 108px;margin-top:30px;padding:10px 0"
                    > {{ i18n('jiaoyi') }} </el-button>
                </div>
            </div>
        </div>
        <div style="display:flex;width:1200px;margin:0 auto;">
            
            <div style="width:50%;background:#FFFFFF;display:flex;align-items: center;">
                <div>
                    <div class="fz-40 fw-500 " style="line-height: 58px;"> {{ i18n('suishisuidikaiqijiaoyi') }} </div>
                    <div class="fz-22 mt-8 " style="line-height: 35px;"> {{ i18n('wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi') }} </div>
                    <div class="mt-40  gap-20" style="display:flex;">
                        <el-popover
                        class="but-dow flex-cen"
                        placement="bottom"
                        width="128px"
                        trigger="hover">
                        
                        <div class="qrcodeIOS" style="height: 138px; width: 138px; border: 1px solid #000; border-radius: 8px;">
                            <vue-qr
                                ref="qrCodeIOS"
                                text="https://api.blockchainflex.com/appDownload/BCFX.mobileconfig"
                                :logoSrc="logoSrc"
                                :color-dark="randomColor"
                                style="margin-top: 5px; margin-left: 5px;"
                                width="128"
                                height="128"></vue-qr>
                        </div>
                        <!-- <el-button slot="reference">click 激活</el-button> -->
                        <div class="but-dow flex-cen" style="background:#FCD535;color:#333;cursor: pointer;"   slot="reference">
                            <img :src="require('@/assets/images/apple_black.png')" alt="">
                            iOS
                        </div>
                        </el-popover>
                        <el-popover
                        class="but-dow flex-cen"
                        placement="bottom"
                        width="128px"
                        trigger="hover">
                        
                        <div class="qrcodeIOS" style="height: 138px; width: 138px; border: 1px solid #000; border-radius: 8px;">
                            <vue-qr
                                ref="qrCodeIOS"
                                text="https://api.blockchainflex.com/appDownload/BCFX.apk"
                                :logoSrc="logoSrc"
                                :color-dark="randomColor"
                                style="margin-top: 5px; margin-left: 5px;"
                                width="128"
                                height="128"></vue-qr>
                        </div>
                        <!-- <el-button slot="reference">click 激活</el-button> -->
                        <div class="but-dow flex-cen" style="cursor: pointer;" slot="reference">
                            <img :src="require('@/assets/images/android_white.png')" alt="">
                            Android
                        </div>
                        </el-popover>
                    </div>
                </div>
            </div>
            <div style="width:50%">
                <img src="@/assets/images/index_center2.png" style="width:100%;height:350px" alt="">
            </div>
        </div>
        <!-- <div class="container">
			
			<div class="mt-120 dow-qr flex_row_between">
				<img :src="require('@/assets/images/home-download-bg.png')" alt="" style="width: 570px;">
				<div class="flex_col_center">
					<div class="qr-box flex-row gap-20">
                        <div class="qrcodeIOS" style="height: 138px; width: 138px; border: 1px solid #000; border-radius: 8px;">
                            <vue-qr
                                ref="qrCodeIOS"
                                :text="textIOS"
                                :logoSrc="logoSrc"
                                :color-dark="randomColor"
                                style="margin-top: 5px; margin-left: 5px;"
                                width="128"
                                height="128"></vue-qr>
                        </div>
						<div>
							<div class="fz-14 mt-40"> {{ i18n('saomaxiazaiApp') }} </div>
							<div class="fz-14 fw-500 mt-20"> {{ i18n('iosheAndroid') }} </div>
						</div>
					</div>
				</div>
			</div>
		</div> -->

        <div class="container mt-80" style="background-color: #FAFAFA;padding:70px 0">
            <div style="width:1200px;margin:0 auto;">
                <div class="fz-40 fw-500 text-c"> {{ i18n('ninkexinrenqieanquandejiamibijiaoyisuo') }} </div>
                <div class="flex-betw mt-80">
                    <div class="card_box">
                        <div class="circle">
                            <img :src="require('@/assets/images/HomeLock1.png')" alt="">
                        </div>
                        <div class="fz-20 fw-500 text-c mt-20"> {{ i18n('tishi1') }} </div>
                        <div class="fz-14 text-c light_gray mt-20"> {{ i18n('xiangqing1') }} </div>
                    </div>
                    <div class="card_box">
                        <div class="circle">
                            <img :src="require('@/assets/images/HomeLock2.png')" alt="">
                        </div>
                        <div class="fz-20 fw-500 text-c mt-20"> {{ i18n('tishi2') }} </div>
                        <div class="fz-14 text-c light_gray mt-20"> {{ i18n('xiangqing2') }} </div>
                    </div>
                    <div class="card_box">
                        <div class="circle">
                            <img :src="require('@/assets/images/HomeLock3.png')" alt="">
                        </div>
                        <div class="fz-20 fw-500 text-c mt-20"> {{ i18n('tishi3') }} </div>
                        <div class="fz-14 text-c light_gray mt-20"> {{ i18n('xiangqing3') }} </div>
                    </div>
                </div>
                <div class="flex-betw mt-80" >
                    <div class="card_box">
                        <div class="circle">
                            <img :src="require('@/assets/images/HomeLock4.png')" alt="">
                        </div>
                        <div class="fz-20 fw-500 text-c mt-20"> {{ i18n('tishi4') }} </div>
                        <div class="fz-14 text-c light_gray mt-20"> {{ i18n('xiangqing4') }} </div>
                    </div>
                    <div class="card_box">
                        <div class="circle">
                            <img :src="require('@/assets/images/HomeLock5.png')" alt="">
                        </div>
                        <div class="fz-20 fw-500 text-c mt-20"> {{ i18n('tishi5') }} </div>
                        <div class="fz-14 text-c light_gray mt-20"> {{ i18n('xiangqing5') }} </div>
                    </div>
                    <div class="card_box">
                        <div class="circle">
                            <img :src="require('@/assets/images/HomeLock6.png')" alt="">
                        </div>
                        <div class="fz-20 fw-500 text-c mt-20"> {{ i18n('tishi6') }} </div>
                        <div class="fz-14 text-c light_gray mt-20"> {{ i18n('xiangqing6') }} </div>
                    </div>
                </div>
            </div>
		</div>
        
    </div>

</template>

<script>
import config from '@/utils/configs'
import { newPriceFormat, classUpOrDown, moneyFormat } from '@/utils/filters.js'

export default {
    data() {
        return {
            textIOS: config.appDownloadIos,
            textAndroid: config.appDownloadAndroid,
            apiAd: config.apiAddress,
            logoSrc: require('@/assets/images/logo.png'),
            randomColor: 'black',
            activeName: 'first',
            hqlist: this.$store.state.hqlist,
            eltableData: [],
            limit:6
        }
    },
    mounted() {
        this.$root.$on('QuotesContractList', (msg) => {
            this.pusheltableData('first') ;
        });

        if (this.$store.state.hqlist.length > 0) {
            this.pusheltableData('first') ;            
        };
    },
    beforeDestroy() {
        this.$root.$off('QuotesContractList');
    },
    computed: {
        i18n() {
            return function (name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("home")[name]
            }
        },
        isLoggedIn() {
            return this.$store.state.loginStatus;
        },
    },
    filters: {
        contractCode: function (contract) {
            var attr = contract.split("-");
            return attr[0];
        },
        newPriceFormat: function (price, n) {
            return newPriceFormat(price, n);
        },
        classUpOrDown: function (nprice, openprice, useType) {
            return classUpOrDown(nprice, openprice, useType);
        },
        filter_classUpOrDown: function (nprice, openprice, useType) {
            return classUpOrDown(nprice, openprice, useType);
        },
    },
    methods: {
        goPage(val){
            if(val == 'recharge'){
                this.$router.push({ name: 'rechargeLayout'});
            }else if(val == 'trade'){
                this.$router.push({ name: 'contractTradeLayout'});
            }else if(val=='register'){
                this.$router.push({ name: 'registerLayout'});
            }
        },
        handleTrade(codename) {
            this.$router.push({ name: 'contractTradeLayout', params: { codename: codename } });
        },
        loadMore(val){
            // if(val == 1){
            //     this.limit = this.$store.state.hqlist.length;
            // }else{
            //     this.limit = 6
            // }
            
            // this.pusheltableData(this.activeName);
            this.$router.push({ name: 'registration' });
        },
        tabClick(tab) {
            this.pusheltableData(tab.name) ;
        },
        contractCodeUsdt (contract) {
            var attr=contract.split("-");
            return  attr[0]; 
        },
        pusheltableData(dataType) {
            switch (dataType) {
                case 'first':
                    this.eltableData = [];
                    var list = [...this.$store.state.hqlist];
                    list.sort((a, b) => a.sort - b.sort);
                    this.eltableData = [...list]
                    break;
                case 'second':
                this.eltableData = [];
                    var list = [...this.$store.state.hqlist];
                    list.forEach (item => {
                        if (item.price !== 0 && item.open !== 0) {
                            var ncPrice = item.price - item.open;
                            var priceRange = ncPrice / item.open;
                            item.rate = moneyFormat(priceRange * 100, 2);
                        }
                    });
                    list.sort((a, b) => b.rate - a.rate);
                    this.eltableData = [...list]
                    break;
                case 'third':
                    this.eltableData = [];
                    var list = [...this.$store.state.hqlist];
                    list.forEach (item => {
                        if (item.price !== 0 && item.open !== 0) {
                            var ncPrice = item.price - item.open;
                            var priceRange = ncPrice / item.open;
                            item.rate = moneyFormat(priceRange * 100, 2);
                        }
                    });
                    list.sort((a, b) => a.rate - b.rate);
                    this.eltableData = [...list]
                    break;
                case 'fourth':
                    this.eltableData = [];
                    var list = [...this.$store.state.hqlist];
                    list.sort((a, b) => a.dealnum - b.dealnum);
                    this.eltableData = [...list]
                    break;
            }
        },
    },
}
</script>


<style  scoped>
.home-tabs .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

/deep/ .home-tabs .el-tabs__active-bar {
    background: #FCD535;
}

/deep/ .home-tabs .el-tabs__item.is-active {
    color: #FCD535 !important;
}

/deep/ .home-tabs .el-tabs__item {
    font-size: 20px !important;
    font-weight: bold !important;
}

.home-tabs .el-tabs__nav-wrap::after {
	display: none !important;
}
.home {
    background: #fff;
    height: auto;
    width: 100%;
    margin: 0 auto;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

.hbanner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
    background: url('@/assets/images/index_top.png') no-repeat no-repeat;
    background-size: 100% 100%;
    color: white;
}

.hbanner_content {
    flex-wrap: nowrap;
    text-align: center;
}

.fz-14 {
    font-size: 14px;
}

.fz-20 {
    font-size: 20px;
}

.fz-24 {
    font-size: 24px;
}

.fz-40 {
    font-size: 40px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-120 {
    margin-top: 120px;
}

.fw-500 {
    font-weight: 500;
}
.fw-550 {
    font-weight: 550;
}
.fw-600 {
    font-weight: 600;
}
.gap-20 {
    gap: 20px;
}

.but-card {
    width: 380px;
    margin-top: 40px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.but-card-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 180px;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    color: #333;
}

.selcon {
	margin-top: 64px;
}

.home-table.el-table::before {
    background-color: #fff;
}

.home-table ::v-deep .el-table__body tr:hover > td {
     background-color: #e9e9e9 !important;
}
 
.color_up{
    color: rgb(23, 99, 182);
}

.color_down{
    color: rgb(225, 35, 67);
}

.text-c {
    text-align: center !important;
}

.flex-cen {
    display: flex;
    justify-content: center;
    align-items: center;
}

.but-dow {
	border-radius: 99px;
	width: 160px;
	height: 48px;
    background-color: #000;
    color: #fff;

}

.but-dow img {
	width: 16px;
	height: 16px;
	margin-right: 8px;
}

.dow-qr {
    display: flex;
}

.qr-box {
	width: 396px;
	height: 136px;
	padding: 16px;
	border-radius: 8px;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-betw {
    display: flex;
    justify-content: space-between;
}

.flex_col_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flex_row_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card_box {
	width: 254px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    line-height: 20px;
}

.light_gray {
    color: #929292;
}

.circle {
    width:64px;
    height: 64px;
    border-radius: 50%;
    text-align: center;
}
.circle img{
    width: 64px;
    height: 64px;
}


</style>
