export default {
    public:{
        zhd:"am besten",
        qsjyh:"Benutzer auf der ganzen Welt",
        gtdxz:'gemeinsame Wahl',
        szhbjyytz:'Chancen und Herausforderungen digitaler Währungen',
        xsddcf:'Vermögenschancen und Herausforderungen im neuen Zeitalter',
        yzsjypt:"One-Stop-Handelsplattform",
        mskljyzh:'Eröffnen Sie ein Handelskonto und beginnen Sie jetzt mit dem Handel',
        jyhg:"Geschäftskonformität",
    },

    header: {
    hello: 'Hallo Welt!',
    changeLanguage: 'Sprache wechseln',
    Login: 'Einloggen',
    Register: 'registrieren',
    Logout: 'Abmelden und einloggen',
    shouye: 'Titelseite',
    bibijiaoyisuo: 'Handel mit Kryptowährungen',
    heyuejiaoyisuo: 'Vertragshandel',
    licaichanpin: 'Bergbaubecken',
    rengou: 'Abonnement',
    ruanjianxiazai: 'Software-Download',
    zhongwen: 'chinesisch',
    yingwen: 'In',
    zichan: 'Vermögenswerte',
    dingdan: 'Befehl',
    heyuedingdan: 'Vertragsauftrag',
    bibidingdan: 'Münzbestellung',
    anquanzhongxin: 'Sicherheitscenter',
    fanyongyaoqing: 'Einladung der Kommission',
    tuichudenglu: 'Abmelden',
    gupiaojiaoyi: 'Aktienhandel',
    waihuijiaoyi: 'Forex-Handel',
    qiquanjiaoyi:'Optionshandel',
    gupiaodingdan: 'Lagerbestellung',
    waihuidingdan: 'Forex-Bestellungen',
    qiquandingdan: 'Optionsreihenfolge',
    jiaoyidingdan:'Handelsauftrag',
    daolizhongxin:'Agenturzentrum',
    huodong:'Aktivität'
    },
    home: {
    huanyinglaidao: 'Willkommen bei',
    pingtaimingcheng: 'Hier',
    mengxiangqihang: 'Träume setzen Segel',
    yijiankaiqishuzihualvcheng: 'Beginnen Sie Ihre digitale Reise mit einem Klick',
    liaojiegengduo: 'Erfahren Sie mehr',
    chongzhi: 'nachfüllen',
    jiaoyi: 'Handel',
    remenbang: 'Beliebte Liste',
    zhangfubang: 'Gainer-Liste',
    diefubang: 'Verliererliste',
    chengjiaoliang: 'Volumen',
    zanwushuju: 'Noch keine Daten',
    jiayidui: 'Handelspaar',
    zuixinjiage: 'aktueller Preis',
    zhangdie24h: '24 Stunden steigen und fallen',
    caozuo: 'arbeiten',
    jiaoyi: 'Handel',
    gengduo: 'Mehr',
    suishisuidikaiqijiaoyi: 'Beginnen Sie jederzeit und überall mit dem Handel',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'Ob App oder Web, Sie können Ihre Transaktion schnell starten',
    saomaxiazaiApp: 'Scannen Sie den QR-Code, um die APP herunterzuladen',
    iosheAndroid: 'iOS und Android',
    ninkexinrenqieanquandejiamibijiaoyisuo: 'Ihr vertrauenswürdiger und sicherer Kryptowährungsaustausch',
    tishi1: 'Reservezertifikat',
    xiangqing1: 'Wir versprechen, alle Vermögenswerte der Nutzer mindestens im Verhältnis 1:1 zu halten',
    tishi2: 'Beste Transaktionsraten',
    xiangqing2: 'Vorzugstransaktionspreise, wettbewerbsfähige VIP-Rechte und erstklassige Dienstleistungen',
    tishi3: 'Sicherheit, auf die Sie zählen können',
    xiangqing3: 'Unsere ausgefeilten Sicherheitsmaßnahmen und SAFU-Fonds schützen Ihre digitalen Vermögenswerte vor allen Risiken',
    tishi4: 'Kundendienst rund um die Uhr',
    xiangqing4: '24*7 Stunden, Vollzeitbetriebsmodus, beantworten Sie Ihre relevanten Anfragen so schnell wie möglich',
    tishi5: 'Stabil und sicher',
    xiangqing5: 'Die weltweit führende Börse bietet Sicherheitsschutz auf Bankniveau für Ihr Vermögen',
    tishi6: 'FAQ',
    xiangqing6: 'Ausführliche Beschreibungen bestimmter Funktionen finden Sie in den FAQ',
    fanhui:"zurückkehren",
    shishihangqing:'Echtzeit-Kurse',
    },
    footer: {
    bangzhuzhongxin: 'Hilfecenter',
    dianhuazixun: 'Telefonische Beratung',
    dianhuahegongzuoshijian: '1670-9756 (10:00–19:00 Uhr an Wochentagen/geschlossen an Wochenenden und Feiertagen)',
    liaotianzixun: '1:1 Chat-Beratung',
    keketuozixun: 'Cocotok-Beratung',
    zaixianbangzhu: 'Online-Hilfe',
    h24kaifang: '(24 Stunden geöffnet)',
    lixianbangzhu: 'Offline-Hilfe',
    gongsidizhi: '1F, Yongsan Purgio Summit, 69 Hangang-gil, Yongsan-gu, Seoul',
    gongzuoshijian: '(Wochentags von 10:00 bis 18:00 Uhr / an Wochenenden und Feiertagen geschlossen)',
    shangshizixun: 'Listungsberatung',
    hezuozixun: 'Kooperationsberatung',
    gongsi: 'Unternehmen',
    lianxiwomwn: 'Kontaktieren Sie uns',
    zhidao: 'Führung',
    yonghuzhichi: 'Benutzerunterstützung',
    zhanghuzhinan: 'Kontoführer – Einzahlung/Auszahlung',
    ruanjianxiazai: 'Software-Download',
    zhengce: 'Politik',
    fuwuyuyinsitiaokuan: 'Nutzungsbedingungen und Datenschutz',
    },
    login: {
    yonghudenglu: 'Benutzeranmeldung',
    huanyingdenglu: 'Willkommen zum Einloggen',
    xiangmumingcheng: 'BCFX',
    guanfangwangzhan: 'offizielle Website',
    youxiang: 'Post',
    qingshuruyouxiang: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
    mima: 'Passwort',
    qingshurumima: 'Bitte geben Sie das Passwort ein',
    wangjimima: 'Passwort vergessen?',
    denglu: 'Einloggen',
    haimeiyouzhanghao: 'Sie haben noch kein Konto? gehen',
    zhuce: 'registrieren',
    dengluchenggong: 'Anmeldung erfolgreich',
    denglushibai: 'Fehler bei der Anmeldung',
    qingshuruyonghuminghemima: 'Bitte geben Sie Benutzernamen und Passwort ein',
    mimachangdubunengxiaoyuliuwei: 'Die Länge des Passworts darf nicht weniger als 6 Zeichen betragen',
    youxiangrenzheng: 'E-Mail-Bestätigung',
    youxiangdizhi: 'E-Mail-Adresse',
    qingshuruyouxiangdizhi: 'Bitte geben Sie die E-Mail-Adresse ein',
    tuxingyanzhengma: 'Grafischer Bestätigungscode',
    qingshurutuxingyanzhengma: 'Bitte geben Sie den grafischen Bestätigungscode ein',
    yanzhengma: 'Bestätigungscode',
    qingshuruyouxiangyanzhengma: 'Bitte geben Sie den E-Mail-Bestätigungscode ein',
    huoquyouxiangyanzhengma: 'Bestätigungscode',
    queding: 'Sicher',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Der grafische Bestätigungscode konnte nicht abgerufen werden. Bitte fordern Sie ihn erneut an',
    qingxianwanchengyouxiangrenzheng: 'Bitte führen Sie zunächst die E-Mail-Bestätigung durch',
    huoquyouxiangyanzhengmashibai: 'Der E-Mail-Bestätigungscode konnte nicht abgerufen werden.',
    houchongxinfasong: 'Nach S erneut senden',
    youxiangyanzhengchenggong: 'E-Mail-Bestätigung erfolgreich',
    yanzhengmacuowu: 'Fehler im Bestätigungscode',
    xiugaidenglumima: 'Login-Passwort ändern',
    xinmima: 'Neues Passwort',
    quedingxinmima: 'Bestätigen Sie das neue Passwort',
    qingshuruxinmima: 'Bitte geben Sie ein neues Passwort ein',
    qingzaicishuruxinmima: 'Bitte geben Sie erneut das neue Passwort ein',
    liangcimimashurubuyizhi: 'Die beiden Passworteingaben sind inkonsistent',
    mimaxiugaichenggong: 'Passwort erfolgreich geändert',
    xiugaimimashibai: 'Passwort konnte nicht geändert werden',
    },
    trade: {
    hangqingheyue: 'Vertrag',
    hangqingzuixinjia: 'aktueller Preis',
    hangqingzhangdiefu: 'Erhöhen oder verringern',
    hangqingzuigao24h: '24H am höchsten',
    hangqingzuidi24h: '24H Mindestens ',
    hangqingchengjiaoliang24h: '24H Umsatz',
    hangqingchengjiabishu: 'Anzahl der Transaktionen',
    zhanghuquanyi: 'Kontorechte',
    zhanghukeyongshouyi: 'Verfügbare Vorteile',
    hangqingweituoliebiao: 'Bestellliste',
    hangqingjiage: 'Preis',
    hangqingshuliang: 'Menge',
    hangqingzuixinchengjiao: 'Letzte Transaktion',
    hangqingjiage1: 'Preis',
    hangqingshuliang1: 'Menge',
    hangqingshijian: 'Zeit',
    dingdanchiyoucangwei: 'Halten Sie eine Position',
    dingdandangqianweituo: 'Aktuelle Bestellung',
    dingdanchengjiaojilu: 'Transaktionsaufzeichnung',
    dingdantiaojiandan: 'bedingte Anordnung',
    chicangheyue: 'Vertrag',
    chicangkaicangjunjia: 'Positionspreis',
    chicangfudongyingkui: 'Gewinn und Verlust',
    chicanggangganbeishu: 'Hebelwirkung',
    chicangchicangliang: 'Position(en)',
    chicangduokong: 'Lang/Kurz',
    chicangduo: 'viele',
    chicangkong: 'null',
    chicangzhiyingjiage: 'Take-Profit-Preis',
    chicangzhisunjiage: 'Stop-Loss-Preis',
    chicangqiangpingyugujiage: 'Geschätzter Liquidationspreis',
    chicangcaozuo: 'arbeiten',
    chicangzhiyingzhisun: 'Gewinn mitnehmen und Verlust stoppen',
    chicangpingcang: 'Schließen ',
    chicangshouxufei: 'Bearbeitungsgebühr',
    weituoheyue: 'Vertrag',
    weituoshuliang: 'Bestellmenge',
    weituojiage: 'Provisionspreis',
    weituofangxiang: 'Richtung',
    weituogangganbeishu: 'Nutzen Sie mehrere',
    weituochengjiaoshuliang: 'Transaktionsmenge',
    weituoshouxufei: 'Bearbeitungsgebühr',
    weituochengjiaojunjia: 'Durchschnittspreis',
    weituoshouyi: 'Einkommen',
    weituozhuangtai: 'Zustand',
    weituochengjiaoshijian: 'Transaktionszeit',
    weituocaozuo: 'arbeiten',
    weituochedan: 'Bestellung stornieren',
    weituotijiaoshibai: 'Die Übermittlung ist fehlgeschlagen',
    weituozhilingtijiao: 'Übermittlung von Anweisungen',
    weituoyitijiao: 'Eingereicht',
    weituobufenchengjiao: 'Teilgeschäft',
    weituobufenchengjiaoyichedan: 'Einige Bestellungen wurden storniert',
    weituowanquanchengjiao: 'Kompletter Deal',
    weituowanquanchedan: 'Bestellung vollständig stornieren',
    weituochedanzhong: 'Bestellung stornieren',
    weituokaiduo: 'Lange geöffnet',
    weituopingkong: 'flacher Himmel',
    weituokaikong: 'Kurz öffnen',
    weituopingduo: 'Pinduo',
    chengjiaoheyue: 'Vertrag',
    chengjiaofangxiang: 'Richtung',
    chengjiaoshuliang: 'Transaktionsmenge',
    chengjiaojiage: 'Transaktionspreis',
    chengjiaoshijian: 'Transaktionszeit',
    chengjiaopingcangyingkui: 'Gewinn und Verlust',
    chengjiaoshouxufei: 'Transaktionsgebühr',
    chengjiaokaiduo: 'Lange geöffnet',
    chengjiaopingkong: 'flacher Himmel',
    chengjiaokaikong: 'Kurz öffnen',
    chengjiaopingduo: 'Pinduo',
    tiaojianzengjiatiaojian: 'Bedingungen hinzufügen',
    tiaojianzantingquanbu: 'Alles pausieren',
    tiaojianqidongquanbu: 'Beginnen Sie alles',
    tiaojianshanchuquanbu: 'Alles löschen',
    tiaojianheyue: 'Vertrag',
    tiaojianweituoshuliang: 'Bestellmenge',
    tiaojianfangxiang: 'Richtung',
    tiaojiantiaojianleixing: 'Konditionsart',
    tiaojianjiageleixing: 'Preisart',
    tiaojianbijiaofuhao: 'Vergleichssymbol',
    tiaojiantiaojian: 'Zustand',
    tiaojianzhuangtai: 'Zustand',
    tiaojianchuangjianshijian: 'Schöpfungszeit',
    tiaojiancaozuo: 'arbeiten',
    tiaojianxiugai: 'Überarbeiten',
    tiaojianqidong: 'Start-up',
    tiaojianzanting: 'Pause',
    tiaojianshanchu: 'löschen',
    tiaojianlijixiadan: 'Jetzt bestellen',
    tiaojiankaiduo: 'Lange geöffnet',
    tiaojianpingkong: 'flacher Himmel',
    tiaojiankaikong: 'Kurz öffnen',
    tiaojianpingduo: 'Pinduo',
    tiaojianjiagetiaojian: 'Preisbedingungen',
    tiaojianshijiantiaojian: 'Zeitbedingung',
    tiaojianzuixinjia: 'Aktueller Preis',
    tiaojianByijia: 'Kaufen Sie einen Preis',
    tiaojianSyijia: 'Verkaufe einen Preis',
    tiaojianzanting: 'Pause',
    tiaojianyunxing: 'Laufen',
    tiaojianyiwancheng: 'Vollendet',
    tiaojianshibai: 'Scheitern',
    tiaojianyishanchu: 'Gelöscht',
    tiaojianshoudongchufawancheng: 'Manueller Auslöser abgeschlossen',
    tiaojianshoudongchufashibai: 'Der manuelle Auslöser ist fehlgeschlagen',
    xiadankaicang: 'Offen',
    xiadanpingcang: 'Schließen',
    xiadanweituoleixing: 'Delegatentyp',
    xiadanqingxuanze: 'Bitte auswählen',
    xiadanshijia: 'Marktpreis',
    xiadanxianjia: 'Preislimit',
    xiadanjiage: 'Preis',
    xiadanqingshurujiage: 'Bitte geben Sie den Preis ein',
    xiadanshuliang: 'Menge',
    xiadanqingshurushuliang: 'Bitte Menge eingeben',
    xiadanzhang: 'offen',
    xiadankekaishuliang: 'Verfügbare Menge',
    xiadanqingxianxuanzechicangdan: 'Bitte wählen Sie zunächst die Positionsreihenfolge aus',
    xiadankepingshuliang: 'Menge, die nivelliert werden kann',
    xiadansuoxubaozhengjin: 'Erforderliche Marge:',
    xiadanshijiazhiyingzhisunyushe: 'Marktpreis Take-Profit und Stop-Loss voreingestellt',
    xiadanyujiqiangpingjiage: 'Geschätzter Liquidationspreis:',
    xiadanqiangpingjiagetishi: 'Dieser erwartete Liquidationspreis wird dynamisch basierend auf dem Gesamtvermögen berechnet. Dieser Preis dient nur als Referenz und wird nicht als endgültige Liquidation verwendet.',
    xiadanzuoduo: 'Lange',
    xiadanzuokong: 'kurz',
    xiadanpingcang: 'schließen',
    zhanghuheyuezichan: 'Vertragsvermögenswerte',
    zhanghuchongbi: 'Nachfüllen',
    zhanghutibi: 'Münzen abheben',
    zhanghuhuazhuan: 'überweisen',
    zhanghuzhanghuquanyi: 'Kontorechte',
    zhanghufudongyingkui: 'variabler Gewinn und Verlust',
    zhanghucangweibaozhengjin: 'Positionsrand',
    zhanghudongjiebaozhengjin: 'Rand einfrieren',
    zhanghukeyongquanyi: 'Verfügbare Vorteile',
    zhanghubaozhengjinshiyonglv: 'Margenauslastungsgrad',
    hangqingheyuexinxi: 'Vertragsinformationen',
    hangqingheyuemingcheng: 'Vertragsname',
    hangqingjijiahuobi: 'Notierungswährung',
    hangqingheyuedaxiao: 'Vertragsgröße',
    hangqingzuixiaojiagebodong: 'minimale Preisschwankung',
    dialogleverRateganggantiaozheng: 'Hebelanpassung',
    dialogleverRatequeding: 'Sicher',
    dialogtransferzichanhuazhuan: 'Vermögensübertragung',
    dialogtransferbizhong: 'Währung',
    dialogtransfercong: 'aus',
    dialogtransferdao: 'ankommen',
    dialogtransfershuliang: 'Menge',
    dialogtransferqingshuruhuazhuanshuliang: 'Bitte geben Sie den Überweisungsbetrag ein',
    dialogtransferquanbu: 'alle',
    dialogtransferkeyong: 'Verfügbar',
    dialogtransferhuazhuan: 'überweisen',
    dialogtransferjichuzhanghu: 'Basiskonto',
    dialogtransferheyuezhanghu: 'Vertragskonto',
    dialogfitlosszhiyingzhisun: 'Gewinn mitnehmen und Verlust stoppen',
    dialogfitlossheyu: 'Vertrag',
    dialogfitlossduokong: 'Lang/Kurz',
    dialogfitlosschichangjunjia: 'durchschnittlicher Positionspreis',
    dialogfitlossshuliang: 'Menge',
    dialogfitlosszuixinjia: 'aktueller Preis',
    dialogfitlosszhiyingdianshu: 'Gewinnpunkte mitnehmen',
    dialogfitlosszhisundianshu: 'Stop-Loss-Punkte',
    dialogfitlossdianshu: 'Punktzahl',
    dialogfitlosszhiyingjiage: 'Take-Profit-Preis',
    dialogfitlosszhisunjiage: 'Stop-Loss-Preis',
    dialogfitlossjiage: 'Preis',
    dialogfitlossyuqishouyi: 'erwartete Rendite',
    dialogfitlossyuqikuisun: 'erwarteter Verlust',
    dialogfitlosstishi1: 'Tipp: Die Mindestschwankung des aktuellen Vertrags beträgt',
    dialogfitlosstishi2: 'Bitte setzen Sie den Preis auf 0, um Take-Profit und Stop-Loss zu stornieren.',
    dialogfitlossqueding: 'Sicher',
    dialogfitlossduo: 'viele',
    dialogfitlosskong: 'null',
    dialogconditionzengjiayuntiaojiandan: 'Wolkenzustandsblatt hinzufügen',
    dialogconditiontiaojianleixing: 'Konditionstyp:',
    dialogconditionjiagetiaojiandan: 'Preisblatt',
    dialogconditionshijiantiaojiandan: 'Zeitkonditionsblatt',
    dialogconditionjiageleixing: 'Preisart:',
    dialogconditionzuixinjia: 'aktueller Preis',
    dialogconditionByijia: 'Kaufen Sie einen Preis',
    dialogconditionSyijia: 'Verkaufe einen Preis',
    dialogconditiontiaojianshezhi: 'Bedingungseinstellungen:',
    dialogconditionjiageshezhi: 'Preiseinstellungen:',
    dialogconditionqingshurujiage: 'Bitte geben Sie den Preis ein',
    dialogconditionshijianshezhi: 'Zeiteinstellung:',
    dialogconditionjiaoyileixing: 'Transaktionstyp:',
    dialogconditionkaiduo: 'Lange geöffnet',
    dialogconditionpingkong: 'flacher Himmel',
    dialogconditionkaikong: 'Kurz öffnen',
    dialogconditionpingduo: 'Pinduo',
    dialogconditiondingdanshuliang: 'Bestellmenge:',
    dialogconditionqingshurudingdanshuliang: 'Bitte geben Sie die Bestellmenge ein',
    dialogconditiondingdanshixiao: 'Bestellzeit:',
    dialogconditionyongjiuyouxia: 'Dauerhaft gültig',
    dialogconditiontijiao: 'einreichen',
    dialogAutoProfitLosszhiyingzhisun: 'Gewinn mitnehmen und Verlust stoppen',
    dialogAutoProfitLossmingcheng: 'Name',
    dialogAutoProfitLosszhiyingdian: 'Nehmen Sie den Gewinnpunkt',
    dialogAutoProfitLosszhisundian: 'Stop-Loss-Punkt',
    dialogAutoProfitLosschongzhi: 'zurücksetzen',
    dialogAutoProfitLoss: 'einreichen',
    dialogCloseOrderpingcang: 'schließen',
    dialogCloseOrderweituoleixing: 'Delegatentyp',
    dialogCloseOrderqingxuanze: 'Bitte auswählen',
    dialogCloseOrdershijia: 'Marktpreis',
    dialogCloseOrderxianjia: 'Preislimit',
    dialogCloseOrderjiage: 'Preis',
    dialogCloseOrderqingshurujiage: 'Bitte geben Sie den Preis ein',
    dialogCloseOrdershuliang: 'Menge',
    dialogCloseOrderqingshurushuliang: 'Bitte Menge eingeben',
    dialogCloseOrderzhang: 'offen',
    dialogCloseOrderkepingshuliang: 'Menge, die nivelliert werden kann',
    dialogCloseOrderqueding: 'Sicher',
    jsMessageResgaiheyuegangganxiugaichenggong: 'Der Vertragshebel wurde erfolgreich geändert',
    jsMessageResgaiheuyegangganxiugaishibai: 'Die Änderung der Vertragshebelwirkung ist fehlgeschlagen',
    jsMessageReskaicangchenggong: 'Position erfolgreich eröffnet',
    jsMessageReskaicangshibai: 'Positionseröffnung fehlgeschlagen:',
    jsMessageReskaicang1: 'Das Eröffnen einer Position ist verboten',
    jsMessageReskaicang2: 'Vertrag existiert nicht',
    jsMessageReskaicang3: 'Die Anzahl der offenen Positionen überschreitet die Obergrenze',
    jsMessageReskaicang4: 'Preis ist 0',
    jsMessageReskaicang5: 'Unzureichende Marge zum Eröffnen einer Position',
    jsMessageRespingcangchenggong: 'Position erfolgreich geschlossen',
    jsMessageRespingcangshibai: 'Position konnte nicht geschlossen werden:',
    jsMessageRespingcang1: 'Vertrag existiert nicht',
    jsMessageRespingcang2: 'Der Positionseingang ist nicht vorhanden',
    jsMessageRespingcang3: 'Unzureichende Menge',
    jsMessageReszijinhuazhuanchenggong: 'Geldtransfer erfolgreich',
    jsMessageReszijinhuazhuanshibai: 'Die Überweisung ist fehlgeschlagen',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'Positionsauftrag Stop-Profit und Stop-Loss erfolgreich gesetzt',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'Die Stop-Profit- und Stop-Loss-Einstellungen für Positionsaufträge sind fehlgeschlagen:',
    jsMessageResweituodanchedanchenggong: 'Bestellung erfolgreich storniert',
    jsMessageResweituodanchedanshibai: 'Die Stornierung der Bestellung ist fehlgeschlagen',
    jsMessageRestiaojiandantianjiachenggong: 'Bedingte Bestellung erfolgreich hinzugefügt',
    jsMessageResheyuebucunzai: 'Vertrag existiert nicht',
    jsMessageResjiageshezhicuowu: 'Fehler bei der Preiseinstellung',
    jsMessageResshijianshezhicuowu: 'Fehler bei der Zeiteinstellung',
    jsMessageResneibucuowu: 'Interner Fehler',
    jsMessageRestiaojiandantianjiashibai: 'Bedingte Bestellung konnte nicht hinzugefügt werden:',
    jsMessageReszhiyingzhisunshezhichenggong: 'Gewinnmitnahme und Stop-Loss erfolgreich gesetzt',
    jsconfirmquedingchedanma: 'Sind Sie sicher, dass Sie die Bestellung stornieren möchten?',
    jsconfirmtishi: 'Hinweis',
    jsconfirmqueding: 'Sicher',
    jsconfirmquxiao: 'Stornieren',
    jsMessageqingxiandenglu: 'Bitte melden Sie sich zuerst an!',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'Dieser Kontrakt hat offene Positionen und der Hebel kann nicht geändert werden.',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'Dieser Vertrag hat ein Mandat und die Hebelwirkung kann nicht geändert werden.',
    jsMessageqingshuruzhengquedeshuliang: 'Bitte geben Sie die richtige Menge ein!',
    jsMessageqingshuruzhengquedejiage: 'Bitte geben Sie den korrekten Preis ein!',
    jsMessageqingxianxuanzechicang: 'Bitte wählen Sie zuerst eine Position aus!',
    jsMessageqingshuruhuazhuanshuliang: 'Bitte geben Sie den Überweisungsbetrag ein!',
    jsMessageqingxianxuanzeheyue: 'Bitte wählen Sie zunächst einen Vertrag aus!',
    jsMessageqingshurutiaojiandanshuliang: 'Bitte geben Sie die bedingte Bestellmenge ein!',
    jsMessageqingshurutiaojiandandejiage: 'Bitte geben Sie den Preis der bedingten Bestellung ein!',
    jsMessageqingshurutiaojiandanzhixingshijian: 'Bitte geben Sie die bedingte Orderausführungszeit ein!',
    emptytext: 'Noch keine Daten',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'Take-Profit- und Stop-Loss-Pips sind falsch eingestellt',
    jsMessageReschicangdanbucunzai: 'Der Positionseingang ist nicht vorhanden',
    zxkcje:'Mindesteröffnungsbetrag'
    },
    commisson:{
    dlzx:'Agenturzentrum',
    sjzl:'Datenübersicht',
    quanbu:"alle",
    jinri:'Heute',
    zuori:'gestern',
    ri:'Tag',
    wdfy:'Mein Rabatt',
    wdsjfy:'Mein Rabatt der dritten Stufe',
    dengji:'Grad',
    yqrs:'Anzahl der eingeladenen Personen',
    tdrs:'Teamgröße',
    yqxq:'Einzelheiten zur Einladung',
    yhm:"Benutzername",
    cjsj:'Schöpfungszeit',
    fyxq:'Rabattdetails',
    zyj:'Gesamtprovision',
    dryj:'Eintägige Provision',
    fyje:'Rabattbetrag',
    
    },
    assets: {
    zongzichan: 'Gesamtvermögen',
    chongbi: 'Nachfüllen',
    tibi: 'Münzen abheben',
    huazhuan: 'überweisen',
    fabizhanghu: 'Basiskonto',
    heyuezhanghu: 'Vertragskonto',
    jiayiliushui: 'Transaktionsfluss',
    dialogzichanhuazhuan: 'Vermögensübertragung',
    cong: 'aus',
    dao: 'ankommen',
    shuliang: 'Menge',
    qingshuruhuazhuanshuliang: 'Bitte geben Sie den Überweisungsbetrag ein',
    quanbu: 'alle',
    keyong: 'Verfügbar',
    huanzhuan: 'überweisen',
    zijinhuazhuanchenggong: 'Geldtransfer erfolgreich',
    zijinhuazhuanshibai: 'Die Überweisung ist fehlgeschlagen',
    bibizhanghu: 'Münzkonto',
    qiquanzhanghu:'Optionskonto',
    zczl:'Vermögensübersicht',
    zzczh:'Gesamtvermögensabschlag',
    ztzc:"Vermögenswerte im Transport",
    djzc:'Vermögenswerte einfrieren',
    zyzc:'Verpfändete Vermögenswerte',
    cwzc:'Vermögenswerte positionieren',
    jyzh:'Handelskonto',
    bizhong:'Währung',
    dongjie:'einfrieren',
    lirun:"profitieren",
    hyzh:"Vertragskonto",
    },
    basicsAccountRecord: {
    fanhui: 'zurückkehren',
    fabizhanghuliushui: 'Handelskontofluss',
    fabijiaoyiliushuileixing: 'Transaktionsflusstyp',
    qingxuanze: 'Bitte auswählen',
    chaxunriqi: 'Datum abfragen',
    liushuileixing: 'Pipelinetyp',
    shijian: 'Zeit',
    biandongjine: 'Betrag ändern',
    yue: 'Gleichgewicht',
    quanbu: 'alle',
    Sellbi: 'Münzen verkaufen',
    Buybi: 'Münzen kaufen',
    tibi: 'Münzen abheben',
    chongbi: 'Nachfüllen',
    fabizhuanheyue: 'Basistransfervertrag',
    heyuezhuanfabi: 'Vertrag zur Basis',
    zhuanzhang: 'überweisen',
    fabihuazhuandaoheyue: 'Grundlegende Übertragung auf Vertrag',
    heyuehuazhuandaofabi: 'Vertragsübergabe an die Basis',
    goumaijijin: 'Kaufen Sie einen Mining-Pool',
    jijinshuhui: 'Einlösung des Mining-Pools',
    jijinshouyi: 'Einnahmen aus der Rücknahme des Mining-Pools',
    fabizhuanqihuo: 'Grundlegende Umstellung auf Futures',
    qihuozhuanfabi: 'Futures-Transferbasis',
    fabizhuanganggu: 'Einfache Übertragung auf Aktien in Hongkong',
    gangguzhuanfabi: 'Grundlagen des Aktientransfers in Hongkong',
    fabizhuanbibi: 'Grundlegende Überweisungswährung',
    bibizhuanfabi: 'Grundlagen des Währungstransfers',
    goumaiSGCB: 'Kaufen Sie ICO',
    chushouSGCB: 'ICO verkaufen',
    huoqushibai: 'Fehler beim Abrufen',
    zanwushuju: 'Noch keine Daten',
    },
    contractAccountRecord: {
    fanhui: 'zurückkehren',
    heyuezhanghuliushui: 'Vertragskontofluss',
    heyuejiaoyiliushuileixing: 'Vertragstransaktionsflusstyp',
    qingxuanze: 'Bitte auswählen',
    chaxunriqi: 'Datum abfragen',
    liushuileixing: 'Pipelinetyp',
    shijian: 'Zeit',
    biandongjine: 'Betrag ändern',
    yue: 'Gleichgewicht',
    quanbu: 'alle',
    kaicangshouxufei: 'Eröffnungsgebühr',
    pingcangshouxufei: 'Abschlussgebühr',
    pingcangyingkui: 'Positionsabschlussgewinn und -verlust',
    fabizhuanheyue: 'Basistransfervertrag',
    heyuezhuanfabi: 'Vertrag zur Basis',
    zhuanzhang: 'überweisen',
    kaicangyongjin: 'Eröffnungskommission',
    pingcangyongjin: 'Abschlusskommission',
    huoqushibai: 'Fehler beim Abrufen',
    zanwushuju: 'Noch keine Daten',
    },
    recharge: {
    zanwushuju: 'Noch keine Daten',
    shijian: 'Zeit',
    leixing: 'Typ',
    shuliang: 'Menge',
    zhuangtai: 'Zustand',
    caozuo: 'arbeiten',
    chakanxiangqing: 'Überprüfen Sie die Details',
    huoqushibai: 'Fehler beim Abrufen',
    dialogtitle: 'Einzelheiten',
    chongbi: 'Nachfüllen',
    fanhui: 'zurückkehren',
    lianmingcheng: 'Kettenname',
    fuzhilianjie: 'Adresse kopieren',
    tishixinxi1: '• Bitte hinterlegen Sie keine Nicht-TRC_USDT- oder ERC_USDT-Vermögenswerte an der oben genannten Adresse, da die Vermögenswerte sonst nicht abgerufen werden',
    tishixinxi2: '• Nach dem Aufladen an die oben genannte Adresse ist die Bestätigung des gesamten Netzwerkknotens erforderlich. Das Konto wird nach 1 Netzwerkbestätigung empfangen und die Währung kann nach 2 Netzwerkbestätigungen abgehoben werden.',
    tishixinxi3: '• Mindesteinzahlungsbetrag: 50USDT, Einzahlungen unter dem Mindestbetrag werden nicht gutgeschrieben und können nicht erstattet werden',
    tishixinxi4: '• Ihre Aufladeadresse ändert sich nicht häufig und kann wiederholt aufgeladen werden. Wir werden unser Bestes tun, um Sie durch Website-Ankündigungen oder E-Mails zu benachrichtigen',
    tishixinxi5: '• Bitte stellen Sie sicher, dass Ihr Computer und Browser sicher sind, um zu verhindern, dass Informationen manipuliert werden oder nach außen dringen.',
    putongchongbi: 'Normale Anzahlung',
    jiaoyichenggong: 'Transaktion erfolgreich',
    jiaoyishibai: 'Die Transaktion ist fehlgeschlagen',
    OMNIweihuzhong: 'Die OMNI-Kette wird gerade gewartet. Bitte wählen Sie TRC20',
    },
    withdraw: {
    fanhui: 'zurückkehren',
    zanwushuju: 'Noch keine Daten',
    tibi: 'Münzen abheben',
    huoqushibai: 'Fehler beim Abrufen',
    OMNIweihuzhong: 'Die OMNI-Kette wird gerade gewartet. Bitte wählen Sie TRC20',
    shijian: 'Zeit',
    leixing: 'Typ',
    shuliang: 'Menge',
    zhuangtai: 'Zustand',
    caozuo: 'arbeiten',
    chakanxiangqing: 'Überprüfen Sie die Details',
    putongtibi: 'Gewöhnlicher Rückzug',
    dialogtitle: 'Einzelheiten',
    dialogtitlewithdraw: 'Münzen abheben',
    houchongxinfasong: 'Nach S erneut senden',
    huoquyouxiangyanzhengma: 'Bestätigungscode',
    lianmingcheng: 'Kettenname',
    tibidizhi: 'Auszahlungsadresse',
    qingshurutibidizhi: 'Bitte geben Sie die Auszahlungsadresse ein',
    tibishuliang: 'Anzahl der abgehobenen Münzen',
    shouxufei: 'Bearbeitungsgebühr',
    qingshurutibishuliang: 'Bitte geben Sie den Auszahlungsbetrag ein',
    quanbu: 'alle',
    keyong: 'Verfügbar',
    daozhangshuliang: 'Ankunftsmenge',
    zuixiaotibishuliang: 'Der Mindestauszahlungsbetrag beträgt:',
    tishixinxi: 'Um die Sicherheit der Gelder zu gewährleisten, führen wir bei einer Änderung Ihrer Kontosicherheitsrichtlinie oder einer Änderung Ihres Passworts eine manuelle Überprüfung der Auszahlung durch. Bitte haben Sie etwas Geduld und warten Sie, bis sich die Mitarbeiter telefonisch oder per E-Mail mit Ihnen in Verbindung setzen. Bitte stellen Sie sicher, dass Ihr Computer und Browser sicher sind, um zu verhindern, dass Daten manipuliert werden oder verloren gehen.',
    tuxingyanzhengma: 'Grafischer Bestätigungscode',
    qingshurutuxingyanzhengma: 'Bitte geben Sie den grafischen Bestätigungscode ein',
    youxiangyanzhengma: 'E-Mail-Bestätigungscode',
    queding: 'Sicher',
    tijiaozhong: 'Einreichen',
    yijujue: 'Abgelehnt',
    yitongguo: 'Bestanden',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Der grafische Bestätigungscode konnte nicht abgerufen werden. Bitte fordern Sie ihn erneut an',
    huoquyouxiangyanzhengmashibai: 'Der E-Mail-Bestätigungscode konnte nicht abgerufen werden.',
    qingxianwanchengyouxiangrenzheng: 'Bitte führen Sie zunächst die E-Mail-Bestätigung durch',
    huoquyouxiangshibaiqingchongxinqingqiu: 'E-Mail-Adresse konnte nicht abgerufen werden, bitte erneut anfordern',
    qingshuruyouxiangyanzhengma: 'Bitte geben Sie den E-Mail-Bestätigungscode ein',
    youxiangyanzhengmacuowu: 'Der E-Mail-Bestätigungscode ist falsch.',
    qxsmrz:'Bitte bestätigen Sie zunächst Ihren echten Namen',
    tishi:'Hinweis',
    queding:'Sicher'
    },
    contractTransactionRecord: {
    fanhui: 'zurückkehren',
    zanwushuju: 'Noch keine Daten',
    heyuedingdan: 'Vertragsauftrag',
    qingxuanze: 'Bitte auswählen',
    quanbu: 'alle',
    kaiduo: 'Lange geöffnet',
    pingkong: 'flacher Himmel',
    kaikong: 'Kurz öffnen',
    pingduo: 'Pinduo',
    xianjiaweituo: 'Limit-Order',
    shijiaweituo: 'Marktordnung',
    suoyouzhuangtai: 'Alle Status',
    tijiaoshibai: 'Die Übermittlung ist fehlgeschlagen',
    zhunbeitijiao: 'bereit zur Abgabe',
    yitijiao: 'Eingereicht',
    bufentijiao: 'Teilgeschäft',
    bufentijiaoyichedan: 'Einige Bestellungen wurden storniert',
    quanbuchengjiao: 'Alle Transaktionen',
    yichedan: 'Bestellung storniert',
    huoqushibai: 'Fehler beim Abrufen',
    heyuedaima: 'Vertragscode',
    ganggan: 'Hebel',
    xiadanshijian: 'Bestellzeit',
    chengjiaoshijian: 'Transaktionszeit',
    fangxiang: 'Richtung',
    baojialeixing: 'Angebotstyp',
    weituoliang: 'Höhe der Provision (Stück)',
    weituojia: 'Bestellpreis (USDT)',
    chengjiaoliang: 'Handelsvolumen (Tickets)',
    chengjiaojunjia: 'Durchschnittlicher Transaktionspreis (USDT)',
    yingkui: 'Gewinn und Verlust (USDT)',
    shouxufei: 'Bearbeitungsgebühr (USDT)',
    zhuangtai: 'Zustand',
    gupiaodingdan: 'Lagerbestellung',
    waihuidingdan: 'Forex-Bestellungen',
    gupiaodaima: 'Lagercode',
    waihuidaima: 'Forex-Code',
    },
    kLine: {
    zhibiao: 'Index',
    shezhi: 'aufstellen',
    quanping: 'Vollbild',
    tuichuquanping: 'Verlassen Sie den Vollbildmodus',
    jishuzhibiao: 'Technische Indikatoren',
    futuzhibiao: 'Unterbildanzeige',
    zhutuzhibiao: 'Hauptindikator des Diagramms',
    huifumoren: 'Standard wiederherstellen',
    lazhutuleixing: 'Candlestick-Typen',
    qingxuanze: 'Bitte auswählen',
    quanshixin: 'Alles solide',
    quankongxin: 'Vollständig hohl',
    zhangkongxin: 'steigende Mulde',
    diekongxin: 'hohl fallen',
    mianjitu: 'Flächendiagramm',
    jiagezhouleixing: 'Preisachsentyp',
    xianxingzhou: 'Linienachse',
    baifenbizhou: 'Prozentachse',
    duishuzhou: 'Logarithmische Achse',
    zuigaojiaxianshi: 'Höchste Preisanzeige',
    zuidijiaxianshi: 'Niedrigste Preisanzeige',
    zuixinjiaxianshi: 'Aktuelle Preisanzeige',
    zhibiaozuixinzhixianshi: 'Der aktuelle Wert des Indikators wird angezeigt',
    daozhizuobiao: "invertierte Koordinaten",
    wanggexianxianshi: 'Gitterlinienanzeige',
    hfcg:'Wiederherstellung erfolgreich'
    },
    SecurityCenter: {
    fanhui: 'zurückkehren',
    anquanzhongxin: 'Sicherheitscenter',
    qingshurunicheng: 'Bitte geben Sie einen Spitznamen ein',
    nichengxiugaishibai: 'Die Änderung des Spitznamens ist fehlgeschlagen',
    huoquyonghuxinxishibai: 'Benutzerinformationen konnten nicht abgerufen werden',
    xiugaidenglumima: 'Login-Passwort ändern',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Der grafische Bestätigungscode konnte nicht abgerufen werden. Bitte fordern Sie ihn erneut an',
    qingshurujiumima: 'Bitte geben Sie das alte Passwort ein',
    qingshuruxinmima: 'Bitte geben Sie ein neues Passwort ein',
    qingzaicishuruxinmima: 'Bitte geben Sie erneut das neue Passwort ein',
    qingshurutuxingyanzhengma: 'Bitte geben Sie den grafischen Bestätigungscode ein',
    qingshuruyanzhengma: 'Bitte geben Sie den Bestätigungscode ein',
    huoquzhuceshoujihaoshibai: 'Die registrierte Mobiltelefonnummer konnte nicht abgerufen werden',
    qingxianwanchengshoujirenzheng: 'Bitte führen Sie zunächst die Mobiltelefonauthentifizierung durch',
    huoqushoujiyanzhengmashibai: 'Der Handy-Bestätigungscode konnte nicht abgerufen werden',
    huoqushoujiyanzhengma: 'Holen Sie sich den Bestätigungscode für Ihr Mobiltelefon',
    houchongxinfasong: 'Nach S erneut senden',
    mimacuowu: 'Falsches Passwort',
    liangcixinmimashurubuyizhiqingjiancha: 'Die beiden neu eingegebenen Passwörter stimmen nicht überein. Bitte überprüfen Sie dies',
    xinmimaheyuanmimabunengyiyang: 'Das neue Passwort darf nicht mit dem ursprünglichen Passwort identisch sein',
    qingshurushoujiyanzhengma: 'Bitte geben Sie den Handy-Verifizierungscode ein',
    yanzhengmacuowu: 'Fehler im Bestätigungscode',
    denglumimaxiugaichenggong: 'Login-Passwort erfolgreich geändert',
    denglumimaxiugaishibai: 'Die Änderung des Anmeldekennworts ist fehlgeschlagen',
    xiugaizijinmima: 'Fondspasswort ändern',
    qingshuruzijinmima: 'Bitte geben Sie das Fondspasswort ein',
    qingzaicishuruzijinmima: 'Bitte geben Sie das Fondspasswort erneut ein',
    liangcizijinmimabuyizhiqingjiancha: 'Die Passwörter der beiden Fonds stimmen nicht überein. Bitte überprüfen Sie dies',
    shezhizijinmimachenggong: 'Fondspasswort erfolgreich festgelegt',
    shezhizijinmimashibai: 'Das Fondspasswort konnte nicht festgelegt werden',
    xiugaizijinmimachenggong: 'Das Fondspasswort wurde erfolgreich geändert',
    xiugaizijinmimashibai: 'Das Fondspasswort konnte nicht geändert werden',
    youxiangrenzheng: 'E-Mail-Authentifizierung',
    huoquyouxiangyanzhengma: 'Bestätigungscode',
    qingshuruyouxiangdizhi: 'Bitte geben Sie die E-Mail-Adresse ein',
    huoquyouxiangyanzhengmashibai: 'Der E-Mail-Bestätigungscode konnte nicht abgerufen werden',
    qingshuruyouxiangyanzhengma: 'Bitte geben Sie den E-Mail-Bestätigungscode ein',
    youxiangyanzhengchenggong: 'E-Mail-Bestätigung erfolgreich',
    weirenzheng: 'Nicht zertifiziert',
    queding: 'Sicher',
    anquanshezhiguanli: 'Verwaltung der Sicherheitseinstellungen',
    denglumima: 'Login-Passwort',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'Wenn Sie ein Anmeldekennwort festlegen, können Sie sich direkt mit Ihrem Kontokennwort anmelden',
    xiugai: 'Überarbeiten',
    zijinmima: 'Passwort für den Fonds',
    yongyubaohuzijinanquan: 'Wird zum Schutz der Sicherheit von Geldern verwendet',
    shezhi: 'aufstellen',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'Wird zur Sicherheitsüberprüfung beim Anmelden, Abheben von Münzen, Abrufen von Passwörtern und Ändern von Sicherheitseinstellungen verwendet.',
    yirenzheng: 'Zertifiziert',
    renzheng: 'Zertifizierung',
    shimingrenzheng: 'Authentifizierung mit echtem Namen',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'Wird zur Sicherheitsauthentifizierung beim Kauf, Verkauf, Abheben, Einzahlen und Übertragen von Münzen verwendet.',
    daishenhe: 'Ausstehende Überprüfung',
    yibohui: 'Entlassen',
    yuandenglumima: 'Ursprüngliches Login-Passwort',
    xinmima: 'Neues Passwort',
    quedingxinmima: 'Bestätigen Sie das neue Passwort',
    tuxingyanzhengma: 'Grafischer Bestätigungscode',
    yanzhengma: 'Bestätigungscode',
    zijinmima: 'Passwort für den Fonds',
    querenzijinmima: 'Bestätigen Sie das Fondspasswort',
    youxiangdizhi: 'E-Mail-Adresse',
    qingxianwanchengyouxiangrenzheng: 'Bitte führen Sie zunächst die E-Mail-Bestätigung durch',
    xiugaitouxiangchenggong: 'Avatar erfolgreich geändert',
    xiugaitouxiangshibai: 'Avatar konnte nicht geändert werden',
    shangchuantouxiangzhinengshijpggeshi: 'Avatar-Bilder können nur im JPG-Format hochgeladen werden!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'Die Größe des hochgeladenen Avatarbildes darf 2 MB nicht überschreiten!',
    },
    verification: {
    fanhui: 'zurückkehren',
    shimingrenzheng: 'Authentifizierung mit echtem Namen',
    qingshuruxingming: 'Bitte Namen eingeben',
    qingshuruzhengjianhaoma: 'Bitte geben Sie Ihre ID-Nummer ein',
    huoquyonghuxinxishibai: 'Benutzerinformationen konnten nicht abgerufen werden',
    wenjiangeshicuowu: 'Dateiformatfehler',
    wenjianbunengdayu5M: 'Die Datei darf nicht größer als 5 MB sein',
    qingtianxiexingming: 'Bitte geben Sie Ihren Namen ein',
    qingtianxiezhengjianhaoma: 'Bitte geben Sie die ID-Nummer ein',
    qingshangchuanzhengjianzhengmian: 'Bitte laden Sie die Vorderseite Ihres Ausweises hoch',
    qingshangchuanzhengjianfanmian: 'Bitte laden Sie die Rückseite Ihres Ausweises hoch',
    tijiaochenggong: 'Übermittlung erfolgreich',
    kaiqishenfenrenzheng: 'Aktivieren Sie die Identitätsauthentifizierung',
    bohuiyuanyin: 'Grund für die Ablehnung',
    qingtianxiejiashizhizhaogongminkahuzhao: 'Bitte geben Sie Ihre Führerschein-/Bürgerausweis-/Reisepassdaten ein',
    xingming: 'Name',
    zhengjianhaoma: 'ID-Nummer',
    qingshangchuanjiashizhizhaogongminkahuzhao: 'Bitte laden Sie die Informationen zu Führerschein/Bürgerkarte/Reisepass hoch',
    shangchuanzhengjianzhengmian: 'Laden Sie die Vorderseite des Ausweises hoch',
    huotuozhuafangru: 'Oder per Drag-and-Drop',
    shangchuanzhengjianfanmian: 'Laden Sie die Rückseite des Zertifikats hoch',
    tijiao: 'einreichen',
    },
    invite: {
    yaoqinghaoyoudejiangli: 'Lade Freunde ein und erhalte Belohnungen',
    yongjiukede: 'dauerhaft verfügbar',
    jiaoyifanyong: 'Handelsrabatt',
    fasongyaoqinggeihaoyou: 'Einladung an Freunde senden',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'Laden Sie Freunde ein, BCFX zu registrieren, indem Sie den Link teilen',
    haoyouzhuce: 'Registrierung von Freunden',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'Der Freund nimmt die Einladung an, schließt die Registrierung ab und führt Transaktionen durch',
    huodexiangyingbilijiangli: 'Erhalten Sie entsprechende proportionale Belohnungen',
    qingsonghuodejiaoyishouxufeijianglifuli: 'Erhalten Sie ganz einfach Bonusvorteile für Transaktionsgebühren',
    wodezhuanshufenxiangfangshi: 'Meine exklusive Art zu teilen',
    yaoqinglianjie: 'Einladungslink',
    jiazaizhong: 'Laden...',
    fuzhilianjie: 'Link kopieren',
    yaoqingma: 'Einladungscode',
    fuzhiyaoqingma: 'Einladungscode kopieren',
    wodeyaoqing: 'meine Einladung',
    yaoqingrenshu: 'Anzahl der eingeladenen Personen',
    huodejiangli: 'Lassen Sie sich belohnen',
    huodongxize: 'Veranstaltungsdetails',
    tips1: 'Die Beziehung zwischen Plattformbenutzern besteht aus drei Ebenen, z. B.: ABCD-Benutzer bilden drei Benutzerebenen, und A ist die höchste Ebene!',
    tips2: 'Interessenbeziehungen zwischen Ebenen',
    tips201: '。',
    tips3: 'Geldprämie: Kunde A kann 40 % der Vertragstransaktionsgebühr von Kunde B + 20 % der Vertragstransaktionsgebühr von Kunde C + 10 % der Vertragstransaktionsgebühr von Kunde D erhalten',
    tips4: 'Beispiel: Kunde A hat 10 Kunden der ersten Ebene B, 10 Kunden der zweiten Ebene C und 10 Kunden der dritten Ebene D. Wenn jeder Kundenvertrag 100.000 USDT handelt',
    tips5: 'Dann die Rendite, die Kunde A erhalten kann',
    tips6: '100000*1,2%*40%*10+100000*1,2%*20%*10+100000*1,2%*10%*10=8400 USDT-Bargeldprämie, die in Echtzeit abgehoben werden kann',
    tips7: 'Wenn die Aktivität angepasst wird, unterliegt sie Aktualisierungen der BCFX-Plattform, und das endgültige Auslegungsrecht liegt bei BCFX.',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Der grafische Bestätigungscode konnte nicht abgerufen werden. Bitte fordern Sie ihn erneut an',
    qingshuruyouxiang: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
    qingshurutuxingyanzhengma: 'Bitte geben Sie den grafischen Bestätigungscode ein',
    qingshuruyanzhengma: 'Bitte geben Sie den Bestätigungscode ein',
    qingshurunindemima: 'Bitte geben Sie Ihr Passwort ein',
    qingzaicishurunindemima: 'Bitte geben Sie Ihr Passwort erneut ein',
    qingshuruzhifumima: 'Bitte geben Sie das Zahlungspasswort ein',
    xuantian: 'Optional',
    bitian: 'Erforderlich',
    yonghuzhuce: 'Benutzerregistrierung',
    huanyingdenglu: 'Willkommen zum Einloggen',
    guanfangwangzhan: 'offizielle Website',
    xiangmumingcheng: 'BCFX',
    youxiang: 'Post',
    tuxingyanzhengma: 'Grafischer Bestätigungscode',
    yanzhengma: 'Bestätigungscode',
    huoquyanzhengma: 'Bestätigungscode',
    shezhimima: 'Passwort festlegen',
    quedingmima: 'Passwort bestätigen',
    zhifumima: 'Zahlungspasswort',
    yaoqingma: 'Einladungscode',
    zhuce: 'registrieren',
    yiyouzhanghao: 'Sie haben bereits ein Konto?',
    denglu: 'Einloggen',
    qingshuruyouxiangdizhi: 'Bitte geben Sie die E-Mail-Adresse ein',
    huoqushibai: 'Fehler beim Abrufen',
    houchongxinfasong: 'Nach S erneut senden',
    liangcishurudemimabuyizhi: 'Die doppelt eingegebenen Passwörter sind inkonsistent',
    zhucechenggong: 'Registrierung erfolgreich',
    zhuceshibai: 'Die Registrierung ist fehlgeschlagen',
    },
    treasure:{
    jichubizhong: 'Basiswährung',
    shouyibizhong: 'Umsatzwährung',
    wakuangtianshu: 'Bergbautage',
    shouyitianshu: 'Verdiensttage',
    suodingtianshu: 'Sperrtage',
    yichanshengshouyi: 'Erwirtschafteter Umsatz',
    yuqishouyi: 'erwartete Rendite',
    kaishishijian: 'Startzeit',
    jieshushijian: 'Endzeit',
    qingshuruninyaosuodingdebizhongshuliang: 'Bitte geben Sie den USDT-Betrag in der Währung ein, die Sie sperren möchten',
    huoqushibai: 'Fehler beim Abrufen',
    meiyitian: 'jeden 1 Tag',
    dakai: 'Offen',
    yuanyuzhoukuangchi: 'Metaverse-Mining-Pool',
    jilu: 'Aufzeichnen',
    kuangchixiangqing: 'Details zum Mining-Pool',
    suo: 'Sperren',
    yitianchanchu: '1 Tag Leistung',
    zuixiaosuodingshijian: 'Mindestsperrzeit',
    nindeUSDT: 'Ihr USDT',
    tian: 'Himmel',
    quanbu: 'alle',
    suoUSDTbingkaishishengchan: 'Sperren Sie USDT und starten Sie die Produktion',
    ninquedingyaosuoUSDTkaishishengchanma: 'Sind Sie sicher, dass Sie USDT sperren und mit der Produktion beginnen möchten?',
    tishi: 'Hinweis',
    queding: 'Sicher',
    quxiao: 'Stornieren',
    kaishishengchan: 'Starten Sie die Produktion!',
    yiquxiao: 'Abgesagt',
    },
    subscription:{
    rengouliebiao:'Abonnementliste',
    jijiangdaolai:'Kommt bald',
    jinxingzhong:'im Gange',
    quanbu:'alle',
    keshiyonghuobi:"Verfügbare Währungen",
    zongshuliang:'Gesamtmenge',
    meiyouxianzhi:'keine Begrenzung',
    shengyushuliang:'Restmenge',
    kaishishijian:'Startzeit',
    zhuangtai:'Zustand',
    jilu:'Aufzeichnen',
    zanwujilu:"Noch keine Aufzeichnung",
    leixing:'Typ',
    shuliang:'Menge',
    goumaijine:'Kaufbetrag',
    shijian:'Zeit',
    goumai:'Kaufen',
    duichu:'Aus dem Nichts',
    jiangli:'vergeben',
    tibi:'Münzen abheben',
    huidaomulu:'Zurück zum Inhaltsverzeichnis',
    dangqianchiyou:'derzeit gehalten',
    keyong:'Verfügbar',
    keduihuan:'Einlösbar',
    shishihangqing:'Echtzeit-Kurse',
    yue:'Gleichgewicht',
    biaoti:'Titel',
    fashoushuliang:'Menge zum Verkauf',
    jindu:"Zeitplan",
    goumaishuliang:'Kaufmenge',
    ge:'individuell',
    woyiyuedu:'Ich habe gelesen',
    daibigoumaixieyi:'Token-Kaufvertrag',
    qingshurugoumaijine:'Bitte geben Sie den Kaufbetrag ein',
    shifoyaogoumai:'Möchten Sie kaufen?',
    tishi:'Hinweis',
    queding:'Sicher',
    quxiao:'Stornieren',
    yjs:'beendet',
    ywc:'Vollendet',
    sgjg:'Abonnementpreis',
    jssj:"Endzeit",
    zqsl:"Gewinnmenge",
    zqje:"Gewinnbetrag",
    tian:"Himmel",
    shi:'Stunde',
    fen:'Punkt',
    miao:'Zweite',
    shengou:'Abonnement',
    xmxq:'Projektdetails',
    },
    }