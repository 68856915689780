export default {
    public:{
        zhd:"بہترین",
        qsjyh:"پوری دنیا کے صارفین",
        gtdxz:'عام انتخاب',
        szhbjyytz:'ڈیجیٹل کرنسی کے مواقع اور چیلنجز',
        xsddcf:'نئے دور میں دولت کے مواقع اور چیلنجز',
        yzsjypt:"ون اسٹاپ ٹریڈنگ پلیٹ فارم",
        mskljyzh:'ٹریڈنگ اکاؤنٹ کھولیں اور ابھی ٹریڈنگ شروع کریں۔',
        jyhg:"کاروباری تعمیل",
    },

    header: {
    hello: 'ہیلو ورلڈ!',
    changeLanguage: 'زبان کو تبدیل کریں',
    Login: 'لاگ ان کریں۔',
    Register: 'رجسٹر کریں',
    Logout: 'لاگ آؤٹ کریں۔',
    shouye: 'صفحہ اول',
    bibijiaoyisuo: 'کریپٹو کرنسی ٹریڈنگ',
    heyuejiaoyisuo: 'معاہدہ ٹریڈنگ',
    licaichanpin: 'کان کنی کا تالاب',
    rengou: 'رکنیت',
    ruanjianxiazai: 'سافٹ ویئر ڈاؤن لوڈ',
    zhongwen: 'چینی',
    yingwen: 'میں',
    zichan: 'اثاثے',
    dingdan: 'آرڈر',
    heyuedingdan: 'معاہدہ کا حکم',
    bibidingdan: 'سکے کا آرڈر',
    anquanzhongxin: 'سیکیورٹی سینٹر',
    fanyongyaoqing: 'کمیشن کی دعوت',
    tuichudenglu: 'لاگ آؤٹ کریں۔',
    gupiaojiaoyi: 'اسٹاک ٹریڈنگ',
    waihuijiaoyi: 'فاریکس ٹریڈنگ',
    qiquanjiaoyi:'اختیارات ٹریڈنگ',
    gupiaodingdan: 'اسٹاک آرڈر',
    waihuidingdan: 'فاریکس آرڈرز',
    qiquandingdan: 'اختیارات آرڈر',
    jiaoyidingdan:'ٹریڈنگ آرڈر',
    daolizhongxin:'ایجنسی سینٹر',
    huodong:'سرگرمی',
    },
    home: {
    huanyinglaidao: 'میں خوش آمدید',
    pingtaimingcheng: 'یہاں',
    mengxiangqihang: 'خوابوں نے سفر کیا۔',
    yijiankaiqishuzihualvcheng: 'ایک کلک سے اپنا ڈیجیٹل سفر شروع کریں۔',
    liaojiegengduo: 'مزید جانیں',
    chongzhi: 'اوپر',
    jiaoyi: 'تجارت',
    remenbang: 'مقبول فہرست',
    zhangfubang: 'حاصل کرنے والوں کی فہرست',
    diefubang: 'ہارنے والوں کی فہرست',
    chengjiaoliang: 'حجم',
    zanwushuju: 'ابھی تک کوئی ڈیٹا نہیں ہے۔',
    jiayidui: 'تجارتی جوڑی',
    zuixinjiage: 'تازہ ترین قیمت',
    zhangdie24h: '24 گھنٹے عروج و زوال',
    caozuo: 'کام',
    jiaoyi: 'تجارت',
    gengduo: 'مزید',
    suishisuidikaiqijiaoyi: 'کسی بھی وقت، کہیں بھی تجارت شروع کریں۔',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'چاہے وہ ایپ ہو یا ویب، آپ اپنا لین دین تیزی سے شروع کر سکتے ہیں۔',
    saomaxiazaiApp: 'اے پی پی ڈاؤن لوڈ کرنے کے لیے کیو آر کوڈ اسکین کریں۔',
    iosheAndroid: 'iOS اور Android',
    ninkexinrenqieanquandejiamibijiaoyisuo: 'آپ کا بھروسہ مند اور محفوظ کریپٹو کرنسی کا تبادلہ',
    tishi1: 'ریزرو سرٹیفکیٹ',
    xiangqing1: 'ہم کم از کم 1:1 کے تناسب میں صارف کے تمام اثاثے رکھنے کا وعدہ کرتے ہیں۔',
    tishi2: 'بہترین لین دین کی شرح',
    xiangqing2: 'ترجیحی لین دین کی شرحیں، مسابقتی VIP حقوق، اور بہترین معیار کی خدمات سے لطف اندوز ہوں۔',
    tishi3: 'سیکیورٹی جس پر آپ اعتماد کر سکتے ہیں۔',
    xiangqing3: 'ہمارے جدید ترین حفاظتی اقدامات اور SAFU فنڈز آپ کے ڈیجیٹل اثاثوں کو تمام خطرات سے بچاتے ہیں۔',
    tishi4: '24*7 گھنٹے کسٹمر سروس سپورٹ',
    xiangqing4: '24*7 گھنٹے، کل وقتی آپریشن موڈ، جلد از جلد اپنی متعلقہ پوچھ گچھ کا جواب دیں۔',
    tishi5: 'مستحکم اور محفوظ',
    xiangqing5: 'دنیا کا معروف ایکسچینج آپ کے اثاثوں کے لیے بینک کی سطح کا حفاظتی تحفظ فراہم کرتا ہے۔',
    tishi6: 'اکثر پوچھے گئے سوالات',
    xiangqing6: 'مخصوص خصوصیات کی تفصیلی وضاحت کے لیے اکثر پوچھے گئے سوالات دیکھیں',
    fanhui:"واپسی",
    shishihangqing:'ریئل ٹائم حوالہ جات',
    },
    footer: {
    bangzhuzhongxin: 'امدادی مرکز',
    dianhuazixun: 'ٹیلی فون مشاورت',
    dianhuahegongzuoshijian: '1670-9756 (ہفتہ کے دنوں میں 10:00~19:00 / اختتام ہفتہ اور تعطیلات پر بند)',
    liaotianzixun: '1:1 چیٹ مشاورت',
    keketuozixun: 'کوکوٹک کنسلٹنگ',
    zaixianbangzhu: 'آن لائن مدد',
    h24kaifang: '(24 گھنٹے کھلا)',
    lixianbangzhu: 'آف لائن مدد',
    gongsidizhi: '1F, Yongsan Purgio Summit, 69 Hangang-gil, Yongsan-gu, Seoul',
    gongzuoshijian: '(10:00 ~ 18:00 ہفتے کے دن / اختتام ہفتہ اور تعطیلات پر بند)',
    shangshizixun: 'فہرست سازی سے متعلق مشاورت',
    hezuozixun: 'تعاون کی مشاورت',
    gongsi: 'کمپنی',
    lianxiwomwn: 'ہم سے رابطہ کریں۔',
    zhidao: 'گائیڈ',
    yonghuzhichi: 'یوزر سپورٹ',
    zhanghuzhinan: 'اکاؤنٹ گائیڈ-ڈپازٹ/وتھراول',
    ruanjianxiazai: 'سافٹ ویئر ڈاؤن لوڈ',
    zhengce: 'پالیسی',
    fuwuyuyinsitiaokuan: 'سروس کی شرائط اور رازداری',
    },
    login: {
    yonghudenglu: 'صارف لاگ ان',
    huanyingdenglu: 'لاگ ان کرنے میں خوش آمدید',
    xiangmumingcheng: 'بی سی ایف ایکس',
    guanfangwangzhan: 'سرکاری ویب سائٹ',
    youxiang: 'میل',
    qingshuruyouxiang: 'براہ کرم اپنا ای میل درج کریں۔',
    mima: 'پاس ورڈ',
    qingshurumima: 'براہ کرم پاس ورڈ درج کریں۔',
    wangjimima: 'پاس ورڈ بھول گئے؟',
    denglu: 'لاگ ان کریں۔',
    haimeiyouzhanghao: 'ابھی تک اکاؤنٹ نہیں ہے؟ جاؤ',
    zhuce: 'رجسٹر کریں',
    dengluchenggong: 'لاگ ان کامیاب',
    denglushibai: 'لاگ ان ناکام ہو گیا۔',
    qingshuruyonghuminghemima: 'براہ کرم صارف نام اور پاس ورڈ درج کریں۔',
    mimachangdubunengxiaoyuliuwei: 'پاس ورڈ کی لمبائی 6 حروف سے کم نہیں ہو سکتی',
    youxiangrenzheng: 'ای میل کی توثیق',
    youxiangdizhi: 'ای میل ایڈریس',
    qingshuruyouxiangdizhi: 'براہ کرم ای میل ایڈریس درج کریں۔',
    tuxingyanzhengma: 'گرافک تصدیقی کوڈ',
    qingshurutuxingyanzhengma: 'براہ کرم گرافک تصدیقی کوڈ درج کریں۔',
    yanzhengma: 'تصدیقی کوڈ',
    qingshuruyouxiangyanzhengma: 'براہ کرم ای میل تصدیقی کوڈ درج کریں۔',
    huoquyouxiangyanzhengma: 'ای میل توثیقی کوڈ حاصل کریں۔',
    queding: 'ضرور',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'گرافک تصدیقی کوڈ حاصل کرنے میں ناکام، براہ کرم دوبارہ درخواست کریں۔',
    qingxianwanchengyouxiangrenzheng: 'براہ کرم پہلے ای میل کی توثیق مکمل کریں۔',
    huoquyouxiangyanzhengmashibai: 'ای میل تصدیقی کوڈ حاصل کرنے میں ناکام،',
    houchongxinfasong: 'ایس کے بعد دوبارہ بھیجیں۔',
    youxiangyanzhengchenggong: 'ای میل کی توثیق کامیاب',
    yanzhengmacuowu: 'توثیقی کوڈ کی خرابی۔',
    xiugaidenglumima: 'لاگ ان پاس ورڈ تبدیل کریں۔',
    xinmima: 'نیا پاس ورڈ',
    quedingxinmima: 'نئے پاس ورڈ کی تصدیق کریں۔',
    qingshuruxinmima: 'براہ کرم نیا پاس ورڈ درج کریں۔',
    qingzaicishuruxinmima: 'براہ کرم دوبارہ نیا پاس ورڈ درج کریں۔',
    liangcimimashurubuyizhi: 'دو پاس ورڈ ان پٹ متضاد ہیں۔',
    mimaxiugaichenggong: 'پاس ورڈ کامیابی سے تبدیل ہو گیا۔',
    xiugaimimashibai: 'پاس ورڈ تبدیل کرنے میں ناکام',
    },
    trade: {
    hangqingheyue: 'معاہدہ',
    hangqingzuixinjia: 'تازہ ترین قیمت',
    hangqingzhangdiefu: 'اضافہ یا کمی',
    hangqingzuigao24h: '24H سب سے زیادہ',
    hangqingzuidi24h: '24H کم از کم',
    hangqingchengjiaoliang24h: '24H تجارتی حجم',
    hangqingchengjiabishu: 'لین دین کی تعداد',
    zhanghuquanyi: 'اکاؤنٹ کے حقوق',
    zhanghukeyongshouyi: 'دستیاب فوائد',
    hangqingweituoliebiao: 'آرڈر کی فہرست',
    hangqingjiage: 'قیمت',
    hangqingshuliang: 'مقدار',
    hangqingzuixinchengjiao: 'تازہ ترین لین دین',
    hangqingjiage1: 'قیمت',
    hangqingshuliang1: 'مقدار',
    hangqingshijian: 'وقت',
    dingdanchiyoucangwei: 'ایک عہدہ رکھیں',
    dingdandangqianweituo: 'موجودہ آرڈر',
    dingdanchengjiaojilu: 'لین دین کا ریکارڈ',
    dingdantiaojiandan: 'مشروط حکم',
    chicangheyue: 'معاہدہ',
    chicangkaicangjunjia: 'پوزیشن کی قیمت',
    chicangfudongyingkui: 'نفع اور نقصان',
    chicanggangganbeishu: 'فائدہ اٹھانا',
    chicangchicangliang: 'عہدے',
    chicangduokong: 'لمبا/مختصر',
    chicangduo: 'بہت سے',
    chicangkong: 'null',
    chicangzhiyingjiage: 'منافع کی قیمت لیں۔',
    chicangzhisunjiage: 'نقصان کی قیمت کو روکیں۔',
    chicangqiangpingyugujiage: 'تخمینی پرسماپن قیمت',
    chicangcaozuo: 'کام',
    chicangzhiyingzhisun: 'نفع لیں اور نقصان کو روکیں۔',
    chicangpingcang: 'بند کریں',
    chicangshouxufei: 'ہینڈلنگ فیس',
    weituoheyue: 'معاہدہ',
    weituoshuliang: 'آرڈر کی مقدار',
    weituojiage: 'کمیشن کی قیمت',
    weituofangxiang: 'سمت',
    weituogangganbeishu: 'متعدد کا فائدہ اٹھانا',
    weituochengjiaoshuliang: 'لین دین کی مقدار',
    weituoshouxufei: 'ہینڈلنگ فیس',
    weituochengjiaojunjia: 'اوسط قیمت',
    weituoshouyi: 'آمدنی',
    weituozhuangtai: 'ریاست',
    weituochengjiaoshijian: 'لین دین کا وقت',
    weituocaozuo: 'کام',
    weituochedan: 'آرڈر منسوخ کریں۔',
    weituotijiaoshibai: 'جمع کرانے میں ناکام',
    weituozhilingtijiao: 'ہدایات جمع کروانا',
    weituoyitijiao: 'جمع کرایا',
    weituobufenchengjiao: 'جزوی سودا',
    weituobufenchengjiaoyichedan: 'کچھ آرڈرز منسوخ کر دیے گئے ہیں۔',
    weituowanquanchengjiao: 'مکمل ڈیل',
    weituowanquanchedan: 'آرڈر کو مکمل طور پر منسوخ کریں۔',
    weituochedanzhong: 'آرڈر منسوخ کرنا',
    weituokaiduo: 'لمبا کھولنا',
    weituopingkong: 'فلیٹ آسمان',
    weituokaikong: 'مختصر کھولیں۔',
    weituopingduo: 'پنڈو',
    chengjiaoheyue: 'معاہدہ',
    chengjiaofangxiang: 'سمت',
    chengjiaoshuliang: 'لین دین کی مقدار',
    chengjiaojiage: 'لین دین کی قیمت',
    chengjiaoshijian: 'لین دین کا وقت',
    chengjiaopingcangyingkui: 'نفع اور نقصان',
    chengjiaoshouxufei: 'لین دین کی فیس',
    chengjiaokaiduo: 'لمبا کھولنا',
    chengjiaopingkong: 'فلیٹ آسمان',
    chengjiaokaikong: 'مختصر کھولیں۔',
    chengjiaopingduo: 'پنڈو',
    tiaojianzengjiatiaojian: 'شرائط شامل کریں۔',
    tiaojianzantingquanbu: 'سب کو روک دو',
    tiaojianqidongquanbu: 'سب شروع کریں۔',
    tiaojianshanchuquanbu: 'سب کو حذف کریں۔',
    tiaojianheyue: 'معاہدہ',
    tiaojianweituoshuliang: 'آرڈر کی مقدار',
    tiaojianfangxiang: 'سمت',
    tiaojiantiaojianleixing: 'حالت کی قسم',
    tiaojianjiageleixing: 'قیمت کی قسم',
    tiaojianbijiaofuhao: 'موازنہ کی علامت',
    tiaojiantiaojian: 'حالت',
    tiaojianzhuangtai: 'ریاست',
    tiaojianchuangjianshijian: 'تخلیق کا وقت',
    tiaojiancaozuo: 'کام',
    tiaojianxiugai: 'نظر ثانی کریں۔',
    tiaojianqidong: 'شروع کریں',
    tiaojianzanting: 'توقف',
    tiaojianshanchu: 'حذف کریں',
    tiaojianlijixiadan: 'ابھی آرڈر کریں۔',
    tiaojiankaiduo: 'لمبا کھولنا',
    tiaojianpingkong: 'فلیٹ آسمان',
    tiaojiankaikong: 'مختصر کھولیں۔',
    tiaojianpingduo: 'پنڈو',
    tiaojianjiagetiaojian: 'قیمت کی شرائط',
    tiaojianshijiantiaojian: 'وقت کی حالت',
    tiaojianzuixinjia: 'تازہ ترین قیمت',
    tiaojianByijia: 'ایک قیمت خریدیں',
    tiaojianSyijia: 'ایک قیمت پر فروخت کریں۔',
    tiaojianzanting: 'توقف',
    tiaojianyunxing: 'چلائیں',
    tiaojianyiwancheng: 'مکمل',
    tiaojianshibai: 'ناکام',
    tiaojianyishanchu: 'حذف کر دیا گیا۔',
    tiaojianshoudongchufawancheng: 'دستی ٹرگر مکمل',
    tiaojianshoudongchufashibai: 'دستی ٹرگر ناکام ہو گیا۔',
    xiadankaicang: 'کھولیں',
    xiadanpingcang: 'بند کریں',
    xiadanweituoleixing: 'قسم',
    xiadanqingxuanze: 'براہ کرم منتخب کریں۔',
    xiadanshijia: 'مارکیٹ کی قیمت',
    xiadanxianjia: 'قیمت کی حد',
    xiadanjiage: 'قیمت',
    xiadanqingshurujiage: 'براہ کرم قیمت درج کریں۔',
    xiadanshuliang: 'مقدار',
    xiadanqingshurushuliang: 'براہ کرم مقدار درج کریں۔',
    xiadanzhang: 'کھلا',
    xiadankekaishuliang: 'دستیاب مقدار',
    xiadanqingxianxuanzechicangdan: 'براہ کرم پہلے پوزیشن آرڈر منتخب کریں۔',
    xiadankepingshuliang: 'مقدار جو برابر کی جا سکتی ہے۔',
    xiadansuoxubaozhengjin: 'مطلوبہ مارجن:',
    xiadanshijiazhiyingzhisunyushe: 'مارکیٹ کی قیمت منافع لے لو اور نقصان کو روکو',
    xiadanyujiqiangpingjiage: 'تخمینی قیمت:',
    xiadanqiangpingjiagetishi: 'اس متوقع پرسماپن قیمت کو کل اثاثوں کی بنیاد پر متحرک طور پر شمار کیا جاتا ہے۔ یہ قیمت صرف حوالہ کے لیے ہے اور اسے حتمی حل کے طور پر استعمال نہیں کیا جائے گا۔',
    xiadanzuoduo: 'لمبا جانا',
    xiadanzuokong: 'مختصر',
    xiadanpingcang: 'بند کریں',
    zhanghuheyuezichan: 'معاہدے کے اثاثے۔',
    zhanghuchongbi: 'سکے جمع کروائیں۔',
    zhanghutibi: 'سکے واپس لیں۔',
    zhanghuhuazhuan: 'منتقلی',
    zhanghuzhanghuquanyi: 'اکاؤنٹ کے حقوق',
    zhanghufudongyingkui: 'فلوٹنگ منافع اور نقصان',
    zhanghucangweibaozhengjin: 'پوزیشن مارجن',
    zhanghudongjiebaozhengjin: 'منجمد مارجن',
    zhanghukeyongquanyi: 'دستیاب فوائد',
    zhanghubaozhengjinshiyonglv: 'مارجن کے استعمال کی شرح',
    hangqingheyuexinxi: 'معاہدے کی معلومات',
    hangqingheyuemingcheng: 'معاہدہ کا نام',
    hangqingjijiahuobi: 'کوٹیشن کرنسی',
    hangqingheyuedaxiao: 'معاہدہ کا سائز',
    hangqingzuixiaojiagebodong: 'کم از کم قیمت میں اتار چڑھاؤ',
    dialogleverRateganggantiaozheng: 'لیوریج ایڈجسٹمنٹ',
    dialogleverRatequeding: 'ضرور',
    dialogtransferzichanhuazhuan: 'اثاثہ کی منتقلی',
    dialogtransferbizhong: 'کرنسی',
    dialogtransfercong: 'سے',
    dialogtransferdao: 'پہنچنا',
    dialogtransfershuliang: 'مقدار',
    dialogtransferqingshuruhuazhuanshuliang: 'براہ کرم منتقلی کی رقم درج کریں۔',
    dialogtransferquanbu: 'تمام',
    dialogtransferkeyong: 'دستیاب ہے۔',
    dialogtransferhuazhuan: 'منتقلی',
    dialogtransferjichuzhanghu: 'بنیادی اکاؤنٹ',
    dialogtransferheyuezhanghu: 'کنٹریکٹ اکاؤنٹ',
    dialogfitlosszhiyingzhisun: 'نفع لیں اور نقصان کو روکیں۔',
    dialogfitlossheyu: 'معاہدہ',
    dialogfitlossduokong: 'لمبا/مختصر',
    dialogfitlosschichangjunjia: 'اوسط پوزیشن کی قیمت',
    dialogfitlossshuliang: 'مقدار',
    dialogfitlosszuixinjia: 'تازہ ترین قیمت',
    dialogfitlosszhiyingdianshu: 'منافع کے پوائنٹس لیں۔',
    dialogfitlosszhisundianshu: 'نقصان کے پوائنٹس کو روکیں۔',
    dialogfitlossdianshu: 'سکور',
    dialogfitlosszhiyingjiage: 'منافع کی قیمت لیں۔',
    dialogfitlosszhisunjiage: 'نقصان کی قیمت کو روکیں۔',
    dialogfitlossjiage: 'قیمت',
    dialogfitlossyuqishouyi: 'متوقع واپسی',
    dialogfitlossyuqikuisun: 'متوقع نقصان',
    dialogfitlosstishi1: 'اشارہ: موجودہ معاہدے کا کم از کم اتار چڑھاؤ ہے۔',
    dialogfitlosstishi2: '، براہ کرم منافع لینے اور نقصان کو روکنے کے لیے قیمت 0 پر سیٹ کریں۔',
    dialogfitlossqueding: 'ضرور',
    dialogfitlossduo: 'بہت سے',
    dialogfitlosskong: 'null',
    dialogconditionzengjiayuntiaojiandan: 'کلاؤڈ کنڈیشن شیٹ شامل کریں۔',
    dialogconditiontiaojianleixing: 'حالت کی قسم:',
    dialogconditionjiagetiaojiandan: 'قیمت کی شرائط کی شیٹ',
    dialogconditionshijiantiaojiandan: 'ٹائم کنڈیشن شیٹ',
    dialogconditionjiageleixing: 'قیمت کی قسم:',
    dialogconditionzuixinjia: 'تازہ ترین قیمت',
    dialogconditionByijia: 'ایک قیمت خریدیں',
    dialogconditionSyijia: 'ایک قیمت پر فروخت کریں۔',
    dialogconditiontiaojianshezhi: 'حالت کی ترتیبات:',
    dialogconditionjiageshezhi: 'قیمت کی ترتیبات:',
    dialogconditionqingshurujiage: 'براہ کرم قیمت درج کریں۔',
    dialogconditionshijianshezhi: 'وقت کی ترتیب:',
    dialogconditionjiaoyileixing: 'لین دین کی قسم:',
    dialogconditionkaiduo: 'لمبا کھولنا',
    dialogconditionpingkong: 'فلیٹ آسمان',
    dialogconditionkaikong: 'مختصر کھولیں۔',
    dialogconditionpingduo: 'پنڈو',
    dialogconditiondingdanshuliang: 'آرڈر کی مقدار:',
    dialogconditionqingshurudingdanshuliang: 'براہ کرم آرڈر کی مقدار درج کریں۔',
    dialogconditiondingdanshixiao: 'آرڈر کا وقت:',
    dialogconditionyongjiuyouxia: 'مستقل طور پر درست ہے۔',
    dialogconditiontijiao: 'جمع کروائیں',
    dialogAutoProfitLosszhiyingzhisun: 'نفع لیں اور نقصان کو روکیں۔',
    dialogAutoProfitLossmingcheng: 'نام',
    dialogAutoProfitLosszhiyingdian: 'منافع کا نقطہ لیں۔',
    dialogAutoProfitLosszhisundian: 'سٹاپ نقصان پوائنٹ',
    dialogAutoProfitLosschongzhi: 'دوبارہ ترتیب دیں',
    dialogAutoProfitLoss: 'جمع کروائیں',
    dialogCloseOrderpingcang: 'بند کریں',
    dialogCloseOrderweituoleixing: 'ڈیلیگیٹ کی قسم',
    dialogCloseOrderqingxuanze: 'براہ کرم منتخب کریں۔',
    dialogCloseOrdershijia: 'مارکیٹ کی قیمت',
    dialogCloseOrderxianjia: 'قیمت کی حد',
    dialogCloseOrderjiage: 'قیمت',
    dialogCloseOrderqingshurujiage: 'براہ کرم قیمت درج کریں۔',
    dialogCloseOrdershuliang: 'مقدار',
    dialogCloseOrderqingshurushuliang: 'براہ کرم مقدار درج کریں۔',
    dialogCloseOrderzhang: 'کھلا',
    dialogCloseOrderkepingshuliang: 'مقدار جو برابر کی جا سکتی ہے۔',
    dialogCloseOrderqueding: 'ضرور',
    jsMessageResgaiheyuegangganxiugaichenggong: 'معاہدے کے لیوریج میں کامیابی کے ساتھ ترمیم کی گئی۔',
    jsMessageResgaiheuyegangganxiugaishibai: 'کنٹریکٹ لیوریج میں ترمیم ناکام ہو گئی۔',
    jsMessageReskaicangchenggong: 'پوزیشن کامیابی سے کھل گئی۔',
    jsMessageReskaicangshibai: 'پوزیشن کھولنے میں ناکام:',
    jsMessageReskaicang1: 'پوزیشن کھولنا ممنوع ہے۔',
    jsMessageReskaicang2: 'معاہدہ موجود نہیں ہے۔',
    jsMessageReskaicang3: 'کھلی پوزیشنوں کی تعداد بالائی حد سے زیادہ ہے۔',
    jsMessageReskaicang4: 'قیمت 0 ہے۔',
    jsMessageReskaicang5: 'پوزیشن کھولنے کے لیے ناکافی مارجن',
    jsMessageRespingcangchenggong: 'پوزیشن کامیابی سے بند ہو گئی۔',
    jsMessageRespingcangshibai: 'پوزیشن بند کرنے میں ناکام:',
    jsMessageRespingcang1: 'معاہدہ موجود نہیں ہے۔',
    jsMessageRespingcang2: 'پوزیشن کی رسید موجود نہیں ہے۔',
    jsMessageRespingcang3: 'ناکافی رقم',
    jsMessageReszijinhuazhuanchenggong: 'فنڈ کی منتقلی کامیاب',
    jsMessageReszijinhuazhuanshibai: 'فنڈ کی منتقلی ناکام ہو گئی۔',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'پوزیشن آرڈر سٹاپ پرافٹ اور سٹاپ نقصان کامیابی سے سیٹ ہو گیا۔',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'پوزیشن آرڈرز کے لیے اسٹاپ پرافٹ اور اسٹاپ لاس سیٹنگ ناکام ہوگئیں:',
    jsMessageResweituodanchedanchenggong: 'آرڈر کامیابی کے ساتھ منسوخ ہو گیا۔',
    jsMessageResweituodanchedanshibai: 'آرڈر کی منسوخی ناکام ہو گئی۔',
    jsMessageRestiaojiandantianjiachenggong: 'مشروط آرڈر کامیابی کے ساتھ شامل کر دیا گیا۔',
    jsMessageResheyuebucunzai: 'معاہدہ موجود نہیں ہے۔',
    jsMessageResjiageshezhicuowu: 'قیمت ترتیب دینے میں خرابی۔',
    jsMessageResshijianshezhicuowu: 'وقت کی ترتیب کی خرابی۔',
    jsMessageResneibucuowu: 'اندرونی خرابی',
    jsMessageRestiaojiandantianjiashibai: 'مشروط ترتیب شامل کرنے میں ناکام:',
    jsMessageReszhiyingzhisunshezhichenggong: 'منافع لیں اور نقصان کو روکیں کامیابی سے سیٹ کریں۔',
    jsconfirmquedingchedanma: 'کیا آپ واقعی آرڈر منسوخ کرنا چاہتے ہیں؟',
    jsconfirmtishi: 'اشارہ',
    jsconfirmqueding: 'ضرور',
    jsconfirmquxiao: 'منسوخ کریں۔',
    jsMessageqingxiandenglu: 'براہ کرم پہلے لاگ ان کریں!',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'اس معاہدے میں کھلی پوزیشنیں ہیں اور لیوریج میں ترمیم نہیں کی جا سکتی ہے۔',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'اس معاہدے کا ایک مینڈیٹ ہے اور لیوریج میں ترمیم نہیں کی جا سکتی ہے۔',
    jsMessageqingshuruzhengquedeshuliang: 'براہ کرم صحیح مقدار درج کریں!',
    jsMessageqingshuruzhengquedejiage: 'براہ کرم صحیح قیمت درج کریں!',
    jsMessageqingxianxuanzechicang: 'براہ کرم پہلے ایک پوزیشن منتخب کریں!',
    jsMessageqingshuruhuazhuanshuliang: 'براہ کرم منتقلی کی رقم درج کریں!',
    jsMessageqingxianxuanzeheyue: 'براہ کرم پہلے ایک معاہدہ منتخب کریں!',
    jsMessageqingshurutiaojiandanshuliang: 'براہ کرم مشروط آرڈر کی مقدار درج کریں!',
    jsMessageqingshurutiaojiandandejiage: 'مشروط آرڈر کی قیمت درج کریں!',
    jsMessageqingshurutiaojiandanzhixingshijian: 'براہ کرم مشروط آرڈر پر عمل درآمد کا وقت درج کریں!',
    emptytext: 'ابھی تک کوئی ڈیٹا نہیں ہے۔',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'ٹیک پرافٹ اور سٹاپ نقصان کے پپس غلط طریقے سے سیٹ کیے گئے ہیں۔',
    jsMessageReschicangdanbucunzai: 'پوزیشن کی رسید موجود نہیں ہے۔',
    zxkcje:'کم از کم افتتاحی رقم',
    },
    commisson:{
    dlzx:'ایجنسی سینٹر',
    sjzl:'ڈیٹا کا جائزہ',
    quanbu:"تمام",
    jinri:'آج',
    zuori:'کل',
    ri:'دن',
    wdfy:'میری چھوٹ',
    wdsjfy:'میری تیسری سطح کی چھوٹ',
    dengji:'گریڈ',
    yqrs:'مدعو لوگوں کی تعداد',
    tdrs:'ٹیم کا سائز',
    yqxq:'دعوت نامے کی تفصیلات',
    yhm:"صارف نام",
    cjsj:'تخلیق کا وقت',
    fyxq:'چھوٹ کی تفصیلات',
    zyj:'کل کمیشن',
    dryj:'سنگل ڈے کمیشن',
    fyje:'چھوٹ کی رقم',
    
    },
    assets: {
    zongzichan: 'کل اثاثے',
    chongbi: 'سکے جمع کروائیں۔',
    tibi: 'سکے واپس لیں۔',
    huazhuan: 'منتقلی',
    fabizhanghu: 'بنیادی اکاؤنٹ',
    heyuezhanghu: 'کنٹریکٹ اکاؤنٹ',
    jiayiliushui: 'لین دین کا بہاؤ',
    dialogzichanhuazhuan: 'اثاثہ کی منتقلی',
    cong: 'سے',
    dao: 'پہنچنا',
    shuliang: 'مقدار',
    qingshuruhuazhuanshuliang: 'براہ کرم منتقلی کی رقم درج کریں۔',
    quanbu: 'تمام',
    keyong: 'دستیاب ہے۔',
    huanzhuan: 'منتقلی',
    zijinhuazhuanchenggong: 'فنڈ کی منتقلی کامیاب',
    zijinhuazhuanshibai: 'فنڈ کی منتقلی ناکام ہو گئی۔',
    bibizhanghu: 'سکے اکاؤنٹ',
    qiquanzhanghu:'اختیارات اکاؤنٹ',
    zczl:'اثاثہ کا جائزہ',
    zzczh:'کل اثاثوں میں رعایت',
    ztzc:"ٹرانزٹ میں اثاثے",
    djzc:'اثاثے منجمد کریں۔',
    zyzc:'گروی رکھے ہوئے اثاثے۔',
    cwzc:'پوزیشن اثاثے',
    jyzh:'تجارتی اکاؤنٹ',
    bizhong:'کرنسی',
    dongjie:'منجمد',
    lirun:"منافع",
    hyzh:"کنٹریکٹ اکاؤنٹ",
    },
    basicsAccountRecord: {
    fanhui: 'واپسی',
    fabizhanghuliushui: 'تجارتی اکاؤنٹ کا بہاؤ',
    fabijiaoyiliushuileixing: 'لین دین کے بہاؤ کی قسم',
    qingxuanze: 'براہ کرم منتخب کریں۔',
    chaxunriqi: 'استفسار کی تاریخ',
    liushuileixing: 'پائپ لائن کی قسم',
    shijian: 'وقت',
    biandongjine: 'رقم تبدیل کریں۔',
    yue: 'توازن',
    quanbu: 'تمام',
    Sellbi: 'سکے فروخت کریں',
    Buybi: 'سکے خریدیں',
    tibi: 'سکے واپس لیں۔',
    chongbi: 'سکے جمع کروائیں۔',
    fabizhuanheyue: 'بنیادی منتقلی کا معاہدہ',
    heyuezhuanfabi: 'بنیاد پر معاہدہ',
    zhuanzhang: 'منتقلی',
    fabihuazhuandaoheyue: 'معاہدے میں بنیادی منتقلی۔',
    heyuehuazhuandaofabi: 'بیس پر معاہدہ کی منتقلی۔',
    goumaijijin: 'کان کنی کا پول خریدیں۔',
    jijinshuhui: 'کان کنی پول چھٹکارا',
    jijinshouyi: 'کان کنی کے پول سے چھٹکارے کی آمدنی',
    fabizhuanqihuo: 'مستقبل میں بنیادی تبدیلی',
    qihuozhuanfabi: 'مستقبل کی منتقلی کی بنیاد',
    fabizhuanganggu: 'ہانگ کانگ اسٹاک میں بنیادی منتقلی۔',
    gangguzhuanfabi: 'ہانگ کانگ اسٹاک ٹرانسفر کی بنیادی باتیں',
    fabizhuanbibi: 'بنیادی ٹرانسفر کرنسی',
    bibizhuanfabi: 'کرنسی سے کرنسی کی منتقلی کی بنیادی باتیں',
    goumaiSGCB: 'ICO خریدیں۔',
    chushouSGCB: 'ICO فروخت کرنا',
    huoqushibai: 'حاصل کرنے میں ناکام',
    zanwushuju: 'ابھی تک کوئی ڈیٹا نہیں ہے۔',
    },
    contractAccountRecord: {
    fanhui: 'واپسی',
    heyuezhanghuliushui: 'کنٹریکٹ اکاؤنٹ کا بہاؤ',
    heyuejiaoyiliushuileixing: 'معاہدے کے لین دین کے بہاؤ کی قسم',
    qingxuanze: 'براہ کرم منتخب کریں۔',
    chaxunriqi: 'استفسار کی تاریخ',
    liushuileixing: 'پائپ لائن کی قسم',
    shijian: 'وقت',
    biandongjine: 'رقم تبدیل کریں۔',
    yue: 'توازن',
    quanbu: 'تمام',
    kaicangshouxufei: 'افتتاحی فیس',
    pingcangshouxufei: 'اختتامی فیس',
    pingcangyingkui: 'پوزیشن بند ہونے والے منافع اور نقصان کا',
    fabizhuanheyue: 'بنیادی منتقلی کا معاہدہ',
    heyuezhuanfabi: 'بنیاد پر معاہدہ',
    zhuanzhang: 'منتقلی',
    kaicangyongjin: 'کمیشن کھولنا',
    pingcangyongjin: 'کمیشن بند کرنا',
    huoqushibai: 'حاصل کرنے میں ناکام',
    zanwushuju: 'ابھی تک کوئی ڈیٹا نہیں ہے۔',
    },
    recharge: {
    zanwushuju: 'ابھی تک کوئی ڈیٹا نہیں ہے۔',
    shijian: 'وقت',
    leixing: 'قسم',
    shuliang: 'مقدار',
    zhuangtai: 'ریاست',
    caozuo: 'کام',
    chakanxiangqing: 'تفصیلات چیک کریں',
    huoqushibai: 'حاصل کرنے میں ناکام',
    dialogtitle: 'تفصیلات',
    chongbi: 'سکے جمع کروائیں۔',
    fanhui: 'واپسی',
    lianmingcheng: 'سلسلہ کا نام',
    fuzhilianjie: 'ایڈریس کاپی کریں۔',
    tishixinxi1: '• براہ کرم مندرجہ بالا پتے پر کوئی غیر TRC_USDT یا ERC_USDT اثاثہ جمع نہ کریں، بصورت دیگر اثاثے دوبارہ حاصل نہیں کیے جائیں گے۔',
    tishixinxi2: '• اوپر والے پتے پر ری چارج کرنے کے بعد، پورے نیٹ ورک نوڈ کی تصدیق کی ضرورت ہوتی ہے، 1 نیٹ ورک کی تصدیق کے بعد کرنسی کو واپس لیا جا سکتا ہے۔',
    tishixinxi3: '• کم از کم ڈپازٹ کی رقم: 50USDT، کم از کم رقم سے کم ڈپازٹس کو کریڈٹ نہیں کیا جائے گا اور اسے واپس نہیں کیا جا سکتا',
    tishixinxi4: '• آپ کا ریچارج پتہ بار بار تبدیل نہیں ہوگا اور اگر کوئی تبدیلی ہوتی ہے تو ہم آپ کو ویب سائٹ کے اعلانات یا ای میلز کے ذریعے مطلع کرنے کی پوری کوشش کریں گے۔',
    tishixinxi5: '• براہ کرم یقینی بنائیں کہ آپ کا کمپیوٹر اور براؤزر معلومات کے ساتھ چھیڑ چھاڑ یا لیک ہونے سے بچانے کے لیے محفوظ ہیں۔',
    putongchongbi: 'عام جمع',
    jiaoyichenggong: 'لین دین کامیاب',
    jiaoyishibai: 'لین دین ناکام ہو گیا۔',
    OMNIweihuzhong: 'OMNI سلسلہ کی دیکھ بھال جاری ہے، براہ کرم TRC20 کو منتخب کریں۔',
    },
    withdraw: {
    fanhui: 'واپسی',
    zanwushuju: 'ابھی تک کوئی ڈیٹا نہیں ہے۔',
    tibi: 'سکے واپس لیں۔',
    huoqushibai: 'حاصل کرنے میں ناکام',
    OMNIweihuzhong: 'OMNI سلسلہ کی دیکھ بھال جاری ہے، براہ کرم TRC20 کو منتخب کریں۔',
    shijian: 'وقت',
    leixing: 'قسم',
    shuliang: 'مقدار',
    zhuangtai: 'ریاست',
    caozuo: 'کام',
    chakanxiangqing: 'تفصیلات چیک کریں',
    putongtibi: 'عام واپسی',
    dialogtitle: 'تفصیلات',
    dialogtitlewithdraw: 'سکے واپس لیں۔',
    houchongxinfasong: 'ایس کے بعد دوبارہ بھیجیں۔',
    huoquyouxiangyanzhengma: 'ای میل توثیقی کوڈ حاصل کریں۔',
    lianmingcheng: 'سلسلہ کا نام',
    tibidizhi: 'واپسی کا پتہ',
    qingshurutibidizhi: 'براہ کرم واپسی کا پتہ درج کریں۔',
    tibishuliang: 'نکالے گئے سکے کی مقدار',
    shouxufei: 'ہینڈلنگ فیس',
    qingshurutibishuliang: 'براہ کرم واپسی کی رقم درج کریں۔',
    quanbu: 'تمام',
    keyong: 'دستیاب ہے۔',
    daozhangshuliang: 'آمد کی مقدار',
    zuixiaotibishuliang: 'کم از کم واپسی کی رقم ہے:',
    tishixinxi: 'فنڈز کی حفاظت کو یقینی بنانے کے لیے، جب آپ کے اکاؤنٹ کی سیکیورٹی پالیسی میں تبدیلی کی جائے گی یا آپ کے پاس ورڈ میں ترمیم کی جائے گی، تو ہم انخلا کا دستی جائزہ لیں گے اور براہ کرم صبر کریں اور عملے کے فون یا ای میل کے ذریعے آپ سے رابطہ کرنے کا انتظار کریں۔ براہ کرم یقینی بنائیں کہ آپ کا کمپیوٹر اور براؤزر ڈیٹا کے ساتھ چھیڑ چھاڑ یا لیک ہونے سے بچانے کے لیے محفوظ ہیں۔',
    tuxingyanzhengma: 'گرافک تصدیقی کوڈ',
    qingshurutuxingyanzhengma: 'براہ کرم گرافک تصدیقی کوڈ درج کریں۔',
    youxiangyanzhengma: 'ای میل توثیقی کوڈ',
    queding: 'ضرور',
    tijiaozhong: 'جمع کروا رہا ہے۔',
    yijujue: 'مسترد',
    yitongguo: 'پاس کیا۔',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'گرافک تصدیقی کوڈ حاصل کرنے میں ناکام، براہ کرم دوبارہ درخواست کریں۔',
    huoquyouxiangyanzhengmashibai: 'ای میل تصدیقی کوڈ حاصل کرنے میں ناکام،',
    qingxianwanchengyouxiangrenzheng: 'براہ کرم پہلے ای میل کی توثیق مکمل کریں۔',
    huoquyouxiangshibaiqingchongxinqingqiu: 'ای میل پتہ حاصل کرنے میں ناکام، براہ کرم دوبارہ درخواست کریں۔',
    qingshuruyouxiangyanzhengma: 'براہ کرم ای میل تصدیقی کوڈ درج کریں۔',
    youxiangyanzhengmacuowu: 'ای میل تصدیقی کوڈ غلط ہے،',
    qxsmrz:'براہ کرم پہلے اپنے اصلی نام کی تصدیق کریں۔',
    tishi:'اشارہ',
    queding:'ضرور',
    },
    contractTransactionRecord: {
    fanhui: 'واپسی',
    zanwushuju: 'ابھی تک کوئی ڈیٹا نہیں ہے۔',
    heyuedingdan: 'معاہدہ کا حکم',
    qingxuanze: 'براہ کرم منتخب کریں۔',
    quanbu: 'تمام',
    kaiduo: 'لمبا کھولنا',
    pingkong: 'فلیٹ آسمان',
    kaikong: 'مختصر کھولیں۔',
    pingduo: 'پنڈو',
    xianjiaweituo: 'حد حکم',
    shijiaweituo: 'مارکیٹ آرڈر',
    suoyouzhuangtai: 'تمام حیثیت',
    tijiaoshibai: 'جمع کرانے میں ناکام',
    zhunbeitijiao: 'جمع کرانے کے لیے تیار',
    yitijiao: 'جمع کرایا',
    bufentijiao: 'جزوی سودا',
    bufentijiaoyichedan: 'کچھ آرڈرز منسوخ کر دیے گئے ہیں۔',
    quanbuchengjiao: 'تمام لین دین',
    yichedan: 'آرڈر منسوخ کر دیا گیا۔',
    huoqushibai: 'حاصل کرنے میں ناکام',
    heyuedaima: 'کنٹریکٹ کوڈ',
    ganggan: 'لیور',
    xiadanshijian: 'آرڈر کا وقت',
    chengjiaoshijian: 'لین دین کا وقت',
    fangxiang: 'سمت',
    baojialeixing: 'اقتباس کی قسم',
    weituoliang: 'کمیشن کی رقم (ٹکڑے)',
    weituojia: 'آرڈر کی قیمت (USDT)',
    chengjiaoliang: 'تجارتی حجم (ٹکٹس)',
    chengjiaojunjia: 'لین دین کی اوسط قیمت (USDT)',
    yingkui: 'منافع اور نقصان (USDT)',
    shouxufei: 'ہینڈلنگ فیس (USDT)',
    zhuangtai: 'ریاست',
    gupiaodingdan: 'اسٹاک آرڈر',
    waihuidingdan: 'فاریکس آرڈرز',
    gupiaodaima: 'اسٹاک کوڈ',
    waihuidaima: 'فاریکس کوڈ',
    },
    kLine: {
    zhibiao: 'انڈیکس',
    shezhi: 'قائم کریں',
    quanping: 'مکمل سکرین',
    tuichuquanping: 'پوری اسکرین سے باہر نکلیں۔',
    jishuzhibiao: 'تکنیکی اشارے',
    futuzhibiao: 'ذیلی تصویر کا اشارہ',
    zhutuzhibiao: 'اہم چارٹ اشارے',
    huifumoren: 'ڈیفالٹ بحال کریں۔',
    lazhutuleixing: 'کینڈل سٹک کی اقسام',
    qingxuanze: 'براہ کرم منتخب کریں۔',
    quanshixin: 'تمام ٹھوس',
    quankongxin: 'مکمل کھوکھلا',
    zhangkongxin: 'بڑھتی ہوئی کھوکھلی',
    diekongxin: 'کھوکھلی گر',
    mianjitu: 'علاقے کا چارٹ',
    jiagezhouleixing: 'قیمت کے محور کی قسم',
    xianxingzhou: 'لائن محور',
    baifenbizhou: 'فیصد محور',
    duishuzhou: 'لوگاریتھمک محور',
    zuigaojiaxianshi: 'سب سے زیادہ قیمت ڈسپلے',
    zuidijiaxianshi: 'سب سے کم قیمت ڈسپلے',
    zuixinjiaxianshi: 'تازہ ترین قیمت ڈسپلے',
    zhibiaozuixinzhixianshi: 'اشارے کی تازہ ترین قیمت ظاہر ہوتی ہے۔',
    daozhizuobiao: "الٹا نقاط",
    wanggexianxianshi: 'گرڈ لائن ڈسپلے',
    hfcg:'بحالی کامیاب',
    },
    SecurityCenter: {
    fanhui: 'واپسی',
    anquanzhongxin: 'سیکیورٹی سینٹر',
    qingshurunicheng: 'براہ کرم ایک عرفی نام درج کریں۔',
    nichengxiugaishibai: 'عرفی نام میں ترمیم ناکام ہو گئی۔',
    huoquyonghuxinxishibai: 'صارف کی معلومات حاصل کرنے میں ناکام',
    xiugaidenglumima: 'لاگ ان پاس ورڈ تبدیل کریں۔',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'گرافک تصدیقی کوڈ حاصل کرنے میں ناکام، براہ کرم دوبارہ درخواست کریں۔',
    qingshurujiumima: 'براہ کرم پرانا پاس ورڈ درج کریں۔',
    qingshuruxinmima: 'براہ کرم نیا پاس ورڈ درج کریں۔',
    qingzaicishuruxinmima: 'براہ کرم دوبارہ نیا پاس ورڈ درج کریں۔',
    qingshurutuxingyanzhengma: 'براہ کرم گرافک تصدیقی کوڈ درج کریں۔',
    qingshuruyanzhengma: 'براہ کرم تصدیقی کوڈ درج کریں۔',
    huoquzhuceshoujihaoshibai: 'رجسٹرڈ موبائل نمبر حاصل کرنے میں ناکام',
    qingxianwanchengshoujirenzheng: 'براہ کرم پہلے موبائل فون کی تصدیق مکمل کریں۔',
    huoqushoujiyanzhengmashibai: 'موبائل فون کا توثیقی کوڈ حاصل کرنے میں ناکام',
    huoqushoujiyanzhengma: 'موبائل فون کا تصدیقی کوڈ حاصل کریں۔',
    houchongxinfasong: 'ایس کے بعد دوبارہ بھیجیں۔',
    mimacuowu: 'غلط پاس ورڈ',
    liangcixinmimashurubuyizhiqingjiancha: 'داخل کردہ دو نئے پاس ورڈ متضاد ہیں، براہ کرم چیک کریں۔',
    xinmimaheyuanmimabunengyiyang: 'نیا پاس ورڈ اصل پاس ورڈ جیسا نہیں ہو سکتا',
    qingshurushoujiyanzhengma: 'براہ کرم موبائل فون کا تصدیقی کوڈ درج کریں۔',
    yanzhengmacuowu: 'توثیقی کوڈ کی خرابی۔',
    denglumimaxiugaichenggong: 'لاگ ان پاس ورڈ کامیابی کے ساتھ تبدیل ہو گیا۔',
    denglumimaxiugaishibai: 'لاگ ان پاس ورڈ میں ترمیم ناکام ہو گئی۔',
    xiugaizijinmima: 'فنڈ کا پاس ورڈ تبدیل کریں۔',
    qingshuruzijinmima: 'براہ کرم فنڈ کا پاس ورڈ درج کریں۔',
    qingzaicishuruzijinmima: 'براہ کرم فنڈ کا پاس ورڈ دوبارہ درج کریں۔',
    liangcizijinmimabuyizhiqingjiancha: 'فنڈ کے دو پاس ورڈ متضاد ہیں، براہ کرم چیک کریں۔',
    shezhizijinmimachenggong: 'فنڈ پاس ورڈ کامیابی سے سیٹ کریں۔',
    shezhizijinmimashibai: 'فنڈ پاس ورڈ سیٹ کرنے میں ناکام',
    xiugaizijinmimachenggong: 'فنڈ کے پاس ورڈ میں کامیابی کے ساتھ ترمیم کی گئی۔',
    xiugaizijinmimashibai: 'فنڈ کا پاس ورڈ تبدیل کرنے میں ناکام',
    youxiangrenzheng: 'ای میل کی توثیق',
    huoquyouxiangyanzhengma: 'ای میل توثیقی کوڈ حاصل کریں۔',
    qingshuruyouxiangdizhi: 'براہ کرم ای میل ایڈریس درج کریں۔',
    huoquyouxiangyanzhengmashibai: 'ای میل توثیقی کوڈ حاصل کرنے میں ناکام',
    qingshuruyouxiangyanzhengma: 'براہ کرم ای میل تصدیقی کوڈ درج کریں۔',
    youxiangyanzhengchenggong: 'ای میل کی توثیق کامیاب',
    weirenzheng: 'سند یافتہ نہیں۔',
    queding: 'ضرور',
    anquanshezhiguanli: 'سیکیورٹی کی ترتیبات کا انتظام',
    denglumima: 'لاگ ان پاس ورڈ',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'لاگ ان پاس ورڈ ترتیب دینے سے، آپ اپنے اکاؤنٹ کا پاس ورڈ استعمال کرکے براہ راست لاگ ان کر سکیں گے۔',
    xiugai: 'نظر ثانی کریں۔',
    zijinmima: 'فنڈ پاس ورڈ',
    yongyubaohuzijinanquan: 'فنڈز کی حفاظت کے لیے استعمال کیا جاتا ہے۔',
    shezhi: 'قائم کریں',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'لاگ ان کرنے، سکے نکالنے، پاس ورڈز کی بازیافت، اور حفاظتی ترتیبات میں ترمیم کرتے وقت سیکیورٹی کی تصدیق کے لیے استعمال کیا جاتا ہے۔',
    yirenzheng: 'تصدیق شدہ',
    renzheng: 'سرٹیفیکیشن',
    shimingrenzheng: 'اصلی نام کی تصدیق',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'سککوں کو خریدنے، بیچنے، نکالنے، جمع کرنے اور منتقل کرنے کے دوران سیکیورٹی کی توثیق کے لیے استعمال کیا جاتا ہے۔',
    daishenhe: 'زیر التواء جائزہ',
    yibohui: 'برطرف',
    yuandenglumima: 'اصل لاگ ان پاس ورڈ',
    xinmima: 'نیا پاس ورڈ',
    quedingxinmima: 'نئے پاس ورڈ کی تصدیق کریں۔',
    tuxingyanzhengma: 'گرافک تصدیقی کوڈ',
    yanzhengma: 'تصدیقی کوڈ',
    zijinmima: 'فنڈ پاس ورڈ',
    querenzijinmima: 'فنڈ پاس ورڈ کی تصدیق کریں۔',
    youxiangdizhi: 'ای میل ایڈریس',
    qingxianwanchengyouxiangrenzheng: 'براہ کرم پہلے ای میل کی توثیق مکمل کریں۔',
    xiugaitouxiangchenggong: 'اوتار میں کامیابی کے ساتھ ترمیم کی گئی۔',
    xiugaitouxiangshibai: 'اوتار میں ترمیم کرنے میں ناکام',
    shangchuantouxiangzhinengshijpggeshi: 'اوتار کی تصاویر اپ لوڈ کرنا صرف JPG فارمیٹ میں ہو سکتا ہے!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'اپ لوڈ کردہ اوتار کی تصویر کا سائز 2MB سے زیادہ نہیں ہو سکتا!',
    },
    verification: {
    fanhui: 'واپسی',
    shimingrenzheng: 'اصلی نام کی تصدیق',
    qingshuruxingming: 'براہ کرم نام درج کریں۔',
    qingshuruzhengjianhaoma: 'براہ کرم اپنا شناختی نمبر درج کریں۔',
    huoquyonghuxinxishibai: 'صارف کی معلومات حاصل کرنے میں ناکام',
    wenjiangeshicuowu: 'فائل فارمیٹ کی خرابی۔',
    wenjianbunengdayu5M: 'فائل 5M سے بڑی نہیں ہو سکتی',
    qingtianxiexingming: 'براہ کرم اپنا نام بھریں۔',
    qingtianxiezhengjianhaoma: 'براہ کرم شناختی نمبر پُر کریں۔',
    qingshangchuanzhengjianzhengmian: 'براہ کرم اپنی ID کا سامنے والا حصہ اپ لوڈ کریں۔',
    qingshangchuanzhengjianfanmian: 'براہ کرم اپنی ID کا الٹا حصہ اپ لوڈ کریں۔',
    tijiaochenggong: 'جمع کرانے کامیاب',
    kaiqishenfenrenzheng: 'شناخت کی تصدیق کو آن کریں۔',
    bohuiyuanyin: 'انکار کی وجہ',
    qingtianxiejiashizhizhaogongminkahuzhao: 'براہ کرم اپنے ڈرائیونگ لائسنس/شہری کارڈ/پاسپورٹ کی معلومات پُر کریں۔',
    xingming: 'نام',
    zhengjianhaoma: 'شناختی نمبر',
    qingshangchuanjiashizhizhaogongminkahuzhao: 'براہ کرم ڈرائیور کا لائسنس/شہری کارڈ/پاسپورٹ کی معلومات اپ لوڈ کریں۔',
    shangchuanzhengjianzhengmian: 'ID کے سامنے کا حصہ اپ لوڈ کریں۔',
    huotuozhuafangru: 'یا ڈریگ اینڈ ڈراپ کریں۔',
    shangchuanzhengjianfanmian: 'سرٹیفکیٹ کا الٹا حصہ اپ لوڈ کریں۔',
    tijiao: 'جمع کروائیں',
    },
    invite: {
    yaoqinghaoyoudejiangli: 'دوستوں کو مدعو کریں اور انعامات حاصل کریں۔',
    yongjiukede: 'مستقل طور پر دستیاب ہے۔',
    jiaoyifanyong: 'تجارتی چھوٹ',
    fasongyaoqinggeihaoyou: 'دوستوں کو دعوت نامہ بھیجیں۔',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'لنک کا اشتراک کرکے دوستوں کو BCFX رجسٹر کرنے کی دعوت دیں۔',
    haoyouzhuce: 'دوست کی رجسٹریشن',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'دوست دعوت قبول کرتا ہے، رجسٹریشن مکمل کرتا ہے اور لین دین کرتا ہے۔',
    huodexiangyingbilijiangli: 'متعلقہ متناسب انعامات وصول کریں۔',
    qingsonghuodejiaoyishouxufeijianglifuli: 'آسانی سے ٹرانزیکشن فیس بونس کے فوائد حاصل کریں۔',
    wodezhuanshufenxiangfangshi: 'اشتراک کرنے کا میرا خصوصی طریقہ',
    yaoqinglianjie: 'دعوتی لنک',
    jiazaizhong: 'لوڈ ہو رہا ہے...',
    fuzhilianjie: 'لنک کاپی کریں۔',
    yaoqingma: 'دعوتی کوڈ',
    fuzhiyaoqingma: 'دعوتی کوڈ کاپی کریں۔',
    wodeyaoqing: 'میری دعوت',
    yaoqingrenshu: 'مدعو لوگوں کی تعداد',
    huodejiangli: 'ثواب حاصل کریں۔',
    huodongxize: 'واقعہ کی تفصیلات',
    tips1: 'پلیٹ فارم استعمال کرنے والوں کے درمیان تعلق تین سطحوں پر ہے، جیسے: اے بی سی ڈی استعمال کرنے والے صارفین کے تین درجے بنتے ہیں، اور A سب سے اعلیٰ سطح ہے!',
    tips2: 'سطحوں کے درمیان دلچسپی کے تعلقات',
    tips201: '.',
    tips3: 'نقد انعام: کلائنٹ A کلائنٹ B کی کنٹریکٹ ٹرانزیکشن فیس کا 40% + کلائنٹ C کی کنٹریکٹ ٹرانزیکشن فیس کا 20% + کلائنٹ D کی کنٹریکٹ ٹرانزیکشن فیس کا 10% وصول کرسکتا ہے۔',
    tips4: 'مثال کے طور پر: گاہک A کے 10 پہلے درجے کے گاہک ہیں B، 10 دوسرے درجے کے گاہک C، اور 10 تیسرے درجے کے گاہک D۔ اگر ہر صارف کا معاہدہ 100,000 USDT',
    tips5: 'پھر وہ واپسی جو گاہک A کو مل سکتی ہے۔',
    tips6: '100000*1.2%*40%*10+100000*1.2%*20%*10+100000*1.2%*10%*10=8400 USDT نقد انعام، جسے حقیقی وقت میں واپس لیا جا سکتا ہے۔',
    tips7: 'اگر سرگرمی کو ایڈجسٹ کیا جاتا ہے، تو یہ BCFX پلیٹ فارم اپ ڈیٹس کے تابع ہو گا، اور تشریح کا حتمی حق BCFX کا ہے۔',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'گرافک تصدیقی کوڈ حاصل کرنے میں ناکام، براہ کرم دوبارہ درخواست کریں۔',
    qingshuruyouxiang: 'براہ کرم اپنا ای میل درج کریں۔',
    qingshurutuxingyanzhengma: 'براہ کرم گرافک تصدیقی کوڈ درج کریں۔',
    qingshuruyanzhengma: 'براہ کرم تصدیقی کوڈ درج کریں۔',
    qingshurunindemima: 'براہ کرم اپنا پاس ورڈ درج کریں۔',
    qingzaicishurunindemima: 'براہ کرم اپنا پاس ورڈ دوبارہ درج کریں۔',
    qingshuruzhifumima: 'براہ کرم ادائیگی کا پاس ورڈ درج کریں۔',
    xuantian: 'اختیاری',
    bitian: 'درکار ہے۔',
    yonghuzhuce: 'صارف کی رجسٹریشن',
    huanyingdenglu: 'لاگ ان کرنے میں خوش آمدید',
    guanfangwangzhan: 'سرکاری ویب سائٹ',
    xiangmumingcheng: 'بی سی ایف ایکس',
    youxiang: 'میل',
    tuxingyanzhengma: 'گرافک تصدیقی کوڈ',
    yanzhengma: 'تصدیقی کوڈ',
    huoquyanzhengma: 'تصدیقی کوڈ',
    shezhimima: 'پاس ورڈ سیٹ کریں۔',
    quedingmima: 'پاس ورڈ کی تصدیق کریں۔',
    zhifumima: 'ادائیگی کا پاس ورڈ',
    yaoqingma: 'دعوتی کوڈ',
    zhuce: 'رجسٹر کریں',
    yiyouzhanghao: 'پہلے سے ہی ایک اکاؤنٹ ہے؟',
    denglu: 'لاگ ان کریں۔',
    qingshuruyouxiangdizhi: 'براہ کرم ای میل ایڈریس درج کریں۔',
    huoqushibai: 'حاصل کرنے میں ناکام',
    houchongxinfasong: 'ایس کے بعد دوبارہ بھیجیں۔',
    liangcishurudemimabuyizhi: 'دو بار درج کردہ پاس ورڈ متضاد ہیں۔',
    zhucechenggong: 'رجسٹریشن کامیاب',
    zhuceshibai: 'رجسٹریشن ناکام ہو گئی۔',
    },
    treasure:{
    jichubizhong: 'بنیادی کرنسی',
    shouyibizhong: 'محصول کی کرنسی',
    wakuangtianshu: 'کان کنی کے دن',
    shouyitianshu: 'کمائی کے دن',
    suodingtianshu: 'تالے کے دن',
    yichanshengshouyi: 'ریونیو پیدا ہوا۔',
    yuqishouyi: 'متوقع واپسی',
    kaishishijian: 'وقت شروع',
    jieshushijian: 'اختتامی وقت',
    qingshuruninyaosuodingdebizhongshuliang: 'براہ کرم اس کرنسی میں USDT کی رقم درج کریں جسے آپ لاک کرنا چاہتے ہیں۔',
    huoqushibai: 'حاصل کرنے میں ناکام',
    meiyitian: 'ہر 1 دن',
    dakai: 'کھولیں۔',
    yuanyuzhoukuangchi: 'میٹاورس مائننگ پول',
    jilu: 'ریکارڈ',
    kuangchixiangqing: 'کان کنی کے تالاب کی تفصیلات',
    suo: 'تالا',
    yitianchanchu: '1 دن کی پیداوار',
    zuixiaosuodingshijian: 'کم از کم لاک ٹائم',
    nindeUSDT: 'آپ کا USDT',
    tian: 'آسمان',
    quanbu: 'تمام',
    suoUSDTbingkaishishengchan: 'USDT کو لاک کریں اور پیداوار شروع کریں۔',
    ninquedingyaosuoUSDTkaishishengchanma: 'کیا آپ واقعی USDT کو مقفل کرنا اور پیداوار شروع کرنا چاہتے ہیں؟',
    tishi: 'اشارہ',
    queding: 'ضرور',
    quxiao: 'منسوخ کریں۔',
    kaishishengchan: 'پیداوار شروع کرو!',
    yiquxiao: 'منسوخ',
    },
    subscription:{
    rengouliebiao:'رکنیت کی فہرست',
    jijiangdaolai:'جلد آرہا ہے۔',
    jinxingzhong:'جاری ہے',
    quanbu:'تمام',
    keshiyonghuobi:"دستیاب کرنسی",
    zongshuliang:'کل مقدار',
    meiyouxianzhi:'کوئی حد نہیں',
    shengyushuliang:'باقی مقدار',
    kaishishijian:'وقت شروع',
    zhuangtai:'ریاست',
    jilu:'ریکارڈ',
    zanwujilu:"ابھی تک کوئی ریکارڈ نہیں۔",
    leixing:'قسم',
    shuliang:'مقدار',
    goumaijine:'خریداری کی رقم',
    shijian:'وقت',
    goumai:'خریدیں۔',
    duichu:'کہیں سے باہر',
    jiangli:'ایوارڈ',
    tibi:'سکے واپس لیں۔',
    huidaomulu:'مواد کے جدول پر واپس جائیں۔',
    dangqianchiyou:'فی الحال منعقد',
    keyong:'دستیاب ہے۔',
    keduihuan:'قابل تلافی',
    shishihangqing:'ریئل ٹائم حوالہ جات',
    yue:'توازن',
    biaoti:'عنوان',
    fashoushuliang:'مقدار برائے فروخت',
    jindu:"شیڈول",
    goumaishuliang:'خریداری کی مقدار',
    ge:'انفرادی',
    woyiyuedu:'میں نے پڑھا ہے۔',
    daibigoumaixieyi:'ٹوکن خریداری کا معاہدہ',
    qingshurugoumaijine:'براہ کرم خریداری کی رقم درج کریں۔',
    shifoyaogoumai:'کیا آپ خریدنا چاہتے ہیں؟',
    tishi:'اشارہ',
    queding:'ضرور',
    quxiao:'منسوخ کریں۔',
    yjs:'ختم',
    ywc:'مکمل',
    sgjg:'سبسکرپشن کی قیمت',
    jssj:"اختتامی وقت",
    zqsl:"جیتنے والی مقدار",
    zqje:"جیتنے والی رقم",
    tian:"آسمان",
    shi:'گھنٹہ',
    fen:'نقطہ',
    miao:'دوسرا',
    shengou:'رکنیت',
    xmxq:'پروجیکٹ کی تفصیلات',
    },
    }