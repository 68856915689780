export default {
    public:{
        zhd:"melhor",
        qsjyh:"Usuários em todo o mundo",
        gtdxz:'escolha comum',
        szhbjyytz:'Oportunidades e desafios da moeda digital',
        xsddcf:'Oportunidades e desafios de riqueza na nova era',
        yzsjypt:"Plataforma de negociação completa",
        mskljyzh:'Abra uma conta de negociação e comece a negociar agora',
        jyhg:"Conformidade empresarial",
    },

    header: {
    hello: 'Olá mundo!',
    changeLanguage: 'mudar de idioma',
    Login: 'Conecte-se',
    Register: 'registrar',
    Logout: 'Sair',
    shouye: 'primeira página',
    bibijiaoyisuo: 'Negociação de criptomoeda',
    heyuejiaoyisuo: 'negociação de contrato',
    licaichanpin: 'Piscina de mineração',
    rengou: 'Subscrição',
    ruanjianxiazai: 'Download de software',
    zhongwen: 'chinês',
    yingwen: 'Em',
    zichan: 'ativos',
    dingdan: 'Ordem',
    heyuedingdan: 'ordem de contrato',
    bibidingdan: 'Ordem de moedas',
    anquanzhongxin: 'Central de segurança',
    fanyongyaoqing: 'Convite da Comissão',
    tuichudenglu: 'Sair',
    gupiaojiaoyi: 'negociação de ações',
    waihuijiaoyi: 'Negociação Forex',
    qiquanjiaoyi:'negociação de opções',
    gupiaodingdan: 'ordem de estoque',
    waihuidingdan: 'Pedidos de Forex',
    qiquandingdan: 'ordem de opções',
    jiaoyidingdan:'ordem de negociação',
    daolizhongxin:'Central de Agências',
    huodong:'Atividade'
    },
    home: {
    huanyinglaidao: 'bem-vindo a',
    pingtaimingcheng: 'aqui',
    mengxiangqihang: 'Os sonhos partiram',
    yijiankaiqishuzihualvcheng: 'Comece sua jornada digital com um clique',
    liaojiegengduo: 'saber mais',
    chongzhi: 'recarregar',
    jiaoyi: 'troca',
    remenbang: 'Lista popular',
    zhangfubang: 'Lista de ganhadores',
    diefubang: 'Lista de perdedores',
    chengjiaoliang: 'Volume',
    zanwushuju: 'Ainda não há dados',
    jiayidui: 'par de negociação',
    zuixinjiage: 'preço mais recente',
    zhangdie24h: '24 horas sobem e descem',
    caozuo: 'operar',
    jiaoyi: 'troca',
    gengduo: 'Mais',
    suishisuidikaiqijiaoyi: 'Comece a negociar a qualquer hora, em qualquer lugar',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'Seja App ou Web, você pode iniciar sua transação rapidamente',
    saomaxiazaiApp: 'Digitalize o código QR para baixar o APP',
    iosheAndroid: 'iOS e Android',
    ninkexinrenqieanquandejiamibijiaoyisuo: 'Sua troca de criptomoedas confiável e segura',
    tishi1: 'Certificado de Reserva',
    xiangqing1: 'Prometemos manter todos os ativos dos usuários em uma proporção de pelo menos 1:1',
    tishi2: 'Melhores taxas de transação',
    xiangqing2: 'Taxas de transação preferenciais, direitos VIP competitivos e desfrute dos serviços da melhor qualidade',
    tishi3: 'Segurança com a qual você pode contar',
    xiangqing3: 'Nossas sofisticadas medidas de segurança e fundos SAFU protegem seus ativos digitais de todos os riscos',
    tishi4: 'Suporte de atendimento ao cliente 24 horas por dia, 7 dias por semana',
    xiangqing4: '24*7 horas, modo de operação em tempo integral, responda às suas perguntas relevantes o mais rápido possível',
    tishi5: 'Estável e seguro',
    xiangqing5: 'A bolsa líder mundial oferece proteção de segurança em nível bancário para seus ativos',
    tishi6: 'Perguntas frequentes',
    xiangqing6: 'Confira o FAQ para descrições detalhadas de recursos específicos',
    fanhui:"retornar",
    shishihangqing:'Cotações em tempo real',
    },
    footer: {
    bangzhuzhongxin: 'Central de Ajuda',
    dianhuazixun: 'Consulta por telefone',
    dianhuahegongzuoshijian: '1670-9756 (10h00~19h00 durante a semana/fechado nos finais de semana e feriados)',
    liaotianzixun: 'Consulta por chat 1:1',
    keketuozixun: 'Cocotok Consultoria',
    zaixianbangzhu: 'Ajuda on-line',
    h24kaifang: '(aberto 24 horas)',
    lixianbangzhu: 'Ajuda off-line',
    gongsidizhi: '1F, Yongsan Purgio Summit, 69 Hangang-gil, Yongsan-gu, Seul',
    gongzuoshijian: '(10h00~18h00 durante a semana / encerrado aos fins-de-semana e feriados)',
    shangshizixun: 'Consulta de listagem',
    hezuozixun: 'Consulta de cooperação',
    gongsi: 'empresa',
    lianxiwomwn: 'Contate-nos',
    zhidao: 'guia',
    yonghuzhichi: 'Suporte ao usuário',
    zhanghuzhinan: 'Guia da conta – Depósito/Retirada',
    ruanjianxiazai: 'Download de software',
    zhengce: 'política',
    fuwuyuyinsitiaokuan: 'Termos de Serviço e Privacidade',
    },
    login: {
    yonghudenglu: 'Login do usuário',
    huanyingdenglu: 'Bem vindo ao login',
    xiangmumingcheng: 'BCFX',
    guanfangwangzhan: 'site oficial',
    youxiang: 'Correspondência',
    qingshuruyouxiang: 'Por favor insira seu e-mail',
    mima: 'senha',
    qingshurumima: 'Por favor insira a senha',
    wangjimima: 'esqueceu a senha?',
    denglu: 'Conecte-se',
    haimeiyouzhanghao: 'Ainda não tem uma conta? ir',
    zhuce: 'registrar',
    dengluchenggong: 'Login bem-sucedido',
    denglushibai: 'falha no login',
    qingshuruyonghuminghemima: 'Por favor insira nome de usuário e senha',
    mimachangdubunengxiaoyuliuwei: 'O comprimento da senha não pode ser inferior a 6 caracteres',
    youxiangrenzheng: 'Verificação de e-mail',
    youxiangdizhi: 'Endereço de email',
    qingshuruyouxiangdizhi: 'Por favor insira o endereço de e-mail',
    tuxingyanzhengma: 'Código de verificação gráfica',
    qingshurutuxingyanzhengma: 'Por favor insira o código de verificação gráfica',
    yanzhengma: 'Código de verificação',
    qingshuruyouxiangyanzhengma: 'Por favor insira o código de verificação de e-mail',
    huoquyouxiangyanzhengma: 'Obtenha o código de verificação por e-mail',
    queding: 'Claro',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Falha ao obter o código de verificação gráfica. Solicite novamente',
    qingxianwanchengyouxiangrenzheng: 'Conclua a verificação de e-mail primeiro',
    huoquyouxiangyanzhengmashibai: 'Falha ao obter o código de verificação de e-mail,',
    houchongxinfasong: 'Reenviar após S',
    youxiangyanzhengchenggong: 'Verificação de e-mail bem-sucedida',
    yanzhengmacuowu: 'Erro no código de verificação',
    xiugaidenglumima: 'Alterar senha de login',
    xinmima: 'Nova Senha',
    quedingxinmima: 'Confirme a nova senha',
    qingshuruxinmima: 'Por favor insira a nova senha',
    qingzaicishuruxinmima: 'Por favor insira a nova senha novamente',
    liangcimimashurubuyizhi: 'As duas entradas de senha são inconsistentes',
    mimaxiugaichenggong: 'Senha alterada com sucesso',
    xiugaimimashibai: 'Falha ao alterar a senha',
    },
    trade: {
    hangqingheyue: 'contrato',
    hangqingzuixinjia: 'preço mais recente',
    hangqingzhangdiefu: 'Aumentar ou diminuir',
    hangqingzuigao24h: '24H mais alto',
    hangqingzuidi24h: '24H Mínimo',
    hangqingchengjiaoliang24h: '24H Volume',
    hangqingchengjiabishu: 'Número de transações',
    zhanghuquanyi: 'Direitos da conta',
    zhanghukeyongshouyi: 'Benefícios disponíveis',
    hangqingweituoliebiao: 'Lista de pedidos',
    hangqingjiage: 'preço',
    hangqingshuliang: 'quantidade',
    hangqingzuixinchengjiao: 'última transação',
    hangqingjiage1: 'preço',
    hangqingshuliang1: 'quantidade',
    hangqingshijian: 'tempo',
    dingdanchiyoucangwei: 'Mantenha uma posição',
    dingdandangqianweituo: 'Pedido atual',
    dingdanchengjiaojilu: 'Registro de transação',
    dingdantiaojiandan: 'ordem condicional',
    chicangheyue: 'contrato',
    chicangkaicangjunjia: 'Preço de posição',
    chicangfudongyingkui: 'Lucro e perda',
    chicanggangganbeishu: 'Aproveitar',
    chicangchicangliang: 'Cargos',
    chicangduokong: 'Longo/Curto',
    chicangduo: 'muitos',
    chicangkong: 'nulo',
    chicangzhiyingjiage: 'Preço de lucro',
    chicangzhisunjiage: 'Preço de parada de perda',
    chicangqiangpingyugujiage: 'Preço estimado de liquidação',
    chicangcaozuo: 'operar',
    chicangzhiyingzhisun: 'Obtenha lucro e pare a perda',
    chicangpingcang: 'Fechar',
    chicangshouxufei: 'taxa de manuseio',
    weituoheyue: 'contrato',
    weituoshuliang: 'Quantidade do pedido',
    weituojiage: 'Preço de comissão',
    weituofangxiang: 'direção',
    weituogangganbeishu: 'Aproveite vários',
    weituochengjiaoshuliang: 'Quantidade de transação',
    weituoshouxufei: 'taxa de manuseio',
    weituochengjiaojunjia: 'Preço médio',
    weituoshouyi: 'renda',
    weituozhuangtai: 'estado',
    weituochengjiaoshijian: 'Tempo de transação',
    weituocaozuo: 'operar',
    weituochedan: 'Cancelar pedido',
    weituotijiaoshibai: 'Falha no envio',
    weituozhilingtijiao: 'Envio de instruções',
    weituoyitijiao: 'Enviado',
    weituobufenchengjiao: 'Acordo parcial',
    weituobufenchengjiaoyichedan: 'Alguns pedidos foram cancelados',
    weituowanquanchengjiao: 'Negócio completo',
    weituowanquanchedan: 'Cancelar pedido completamente',
    weituochedanzhong: 'Cancelando pedido',
    weituokaiduo: 'Aberto por muito tempo',
    weituopingkong: 'céu plano',
    weituokaikong: 'Abrir curto',
    weituopingduo: 'Pinduo',
    chengjiaoheyue: 'contrato',
    chengjiaofangxiang: 'direção',
    chengjiaoshuliang: 'Quantidade de transação',
    chengjiaojiage: 'preço de transação',
    chengjiaoshijian: 'Tempo de transação',
    chengjiaopingcangyingkui: 'Lucro e perda',
    chengjiaoshouxufei: 'Taxa de transação',
    chengjiaokaiduo: 'Aberto por muito tempo',
    chengjiaopingkong: 'céu plano',
    chengjiaokaikong: 'Abrir curto',
    chengjiaopingduo: 'Pinduo',
    tiaojianzengjiatiaojian: 'Adicionar condições',
    tiaojianzantingquanbu: 'pausar tudo',
    tiaojianqidongquanbu: 'Comece tudo',
    tiaojianshanchuquanbu: 'Excluir tudo',
    tiaojianheyue: 'contrato',
    tiaojianweituoshuliang: 'Quantidade do pedido',
    tiaojianfangxiang: 'direção',
    tiaojiantiaojianleixing: 'tipo de condição',
    tiaojianjiageleixing: 'tipo de preço',
    tiaojianbijiaofuhao: 'Símbolo de comparação',
    tiaojiantiaojian: 'doença',
    tiaojianzhuangtai: 'estado',
    tiaojianchuangjianshijian: 'hora de criação',
    tiaojiancaozuo: 'operar',
    tiaojianxiugai: 'Rever',
    tiaojianqidong: 'comece',
    tiaojianzanting: 'pausa',
    tiaojianshanchu: 'excluir',
    tiaojianlijixiadan: 'Encomende agora',
    tiaojiankaiduo: 'Aberto por muito tempo',
    tiaojianpingkong: 'céu plano',
    tiaojiankaikong: 'Abrir curto',
    tiaojianpingduo: 'Pinduo',
    tiaojianjiagetiaojian: 'termos de preço',
    tiaojianshijiantiaojian: 'condição de tempo',
    tiaojianzuixinjia: 'preço mais recente',
    tiaojianByijia: 'compre um preço',
    tiaojianSyijia: 'Vender um preço',
    tiaojianzanting: 'pausa',
    tiaojianyunxing: 'correr',
    tiaojianyiwancheng: 'Concluído',
    tiaojianshibai: 'falhar',
    tiaojianyishanchu: 'Excluído',
    tiaojianshoudongchufawancheng: 'Acionamento manual concluído',
    tiaojianshoudongchufashibai: 'Falha no acionamento manual',
    xiadankaicang: 'Abrir',
    xiadanpingcang: 'Fechar',
    xiadanweituoleixing: 'Tipo',
    xiadanqingxuanze: 'Selecione',
    xiadanshijia: 'preço de mercado',
    xiadanxianjia: 'limite de preço',
    xiadanjiage: 'preço',
    xiadanqingshurujiage: 'Por favor insira o preço',
    xiadanshuliang: 'quantidade',
    xiadanqingshurushuliang: 'Por favor insira a quantidade',
    xiadanzhang: 'abrir',
    xiadankekaishuliang: 'Quantidade disponível',
    xiadanqingxianxuanzechicangdan: 'Selecione a ordem da posição primeiro',
    xiadankepingshuliang: 'Quantidade que pode ser nivelada',
    xiadansuoxubaozhengjin: 'Margem necessária:',
    xiadanshijiazhiyingzhisunyushe: 'Predefinição de lucro e stop loss do preço de mercado',
    xiadanyujiqiangpingjiage: 'Preço estimado de liquidação:',
    xiadanqiangpingjiagetishi: 'Este preço de liquidação esperado é calculado dinamicamente com base no total de ativos. Este preço é apenas para referência e não será utilizado como liquidação final.',
    xiadanzuoduo: 'Vá longo',
    xiadanzuokong: 'curto',
    xiadanpingcang: 'Fechar',
    zhanghuheyuezichan: 'Ativos contratuais',
    zhanghuchongbi: 'Depositar moedas',
    zhanghutibi: 'Retirar moedas',
    zhanghuhuazhuan: 'transferir',
    zhanghuzhanghuquanyi: 'Direitos da conta',
    zhanghufudongyingkui: 'lucros e perdas flutuantes',
    zhanghucangweibaozhengjin: 'Margem de posição',
    zhanghudongjiebaozhengjin: 'Margem de congelamento',
    zhanghukeyongquanyi: 'Benefícios disponíveis',
    zhanghubaozhengjinshiyonglv: 'Taxa de utilização de margem',
    hangqingheyuexinxi: 'Informações do contrato',
    hangqingheyuemingcheng: 'Nome do contrato',
    hangqingjijiahuobi: 'Moeda de cotação',
    hangqingheyuedaxiao: 'Tamanho do contrato',
    hangqingzuixiaojiagebodong: 'flutuação de preço mínimo',
    dialogleverRateganggantiaozheng: 'Ajuste de alavancagem',
    dialogleverRatequeding: 'Claro',
    dialogtransferzichanhuazhuan: 'Transferência de ativos',
    dialogtransferbizhong: 'Moeda',
    dialogtransfercong: 'de',
    dialogtransferdao: 'chegar',
    dialogtransfershuliang: 'quantidade',
    dialogtransferqingshuruhuazhuanshuliang: 'Por favor insira o valor da transferência',
    dialogtransferquanbu: 'todos',
    dialogtransferkeyong: 'Disponível',
    dialogtransferhuazhuan: 'transferir',
    dialogtransferjichuzhanghu: 'Conta básica',
    dialogtransferheyuezhanghu: 'Conta de contrato',
    dialogfitlosszhiyingzhisun: 'Obtenha lucro e pare a perda',
    dialogfitlossheyu: 'contrato',
    dialogfitlossduokong: 'Longo/Curto',
    dialogfitlosschichangjunjia: 'preço médio da posição',
    dialogfitlossshuliang: 'quantidade',
    dialogfitlosszuixinjia: 'preço mais recente',
    dialogfitlosszhiyingdianshu: 'Obtenha pontos de lucro',
    dialogfitlosszhisundianshu: 'Parar pontos de perda',
    dialogfitlossdianshu: 'pontuação',
    dialogfitlosszhiyingjiage: 'Preço de lucro',
    dialogfitlosszhisunjiage: 'Preço de parada de perda',
    dialogfitlossjiage: 'preço',
    dialogfitlossyuqishouyi: 'retorno esperado',
    dialogfitlossyuqikuisun: 'perda esperada',
    dialogfitlosstishi1: 'Dica: A oscilação mínima do contrato atual é',
    dialogfitlosstishi2: ', defina o preço como 0 para cancelar o lucro e o stop loss.',
    dialogfitlossqueding: 'Claro',
    dialogfitlossduo: 'muitos',
    dialogfitlosskong: 'nulo',
    dialogconditionzengjiayuntiaojiandan: 'Adicionar planilha de condições de nuvem',
    dialogconditiontiaojianleixing: 'Tipo de condição:',
    dialogconditionjiagetiaojiandan: 'Folha de termos de preço',
    dialogconditionshijiantiaojiandan: 'Folha de condições de tempo',
    dialogconditionjiageleixing: 'Tipo de preço:',
    dialogconditionzuixinjia: 'preço mais recente',
    dialogconditionByijia: 'compre um preço',
    dialogconditionSyijia: 'Vender um preço',
    dialogconditiontiaojianshezhi: 'Configurações de condição:',
    dialogconditionjiageshezhi: 'Configurações de preço:',
    dialogconditionqingshurujiage: 'Por favor insira o preço',
    dialogconditionshijianshezhi: 'Configuração de tempo:',
    dialogconditionjiaoyileixing: 'Tipo de transação:',
    dialogconditionkaiduo: 'Aberto por muito tempo',
    dialogconditionpingkong: 'céu plano',
    dialogconditionkaikong: 'Abrir curto',
    dialogconditionpingduo: 'Pinduo',
    dialogconditiondingdanshuliang: 'Quantidade do pedido:',
    dialogconditionqingshurudingdanshuliang: 'Por favor insira a quantidade do pedido',
    dialogconditiondingdanshixiao: 'Hora do pedido:',
    dialogconditionyongjiuyouxia: 'Válido permanentemente',
    dialogconditiontijiao: 'enviar',
    dialogAutoProfitLosszhiyingzhisun: 'Obtenha lucro e pare a perda',
    dialogAutoProfitLossmingcheng: 'nome',
    dialogAutoProfitLosszhiyingdian: 'Obter ponto de lucro',
    dialogAutoProfitLosszhisundian: 'Pare o ponto de perda',
    dialogAutoProfitLosschongzhi: 'reiniciar',
    dialogAutoProfitLoss: 'enviar',
    dialogCloseOrderpingcang: 'Fechar',
    dialogCloseOrderweituoleixing: 'Tipo de delegado',
    dialogCloseOrderqingxuanze: 'Selecione',
    dialogCloseOrdershijia: 'preço de mercado',
    dialogCloseOrderxianjia: 'limite de preço',
    dialogCloseOrderjiage: 'preço',
    dialogCloseOrderqingshurujiage: 'Por favor insira o preço',
    dialogCloseOrdershuliang: 'quantidade',
    dialogCloseOrderqingshurushuliang: 'Por favor insira a quantidade',
    dialogCloseOrderzhang: 'abrir',
    dialogCloseOrderkepingshuliang: 'Quantidade que pode ser nivelada',
    dialogCloseOrderqueding: 'Claro',
    jsMessageResgaiheyuegangganxiugaichenggong: 'A alavancagem do contrato foi modificada com sucesso',
    jsMessageResgaiheuyegangganxiugaishibai: 'A modificação da alavancagem do contrato falhou',
    jsMessageReskaicangchenggong: 'Posição aberta com sucesso',
    jsMessageReskaicangshibai: 'Falha na abertura de posição:',
    jsMessageReskaicang1: 'É proibido abrir uma posição',
    jsMessageReskaicang2: 'Contrato não existe',
    jsMessageReskaicang3: 'O número de posições abertas excede o limite superior',
    jsMessageReskaicang4: 'O preço é 0',
    jsMessageReskaicang5: 'Margem insuficiente para abertura de posição',
    jsMessageRespingcangchenggong: 'Posição fechada com sucesso',
    jsMessageRespingcangshibai: 'Falha ao fechar a posição:',
    jsMessageRespingcang1: 'Contrato não existe',
    jsMessageRespingcang2: 'O recibo de posição não existe',
    jsMessageRespingcang3: 'Quantidade insuficiente',
    jsMessageReszijinhuazhuanchenggong: 'Transferência de fundos bem-sucedida',
    jsMessageReszijinhuazhuanshibai: 'Falha na transferência de fundos',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'Ordem de posição Stop Profit e Stop Loss definida com sucesso',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'As configurações de stop-profit e stop-loss para ordens de posição falharam:',
    jsMessageResweituodanchedanchenggong: 'Pedido cancelado com sucesso',
    jsMessageResweituodanchedanshibai: 'Falha no cancelamento do pedido',
    jsMessageRestiaojiandantianjiachenggong: 'Pedido condicional adicionado com sucesso',
    jsMessageResheyuebucunzai: 'Contrato não existe',
    jsMessageResjiageshezhicuowu: 'Erro na definição de preço',
    jsMessageResshijianshezhicuowu: 'Erro de configuração de hora',
    jsMessageResneibucuowu: 'Erro interno',
    jsMessageRestiaojiandantianjiashibai: 'Falha ao adicionar pedido condicional:',
    jsMessageReszhiyingzhisunshezhichenggong: 'Take Profit e Stop Loss definidos com sucesso',
    jsconfirmquedingchedanma: 'Tem certeza de que deseja cancelar o pedido?',
    jsconfirmtishi: 'dica',
    jsconfirmqueding: 'Claro',
    jsconfirmquxiao: 'Cancelar',
    jsMessageqingxiandenglu: 'Por favor, faça login primeiro!',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'Este contrato possui posições abertas e a alavancagem não pode ser modificada.',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'Este contrato tem um mandato e a alavancagem não pode ser modificada.',
    jsMessageqingshuruzhengquedeshuliang: 'Por favor insira a quantidade correta!',
    jsMessageqingshuruzhengquedejiage: 'Por favor insira o preço correto!',
    jsMessageqingxianxuanzechicang: 'Selecione uma posição primeiro!',
    jsMessageqingshuruhuazhuanshuliang: 'Por favor, insira o valor da transferência!',
    jsMessageqingxianxuanzeheyue: 'Selecione um contrato primeiro!',
    jsMessageqingshurutiaojiandanshuliang: 'Por favor, insira a quantidade condicional do pedido!',
    jsMessageqingshurutiaojiandandejiage: 'Por favor, insira o preço do pedido condicional!',
    jsMessageqingshurutiaojiandanzhixingshijian: 'Por favor, insira o tempo de execução da ordem condicional!',
    emptytext: 'Ainda não há dados',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'Os pips Take Profit e Stop Loss estão definidos incorretamente',
    jsMessageReschicangdanbucunzai: 'O recibo de posição não existe',
    zxkcje:'Valor mínimo de abertura'
    },
    commisson:{
    dlzx:'Central de Agências',
    sjzl:'Visão geral dos dados',
    quanbu:"todos",
    jinri:'hoje',
    zuori:'ontem',
    ri:'dia',
    wdfy:'Meu desconto',
    wdsjfy:'Meu desconto de terceiro nível',
    dengji:'nota',
    yqrs:'Número de pessoas convidadas',
    tdrs:'Tamanho da equipe',
    yqxq:'Detalhes do convite',
    yhm:"nome de usuário",
    cjsj:'hora de criação',
    fyxq:'Detalhes do desconto',
    zyj:'comissão total',
    dryj:'Comissão de um dia',
    fyje:'Valor do desconto',
    
    },
    assets: {
    zongzichan: 'ativos totais',
    chongbi: 'Depositar moedas',
    tibi: 'Retirar moedas',
    huazhuan: 'transferir',
    fabizhanghu: 'Conta básica',
    heyuezhanghu: 'Conta de contrato',
    jiayiliushui: 'fluxo de transação',
    dialogzichanhuazhuan: 'Transferência de ativos',
    cong: 'de',
    dao: 'chegar',
    shuliang: 'quantidade',
    qingshuruhuazhuanshuliang: 'Por favor insira o valor da transferência',
    quanbu: 'todos',
    keyong: 'Disponível',
    huanzhuan: 'transferir',
    zijinhuazhuanchenggong: 'Transferência de fundos bem-sucedida',
    zijinhuazhuanshibai: 'Falha na transferência de fundos',
    bibizhanghu: 'Conta de moeda',
    qiquanzhanghu:'conta de opções',
    zczl:'Visão geral dos ativos',
    zzczh:'Desconto total de ativos',
    ztzc:"Ativos em trânsito",
    djzc:'Congelar ativos',
    zyzc:'Ativos penhorados',
    cwzc:'Posicionar ativos',
    jyzh:'conta de negociação',
    bizhong:'Moeda',
    dongjie:'congelar',
    lirun:"lucro",
    hyzh:"Conta de contrato",
    },
    basicsAccountRecord: {
    fanhui: 'retornar',
    fabizhanghuliushui: 'Fluxo da conta de negociação',
    fabijiaoyiliushuileixing: 'Tipo de fluxo de transação',
    qingxuanze: 'Selecione',
    chaxunriqi: 'Data da consulta',
    liushuileixing: 'Tipo de pipeline',
    shijian: 'tempo',
    biandongjine: 'Alterar valor',
    yue: 'Equilíbrio',
    quanbu: 'todos',
    Sellbi: 'vender moedas',
    Buybi: 'comprar moedas',
    tibi: 'Retirar moedas',
    chongbi: 'Depositar moedas',
    fabizhuanheyue: 'Contrato básico de transferência',
    heyuezhuanfabi: 'Contrato para base',
    zhuanzhang: 'transferir',
    fabihuazhuandaoheyue: 'Transferência básica para contrato',
    heyuehuazhuandaofabi: 'Transferência de contrato para base',
    goumaijijin: 'Compre uma piscina de mineração',
    jijinshuhui: 'Resgate de pool de mineração',
    jijinshouyi: 'Renda de resgate do pool de mineração',
    fabizhuanqihuo: 'Conversão básica para futuros',
    qihuozhuanfabi: 'Base de transferência de futuros',
    fabizhuanganggu: 'Transferência básica para ações de Hong Kong',
    gangguzhuanfabi: 'Noções básicas de transferência de ações de Hong Kong',
    fabizhuanbibi: 'Moeda básica de transferência',
    bibizhuanfabi: 'Noções básicas de transferência de moeda para moeda',
    goumaiSGCB: 'Comprar OIC',
    chushouSGCB: 'Vendendo ICO',
    huoqushibai: 'Não foi possível obter',
    zanwushuju: 'Ainda não há dados',
    },
    contractAccountRecord: {
    fanhui: 'retornar',
    heyuezhanghuliushui: 'Fluxo da conta do contrato',
    heyuejiaoyiliushuileixing: 'Tipo de fluxo de transação de contrato',
    qingxuanze: 'Selecione',
    chaxunriqi: 'Data da consulta',
    liushuileixing: 'Tipo de pipeline',
    shijian: 'tempo',
    biandongjine: 'Alterar valor',
    yue: 'Equilíbrio',
    quanbu: 'todos',
    kaicangshouxufei: 'Taxa de abertura',
    pingcangshouxufei: 'Taxa de fechamento',
    pingcangyingkui: 'Lucros e perdas de fechamento de posição',
    fabizhuanheyue: 'Contrato básico de transferência',
    heyuezhuanfabi: 'Contrato para base',
    zhuanzhang: 'transferir',
    kaicangyongjin: 'Comissão de abertura',
    pingcangyongjin: 'Comissão de fechamento',
    huoqushibai: 'Não foi possível obter',
    zanwushuju: 'Ainda não há dados',
    },
    recharge: {
    zanwushuju: 'Ainda não há dados',
    shijian: 'tempo',
    leixing: 'tipo',
    shuliang: 'quantidade',
    zhuangtai: 'estado',
    caozuo: 'operar',
    chakanxiangqing: 'verifique os detalhes',
    huoqushibai: 'Não foi possível obter',
    dialogtitle: 'Detalhes',
    chongbi: 'Depositar moedas',
    fanhui: 'retornar',
    lianmingcheng: 'Nome da cadeia',
    fuzhilianjie: 'Copiar endereço',
    tishixinxi1: '• Por favor, não deposite quaisquer ativos que não sejam TRC_USDT ou ERC_USDT no endereço acima, caso contrário, os ativos não serão recuperados',
    tishixinxi2: '• Após recarregar no endereço acima, é necessária a confirmação de todo o nó da rede. A conta será recebida após 1 confirmação de rede e a moeda poderá ser retirada após 2 confirmações de rede.',
    tishixinxi3: '• Valor mínimo de depósito: 50USDT, depósitos inferiores ao valor mínimo não serão creditados e não poderão ser reembolsados',
    tishixinxi4: '• Seu endereço de recarga não mudará com frequência e poderá ser recarregado repetidamente, caso haja alguma alteração, faremos o possível para notificá-lo através de anúncios no site ou e-mails;',
    tishixinxi5: '• Certifique-se de que seu computador e navegador estejam seguros para evitar que informações sejam adulteradas ou vazadas.',
    putongchongbi: 'Depósito normal',
    jiaoyichenggong: 'Transação bem-sucedida',
    jiaoyishibai: 'Falha na transação',
    OMNIweihuzhong: 'A cadeia OMNI está em manutenção, selecione TRC20',
    },
    withdraw: {
    fanhui: 'retornar',
    zanwushuju: 'Ainda não há dados',
    tibi: 'Retirar moedas',
    huoqushibai: 'Não foi possível obter',
    OMNIweihuzhong: 'A cadeia OMNI está em manutenção, selecione TRC20',
    shijian: 'tempo',
    leixing: 'tipo',
    shuliang: 'quantidade',
    zhuangtai: 'estado',
    caozuo: 'operar',
    chakanxiangqing: 'verifique os detalhes',
    putongtibi: 'Retirada normal',
    dialogtitle: 'Detalhes',
    dialogtitlewithdraw: 'Retirar moedas',
    houchongxinfasong: 'Reenviar após S',
    huoquyouxiangyanzhengma: 'Obtenha o código de verificação por e-mail',
    lianmingcheng: 'Nome da cadeia',
    tibidizhi: 'Endereço de retirada',
    qingshurutibidizhi: 'Por favor, insira o endereço de retirada',
    tibishuliang: 'Quantidade de moedas retiradas',
    shouxufei: 'taxa de manuseio',
    qingshurutibishuliang: 'Por favor insira o valor do saque',
    quanbu: 'todos',
    keyong: 'Disponível',
    daozhangshuliang: 'Quantidade de chegada',
    zuixiaotibishuliang: 'O valor mínimo de retirada é:',
    tishixinxi: 'Para garantir a segurança dos fundos, quando a política de segurança da sua conta for alterada ou a sua senha for modificada, realizaremos uma revisão manual do saque. Seja paciente e aguarde que a equipe entre em contato com você por telefone ou e-mail. Certifique-se de que seu computador e navegador estejam seguros para evitar que os dados sejam adulterados ou vazados.',
    tuxingyanzhengma: 'Código de verificação gráfica',
    qingshurutuxingyanzhengma: 'Por favor insira o código de verificação gráfica',
    youxiangyanzhengma: 'Código de verificação de e-mail',
    queding: 'Claro',
    tijiaozhong: 'Enviando',
    yijujue: 'Rejeitado',
    yitongguo: 'Aprovado',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Falha ao obter o código de verificação gráfica. Solicite novamente',
    huoquyouxiangyanzhengmashibai: 'Falha ao obter o código de verificação de e-mail,',
    qingxianwanchengyouxiangrenzheng: 'Conclua a verificação de e-mail primeiro',
    huoquyouxiangshibaiqingchongxinqingqiu: 'Não foi possível obter o endereço de e-mail. Solicite novamente',
    qingshuruyouxiangyanzhengma: 'Por favor insira o código de verificação de e-mail',
    youxiangyanzhengmacuowu: 'O código de verificação de e-mail está errado,',
    qxsmrz:'Verifique seu nome verdadeiro primeiro',
    tishi:'dica',
    queding:'Claro'
    },
    contractTransactionRecord: {
    fanhui: 'retornar',
    zanwushuju: 'Ainda não há dados',
    heyuedingdan: 'ordem de contrato',
    qingxuanze: 'Selecione',
    quanbu: 'todos',
    kaiduo: 'Aberto por muito tempo',
    pingkong: 'céu plano',
    kaikong: 'Abrir curto',
    pingduo: 'Pinduo',
    xianjiaweituo: 'ordem limite',
    shijiaweituo: 'ordem de mercado',
    suoyouzhuangtai: 'Todos os status',
    tijiaoshibai: 'Falha no envio',
    zhunbeitijiao: 'pronto para enviar',
    yitijiao: 'Enviado',
    bufentijiao: 'Acordo parcial',
    bufentijiaoyichedan: 'Alguns pedidos foram cancelados',
    quanbuchengjiao: 'Todas as transacções',
    yichedan: 'Pedido cancelado',
    huoqushibai: 'Não foi possível obter',
    heyuedaima: 'Código do contrato',
    ganggan: 'alavanca',
    xiadanshijian: 'Hora do pedido',
    chengjiaoshijian: 'Tempo de transação',
    fangxiang: 'direção',
    baojialeixing: 'Tipo de cotação',
    weituoliang: 'Valor da comissão (peças)',
    weituojia: 'Preço do pedido (USDT)',
    chengjiaoliang: 'Volume de negociação (tickets)',
    chengjiaojunjia: 'Preço médio de transação (USDT)',
    yingkui: 'Lucros e perdas (USDT)',
    shouxufei: 'Taxa de manuseio (USDT)',
    zhuangtai: 'estado',
    gupiaodingdan: 'ordem de estoque',
    waihuidingdan: 'Pedidos de Forex',
    gupiaodaima: 'Código de estoque',
    waihuidaima: 'Código Forex',
    },
    kLine: {
    zhibiao: 'índice',
    shezhi: 'configurar',
    quanping: 'tela cheia',
    tuichuquanping: 'Sair da tela inteira',
    jishuzhibiao: 'Indicadores técnicos',
    futuzhibiao: 'Indicador de subimagem',
    zhutuzhibiao: 'Indicador gráfico principal',
    huifumoren: 'Restaurar padrão',
    lazhutuleixing: 'Tipos de velas',
    qingxuanze: 'Selecione',
    quanshixin: 'Tudo sólido',
    quankongxin: 'Totalmente oco',
    zhangkongxin: 'subindo oco',
    diekongxin: 'cair oco',
    mianjitu: 'gráfico de área',
    jiagezhouleixing: 'Tipo de eixo de preço',
    xianxingzhou: 'eixo de linha',
    baifenbizhou: 'Eixo percentual',
    duishuzhou: 'Eixo logarítmico',
    zuigaojiaxianshi: 'Exibição do preço mais alto',
    zuidijiaxianshi: 'Exibição de preço mais baixo',
    zuixinjiaxianshi: 'Exibição de preço mais recente',
    zhibiaozuixinzhixianshi: 'O último valor do indicador é exibido',
    daozhizuobiao: "coordenadas invertidas",
    wanggexianxianshi: 'Exibição de linha de grade',
    hfcg:'Recuperação bem-sucedida'
    },
    SecurityCenter: {
    fanhui: 'retornar',
    anquanzhongxin: 'Central de segurança',
    qingshurunicheng: 'Por favor insira um apelido',
    nichengxiugaishibai: 'Falha na modificação do apelido',
    huoquyonghuxinxishibai: 'Falha ao obter informações do usuário',
    xiugaidenglumima: 'Alterar senha de login',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Falha ao obter o código de verificação gráfica. Solicite novamente',
    qingshurujiumima: 'Por favor insira a senha antiga',
    qingshuruxinmima: 'Por favor insira a nova senha',
    qingzaicishuruxinmima: 'Por favor insira a nova senha novamente',
    qingshurutuxingyanzhengma: 'Por favor insira o código de verificação gráfica',
    qingshuruyanzhengma: 'Por favor insira o código de verificação',
    huoquzhuceshoujihaoshibai: 'Falha ao obter número de celular registrado',
    qingxianwanchengshoujirenzheng: 'Por favor, complete a autenticação do telefone celular primeiro',
    huoqushoujiyanzhengmashibai: 'Falha ao obter o código de verificação do celular',
    huoqushoujiyanzhengma: 'Obtenha o código de verificação do celular',
    houchongxinfasong: 'Reenviar após S',
    mimacuowu: 'Senha errada',
    liangcixinmimashurubuyizhiqingjiancha: 'As duas novas senhas inseridas são inconsistentes, verifique',
    xinmimaheyuanmimabunengyiyang: 'A nova senha não pode ser igual à senha original',
    qingshurushoujiyanzhengma: 'Por favor, insira o código de verificação do celular',
    yanzhengmacuowu: 'Erro no código de verificação',
    denglumimaxiugaichenggong: 'Senha de login alterada com sucesso',
    denglumimaxiugaishibai: 'Falha na modificação da senha de login',
    xiugaizijinmima: 'Alterar senha do fundo',
    qingshuruzijinmima: 'Por favor insira a senha do fundo',
    qingzaicishuruzijinmima: 'Por favor, insira a senha do fundo novamente',
    liangcizijinmimabuyizhiqingjiancha: 'As duas senhas de fundos são inconsistentes, verifique',
    shezhizijinmimachenggong: 'Defina a senha do fundo com sucesso',
    shezhizijinmimashibai: 'Falha ao definir a senha do fundo',
    xiugaizijinmimachenggong: 'Senha do fundo modificada com sucesso',
    xiugaizijinmimashibai: 'Falha ao alterar a senha do fundo',
    youxiangrenzheng: 'Autenticação de e-mail',
    huoquyouxiangyanzhengma: 'Obtenha o código de verificação por e-mail',
    qingshuruyouxiangdizhi: 'Por favor insira o endereço de e-mail',
    huoquyouxiangyanzhengmashibai: 'Falha ao obter o código de verificação de e-mail',
    qingshuruyouxiangyanzhengma: 'Por favor insira o código de verificação de e-mail',
    youxiangyanzhengchenggong: 'Verificação de e-mail bem-sucedida',
    weirenzheng: 'Não certificado',
    queding: 'Claro',
    anquanshezhiguanli: 'Gerenciamento de configurações de segurança',
    denglumima: 'Senha de login',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'Ao definir uma senha de login, você poderá fazer login diretamente usando a senha da sua conta',
    xiugai: 'Rever',
    zijinmima: 'Senha do fundo',
    yongyubaohuzijinanquan: 'Usado para proteger a segurança dos fundos',
    shezhi: 'configurar',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'Usado para verificação de segurança ao fazer login, retirar moedas, recuperar senhas e modificar configurações de segurança.',
    yirenzheng: 'Certificado',
    renzheng: 'Certificação',
    shimingrenzheng: 'Autenticação de nome real',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'Usado para autenticação de segurança ao comprar, vender, sacar, depositar e transferir moedas.',
    daishenhe: 'Revisão pendente',
    yibohui: 'Dispensado',
    yuandenglumima: 'Senha de login original',
    xinmima: 'Nova Senha',
    quedingxinmima: 'Confirme a nova senha',
    tuxingyanzhengma: 'Código de verificação gráfica',
    yanzhengma: 'Código de verificação',
    zijinmima: 'Senha do fundo',
    querenzijinmima: 'Confirme a senha do fundo',
    youxiangdizhi: 'Endereço de email',
    qingxianwanchengyouxiangrenzheng: 'Conclua a verificação de e-mail primeiro',
    xiugaitouxiangchenggong: 'Avatar modificado com sucesso',
    xiugaitouxiangshibai: 'Falha ao modificar o avatar',
    shangchuantouxiangzhinengshijpggeshi: 'O upload de fotos de avatar só pode estar no formato JPG!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'O tamanho da imagem do avatar enviada não pode exceder 2 MB!',
    },
    verification: {
    fanhui: 'retornar',
    shimingrenzheng: 'Autenticação de nome real',
    qingshuruxingming: 'Por favor insira o nome',
    qingshuruzhengjianhaoma: 'Por favor insira seu número de identificação',
    huoquyonghuxinxishibai: 'Falha ao obter informações do usuário',
    wenjiangeshicuowu: 'Erro no formato do arquivo',
    wenjianbunengdayu5M: 'O arquivo não pode ser maior que 5M',
    qingtianxiexingming: 'Por favor preencha seu nome',
    qingtianxiezhengjianhaoma: 'Por favor preencha o número de identificação',
    qingshangchuanzhengjianzhengmian: 'Faça upload da frente do seu documento de identidade',
    qingshangchuanzhengjianfanmian: 'Por favor, carregue o verso do seu documento de identidade',
    tijiaochenggong: 'Envio bem-sucedido',
    kaiqishenfenrenzheng: 'Ative a autenticação de identidade',
    bohuiyuanyin: 'Motivo da rejeição',
    qingtianxiejiashizhizhaogongminkahuzhao: 'Por favor preencha os dados da sua carta de condução/cartão de cidadão/passaporte',
    xingming: 'Nome',
    zhengjianhaoma: 'Número de identificação',
    qingshangchuanjiashizhizhaogongminkahuzhao: 'Por favor carregue informações de carteira de motorista/cartão de cidadão/passaporte',
    shangchuanzhengjianzhengmian: 'Carregue a frente do ID',
    huotuozhuafangru: 'Ou arraste e solte',
    shangchuanzhengjianfanmian: 'Carregar o verso do certificado',
    tijiao: 'enviar',
    },
    invite: {
    yaoqinghaoyoudejiangli: 'Convide amigos e ganhe recompensas',
    yongjiukede: 'permanentemente disponível',
    jiaoyifanyong: 'Desconto de negociação',
    fasongyaoqinggeihaoyou: 'Enviar convite para amigos',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'Convide amigos para registrar o BCFX compartilhando o link',
    haoyouzhuce: 'Registro de amigo',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'O amigo aceita o convite, completa o cadastro e realiza transações',
    huodexiangyingbilijiangli: 'Receba recompensas proporcionais correspondentes',
    qingsonghuodejiaoyishouxufeijianglifuli: 'Obtenha facilmente benefícios de bônus de taxas de transação',
    wodezhuanshufenxiangfangshi: 'Minha maneira exclusiva de compartilhar',
    yaoqinglianjie: 'Link de convite',
    jiazaizhong: 'carregando...',
    fuzhilianjie: 'Copiar link',
    yaoqingma: 'Código de convite',
    fuzhiyaoqingma: 'Copiar código de convite',
    wodeyaoqing: 'meu convite',
    yaoqingrenshu: 'Número de pessoas convidadas',
    huodejiangli: 'Seja recompensado',
    huodongxize: 'Detalhes do evento',
    tips1: 'O relacionamento entre os usuários da plataforma é de três níveis, tais como: os usuários ABCD constituem três níveis de usuários e A é o nível mais alto!',
    tips2: 'Relações de interesse entre níveis',
    tips201: '。',
    tips3: 'Recompensa em dinheiro: o Cliente A pode receber 40% da taxa de transação do contrato do Cliente B + 20% da taxa de transação do contrato do Cliente C + 10% da taxa de transação do contrato do Cliente D',
    tips4: 'Por exemplo: o cliente A tem 10 clientes de primeiro nível B, 10 clientes de segundo nível C e 10 clientes de terceiro nível D. Se cada contrato de cliente negociar 100.000 USDT',
    tips5: 'Então o retorno que o cliente A pode obter',
    tips6: '100.000 * 1,2% * 40% * 10 + 100.000 * 1,2% * 20% * 10 + 100.000 * 1,2% * 10% * 10 = recompensa em dinheiro de 8.400 USDT, que pode ser sacada em tempo real',
    tips7: 'Caso a atividade seja ajustada, estará sujeita a atualizações da plataforma BCFX, cabendo o direito final de interpretação ao BCFX.',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Falha ao obter o código de verificação gráfica. Solicite novamente',
    qingshuruyouxiang: 'Por favor insira seu e-mail',
    qingshurutuxingyanzhengma: 'Por favor insira o código de verificação gráfica',
    qingshuruyanzhengma: 'Por favor insira o código de verificação',
    qingshurunindemima: 'Por favor digite sua senha',
    qingzaicishurunindemima: 'Por favor digite sua senha novamente',
    qingshuruzhifumima: 'Por favor insira a senha de pagamento',
    xuantian: 'Opcional',
    bitian: 'Obrigatório',
    yonghuzhuce: 'Cadastro de usuário',
    huanyingdenglu: 'Bem vindo ao login',
    guanfangwangzhan: 'site oficial',
    xiangmumingcheng: 'BCFX',
    youxiang: 'Correspondência',
    tuxingyanzhengma: 'Código de verificação gráfica',
    yanzhengma: 'Código de verificação',
    huoquyanzhengma: 'Código ',
    shezhimima: 'Definir senha',
    quedingmima: 'Confirme sua senha',
    zhifumima: 'Senha de pagamento',
    yaoqingma: 'Código de convite',
    zhuce: 'registrar',
    yiyouzhanghao: 'Já tem uma conta?',
    denglu: 'Conecte-se',
    qingshuruyouxiangdizhi: 'Por favor insira o endereço de e-mail',
    huoqushibai: 'Não foi possível obter',
    houchongxinfasong: 'Reenviar após S',
    liangcishurudemimabuyizhi: 'As senhas inseridas duas vezes são inconsistentes',
    zhucechenggong: 'Registro realizado com sucesso',
    zhuceshibai: 'Falha no registro',
    },
    treasure:{
    jichubizhong: 'moeda base',
    shouyibizhong: 'Moeda da receita',
    wakuangtianshu: 'Dias de mineração',
    shouyitianshu: 'Dias de ganho',
    suodingtianshu: 'Dias de bloqueio',
    yichanshengshouyi: 'Receita gerada',
    yuqishouyi: 'retorno esperado',
    kaishishijian: 'hora de início',
    jieshushijian: 'hora de término',
    qingshuruninyaosuodingdebizhongshuliang: 'Por favor, insira o valor de USDT na moeda que você deseja bloquear',
    huoqushibai: 'Não foi possível obter',
    meiyitian: 'a cada 1 dia',
    dakai: 'Abrir',
    yuanyuzhoukuangchi: 'Pool de mineração do metaverso',
    jilu: 'Registro',
    kuangchixiangqing: 'Detalhes do pool de mineração',
    suo: 'Trancar',
    yitianchanchu: 'Produção de 1 dia',
    zuixiaosuodingshijian: 'Tempo mínimo de bloqueio',
    nindeUSDT: 'Seu USDT',
    tian: 'céu',
    quanbu: 'todos',
    suoUSDTbingkaishishengchan: 'Bloqueie o USDT e inicie a produção',
    ninquedingyaosuoUSDTkaishishengchanma: 'Tem certeza de que deseja bloquear o USDT e iniciar a produção?',
    tishi: 'dica',
    queding: 'Claro',
    quxiao: 'Cancelar',
    kaishishengchan: 'Comece a produção!',
    yiquxiao: 'Cancelado',
    },
    subscription:{
    rengouliebiao:'Lista de assinaturas',
    jijiangdaolai:'Em breve',
    jinxingzhong:'em andamento',
    quanbu:'todos',
    keshiyonghuobi:"Moedas disponíveis",
    zongshuliang:'quantidade total',
    meiyouxianzhi:'sem limite',
    shengyushuliang:'Quantidade restante',
    kaishishijian:'hora de início',
    zhuangtai:'estado',
    jilu:'Registro',
    zanwujilu:"Ainda não há registro",
    leixing:'tipo',
    shuliang:'quantidade',
    goumaijine:'Valor da compra',
    shijian:'tempo',
    goumai:'Comprar',
    duichu:'Do nada',
    jiangli:'prêmio',
    tibi:'Retirar moedas',
    huidaomulu:'Voltar ao índice',
    dangqianchiyou:'atualmente mantido',
    keyong:'Disponível',
    keduihuan:'Resgatável',
    shishihangqing:'Cotações em tempo real',
    yue:'Equilíbrio',
    biaoti:'título',
    fashoushuliang:'Quantidade à venda',
    jindu:"agendar",
    goumaishuliang:'Quantidade de compra',
    ge:'individual',
    woyiyuedu:'eu li',
    daibigoumaixieyi:'Contrato de compra de token',
    qingshurugoumaijine:'Por favor insira o valor da compra',
    shifoyaogoumai:'Você quer comprar',
    tishi:'dica',
    queding:'Claro',
    quxiao:'Cancelar',
    yjs:'terminou',
    ywc:'Concluído',
    sgjg:'Preço da assinatura',
    jssj:"hora de término",
    zqsl:"Quantidade vencedora",
    zqje:"Valor ganho",
    tian:"céu",
    shi:'hora',
    fen:'apontar',
    miao:'Segundo',
    shengou:'Subscrição',
    xmxq:'Detalhes do projeto',
    },
    }