export default {
    public:{
        zhd:"mejor",
        qsjyh:"Usuarios en todo el mundo",
        gtdxz:'elección común',
        szhbjyytz:'Oportunidades y desafíos de la moneda digital',
        xsddcf:'Oportunidades y desafíos de riqueza en la nueva era',
        yzsjypt:"Plataforma comercial integral",
        mskljyzh:'Abra una cuenta comercial y comience a operar ahora',
        jyhg:"Cumplimiento empresarial",
    },

    header: {
    hello: '¡Hola Mundo!',
    changeLanguage: 'cambiar de idioma',
    Login: 'Acceso',
    Register: 'registro',
    Logout: 'Finalizar la sesión',
    shouye: 'página delantera',
    bibijiaoyisuo: 'Comercio de criptomonedas',
    heyuejiaoyisuo: 'comercio por contrato',
    licaichanpin: 'Piscina minera',
    rengou: 'Suscripción',
    ruanjianxiazai: 'Descarga de programas',
    zhongwen: 'Chino',
    yingwen: 'En',
    zichan: 'activos',
    dingdan: 'Orden',
    heyuedingdan: 'orden de contrato',
    bibidingdan: 'Orden de monedas',
    anquanzhongxin: 'Centro de seguridad',
    fanyongyaoqing: 'invitación de la comisión',
    tuichudenglu: 'Finalizar la sesión',
    gupiaojiaoyi: 'comercio de acciones',
    waihuijiaoyi: 'comercio de divisas',
    qiquanjiaoyi:'comercio de opciones',
    gupiaodingdan: 'orden de existencias',
    waihuidingdan: 'Órdenes de divisas',
    qiquandingdan: 'orden de opciones',
    jiaoyidingdan:'orden comercial',
    daolizhongxin:'Centro de agencia',
    huodong:'Actividad'
    },
    home: {
    huanyinglaidao: 'bienvenido a',
    pingtaimingcheng: 'aquí',
    mengxiangqihang: 'Los sueños zarpan',
    yijiankaiqishuzihualvcheng: 'Comienza tu viaje digital con un clic',
    liaojiegengduo: 'aprender más',
    chongzhi: 'completar',
    jiaoyi: 'comercio',
    remenbang: 'lista popular',
    zhangfubang: 'Lista de ganadores',
    diefubang: 'lista de perdedores',
    chengjiaoliang: 'Volumen',
    zanwushuju: 'Aún no hay datos',
    jiayidui: 'par comercial',
    zuixinjiage: 'último precio',
    zhangdie24h: '24 horas de ascenso y descenso',
    caozuo: 'funcionar',
    jiaoyi: 'comercio',
    gengduo: 'Más',
    suishisuidikaiqijiaoyi: 'Comience a operar en cualquier momento y en cualquier lugar',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'Ya sea por aplicación o web, puede iniciar rápidamente su transacción',
    saomaxiazaiApp: 'Escanea el código QR para descargar la APP',
    iosheAndroid: 'iOS y Android',
    ninkexinrenqieanquandejiamibijiaoyisuo: 'Su intercambio de criptomonedas confiable y seguro',
    tishi1: 'Certificado de reserva',
    xiangqing1: 'Prometemos conservar todos los activos de los usuarios al menos en una proporción de 1:1',
    tishi2: 'Las mejores tarifas de transacción',
    xiangqing2: 'Tarifas de transacción preferenciales, derechos VIP competitivos y disfrute de servicios de la mejor calidad.',
    tishi3: 'Seguridad en la que puedes confiar',
    xiangqing3: 'Nuestras sofisticadas medidas de seguridad y fondos SAFU protegen sus activos digitales de todos los riesgos.',
    tishi4: 'Atención al cliente 24*7 horas',
    xiangqing4: '24*7 horas, modo de operación a tiempo completo, responda sus consultas relevantes lo antes posible',
    tishi5: 'Estable y seguro',
    xiangqing5: 'El intercambio líder en el mundo brinda protección de seguridad a nivel bancario para sus activos',
    tishi6: 'Preguntas frecuentes',
    xiangqing6: 'Consulte las preguntas frecuentes para obtener descripciones detalladas de funciones específicas.',
    fanhui:"devolver",
    shishihangqing:'Cotizaciones en tiempo real',

    },
    footer: {
    bangzhuzhongxin: 'Centro de ayuda',
    dianhuazixun: 'Consulta telefónica',
    dianhuahegongzuoshijian: '1670-9756 (de 10:00 a 19:00 de lunes a viernes/cerrado los fines de semana y festivos)',
    liaotianzixun: 'Consulta por chat 1:1',
    keketuozixun: 'Cocotok Consulting',
    zaixianbangzhu: 'Ayuda en línea',
    h24kaifang: '(abierto las 24 horas)',
    lixianbangzhu: 'Ayuda sin conexión',
    gongsidizhi: '1F, Cumbre Yongsan Purgio, 69 Hangang-gil, Yongsan-gu, Seúl',
    gongzuoshijian: '(10:00~18:00 de lunes a viernes / cerrado los fines de semana y festivos)',
    shangshizixun: 'Consulta de listado',
    hezuozixun: 'Consulta de cooperación',
    gongsi: 'compañía',
    lianxiwomwn: 'Contáctenos',
    zhidao: 'guía',
    yonghuzhichi: 'Soporte al usuario',
    zhanghuzhinan: 'Guía de cuenta: depósito/retiro',
    ruanjianxiazai: 'Descarga de programas',
    zhengce: 'política',
    fuwuyuyinsitiaokuan: 'Términos de servicio y privacidad',
    },
    login: {
    yonghudenglu: 'Inicio de sesión de usuario',
    huanyingdenglu: 'Bienvenido a iniciar sesión',
    xiangmumingcheng: 'BCFX',
    guanfangwangzhan: 'sitio web oficial',
    youxiang: 'Correo',
    qingshuruyouxiang: 'Por favor ingrese su correo electrónico',
    mima: 'contraseña',
    qingshurumima: 'Por favor ingrese la contraseña',
    wangjimima: '¿Olvidaste la contraseña?',
    denglu: 'Acceso',
    haimeiyouzhanghao: '¿Aún no tienes una cuenta? ir',
    zhuce: 'registro',
    dengluchenggong: 'Iniciar sesión exitosamente',
    denglushibai: 'error de inicio de sesion',
    qingshuruyonghuminghemima: 'Por favor ingrese nombre de usuario y contraseña',
    mimachangdubunengxiaoyuliuwei: 'La longitud de la contraseña no puede ser inferior a 6 caracteres.',
    youxiangrenzheng: 'Verificación de correo electrónico',
    youxiangdizhi: 'Dirección de correo electrónico',
    qingshuruyouxiangdizhi: 'Por favor ingrese la dirección de correo electrónico',
    tuxingyanzhengma: 'Código de verificación gráfico',
    qingshurutuxingyanzhengma: 'Por favor ingresa el código de verificación gráfico',
    yanzhengma: 'Código de verificación',
    qingshuruyouxiangyanzhengma: 'Por favor ingrese el código de verificación del correo electrónico',
    huoquyouxiangyanzhengma: 'Obtener código de verificación por correo electrónico',
    queding: 'Seguro',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'No se pudo obtener el código de verificación gráfica, solicítelo nuevamente',
    qingxianwanchengyouxiangrenzheng: 'Primero complete la verificación por correo electrónico',
    huoquyouxiangyanzhengmashibai: 'No se pudo obtener el código de verificación por correo electrónico,',
    houchongxinfasong: 'Reenviar después de S',
    youxiangyanzhengchenggong: 'Verificación de correo electrónico exitosa',
    yanzhengmacuowu: 'Error del código de verificación',
    xiugaidenglumima: 'Cambiar contraseña de inicio de sesión',
    xinmima: 'Nueva contraseña',
    quedingxinmima: 'Confirmar nueva contraseña',
    qingshuruxinmima: 'Por favor ingrese una nueva contraseña',
    qingzaicishuruxinmima: 'Por favor ingrese nuevamente la nueva contraseña',
    liangcimimashurubuyizhi: 'Las dos entradas de contraseña son inconsistentes',
    mimaxiugaichenggong: 'La contraseña se cambió correctamente',
    xiugaimimashibai: 'No se pudo cambiar la contraseña',
    },
    trade: {
    hangqingheyue: 'contrato',
    hangqingzuixinjia: 'último precio',
    hangqingzhangdiefu: 'Aumentar o disminuir',
    hangqingzuigao24h: '24H más alto',
    hangqingzuidi24h: '24H mínimo',
    hangqingchengjiaoliang24h: '24h Volumen',
    hangqingchengjiabishu: 'Transacciones',
    zhanghuquanyi: 'Derechos de cuenta',
    zhanghukeyongshouyi: 'Beneficios disponibles',
    hangqingweituoliebiao: 'lista de pedidos',
    hangqingjiage: 'precio',
    hangqingshuliang: 'cantidad',
    hangqingzuixinchengjiao: 'última transacción',
    hangqingjiage1: 'precio',
    hangqingshuliang1: 'cantidad',
    hangqingshijian: 'tiempo',
    dingdanchiyoucangwei: 'mantener una posición',
    dingdandangqianweituo: 'Orden actual',
    dingdanchengjiaojilu: 'Registro de transacciones',
    dingdantiaojiandan: 'orden condicional',
    chicangheyue: 'contrato',
    chicangkaicangjunjia: 'Precio de posición',
    chicangfudongyingkui: 'Ganancias y pérdidas',
    chicanggangganbeishu: 'Aprovechar',
    chicangchicangliang: 'Posición(es)',
    chicangduokong: 'Largo/Corto',
    chicangduo: 'muchos',
    chicangkong: 'nulo',
    chicangzhiyingjiage: 'Precio de toma de ganancias',
    chicangzhisunjiage: 'Precio de parada de pérdidas',
    chicangqiangpingyugujiage: 'Precio de liquidación estimado',
    chicangcaozuo: 'funcionar',
    chicangzhiyingzhisun: 'Obtenga ganancias y detenga las pérdidas',
    chicangpingcang: 'Cierre',
    chicangshouxufei: 'tarifa de manejo',
    weituoheyue: 'contrato',
    weituoshuliang: 'Cantidad de pedido',
    weituojiage: 'Precio de comisión',
    weituofangxiang: 'dirección',
    weituogangganbeishu: 'Aprovechar múltiples',
    weituochengjiaoshuliang: 'Cantidad de transacción',
    weituoshouxufei: 'tarifa de manejo',
    weituochengjiaojunjia: 'Precio medio',
    weituoshouyi: 'ingreso',
    weituozhuangtai: 'estado',
    weituochengjiaoshijian: 'tiempo de transacción',
    weituocaozuo: 'funcionar',
    weituochedan: 'Cancelar pedido',
    weituotijiaoshibai: 'Envío fallido',
    weituozhilingtijiao: 'Envío de instrucciones',
    weituoyitijiao: 'Enviado',
    weituobufenchengjiao: 'Acuerdo parcial',
    weituobufenchengjiaoyichedan: 'Algunos pedidos han sido cancelados.',
    weituowanquanchengjiao: 'Trato completo',
    weituowanquanchedan: 'Cancelar pedido por completo',
    weituochedanzhong: 'Cancelar orden',
    weituokaiduo: 'Abierto largo',
    weituopingkong: 'cielo plano',
    weituokaikong: 'abierto corto',
    weituopingduo: 'Pinduo',
    chengjiaoheyue: 'contrato',
    chengjiaofangxiang: 'dirección',
    chengjiaoshuliang: 'Cantidad de transacción',
    chengjiaojiage: 'precio de transacción',
    chengjiaoshijian: 'tiempo de transacción',
    chengjiaopingcangyingkui: 'Ganancias y pérdidas',
    chengjiaoshouxufei: 'Tarifa de transacción',
    chengjiaokaiduo: 'Abierto largo',
    chengjiaopingkong: 'cielo plano',
    chengjiaokaikong: 'abierto corto',
    chengjiaopingduo: 'Pinduo',
    tiaojianzengjiatiaojian: 'Agregar condiciones',
    tiaojianzantingquanbu: 'pausar todo',
    tiaojianqidongquanbu: 'Empezar todo',
    tiaojianshanchuquanbu: 'eliminar todo',
    tiaojianheyue: 'contrato',
    tiaojianweituoshuliang: 'Cantidad de pedido',
    tiaojianfangxiang: 'dirección',
    tiaojiantiaojianleixing: 'tipo de condición',
    tiaojianjiageleixing: 'tipo de precio',
    tiaojianbijiaofuhao: 'Símbolo de comparación',
    tiaojiantiaojian: 'condición',
    tiaojianzhuangtai: 'estado',
    tiaojianchuangjianshijian: 'tiempo de creación',
    tiaojiancaozuo: 'funcionar',
    tiaojianxiugai: 'Revisar',
    tiaojianqidong: 'puesta en marcha',
    tiaojianzanting: 'pausa',
    tiaojianshanchu: 'borrar',
    tiaojianlijixiadan: 'Ordene ahora',
    tiaojiankaiduo: 'Abierto largo',
    tiaojianpingkong: 'cielo plano',
    tiaojiankaikong: 'abierto corto',
    tiaojianpingduo: 'Pinduo',
    tiaojianjiagetiaojian: 'términos de precio',
    tiaojianshijiantiaojian: 'condición de tiempo',
    tiaojianzuixinjia: 'último precio',
    tiaojianByijia: 'comprar un precio',
    tiaojianSyijia: 'Vender un precio',
    tiaojianzanting: 'pausa',
    tiaojianyunxing: 'correr',
    tiaojianyiwancheng: 'Terminado',
    tiaojianshibai: 'fallar',
    tiaojianyishanchu: 'Eliminado',
    tiaojianshoudongchufawancheng: 'Gatillo manual completo',
    tiaojianshoudongchufashibai: 'Fallo en el disparador manual',
    xiadankaicang: 'Abrir',
    xiadanpingcang: 'Cierre',
    xiadanweituoleixing: 'tipo',
    xiadanqingxuanze: 'Por favor seleccione',
    xiadanshijia: 'precio de mercado',
    xiadanxianjia: 'límite de precio',
    xiadanjiage: 'precio',
    xiadanqingshurujiage: 'Por favor ingrese el precio',
    xiadanshuliang: 'cantidad',
    xiadanqingshurushuliang: 'Por favor ingrese la cantidad',
    xiadanzhang: 'abierto',
    xiadankekaishuliang: 'Cantidad disponible',
    xiadanqingxianxuanzechicangdan: 'Seleccione primero el orden de posición',
    xiadankepingshuliang: 'Cantidad que se puede nivelar',
    xiadansuoxubaozhengjin: 'Margen requerido:',
    xiadanshijiazhiyingzhisunyushe: 'Precio de mercado para tomar ganancias y detener pérdidas preestablecidas',
    xiadanyujiqiangpingjiage: 'Precio de liquidación estimado:',
    xiadanqiangpingjiagetishi: 'Este precio de liquidación esperado se calcula dinámicamente en función de los activos totales. Este precio es sólo de referencia y no se utilizará como liquidación final.',
    xiadanzuoduo: 'ir largo',
    xiadanzuokong: 'corto',
    xiadanpingcang: 'Cierre',
    zhanghuheyuezichan: 'Activos del contrato',
    zhanghuchongbi: 'Completar',
    zhanghutibi: 'retirar monedas',
    zhanghuhuazhuan: 'transferir',
    zhanghuzhanghuquanyi: 'Derechos de cuenta',
    zhanghufudongyingkui: 'pérdidas y ganancias flotantes',
    zhanghucangweibaozhengjin: 'Margen de posición',
    zhanghudongjiebaozhengjin: 'Congelar margen',
    zhanghukeyongquanyi: 'Beneficios disponibles',
    zhanghubaozhengjinshiyonglv: 'Tasa de utilización del margen',
    hangqingheyuexinxi: 'Información del contrato',
    hangqingheyuemingcheng: 'Nombre del contrato',
    hangqingjijiahuobi: 'Moneda de cotización',
    hangqingheyuedaxiao: 'Tamaño del contrato',
    hangqingzuixiaojiagebodong: 'fluctuación mínima del precio',
    dialogleverRateganggantiaozheng: 'Ajuste de apalancamiento',
    dialogleverRatequeding: 'Seguro',
    dialogtransferzichanhuazhuan: 'Transferencia de activos',
    dialogtransferbizhong: 'Divisa',
    dialogtransfercong: 'de',
    dialogtransferdao: 'llegar',
    dialogtransfershuliang: 'cantidad',
    dialogtransferqingshuruhuazhuanshuliang: 'Por favor ingrese el monto de la transferencia',
    dialogtransferquanbu: 'todo',
    dialogtransferkeyong: 'Disponible',
    dialogtransferhuazhuan: 'transferir',
    dialogtransferjichuzhanghu: 'cuenta básica',
    dialogtransferheyuezhanghu: 'cuenta de contrato',
    dialogfitlosszhiyingzhisun: 'Obtenga ganancias y detenga las pérdidas',
    dialogfitlossheyu: 'contrato',
    dialogfitlossduokong: 'Largo/Corto',
    dialogfitlosschichangjunjia: 'precio medio de posición',
    dialogfitlossshuliang: 'cantidad',
    dialogfitlosszuixinjia: 'último precio',
    dialogfitlosszhiyingdianshu: 'Tomar puntos de ganancias',
    dialogfitlosszhisundianshu: 'Puntos de parada de pérdidas',
    dialogfitlossdianshu: 'puntaje',
    dialogfitlosszhiyingjiage: 'Precio de toma de ganancias',
    dialogfitlosszhisunjiage: 'Precio de parada de pérdidas',
    dialogfitlossjiage: 'precio',
    dialogfitlossyuqishouyi: 'retorno esperado',
    dialogfitlossyuqikuisun: 'pérdida esperada',
    dialogfitlosstishi1: 'Consejo: La fluctuación mínima del contrato actual es',
    dialogfitlosstishi2: ', establezca el precio en 0 para cancelar la toma de ganancias y detener la pérdida.',
    dialogfitlossqueding: 'Seguro',
    dialogfitlossduo: 'muchos',
    dialogfitlosskong: 'nulo',
    dialogconditionzengjiayuntiaojiandan: 'Agregar hoja de condiciones de la nube',
    dialogconditiontiaojianleixing: 'Tipo de condición:',
    dialogconditionjiagetiaojiandan: 'Hoja de términos de precios',
    dialogconditionshijiantiaojiandan: 'hoja de condiciones de tiempo',
    dialogconditionjiageleixing: 'Tipo de precio:',
    dialogconditionzuixinjia: 'último precio',
    dialogconditionByijia: 'comprar un precio',
    dialogconditionSyijia: 'Vender un precio',
    dialogconditiontiaojianshezhi: 'Configuración de condiciones:',
    dialogconditionjiageshezhi: 'Configuración de precios:',
    dialogconditionqingshurujiage: 'Por favor ingrese el precio',
    dialogconditionshijianshezhi: 'Configuración de hora:',
    dialogconditionjiaoyileixing: 'Tipo de transacción:',
    dialogconditionkaiduo: 'Abierto largo',
    dialogconditionpingkong: 'cielo plano',
    dialogconditionkaikong: 'abierto corto',
    dialogconditionpingduo: 'Pinduo',
    dialogconditiondingdanshuliang: 'Cantidad de pedido:',
    dialogconditionqingshurudingdanshuliang: 'Por favor ingrese la cantidad del pedido',
    dialogconditiondingdanshixiao: 'Hora del pedido:',
    dialogconditionyongjiuyouxia: 'Válido permanentemente',
    dialogconditiontijiao: 'entregar',
    dialogAutoProfitLosszhiyingzhisun: 'Obtenga ganancias y detenga las pérdidas',
    dialogAutoProfitLossmingcheng: 'nombre',
    dialogAutoProfitLosszhiyingdian: 'Punto de obtención de beneficios',
    dialogAutoProfitLosszhisundian: 'Punto de parada de pérdidas',
    dialogAutoProfitLosschongzhi: 'reiniciar',
    dialogAutoProfitLoss: 'entregar',
    dialogCloseOrderpingcang: 'Cierre',
    dialogCloseOrderweituoleixing: 'Tipo de delegado',
    dialogCloseOrderqingxuanze: 'Por favor seleccione',
    dialogCloseOrdershijia: 'precio de mercado',
    dialogCloseOrderxianjia: 'límite de precio',
    dialogCloseOrderjiage: 'precio',
    dialogCloseOrderqingshurujiage: 'Por favor ingrese el precio',
    dialogCloseOrdershuliang: 'cantidad',
    dialogCloseOrderqingshurushuliang: 'Por favor ingrese la cantidad',
    dialogCloseOrderzhang: 'abierto',
    dialogCloseOrderkepingshuliang: 'Cantidad que se puede nivelar',
    dialogCloseOrderqueding: 'Seguro',
    jsMessageResgaiheyuegangganxiugaichenggong: 'El apalancamiento del contrato se modificó con éxito.',
    jsMessageResgaiheuyegangganxiugaishibai: 'La modificación del apalancamiento del contrato falló.',
    jsMessageReskaicangchenggong: 'Posición abierta con éxito',
    jsMessageReskaicangshibai: 'La apertura de posición falló:',
    jsMessageReskaicang1: 'Está prohibido abrir una posición.',
    jsMessageReskaicang2: 'El contrato no existe',
    jsMessageReskaicang3: 'El número de posiciones abiertas excede el límite superior',
    jsMessageReskaicang4: 'El precio es 0',
    jsMessageReskaicang5: 'Margen insuficiente para abrir una posición',
    jsMessageRespingcangchenggong: 'Posición cerrada con éxito',
    jsMessageRespingcangshibai: 'No se pudo cerrar la posición:',
    jsMessageRespingcang1: 'El contrato no existe',
    jsMessageRespingcang2: 'El recibo de posición no existe.',
    jsMessageRespingcang3: 'Cantidad insuficiente',
    jsMessageReszijinhuazhuanchenggong: 'Transferencia de fondos exitosa',
    jsMessageReszijinhuazhuanshibai: 'La transferencia de fondos falló',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'Orden de posición detener ganancias y detener pérdidas establecida con éxito',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'Fallaron los ajustes de stop-profit y stop-loss para las órdenes de posición:',
    jsMessageResweituodanchedanchenggong: 'Pedido cancelado con éxito',
    jsMessageResweituodanchedanshibai: 'Error en la cancelación del pedido',
    jsMessageRestiaojiandantianjiachenggong: 'Orden condicional agregada exitosamente',
    jsMessageResheyuebucunzai: 'El contrato no existe',
    jsMessageResjiageshezhicuowu: 'Error al fijar el precio',
    jsMessageResshijianshezhicuowu: 'Error de configuración de hora',
    jsMessageResneibucuowu: 'error interno',
    jsMessageRestiaojiandantianjiashibai: 'No se pudo agregar el pedido condicional:',
    jsMessageReszhiyingzhisunshezhichenggong: 'Take Profit y Stop Loss establecidos con éxito',
    jsconfirmquedingchedanma: '¿Estás seguro de que deseas cancelar el pedido?',
    jsconfirmtishi: 'pista',
    jsconfirmqueding: 'Seguro',
    jsconfirmquxiao: 'Cancelar',
    jsMessageqingxiandenglu: '¡Por favor inicia sesión primero!',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'Este contrato tiene posiciones abiertas y el apalancamiento no se puede modificar.',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'Este contrato tiene un mandato y el apalancamiento no puede modificarse.',
    jsMessageqingshuruzhengquedeshuliang: '¡Ingrese la cantidad correcta!',
    jsMessageqingshuruzhengquedejiage: '¡Por favor ingrese el precio correcto!',
    jsMessageqingxianxuanzechicang: '¡Por favor seleccione una posición primero!',
    jsMessageqingshuruhuazhuanshuliang: '¡Por favor ingrese el monto de la transferencia!',
    jsMessageqingxianxuanzeheyue: '¡Seleccione primero un contrato!',
    jsMessageqingshurutiaojiandanshuliang: '¡Ingrese la cantidad de pedido condicional!',
    jsMessageqingshurutiaojiandandejiage: '¡Ingrese el precio del pedido condicional!',
    jsMessageqingshurutiaojiandanzhixingshijian: '¡Ingrese el tiempo de ejecución de la orden condicional!',
    emptytext: 'Aún no hay datos',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'Los pips de Take Profit y Stop Loss están configurados incorrectamente',
    jsMessageReschicangdanbucunzai: 'El recibo de posición no existe.',
    zxkcje:'Importe mínimo de apertura'
    },
    commisson:{
    dlzx:'Centro de agencia',
    sjzl:'Resumen de datos',
    quanbu:"todo",
    jinri:'hoy',
    zuori:'ayer',
    ri:'día',
    wdfy:'mi reembolso',
    wdsjfy:'Mi reembolso de tercer nivel',
    dengji:'calificación',
    yqrs:'Número de personas invitadas',
    tdrs:'Tamaño del equipo',
    yqxq:'Detalles de la invitación',
    yhm:"nombre de usuario",
    cjsj:'tiempo de creación',
    fyxq:'Detalles del reembolso',
    zyj:'comisión total',
    dryj:'Comisión de un solo día',
    fyje:'Monto del reembolso',
    
    },
    assets: {
    zongzichan: 'activos totales',
    chongbi: 'Completar',
    tibi: 'retirar monedas',
    huazhuan: 'transferir',
    fabizhanghu: 'cuenta básica',
    heyuezhanghu: 'cuenta de contrato',
    jiayiliushui: 'flujo de transacciones',
    dialogzichanhuazhuan: 'Transferencia de activos',
    cong: 'de',
    dao: 'llegar',
    shuliang: 'cantidad',
    qingshuruhuazhuanshuliang: 'Por favor ingrese el monto de la transferencia',
    quanbu: 'todo',
    keyong: 'Disponible',
    huanzhuan: 'transferir',
    zijinhuazhuanchenggong: 'Transferencia de fondos exitosa',
    zijinhuazhuanshibai: 'La transferencia de fondos falló',
    bibizhanghu: 'cuenta de monedas',
    qiquanzhanghu:'cuenta de opciones',
    zczl:'Resumen de activos',
    zzczh:'Descuento de activos totales',
    ztzc:"Activos en tránsito",
    djzc:'Congelar activos',
    zyzc:'Activos pignorados',
    cwzc:'Activos de posición',
    jyzh:'cuenta comercial',
    bizhong:'Divisa',
    dongjie:'congelar',
    lirun:"ganancia",
    hyzh:"cuenta de contrato",
    },
    basicsAccountRecord: {
    fanhui: 'devolver',
    fabizhanghuliushui: 'Flujo de la cuenta comercial',
    fabijiaoyiliushuileixing: 'Tipo de flujo de transacción',
    qingxuanze: 'Por favor seleccione',
    chaxunriqi: 'Fecha de consulta',
    liushuileixing: 'Tipo de tubería',
    shijian: 'tiempo',
    biandongjine: 'Cambiar cantidad',
    yue: 'Balance',
    quanbu: 'todo',
    Sellbi: 'vender monedas',
    Buybi: 'comprar monedas',
    tibi: 'retirar monedas',
    chongbi: 'Completar',
    fabizhuanheyue: 'Contrato de transferencia básico',
    heyuezhuanfabi: 'Contrato a base',
    zhuanzhang: 'transferir',
    fabihuazhuandaoheyue: 'Transferencia básica a contrato.',
    heyuehuazhuandaofabi: 'Transferencia de contrato a la base.',
    goumaijijin: 'Comprar un pool de minería',
    jijinshuhui: 'Redención del pool minero',
    jijinshouyi: 'Ingresos por rescate del pool minero',
    fabizhuanqihuo: 'Conversión básica a futuros',
    qihuozhuanfabi: 'Base de transferencia de futuros',
    fabizhuanganggu: 'Transferencia básica a acciones de Hong Kong',
    gangguzhuanfabi: 'Conceptos básicos de la transferencia de acciones de Hong Kong',
    fabizhuanbibi: 'Moneda de transferencia básica',
    bibizhuanfabi: 'Conceptos básicos de la transferencia de moneda a moneda',
    goumaiSGCB: 'Comprar ICO',
    chushouSGCB: 'Vender ICO',
    huoqushibai: 'No se pudo obtener',
    zanwushuju: 'Aún no hay datos',
    },
    contractAccountRecord: {
    fanhui: 'devolver',
    heyuezhanghuliushui: 'Flujo de cuenta de contrato',
    heyuejiaoyiliushuileixing: 'Tipo de flujo de transacción de contrato',
    qingxuanze: 'Por favor seleccione',
    chaxunriqi: 'Fecha de consulta',
    liushuileixing: 'Tipo de tubería',
    shijian: 'tiempo',
    biandongjine: 'Cambiar cantidad',
    yue: 'Balance',
    quanbu: 'todo',
    kaicangshouxufei: 'Tarifa de apertura',
    pingcangshouxufei: 'Tarifa de cierre',
    pingcangyingkui: 'Pérdidas y ganancias al cerrar la posición',
    fabizhuanheyue: 'Contrato de transferencia básico',
    heyuezhuanfabi: 'Contrato a base',
    zhuanzhang: 'transferir',
    kaicangyongjin: 'Comisión de apertura',
    pingcangyongjin: 'Comisión de cierre',
    huoqushibai: 'No se pudo obtener',
    zanwushuju: 'Aún no hay datos',
    },
    recharge: {
    zanwushuju: 'Aún no hay datos',
    shijian: 'tiempo',
    leixing: 'tipo',
    shuliang: 'cantidad',
    zhuangtai: 'estado',
    caozuo: 'funcionar',
    chakanxiangqing: 'comprobar los detalles',
    huoqushibai: 'No se pudo obtener',
    dialogtitle: 'Detalles',
    chongbi: 'Completar',
    fanhui: 'devolver',
    lianmingcheng: 'Nombre de la cadena',
    fuzhilianjie: 'Copiar dirección',
    tishixinxi1: '• No deposite ningún activo que no sea TRC_USDT o ERC_USDT en la dirección anterior; de lo contrario, los activos no se recuperarán',
    tishixinxi2: '• Después de recargar a la dirección anterior, se requiere la confirmación de todo el nodo de la red. La cuenta se recibirá después de 1 confirmación de red y la moneda se podrá retirar después de 2 confirmaciones de red.',
    tishixinxi3: '• Monto mínimo de depósito: 50 USDT, los depósitos inferiores al monto mínimo no se acreditarán y no se podrán reembolsar',
    tishixinxi4: '• Su dirección de recarga no cambiará con frecuencia y puede recargarse repetidamente si hay algún cambio, haremos todo lo posible para notificarle a través de anuncios en el sitio web o correos electrónicos;',
    tishixinxi5: '• Asegúrese de que su computadora y su navegador sean seguros para evitar que la información sea manipulada o filtrada.',
    putongchongbi: 'Depósito ordinario',
    jiaoyichenggong: 'Transacción exitosa',
    jiaoyishibai: 'La transacción falló',
    OMNIweihuzhong: 'La cadena OMNI está en mantenimiento, seleccione TRC20',
    },
    withdraw: {
    fanhui: 'devolver',
    zanwushuju: 'Aún no hay datos',
    tibi: 'retirar monedas',
    huoqushibai: 'No se pudo obtener',
    OMNIweihuzhong: 'La cadena OMNI está en mantenimiento, seleccione TRC20',
    shijian: 'tiempo',
    leixing: 'tipo',
    shuliang: 'cantidad',
    zhuangtai: 'estado',
    caozuo: 'funcionar',
    chakanxiangqing: 'comprobar los detalles',
    putongtibi: 'Retiro ordinario',
    dialogtitle: 'Detalles',
    dialogtitlewithdraw: 'retirar monedas',
    houchongxinfasong: 'Reenviar después de S',
    huoquyouxiangyanzhengma: 'Obtener código de verificación por correo electrónico',
    lianmingcheng: 'Nombre de la cadena',
    tibidizhi: 'dirección de retiro',
    qingshurutibidizhi: 'Por favor ingrese la dirección de retiro',
    tibishuliang: 'Cantidad de monedas retiradas',
    shouxufei: 'tarifa de manejo',
    qingshurutibishuliang: 'Por favor ingrese el monto del retiro',
    quanbu: 'todo',
    keyong: 'Disponible',
    daozhangshuliang: 'Cantidad de llegada',
    zuixiaotibishuliang: 'El monto mínimo de retiro es:',
    tishixinxi: 'Para garantizar la seguridad de los fondos, cuando la política de seguridad de su cuenta cambie o se modifique su contraseña, realizaremos una revisión manual del retiro. Tenga paciencia y espere a que el personal se comunique con usted por teléfono o correo electrónico. Asegúrese de que su computadora y su navegador sean seguros para evitar que los datos sean manipulados o filtrados.',
    tuxingyanzhengma: 'Código de verificación gráfico',
    qingshurutuxingyanzhengma: 'Por favor ingresa el código de verificación gráfico',
    youxiangyanzhengma: 'Código de verificación de correo electrónico',
    queding: 'Seguro',
    tijiaozhong: 'Sumisión',
    yijujue: 'Rechazado',
    yitongguo: 'Aprobado',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'No se pudo obtener el código de verificación gráfica, solicítelo nuevamente',
    huoquyouxiangyanzhengmashibai: 'No se pudo obtener el código de verificación por correo electrónico,',
    qingxianwanchengyouxiangrenzheng: 'Primero complete la verificación por correo electrónico',
    huoquyouxiangshibaiqingchongxinqingqiu: 'No se pudo obtener la dirección de correo electrónico, solicítela nuevamente',
    qingshuruyouxiangyanzhengma: 'Por favor ingrese el código de verificación del correo electrónico',
    youxiangyanzhengmacuowu: 'El código de verificación de correo electrónico es incorrecto.',
    qxsmrz:'Por favor verifica primero tu nombre real',
    tishi:'pista',
    queding:'Seguro'
    },
    contractTransactionRecord: {
    fanhui: 'devolver',
    zanwushuju: 'Aún no hay datos',
    heyuedingdan: 'orden de contrato',
    qingxuanze: 'Por favor seleccione',
    quanbu: 'todo',
    kaiduo: 'Abierto largo',
    pingkong: 'cielo plano',
    kaikong: 'abierto corto',
    pingduo: 'Pinduo',
    xianjiaweituo: 'orden límite',
    shijiaweituo: 'orden de mercado',
    suoyouzhuangtai: 'Todo el estado',
    tijiaoshibai: 'Envío fallido',
    zhunbeitijiao: 'listo para enviar',
    yitijiao: 'Enviado',
    bufentijiao: 'Acuerdo parcial',
    bufentijiaoyichedan: 'Algunos pedidos han sido cancelados.',
    quanbuchengjiao: 'Todas las transacciones',
    yichedan: 'Orden cancelada',
    huoqushibai: 'No se pudo obtener',
    heyuedaima: 'Código de contrato',
    ganggan: 'palanca',
    xiadanshijian: 'hora del pedido',
    chengjiaoshijian: 'tiempo de transacción',
    fangxiang: 'dirección',
    baojialeixing: 'Tipo de cotización',
    weituoliang: 'Monto de la comisión (piezas)',
    weituojia: 'Precio del pedido (USDT)',
    chengjiaoliang: 'Volumen de negociación (tickets)',
    chengjiaojunjia: 'Precio medio de transacción (USDT)',
    yingkui: 'Ganancias y pérdidas (USDT)',
    shouxufei: 'Tarifa de gestión (USDT)',
    zhuangtai: 'estado',
    gupiaodingdan: 'orden de existencias',
    waihuidingdan: 'Órdenes de divisas',
    gupiaodaima: 'Código de acciones',
    waihuidaima: 'código de divisas',
    },
    kLine: {
    zhibiao: 'índice',
    shezhi: 'configuración',
    quanping: 'pantalla completa',
    tuichuquanping: 'Salir de pantalla completa',
    jishuzhibiao: 'Indicadores técnicos',
    futuzhibiao: 'Indicador de subimagen',
    zhutuzhibiao: 'Indicador del gráfico principal',
    huifumoren: 'Restaurar valor predeterminado',
    lazhutuleixing: 'Tipos de velas',
    qingxuanze: 'Por favor seleccione',
    quanshixin: 'Todo solido',
    quankongxin: 'Totalmente hueco',
    zhangkongxin: 'hueco ascendente',
    diekongxin: 'caer hueco',
    mianjitu: 'gráfico de área',
    jiagezhouleixing: 'Tipo de eje de precios',
    xianxingzhou: 'eje de línea',
    baifenbizhou: 'Eje porcentual',
    duishuzhou: 'Eje logarítmico',
    zuigaojiaxianshi: 'Visualización del precio más alto',
    zuidijiaxianshi: 'Visualización del precio más bajo',
    zuixinjiaxianshi: 'Visualización del último precio',
    zhibiaozuixinzhixianshi: 'Se muestra el último valor del indicador.',
    daozhizuobiao: "coordenadas invertidas",
    wanggexianxianshi: 'Visualización de líneas de cuadrícula',
    hfcg:'Recuperación exitosa'
    },
    SecurityCenter: {
    fanhui: 'devolver',
    anquanzhongxin: 'Centro de seguridad',
    qingshurunicheng: 'Por favor ingresa un apodo',
    nichengxiugaishibai: 'Falló la modificación del apodo',
    huoquyonghuxinxishibai: 'No se pudo obtener información del usuario',
    xiugaidenglumima: 'Cambiar contraseña de inicio de sesión',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'No se pudo obtener el código de verificación gráfica, solicítelo nuevamente',
    qingshurujiumima: 'Por favor ingrese la contraseña anterior',
    qingshuruxinmima: 'Por favor ingrese una nueva contraseña',
    qingzaicishuruxinmima: 'Por favor ingrese nuevamente la nueva contraseña',
    qingshurutuxingyanzhengma: 'Por favor ingresa el código de verificación gráfico',
    qingshuruyanzhengma: 'Por favor ingresa el código de verificación',
    huoquzhuceshoujihaoshibai: 'No se pudo obtener el número de móvil registrado',
    qingxianwanchengshoujirenzheng: 'Primero complete la autenticación del teléfono móvil',
    huoqushoujiyanzhengmashibai: 'No se pudo obtener el código de verificación del teléfono móvil',
    huoqushoujiyanzhengma: 'Obtener código de verificación de teléfono móvil',
    houchongxinfasong: 'Reenviar después de S',
    mimacuowu: 'Contraseña incorrecta',
    liangcixinmimashurubuyizhiqingjiancha: 'Las dos nuevas contraseñas ingresadas son inconsistentes, verifique',
    xinmimaheyuanmimabunengyiyang: 'La nueva contraseña no puede ser la misma que la contraseña original.',
    qingshurushoujiyanzhengma: 'Por favor ingresa el código de verificación del teléfono móvil',
    yanzhengmacuowu: 'Error del código de verificación',
    denglumimaxiugaichenggong: 'La contraseña de inicio de sesión se cambió correctamente',
    denglumimaxiugaishibai: 'Error al modificar la contraseña de inicio de sesión',
    xiugaizijinmima: 'Cambiar contraseña de fondo',
    qingshuruzijinmima: 'Por favor ingrese la contraseña del fondo',
    qingzaicishuruzijinmima: 'Por favor ingrese la contraseña del fondo nuevamente',
    liangcizijinmimabuyizhiqingjiancha: 'Las dos contraseñas de fondos son inconsistentes, verifique',
    shezhizijinmimachenggong: 'Establecer contraseña de fondo exitosamente',
    shezhizijinmimashibai: 'No se pudo establecer la contraseña del fondo',
    xiugaizijinmimachenggong: 'La contraseña del fondo se modificó con éxito',
    xiugaizijinmimashibai: 'No se pudo cambiar la contraseña del fondo',
    youxiangrenzheng: 'Autenticación de correo electrónico',
    huoquyouxiangyanzhengma: 'Obtener código de verificación por correo electrónico',
    qingshuruyouxiangdizhi: 'Por favor ingrese la dirección de correo electrónico',
    huoquyouxiangyanzhengmashibai: 'No se pudo obtener el código de verificación por correo electrónico',
    qingshuruyouxiangyanzhengma: 'Por favor ingrese el código de verificación del correo electrónico',
    youxiangyanzhengchenggong: 'Verificación de correo electrónico exitosa',
    weirenzheng: 'No certificado',
    queding: 'Seguro',
    anquanshezhiguanli: 'Gestión de configuración de seguridad',
    denglumima: 'Contraseña de inicio de sesión',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'Al establecer una contraseña de inicio de sesión, podrá iniciar sesión directamente utilizando la contraseña de su cuenta.',
    xiugai: 'Revisar',
    zijinmima: 'Contraseña del fondo',
    yongyubaohuzijinanquan: 'Se utiliza para proteger la seguridad de los fondos.',
    shezhi: 'configuración',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'Se utiliza para la verificación de seguridad al iniciar sesión, retirar monedas, recuperar contraseñas y modificar la configuración de seguridad.',
    yirenzheng: 'Certificado',
    renzheng: 'Proceso de dar un título',
    shimingrenzheng: 'Autenticación de nombre real',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'Se utiliza para la autenticación de seguridad al comprar, vender, retirar, depositar y transferir monedas.',
    daishenhe: 'Pendiente de revisión',
    yibohui: 'Despedido',
    yuandenglumima: 'Contraseña de inicio de sesión original',
    xinmima: 'Nueva contraseña',
    quedingxinmima: 'Confirmar nueva contraseña',
    tuxingyanzhengma: 'Código de verificación gráfico',
    yanzhengma: 'Código de verificación',
    zijinmima: 'Contraseña del fondo',
    querenzijinmima: 'Confirmar contraseña del fondo',
    youxiangdizhi: 'Dirección de correo electrónico',
    qingxianwanchengyouxiangrenzheng: 'Primero complete la verificación por correo electrónico',
    xiugaitouxiangchenggong: 'Avatar modificado con éxito',
    xiugaitouxiangshibai: 'No se pudo modificar el avatar',
    shangchuantouxiangzhinengshijpggeshi: '¡Subir imágenes de avatar solo puede estar en formato JPG!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: '¡El tamaño de la imagen de avatar cargada no puede exceder los 2 MB!',
    },
    verification: {
    fanhui: 'devolver',
    shimingrenzheng: 'Autenticación de nombre real',
    qingshuruxingming: 'Por favor ingrese el nombre',
    qingshuruzhengjianhaoma: 'Por favor ingrese su número de identificación',
    huoquyonghuxinxishibai: 'No se pudo obtener información del usuario',
    wenjiangeshicuowu: 'Error de formato de archivo',
    wenjianbunengdayu5M: 'El archivo no puede tener más de 5M',
    qingtianxiexingming: 'Por favor escribe tu nombre',
    qingtianxiezhengjianhaoma: 'Por favor complete el número de identificación',
    qingshangchuanzhengjianzhengmian: 'Por favor cargue el anverso de su identificación',
    qingshangchuanzhengjianfanmian: 'Por favor cargue el reverso de su identificación',
    tijiaochenggong: 'Envío exitoso',
    kaiqishenfenrenzheng: 'Activar la autenticación de identidad',
    bohuiyuanyin: 'Motivo del rechazo',
    qingtianxiejiashizhizhaogongminkahuzhao: 'Por favor complete la información de su permiso de conducir/tarjeta de ciudadanía/pasaporte',
    xingming: 'Nombre',
    zhengjianhaoma: 'número de identificación',
    qingshangchuanjiashizhizhaogongminkahuzhao: 'Por favor cargue la información de la licencia de conducir/tarjeta de ciudadanía/pasaporte',
    shangchuanzhengjianzhengmian: 'Sube el frente del DNI',
    huotuozhuafangru: 'O arrastrar y soltar',
    shangchuanzhengjianfanmian: 'Cargue el reverso del certificado',
    tijiao: 'entregar',
    },
    invite: {
    yaoqinghaoyoudejiangli: 'Invita a amigos y obtén recompensas',
    yongjiukede: 'permanentemente disponible',
    jiaoyifanyong: 'Reembolso comercial',
    fasongyaoqinggeihaoyou: 'Enviar invitación a amigos',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'Invita a tus amigos a registrarse en BCFX compartiendo el enlace',
    haoyouzhuce: 'Registro de amigo',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'El amigo acepta la invitación, completa el registro y realiza transacciones.',
    huodexiangyingbilijiangli: 'Reciba las recompensas proporcionales correspondientes',
    qingsonghuodejiaoyishouxufeijianglifuli: 'Obtenga fácilmente beneficios adicionales de tarifas de transacción',
    wodezhuanshufenxiangfangshi: 'Mi forma exclusiva de compartir.',
    yaoqinglianjie: 'Enlace de invitación',
    jiazaizhong: 'cargando...',
    fuzhilianjie: 'Copiar enlace',
    yaoqingma: 'Código de invitación',
    fuzhiyaoqingma: 'Copiar código de invitación',
    wodeyaoqing: 'mi invitación',
    yaoqingrenshu: 'Número de personas invitadas',
    huodejiangli: 'Obtener recompensa',
    huodongxize: 'Detalles del evento',
    tips1: 'La relación entre los usuarios de la plataforma es de tres niveles, tales como: los usuarios ABCD constituyen tres niveles de usuarios y A es el nivel más alto.',
    tips2: 'Relaciones de interés entre niveles.',
    tips201: '。',
    tips3: 'Recompensa en efectivo: el cliente A puede recibir el 40 % de la tarifa de transacción del contrato del cliente B + el 20 % de la tarifa de transacción del contrato del cliente C + el 10 % de la tarifa de transacción del contrato del cliente D',
    tips4: 'Por ejemplo: el cliente A tiene 10 clientes B de primer nivel, 10 clientes C de segundo nivel y 10 clientes D de tercer nivel. Si cada contrato de cliente negocia 100 000 USDT',
    tips5: 'Entonces la devolución que el cliente A puede obtener',
    tips6: '100000*1.2%*40%*10+100000*1.2%*20%*10+100000*1.2%*10%*10=8400 USDT de recompensa en efectivo, que se puede retirar en tiempo real',
    tips7: 'Si la actividad se ajusta, estará sujeta a actualizaciones de la plataforma BCFX, y el derecho final de interpretación pertenece a BCFX.',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'No se pudo obtener el código de verificación gráfica, solicítelo nuevamente',
    qingshuruyouxiang: 'Por favor ingrese su correo electrónico',
    qingshurutuxingyanzhengma: 'Por favor ingresa el código de verificación gráfico',
    qingshuruyanzhengma: 'Por favor ingresa el código de verificación',
    qingshurunindemima: 'Por favor ingrese su contraseña',
    qingzaicishurunindemima: 'Por favor ingrese su contraseña nuevamente',
    qingshuruzhifumima: 'Por favor ingrese la contraseña de pago',
    xuantian: 'Opcional',
    bitian: 'Requerido',
    yonghuzhuce: 'Registro de usuario',
    huanyingdenglu: 'Bienvenido a iniciar sesión',
    guanfangwangzhan: 'sitio web oficial',
    xiangmumingcheng: 'BCFX',
    youxiang: 'Correo',
    tuxingyanzhengma: 'Código de verificación gráfico',
    yanzhengma: 'Código de verificación',
    huoquyanzhengma: 'Código',
    shezhimima: 'Establecer contraseña',
    quedingmima: 'Confirmar Contraseña',
    zhifumima: 'Contraseña de pago',
    yaoqingma: 'Código de invitación',
    zhuce: 'registro',
    yiyouzhanghao: '¿Ya tienes una cuenta?',
    denglu: 'Acceso',
    qingshuruyouxiangdizhi: 'Por favor ingrese la dirección de correo electrónico',
    huoqushibai: 'No se pudo obtener',
    houchongxinfasong: 'Reenviar después de S',
    liangcishurudemimabuyizhi: 'Las contraseñas ingresadas dos veces son inconsistentes',
    zhucechenggong: 'Registro exitoso',
    zhuceshibai: 'Registro fallido',
    },
    treasure:{
    jichubizhong: 'moneda base',
    shouyibizhong: 'Moneda de ingresos',
    wakuangtianshu: 'Jornadas mineras',
    shouyitianshu: 'Días de ganancia',
    suodingtianshu: 'Días de bloqueo',
    yichanshengshouyi: 'Ingresos generados',
    yuqishouyi: 'retorno esperado',
    kaishishijian: 'hora de inicio',
    jieshushijian: 'tiempo final',
    qingshuruninyaosuodingdebizhongshuliang: 'Ingrese la cantidad de USDT en la moneda que desea bloquear',
    huoqushibai: 'No se pudo obtener',
    meiyitian: 'cada 1 día',
    dakai: 'Abierto',
    yuanyuzhoukuangchi: 'Grupo de minería metaverso',
    jilu: 'Registro',
    kuangchixiangqing: 'Detalles del pool minero',
    suo: 'Cerrar',
    yitianchanchu: 'Salida de 1 día',
    zuixiaosuodingshijian: 'Tiempo mínimo de bloqueo',
    nindeUSDT: 'Tu USDT',
    tian: 'cielo',
    quanbu: 'todo',
    suoUSDTbingkaishishengchan: 'Bloquear USDT y comenzar la producción',
    ninquedingyaosuoUSDTkaishishengchanma: '¿Está seguro de que desea bloquear el USDT y comenzar la producción?',
    tishi: 'pista',
    queding: 'Seguro',
    quxiao: 'Cancelar',
    kaishishengchan: '¡Comienza la producción!',
    yiquxiao: 'Cancelado',
    },
    subscription:{
    rengouliebiao:'Lista de suscripciones',
    jijiangdaolai:'Muy pronto',
    jinxingzhong:'en curso',
    quanbu:'todo',
    keshiyonghuobi:"Monedas disponibles",
    zongshuliang:'cantidad total',
    meiyouxianzhi:'sin límite',
    shengyushuliang:'Cantidad restante',
    kaishishijian:'hora de inicio',
    zhuangtai:'estado',
    jilu:'Registro',
    zanwujilu:"Aún no hay registro",
    leixing:'tipo',
    shuliang:'cantidad',
    goumaijine:'Monto de la compra',
    shijian:'tiempo',
    goumai:'Comprar',
    duichu:'De la nada',
    jiangli:'otorgar',
    tibi:'retirar monedas',
    huidaomulu:'Volver a la tabla de contenidos',
    dangqianchiyou:'actualmente retenido',
    keyong:'Disponible',
    keduihuan:'Redimible',
    shishihangqing:'Cotizaciones en tiempo real',
    yue:'Balance',
    biaoti:'título',
    fashoushuliang:'Cantidad a la venta',
    jindu:"cronograma",
    goumaishuliang:'Cantidad de compra',
    ge:'individual',
    woyiyuedu:'he leído',
    daibigoumaixieyi:'Acuerdo de compra de tokens',
    qingshurugoumaijine:'Por favor ingresa el monto de la compra',
    shifoyaogoumai:'¿Quieres comprar',
    tishi:'pista',
    queding:'Seguro',
    quxiao:'Cancelar',
    yjs:'terminó',
    ywc:'Terminado',
    sgjg:'Precio de suscripción',
    jssj:"tiempo final",
    zqsl:"Cantidad ganadora",
    zqje:"Monto ganador",
    tian:"cielo",
    shi:'hora',
    fen:'punto',
    miao:'Segundo',
    shengou:'Suscripción',
    xmxq:'Detalles del proyecto',
    },
    }
    
    