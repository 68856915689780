<template>
    <div class="contract-trade-layout">
        <div class="pub-detail flex_row_around bg_black">
            <div class="pub-name flex_row_left" style="width: 230px;"> 
                <el-dropdown  @command="eldropdownCommand">
                    <span class="el-dropdown-link" style="color: #ffffff;" v-if = "activeObj && activeObj.code">
                        <i class="el-icon-caret-bottom" ></i>
                        {{activeObj.code | contractCode}} / USDT
                    </span>
                    <span class="el-dropdown-link" style="color: #ffffff" v-else>
                        <i class="el-icon-caret-bottom" ></i>
                        --
                    </span>
                    <el-dropdown-menu slot="dropdown" style="background-color: #161C20; border: none; width: 400px; margin-left: 20px;">
                        <el-dropdown-item disabled style="color: #ffffff">
                            <span class="option-span" style="width:100px;"> {{i18n('hangqingheyue')}} </span>
                            <span class="option-span-short" style="width:100px;"> {{i18n('hangqingzuixinjia')}} </span>
                            <span class="option-span-long"> {{i18n('hangqingzhangdiefu')}} </span>
                        </el-dropdown-item>

                        <el-dropdown-item v-for="(item, index) in hqlist" 
                            :key="index" 
                            :class = "dropdown_itemClass(item)"  
                            :disabled = "item.code === activeContract"
                            :command = "item.code">
                            <span class="option-span" style="color: #ffffff; width:100px;">{{item.code | contractCode}} / USDT</span>
                            <span class = "option-span-short" style="width:100px;" v-if = "item.price <= 0">
                                - - </span>
                            <span :class = "dropdown_priceClass(item)" style="width:100px;" v-else> 
                                {{item.price | filter_newPriceFormat(item.float_num)}} </span>
                            <span  :class = "dropdown_rateClass(item)" v-if = "item.price > 0"> 
                                <el-image style="width: 12px; height: 10px; margin-right: 10px;" 
                                :src = "require('@/assets/images/up-icon.png')" v-if = "dropdown_imgClass(item) === 'color_up'"></el-image>
                                <el-image style="width: 12px; height: 10px; margin-right: 10px;" 
                                :src = "require('@/assets/images/down-icon.png')" v-if = "dropdown_imgClass(item) === 'color_down'"></el-image>
                                {{item.price | filter_classUpOrDown(item.open,'rate')}} </span>
                            <span class="color_up option-span-long" v-else> 
                                +0.00% </span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="flex_row_left pub-price" style="width: 120px;">
                <span :class = "nowPrice1_class(activeObj)" v-if = "activeObj && activeObj.price && activeObj.price !== 0"> {{ activeObj.price | filter_newPriceFormat(activeObj.float_num)}} </span>
                <span class="actPrice" v-else> -- </span>
            </div>
            <div class="pub-price pub-updown" style="width: 120px;">
                <span :class = "nowPrice1_class(activeObj)" v-if = "activeObj && activeObj.price && activeObj.open && activeObj.price !== 0 && activeObj.open !== 0">
                    {{ activeObj.price | filter_classUpOrDown(activeObj.open,'rate')}}
                </span>
                <span class="actPrice" v-else>--</span>
            </div>

            <div class="flex_row_center manyprice">
                <div class="sub-part" style="margin-left:30px">
                    <div class="sub-title"> {{i18n('hangqingzuigao24h')}} </div>
                    <div class="sub-price" v-if = "activeObj && activeObj.high && activeObj.high !== 0"> {{ activeObj.high | filter_newPriceFormat(activeObj.float_num)}} </div>
                    <div class="sub-price" v-else>--</div>
                </div>
                <div class="sub-part" style="margin-left:30px">
                    <div class="sub-title"> {{i18n('hangqingzuidi24h')}} </div>
                    <div class="sub-price" v-if = "activeObj && activeObj.low && activeObj.low !== 0"> {{ activeObj.low | filter_newPriceFormat(activeObj.float_num)}} </div>
                    <div class="sub-price" v-else>--</div>
                </div>
                <div class="sub-part" style="margin-left:30px">
                    <div class="sub-title"> {{i18n('hangqingchengjiaoliang24h')}} </div>
                    <div class="sub-price" v-if = "activeObj && activeObj.dealnum && activeObj.dealnum !== 0"> {{ activeObj.dealnum }} </div>
                    <div class="sub-price" v-else>--</div>
                </div>
                <div class="sub-part" style="margin-left:30px">
                    <div class="sub-title"> {{i18n('hangqingchengjiabishu')}} </div>
                    <div class="sub-price" v-if = "activeObj && activeObj.Totalnum && activeObj.Totalnum !== 0"> {{ activeObj.Totalnum }} </div>
                    <div class="sub-price" v-else>--</div>
                </div>
            </div>
            <div class="flex_row_center mysubnum">
                <div class="sub-part">
                    <div class="sub-title"> {{i18n('zhanghuquanyi')}} </div>
                    <div class="sub-price zhqy"> {{ (this.userWallet.marginBalance + this.userWallet.calcprofit) | filter_moneyFormat(8) }}</div>
                </div>
                <div class="sub-part">
                    <div class="sub-title"> {{i18n('zhanghukeyongshouyi')}} </div>
                    <div class="sub-price kyqy"> {{ (this.userWallet.marginAvailable + this.userWallet.calcprofit) | filter_moneyFormat(8) }} </div>
                </div>
            </div>
        </div>

        <!-- k线图 展示等 -->
        <div class="flex_row_left" style="align-items: flex-start;padding-bottom: 10px;">
            <div class="left-part flex1">
                <div class="flex_row_left" style="align-items: flex-start;">
                    <!-- k线图 -->
                    <div class="flex1 bg_black kline">
                        <klineChat :parentMessage="activeObj"></klineChat>
                    </div>
                    <div class="delegationlist bg_black">
                        <div class="delist-title"> {{i18n('hangqingweituoliebiao')}} </div>
                        <div class="flex_row_between delist-price">
                            <div class=""> {{i18n('hangqingjiage')}}(USDT) </div>
                            <div class=""> {{i18n('hangqingshuliang')}} </div>
                        </div>
                        <div class="delegationshow">
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.saleprice4 || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.salevol4 || '--' }} </div>
                                <div class="progress" :style="{ width: progressWidth4 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.saleprice3 || '--' | filter_newPriceFormat(activeObj.float_num)}}</div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.salevol3 || '--' }} </div>
                                <div class="progress" :style="{ width: progressWidth3 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.saleprice2 || '--' | filter_newPriceFormat(activeObj.float_num)}}</div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.salevol2 || '--' }} </div>
                                <div class="progress" :style="{ width: progressWidth2 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.saleprice1 || '--' | filter_newPriceFormat(activeObj.float_num)}}</div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.salevol1 || '--' }} </div>
                                <div class="progress" :style="{ width: progressWidth1 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.saleprice0 || '--' | filter_newPriceFormat(activeObj.float_num)}}</div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.salevol0 || '--' }} </div>
                                <div class="progress" :style="{ width: progressWidth0 + '%' }"></div>
                            </div>
                            <div class="flex_row_between up-row">
                                <div :class = "nowPrice2_class(activeObj)"> {{ activeObj && activeObj.price || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <span :class = "nowRate2_class(activeObj)" v-if = "activeObj && activeObj.price && activeObj.open && activeObj.price !== 0 && activeObj.open !== 0">
                                    {{ activeObj.price | filter_classUpOrDown(activeObj.open,'rate') }}
                                </span>
                                <span class="actPrice" v-else>--</span>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyprice0 || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyvol0  || '--'}} </div>
                                <div class="progress-up" :style="{ width: progressupWidth0 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyprice1 || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyvol1 || '--' }} </div>
                                <div class="progress-up" :style="{ width: progressupWidth1 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyprice2 || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyvol2 || '--' }} </div>
                                <div class="progress-up" :style="{ width: progressupWidth2 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyprice3 || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyvol3 || '--' }} </div>
                                <div class="progress-up" :style="{ width: progressupWidth3 + '%' }"></div>
                            </div>
                            <div class="flex_row_between delist-show">
                                <div class="flex1 text_left"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyprice4 || '--' | filter_newPriceFormat(activeObj.float_num)}} </div>
                                <div class="flex1 text_right"> {{ activeObj && activeObj.fiveHqList && activeObj.fiveHqList.buyvol4 || '--' }} </div>
                                <div class="progress-up" :style="{ width: progressupWidth4 + '%' }"></div>
                            </div>
                        </div>

                        <div class="delist-title"> {{i18n('hangqingzuixinchengjiao')}} </div>
                        <div class="flex_row_between delist-price">
                            <div class="flex1"> {{i18n('hangqingjiage1')}}(USDT) </div>
                            <div class="flex1"> {{i18n('hangqingshuliang1')}} </div>
                            <div class="flex1"> {{i18n('hangqingshijian')}} </div>
                        </div>
                        <div class="latestdeal">
                            <div class="flex_row_center delist-show">
                                <div class="flex1 color_green">
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.price0 !== 0"> 
                                        {{ activeObj.latestdealList.price0 | filter_newPriceFormat(activeObj.float_num)}} </span>
                                    <span v-else> -- </span> 
                                </div>
                                <div class="flex1"> 
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.num0 !== 0"> 
                                        {{ activeObj.latestdealList.num0 }} 
                                    </span>
                                    <span v-else> -- </span>
                                </div>
                                <div class="flex1" > 
                                    <span class="time" v-if="activeObj && activeObj.latestdealList && activeObj.latestdealList.time0 !== 0">
                                        {{activeObj.latestdealList.time0 | timeFormat}}</span>
                                    <span class="time" v-else>--</span>
                                </div>
                            </div>
                            <div class="flex_row_center delist-show">
                                <div class="flex1 color_green">
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.price1 !== 0"> 
                                        {{ activeObj.latestdealList.price1 | filter_newPriceFormat(activeObj.float_num)}} </span>
                                    <span v-else> -- </span> 
                                </div>
                                <div class="flex1"> 
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.num1 !== 0"> 
                                        {{ activeObj.latestdealList.num1 }} 
                                    </span>
                                    <span v-else> -- </span>
                                </div>
                                <div class="flex1" > 
                                    <span class="time" v-if="activeObj && activeObj.latestdealList && activeObj.latestdealList.time1 !== 0">
                                        {{activeObj.latestdealList.time1 | timeFormat}}</span>
                                    <span class="time" v-else>--</span>
                                </div>
                            </div>
                            <div class="flex_row_center delist-show">
                                <div class="flex1 color_green">
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.price2 !== 0"> 
                                        {{ activeObj.latestdealList.price2 | filter_newPriceFormat(activeObj.float_num)}} </span>
                                    <span v-else> -- </span> 
                                </div>
                                <div class="flex1"> 
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.num2 !== 0"> 
                                        {{ activeObj.latestdealList.num2 }} 
                                    </span>
                                    <span v-else> -- </span>
                                </div>
                                <div class="flex1" > 
                                    <span class="time" v-if="activeObj && activeObj.latestdealList && activeObj.latestdealList.time2 !== 0">
                                        {{activeObj.latestdealList.time2 | timeFormat}}</span>
                                    <span class="time" v-else>--</span>
                                </div>
                            </div>
                            <div class="flex_row_center delist-show">
                                <div class="flex1 color_green">
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.price3 !== 0"> 
                                        {{ activeObj.latestdealList.price3 | filter_newPriceFormat(activeObj.float_num)}} </span>
                                    <span v-else> -- </span> 
                                </div>
                                <div class="flex1"> 
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.num3 !== 0"> 
                                        {{ activeObj.latestdealList.num3 }} 
                                    </span>
                                    <span v-else> -- </span>
                                </div>
                                <div class="flex1" > 
                                    <span class="time" v-if="activeObj && activeObj.latestdealList && activeObj.latestdealList.time3 !== 0">
                                        {{activeObj.latestdealList.time3 | timeFormat}}</span>
                                    <span class="time" v-else>--</span>
                                </div>
                            </div>
                            <div class="flex_row_center delist-show">
                                <div class="flex1 color_green">
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.price4 !== 0"> 
                                        {{ activeObj.latestdealList.price4 | filter_newPriceFormat(activeObj.float_num)}} </span>
                                    <span v-else> -- </span> 
                                </div>
                                <div class="flex1"> 
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.num4 !== 0"> 
                                        {{ activeObj.latestdealList.num4 }} 
                                    </span>
                                    <span v-else> -- </span>
                                </div>
                                <div class="flex1" > 
                                    <span class="time" v-if="activeObj && activeObj.latestdealList && activeObj.latestdealList.time4 !== 0">
                                        {{activeObj.latestdealList.time4 | timeFormat}}</span>
                                    <span class="time" v-else>--</span>
                                </div>
                            </div>
                            <div class="flex_row_center delist-show">
                                <div class="flex1 color_green">
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.price5 !== 0"> 
                                        {{ activeObj.latestdealList.price5 | filter_newPriceFormat(activeObj.float_num)}} </span>
                                    <span v-else> -- </span> 
                                </div>
                                <div class="flex1"> 
                                    <span v-if = "activeObj && activeObj.latestdealList && activeObj.latestdealList.num5 !== 0"> 
                                        {{ activeObj.latestdealList.num5 }} 
                                    </span>
                                    <span v-else> -- </span>
                                </div>
                                <div class="flex1" > 
                                    <span class="time" v-if="activeObj && activeObj.latestdealList && activeObj.latestdealList.time5 !== 0">
                                        {{activeObj.latestdealList.time5 | timeFormat}}</span>
                                    <span class="time" v-else>--</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- 左下部分 -->
                <div class="holdlist bg_black">
                    <div class="hold-title flex_row_between">
                        <el-tabs class="flex_row_left flex1" v-model="orderTabName"
                        :stretch="false"
                        @tab-click="orderTabClick"
                        style="color: white; height: 300px; width: 100%; caret-color: transparent; margin-left: 50px; margin-top: 13px;">
                            <el-tab-pane :label="i18n('dingdanchiyoucangwei')" name="first"></el-tab-pane>
                            <el-tab-pane :label="i18n('dingdandangqianweituo')" name="second"></el-tab-pane>
                            <el-tab-pane :label="i18n('dingdanchengjiaojilu')" name="third"></el-tab-pane>
                            <!-- <el-tab-pane :label="i18n('dingdantiaojiandan')" name="fourth"></el-tab-pane> -->
                        </el-tabs>
                    </div>
                    <!-- 持有仓位 -->
                    <div class="dataViewTable">
                        <el-table
                        v-show="orderTabName === 'first'"
                        :data="holdOrderList"
                        :empty-text="i18n('emptytext')"
                        @row-click="HoldorderListClick"
                        :row-style="HoldorderListStyle"
                        style="width: 100%; background-color: transparent; font-size: 12px;"
                        :header-cell-style="{background:'#161c20', 'border-color':'#20282E', padding: '0', height: '30px'}"
                        :cell-style="{padding: '0', height: '30px'}">   
                            <el-table-column
                                :label="i18n('chicangheyue')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Contractcode}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangkaicangjunjia')"
                                min-width="140">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Costopen | filter_moneyFormat(4)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangfudongyingkui')"
                                min-width="160">
                                <template slot-scope="scope">
                                    <span :class="scope.row.Profit > 0 ? 'color_green' : 'color_red'"> 
                                        {{scope.row.Profit | filter_moneyFormat(4)}} </span>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column
                                :label="i18n('chicanggangganbeishu')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span :class="scope.row.direction === 0 ? 'color_green' : 'color_red'"> 
                                        {{ scope.row.Leverrate }}x </span>
                                </template>
                            </el-table-column> -->
                            <el-table-column
                                :label="i18n('zhanghucangweibaozhengjin')"
                                min-width="150">
                                <template slot-scope="scope">
                                    <span :class="scope.row.direction === 0 ? 'color_green' : 'color_red'"> 
                                        {{ scope.row.positionMargin  | filter_moneyFormat(4)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangshouxufei')"
                                min-width="150">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{ scope.row.fee | filter_moneyFormat(4)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangchicangliang')"
                                min-width="130">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{ scope.row.volume  | filter_moneyFormat(6)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangduokong')"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{ scope.row.direction === 0 ? i18n('chicangduo') : i18n('chicangkong') }} </span>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column
                                :label="i18n('chicangzhiyingjiage')"
                                min-width="70">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.profitPrice | filter_moneyFormat(4)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangzhisunjiage')"
                                min-width="70">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.lossPrice | filter_moneyFormat(6)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chicangqiangpingyugujiage')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.qpPrice | filter_moneyFormat(4)}} </span>
                                </template>
                            </el-table-column> -->
                            <el-table-column
                                :label="i18n('chicangcaozuo')"
                                min-width="120">
                                <template slot-scope="scope">
                                    <!-- <el-button
                                    size="mini"
                                    type="primary"
                                    style="background-color: #161C20; border-color: #0059FF; color: #0059FF; height: 25px; width: 80px;"
                                    @click="holdprofitloss(scope.row)"> {{i18n('chicangzhiyingzhisun')}} </el-button> -->
                                    <el-button
                                    size="mini"
                                    type="primary"
                                    style="background-color: #FCD535;border:none;color:#333; height: 25px;"
                                    @click="holdclose(scope.row)"> {{i18n('chicangpingcang')}} </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 当前委托 -->
                        <el-table
                        v-show="orderTabName === 'second'"
                        :data="EntrustorderList"
                        :empty-text="i18n('emptytext')"
                        :row-style="{background:'#161c20', 'border-color':'#20282E'}"
                        style="width: 100%; background-color: transparent; font-size: 12px;"
                        :header-cell-style="{background:'#161c20', 'border-color':'#20282E', padding: '0', height:'30px'}"
                        :cell-style="{padding: '0', height: '30px'}">     
                            <el-table-column
                                :label="i18n('weituoheyue')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Contractcode}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('weituoshuliang')"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.volume | filter_moneyFormat(6)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('weituojiage')"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Price | filter_moneyFormat(6)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('weituofangxiang')"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span :class="scope.row.direction === scope.row.offset ? 'color_green' : 'color_red'"> 
                                        {{ scope.row.DirectionText }}</span>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column
                                :label="i18n('weituogangganbeishu')"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Levelrate}}x </span>
                                </template>
                            </el-table-column> -->
                            <el-table-column
                                :label="i18n('weituochengjiaoshuliang')"
                                min-width="140">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Tradevolume | filter_moneyFormat(6)}} </span>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column
                                :label="i18n('weituoshouxufei')"
                                min-width="80">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Fee | filter_moneyFormat(4)}} </span>
                                </template>
                            </el-table-column> -->
                            <el-table-column
                                :label="i18n('weituochengjiaojunjia')"
                                min-width="130">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Tradeavgprice | filter_moneyFormat(4)}} </span>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column
                                :label="i18n('weituoshouyi')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Profit | filter_moneyFormat(6)}} </span>
                                </template>
                            </el-table-column> -->
                            <el-table-column
                                :label="i18n('weituozhuangtai')"
                                min-width="120">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.StatusText }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('weituochengjiaoshijian')"
                                min-width="130">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Tradedate | timeFormat('ymdhms') }} </span>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column
                                :label="i18n('weituocaozuo')"
                                min-width="100">
                                <template slot-scope="scope">
                                    <el-button
                                    v-show="scope.row.Status === 2"
                                    size="mini"
                                    type="primary"
                                    style="background-color: #FCD535;border:none;color:#333; height: 25px; width: 80px;"
                                    @click="EntrustorderCancle(scope.row)"> {{i18n('weituochedan')}} </el-button>
                                </template>
                            </el-table-column> -->
                        </el-table>
                        <!-- 成交记录 -->
                        <el-table
                        v-show="orderTabName === 'third'"
                        :data="DealOrderList"
                        :empty-text="i18n('emptytext')"
                        :row-style="{background:'#161c20', 'border-color':'#20282E'}"
                        style="width: 100%; background-color: transparent; font-size: 12px;"
                        :header-cell-style="{background:'#161c20', 'border-color':'#20282E', padding: '0', height:'30px'}"
                        :cell-style="{padding: '0', height: '30px'}">     
                            <el-table-column
                                :label="i18n('chengjiaoheyue')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Contractcode}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chengjiaofangxiang')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span :class="scope.row.direction === scope.row.offset ? 'color_green' : 'color_red'"> 
                                        {{ scope.row.DirectionText }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chengjiaoshuliang')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Tradevolume | filter_moneyFormat(6)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chengjiaojiage')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Tradeprice | filter_moneyFormat(6)}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chengjiaoshijian')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Createdate | timeFormat('ymdhms') }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chengjiaopingcangyingkui')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;" v-show="scope.row.offset === 0"> 
                                        -- </span>
                                    <span style="color: white;" v-show="scope.row.offset !== 0"> 
                                        {{ scope.row.Offsetprofitloss | filter_moneyFormat(6) }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('chengjiaoshouxufei')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Tradefee | filter_moneyFormat(4)}} </span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 条件单 -->
                        <div class="flex_row_left" v-show="orderTabName === 'fourth'">
                            <div class="conditionOprate" @click="conditionOrderAddClick"> {{ i18n('tiaojianzengjiatiaojian') }} </div>
                            <div class="conditionOprate" @click="conditionOrderPauseAll"> {{ i18n('tiaojianzantingquanbu') }} </div>
                            <div class="conditionOprate" @click="conditionOrderRunAll"> {{ i18n('tiaojianqidongquanbu') }} </div>
                            <div class="conditionOprate" @click="conditionOrderDelAll"> {{ i18n('tiaojianshanchuquanbu') }} </div>
                        </div>
                        <el-table
                        v-show="orderTabName === 'fourth'"
                        :data="ConditionOrderList"
                        :empty-text="i18n('emptytext')"
                        :row-style="{background:'#161c20', 'border-color':'#20282E'}"
                        style="width: 100%; background-color: transparent; font-size: 12px;"
                        :header-cell-style="{background:'#161c20', 'border-color':'#20282E', padding: '0', height:'30px'}"
                        :cell-style="{padding: '0', height: '30px'}">    
                            <el-table-column
                                :label="i18n('tiaojianheyue')"
                                min-width="70">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Contractcode}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('tiaojianweituoshuliang')"
                                min-width="100">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.volume}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('tiaojianfangxiang')"
                                min-width="70">
                                <template slot-scope="scope">
                                    <span :class="scope.row.direction === scope.row.offset ? 'color_green' : 'color_red'"> 
                                        {{ scope.row.DirectionText }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('tiaojiantiaojianleixing')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.typetext}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('tiaojianjiageleixing')"
                                min-width="70">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.priceTypetext}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('tiaojianbijiaofuhao')"
                                min-width="60">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.compartext}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('tiaojiantiaojian')"
                                min-width="130">
                                <template slot-scope="scope">
                                    <span style="color: white;" v-if="scope.row.type === 0"> 
                                        {{ scope.row.Endprice }} </span>
                                    <span style="color: white;" v-else> 
                                        {{ scope.row.endpricetime | timeFormat('ymdhms')}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('tiaojianzhuangtai')"
                                min-width="90">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.statetext}} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('tiaojianchuangjianshijian')"
                                min-width="130">
                                <template slot-scope="scope">
                                    <span style="color: white;"> 
                                        {{scope.row.Createtime | timeFormat('ymdhms') }} </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="i18n('tiaojiancaozuo')"
                                width="200">
                                <template slot-scope="scope">
                                    <div>
                                        <el-button
                                        v-show="scope.row.Status === 0 || scope.row.Status === 1"
                                        size="mini"
                                        style="background-color: #161C20; border-color: #0059FF; color: #0059FF; height: 25px; width: 80px;"
                                        @click="ConditionOrderchange(scope.row)"> {{i18n('tiaojianxiugai')}} </el-button>
                                        <el-button
                                        v-show="scope.row.Status === 0"
                                        size="mini"
                                        style="background-color: #161C20; border-color: #0059FF; color: #0059FF; height: 25px; width: 80px;"
                                        @click="onditionOrderRun(scope.row)"> {{i18n('tiaojianqidong')}} </el-button>
                                        <el-button
                                        v-show="scope.row.Status === 1"
                                        size="mini"
                                        style="background-color: #161C20; border-color: #0059FF; color: #0059FF; height: 25px; width: 80px;"
                                        @click="onditionOrderpause(scope.row)"> {{i18n('tiaojianzanting')}} </el-button>
                                    </div>
                            
                                    <div style="margin-top: 2px;">
                                        <el-button
                                        v-show="scope.row.Status === 0 || scope.row.Status === 1"
                                        size="mini"
                                        style="background-color: #161C20; border-color: #0059FF; color: #0059FF; height: 25px; width: 80px;" 
                                        @click="ConditionOrderdelete(scope.row)"> {{i18n('tiaojianshanchu')}} </el-button>
                                        <el-button
                                        v-show="scope.row.Status === 0 || scope.row.Status === 1"
                                        size="mini"
                                        style="background-color: #161C20; border-color: #0059FF; color: #0059FF; height: 25px; width: 80px;"
                                        @click="ConditionOrderNow(scope.row)"> {{i18n('tiaojianlijixiadan')}} </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <!-- 右边部分 -->
            <div class="right-part">
                <div class="bg_black" style="height: 463px;">
                    <div class="right-title flex_row_between">
                        <el-tabs class="flex_row_left flex1" v-model="OpenOrClose"
                        :stretch="false"
                        @tab-click="OpenClosetabClick"
                        style="color: white; height: 300px; width: 100%; caret-color: transparent; margin-top: 12px; margin-left: 10px;">
                            <el-tab-pane :label="i18n('xiadankaicang')" name="Open"></el-tab-pane>
                            <el-tab-pane :label="i18n('xiadanpingcang')" name="Close"></el-tab-pane>
                        </el-tabs>
                    </div>
                    <!-- 开仓展示 -->
                    <div class="showopen">
                        <div class="flex_row_left">
                            <div class="emptybtn" style="margin-left: 20px; margin-right: 20px;"> {{ OpenOrClose === 'Open' ? i18n('xiadankaicang') : i18n('xiadanpingcang')}} </div>
                            <el-button
                            size="medium"
                            type="primary"
                            v-show="OpenOrClose === 'Open'"
                            style="background-color: #161C20; border-color: #3A434A; color: #EEEEEE; 
                                    border-radius: 2px; width: 41%; height: 34px; font-size: 14px; font-weight: 400;"
                            @click="userleverRateDialogShow(activepfex, activeContract)"> 
                            {{ activepfex === '' ? '1' : activepfex }}x </el-button>
                        </div>
                        <div class="flex_row_between" style="height:60px;" >
                            <div style="width:30%; margin-left: 15px; font-size: 14px;"> {{i18n('xiadanweituoleixing')}} </div>
                            <el-select v-model="priceType" :placeholder="i18n('xiadanqingxuanze')"
                            size="mini"
                            @change="priceTypeChange" 
                            style="width: 45%; font-size: 14px; margin-right:15px;"
                            class="select_trade" popper-class="selectpopper_trade">
                                <el-option :label="i18n('xiadanshijia')" value="1"></el-option>
                                <el-option :label="i18n('xiadanxianjia')" value="0"></el-option>
                            </el-select>
                        </div> 
                        <div class="flex_row_left inputarea">
                            <div class="inputname" style="width: 48px;"> {{i18n('xiadanjiage')}} </div>
                            <el-input-number v-model="orderPrice" size="mini" 
                            :disabled="priceType === '1'? true:false"
                            :precision="activeprecision" :step="activestep" :min="activemin" :max="activemax" 
                            @blur="blurorderPrice"
                            style="margin-left: 10px; background-color: #1C2328; width: 170px;" 
                            :placeholder="priceType === '1'? i18n('xiadanshijia') : i18n('xiadanqingshurujiage')"></el-input-number>
                        </div>
                        <!-- <div class="flex_row_left inputarea">
                            <div class="inputname" style="width: 48px;"> {{i18n('xiadanshuliang')}} </div>
                            <el-input-number v-model="orderNum" size="mini" 
                            :precision="8" :step="0.00000001" :min="0" :max="OpenOrClose === 'Open'? canOpenNum : canCloseNum" 
                            style="margin-left: 10px; background-color: #1C2328; width: 170px;" 
                            :placeholder="i18n('xiadanqingshurushuliang')"></el-input-number>
                            <div style="margin-left: 10px;"> {{i18n('xiadanzhang')}} </div>
                        </div> -->
                        <!-- USDT输入框 -->
                        <div class="flex_row_left inputarea" v-show="OpenOrClose === 'Open'">
                            <div class="inputname" style="width: 48px;"> USDT </div>
                            <el-input-number v-model="USDTNum" size="mini" 
                            ref="USDNumber"
                            :precision="0" :step="100" :min="0" :max="userWallet.marginAvailable" 
                            style="margin-left: 10px; background-color: #1C2328; width: 170px;" 
                            :placeholder="i18n('xiadanqingshurushuliang')"
                            @input.native="USDTNumInput()"
                            @change="USDTNumChange"></el-input-number>
                        </div>
                        <!-- 滑杆控件 -->
                        <div class="inputarea_slider" v-show="OpenOrClose === 'Open'">
                            <el-slider
                                v-model="slidervalue"
                                :step="25"
                                :marks="marks"
                                :show-tooltip="false"
                                @change="sliderChange"
                                show-stops>
                            </el-slider>
                        </div>
                        <!-- <div class="equal" v-show="OpenOrClose === 'Open'"> {{i18n('xiadankekaishuliang') + ' '}} {{ canOpenNum | filter_moneyFormat(8) }} {{' ' + i18n('xiadanzhang')}} </div> -->
                        <div class="equal" v-show="OpenOrClose === 'Close' && !selectHoldorder"> {{ i18n('xiadanqingxianxuanzechicangdan') }} </div>
                        <div class="equal" v-show="OpenOrClose === 'Close' && selectHoldorder"> {{i18n('xiadankepingshuliang') + ' '}} {{ canCloseNum | filter_moneyFormat(8) }} USDT </div>
                        <!-- <div class="needBZJ showpc" v-show="OpenOrClose === 'Open'"> {{i18n('xiadansuoxubaozhengjin')}} {{ opendeposit | filter_moneyFormat(8) }} USDT</div> -->
                        <!-- <div class="setProloss" @click="setAutoProfitLoss"> {{ i18n('xiadanshijiazhiyingzhisunyushe') }} </div> -->
                        <!-- <div class="yjqpjg showpc" v-show="OpenOrClose === 'Open'"> {{ i18n('xiadanyujiqiangpingjiage') }}
                            <span class="tooltip pos_relative">
                                <i class="el-icon-question"></i>
                                <span class="tooltiptext tooltip-top"> {{ i18n('xiadanqiangpingjiagetishi') }} </span>
                            </span>
                        </div> -->
                        <!-- <div class="flex_row_between opnum showpc" v-show="OpenOrClose === 'Open'">
                            <div class="buyqpjg" v-if="buyliquidation !== 0 && activeObj"> 
                                {{buyliquidation | filter_newPriceFormat(activeObj.float_num) }} </div>
                            <div class="buyqpjg" v-else> -- </div>
                            <div class="sellqpjg" v-if="sellliquidation !== 0  && activeObj"> 
                                {{ sellliquidation | filter_newPriceFormat(activeObj.float_num) }} </div>
                            <div class="sellqpjg" v-else> -- </div>
                        </div> -->
                        <div style="margin-top: 20px;">
                            <div class="flex_row_between opbtn" v-show="OpenOrClose === 'Open'">
                                <el-button
                                class='zuoduo'
                                size="medium"
                                type="primary"
                                style="background-color: #1763B6; border-color: #1763B6; color: #EEEEEE; 
                                        border-radius: 2px; width: 45%; height: 34px; font-size: 14px; font-weight: 400;"
                                @click="openLong"> 
                                {{ i18n('xiadanzuoduo') }} </el-button>
                                <el-button
                                class='zuokong'
                                size="medium"
                                type="primary"
                                style="background-color: #E12343; border-color: #E12343; color: #EEEEEE; 
                                        border-radius: 2px; width: 45%; height: 34px; font-size: 14px; font-weight: 400;"
                                @click="openShort"> 
                                {{ i18n('xiadanzuokong') }} </el-button>
                            </div>
                            <div class="flex_row_center opbtn" v-show="OpenOrClose === 'Close'">
                                <el-button
                                class='zuokong'
                                size="medium"
                                type="primary"
                                style="background-color: #E12343; border-color: #E12343; color: #EEEEEE; 
                                        border-radius: 2px; width: 45%; height: 34px; font-size: 14px; font-weight: 400;"
                                @click="Closeposition"> 
                                {{ i18n('xiadanpingcang') }} </el-button>
                            </div>
                        </div>  
                    </div>
                </div>
                <!-- 合约资产 -->
                <div class="assets bg_black">
                    <div class="assets-title"> {{ i18n('zhanghuheyuezichan') }} </div>
                    <div class="flex_row_around">
                        <div class="opbsbtn" @click="rechargeClick"> {{ i18n('zhanghuchongbi') }} </div>
                        <div class="opbsbtn" @click="withdrawClick"> {{ i18n('zhanghutibi') }} </div>
                        <div class="opbsbtn" @click="transferDialogShow()"> {{ i18n('zhanghuhuazhuan') }} </div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('zhanghuzhanghuquanyi') }} </div>
                        <div class="assets-num"> {{ (this.userWallet.marginBalance + this.userWallet.calcprofitWh+this.userWallet.calcprofit+this.userWallet.calcprofitMg) | filter_moneyFormat(8) }} </div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('zhanghufudongyingkui') }} </div>
                        <div class="assets-num">{{ this.userWallet.calcprofit | filter_moneyFormat(8) }}</div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('zhanghucangweibaozhengjin') }} </div>
                        <div class="assets-num">{{ this.userWallet.marginPosition | filter_moneyFormat(8) }}</div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('zhanghudongjiebaozhengjin') }} </div>
                        <div class="assets-num">{{ this.userWallet.marginFrozen | filter_moneyFormat(8) }}</div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('zhanghukeyongquanyi') }} </div>
                        <div class="assets-num"> {{ (this.userWallet.marginAvailable) | filter_moneyFormat(8) }} </div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('zhanghubaozhengjinshiyonglv') }} </div>
                        <div class="assets-num">{{ this.userWallet.riskRate | filter_moneyFormat(8) }}</div>
                    </div>
                </div>
                <!-- 合约信息 -->
                <div class="assets bg_black">
                    <div class="assets-title"> {{ i18n('hangqingheyuexinxi') }} </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('hangqingheyuemingcheng') }} </div>
                        <div class="assets-num assets-infoname">
                            <span v-if = "activeObj && activeObj.code">
                                {{ activeObj.code }}
                            </span>
                            <span v-else> -- </span>
                        </div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('hangqingjijiahuobi') }} </div>
                        <div class="assets-num assets-infomoney">
                            <span v-if = "activeObj && activeObj.code">
                                {{ activeObj.code.split("-")[1] }}
                            </span>
                            <span v-else> -- </span>
                        </div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('hangqingheyuedaxiao') }} </div>
                        <div class="assets-num assets-infosiaze">
                            <span v-if = "activeObj && activeObj.val">
                                {{ activeObj.val }}
                            </span>
                            <span v-else> -- </span>
                        </div>
                    </div>
                    <div class="flex_row_between padding1">
                        <div class="assets-name"> {{ i18n('hangqingzuixiaojiagebodong') }} </div>
                        <div class="assets-num assets-infomin">
                            <span v-if = "activeObj && activeObj.wave">
                                {{ activeObj.wave }}
                            </span>
                            <span v-else> -- </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 杠杆调整 -->
        <el-dialog :title="i18n('dialogleverRateganggantiaozheng')" :visible.sync="dialogleverRate" width="30%">
            <div class="flex_row_left" style="margin-left: 50px;">
                <el-button 
                :style="{backgroundColor: (buttonleverRate === 1 ? '#0059FF' : '#1C2328'), borderColor: '#0059FF', color: (buttonleverRate === 1 ? '#eee' : '#0059FF'), width: '65px', fontSize: '16px', fontWeight: '400'}"
                @click="buttonleverRateClick(1)">1</el-button>
                <!-- <el-button 
                :style="{backgroundColor: (buttonleverRate === 50 ? '#0059FF' : '#1C2328'), borderColor: '#0059FF', color: (buttonleverRate === 50 ? '#eee' : '#0059FF'), width: '65px', fontSize: '16px', fontWeight: '400'}"
                @click="buttonleverRateClick(50)">50</el-button>
                <el-button 
                :style="{backgroundColor: (buttonleverRate === 75 ? '#0059FF' : '#1C2328'), borderColor: '#0059FF', color: (buttonleverRate === 75 ? '#eee' : '#0059FF'), width: '65px', fontSize: '16px', fontWeight: '400'}"
                @click="buttonleverRateClick(75)">75</el-button>
                <el-button 
                :style="{backgroundColor: (buttonleverRate === 100 ? '#0059FF' : '#1C2328'), borderColor: '#0059FF', color: (buttonleverRate === 100 ? '#eee' : '#0059FF'), width: '65px', fontSize: '16px', fontWeight: '400'}"
                @click="buttonleverRateClick(100)">100</el-button>
                <el-button 
                :style="{backgroundColor: (buttonleverRate === 150 ? '#0059FF' : '#1C2328'), borderColor: '#0059FF', color: (buttonleverRate === 150 ? '#eee' : '#0059FF'), width: '65px', fontSize: '16px', fontWeight: '400'}"
                @click="buttonleverRateClick(150)">150</el-button> -->
            </div>
            <!-- <div class="flex_row_left" style="margin-left: 50px; margin-top: 20px;">
                <el-button 
                :style="{backgroundColor: (buttonleverRate === 200 ? '#0059FF' : '#1C2328'), borderColor: '#0059FF', color: (buttonleverRate === 200 ? '#eee' : '#0059FF'), width: '65px', fontSize: '16px', fontWeight: '400'}"
                @click="buttonleverRateClick(200)">200</el-button>
                <el-button 
                :style="{backgroundColor: (buttonleverRate === 250 ? '#0059FF' : '#1C2328'), borderColor: '#0059FF', color: (buttonleverRate === 250 ? '#eee' : '#0059FF'), width: '65px', fontSize: '16px', fontWeight: '400'}"
                @click="buttonleverRateClick(250)">250</el-button>
                <el-button 
                :style="{backgroundColor: (buttonleverRate === 300 ? '#0059FF' : '#1C2328'), borderColor: '#0059FF', color: (buttonleverRate === 300 ? '#eee' : '#0059FF'), width: '65px', fontSize: '16px', fontWeight: '400'}"
                @click="buttonleverRateClick(300)">300</el-button>
                <el-button 
                :style="{backgroundColor: (buttonleverRate === 500 ? '#0059FF' : '#1C2328'), borderColor: '#0059FF', color: (buttonleverRate === 500 ? '#eee' : '#0059FF'), width: '65px', fontSize: '16px', fontWeight: '400'}"
                @click="buttonleverRateClick(500)">500</el-button>
            </div> -->

            <div slot="footer" class="dialog-footer flex_row_center">
                <div class="pfexbtn " @click="changeLeverRate(buttonleverRate, activeContract)"> {{i18n('dialogleverRatequeding')}} </div>
            </div>
        </el-dialog>
        <!-- 划转 -->
        <el-dialog :title="i18n('dialogtransferzichanhuazhuan')" :visible.sync="dialogtransfer" width="30%">
            <div class="color_aaa"> {{ i18n('dialogtransferbizhong') }} </div>
            <div class="currency flex_row_left">
                <div class="flex1 currencyvalue" style="color: #eee;">USDT</div>
            </div>
            <!-- 从到 -->
            <div class="flex_row_around color_aaa">
                <div class="flex1"> {{i18n('dialogtransfercong')}} </div>
                <div class="transfericon"></div>
                <div class="flex1"> {{i18n('dialogtransferdao')}} </div>
            </div>
            <div class="flex_row_around">
                <div class="from flex_row_left flex1">
                    <div class="flex1" style="color: #eee;"> {{ transferfromvalue }} </div>
                </div>
                <img class="transfericon" :src="require('@/assets/images/transfer.png')" alt="" @click="transfericonClick">
                <div class="to flex_row_left flex1">
                    <div class="flex1" style="color: #eee;"> {{ transfertovalue }} </div>
                </div>
            </div>
            <!-- 数量 -->
            <div class="color_aaa"> {{i18n('dialogtransfershuliang')}} </div>
            <div class="flex_row_left trsnum">
                <el-input-number v-model="transferNum" size="mini" 
                :precision="8" :step="1" :min="1" :max="transdirection === 0 ? this.userWallet.LegalCurrency : this.userWallet.marginAvailable"
                style="margin-left: 20px; background-color: #1C2328; width: 70%; margin-right: 20px;" 
                :placeholder="i18n('dialogtransferqingshuruhuazhuanshuliang')"></el-input-number>
                <div class="currency-show" style="color: #eee;">USDT</div>
                <div class=" tarnsall" style="margin-left: 10px;cursor: pointer;color:#FCD535" @click="transferAllclick"> {{i18n('dialogtransferquanbu')}} </div>
            </div>
            <div class="fontSize12 color_aaa tipss">{{i18n('dialogtransferkeyong')}} {{ (transdirection === 0 ? this.userWallet.LegalCurrency : this.userWallet.marginAvailable) | filter_moneyFormat(8) }} </div>
            <div class="flex_row_center">
                <div class="transferbtn" @click="transferbtnclick"> {{i18n('dialogtransferhuazhuan')}}</div>
            </div>
        </el-dialog>
        <!-- 单独设置止盈止损 -->
        <el-dialog :title="i18n('dialogfitlosszhiyingzhisun')" :visible.sync="dialogfitloss" width="35%">
            <div class="flex_row_center" >
                <div>
                    <div class="flex_row_left fitrow">
                        <div class="flex1 flex_row_left" style="background: #1C2328; margin-right: 20px;">
                            <div class="fitloss-lable" style="margin-left: 20px;"> {{ i18n('dialogfitlossheyu') }} </div>
                            <div class="fitloss-value" style="margin-left: 20px;"> {{ fitlossContractcode }} </div>
                        </div>
                        <div class="flex1 flex_row_left" style="background: #1C2328; ">
                            <div class="fitloss-lable" style="margin-left: 20px;"> {{i18n('dialogfitlossduokong')}} </div>
                            <div class="fitloss-value" style="margin-left: 20px;"> {{ fitlossdirection }} </div>
                        </div>
                    </div>
                    <div class="flex_row_left fitrow" >
                        <div class="flex1 flex_row_left" style="background: #1C2328; margin-right: 20px;">
                            <div class="fitloss-lable" style="margin-left: 20px;"> {{i18n('dialogfitlosschichangjunjia')}} </div>
                            <div class="fitloss-value" style="margin-left: 20px;"> {{ fitlosscostOpen }} </div>
                        </div>
                        <div class="flex1 flex_row_left" style="background: #1C2328; ">
                            <div class="fitloss-lable" style="margin-left: 20px;"> {{i18n('dialogfitlossshuliang')}} </div>
                            <div class="fitloss-value" style="margin-left: 20px;"> {{ fitlossvolume }} </div>
                        </div>
                    </div>
                    <div class="flex_row_left fitrow" style="background: #1C2328; width: 257px;">
                        <div class="flex1 flex_row_left">
                            <div class="fitloss-lable" style="margin-left: 20px;"> {{i18n('dialogfitlosszuixinjia')}} </div>
                            <div class="fitloss-value" style="margin-left: 20px;"> {{ fitlossnewprice }} </div>
                        </div>
                    </div>
                    <div class="flex_row_left fitrow" >
                        <div class="flex1 flex_row_left" style="background: #1C2328; margin-right: 20px;">
                            <div class="fitloss-lable" style="margin-left: 20px;"> {{i18n('dialogfitlosszhiyingdianshu')}} </div>
                            <el-input-number v-model="fitDotnum" size="mini" 
                            :precision="0" :step="1" :min="0" :max="100000"
                            style="margin-left: 20px; background-color: #1C2328; width: 100%; margin-right: 20px;" 
                            @change="fitDotnumChange"
                            :placeholder="i18n('dialogfitlossdianshu')"></el-input-number>
                        </div>
                        <div class="flex1 flex_row_left" style="background: #1C2328; ">
                            <div class="fitloss-lable" style="margin-left: 20px;"> {{i18n('dialogfitlosszhisundianshu')}} </div>
                            <el-input-number v-model="lossDotnum" size="mini" 
                            :precision="0" :step="1" :min="0" :max="100000"
                            style="margin-left: 20px; background-color: #1C2328; width: 100%; margin-right: 20px;" 
                            @change="lossDotnumChange"
                            :placeholder="i18n('dialogfitlossdianshu')"></el-input-number>
                        </div>
                    </div>
                    <div class="flex_row_left fitrow" >
                        <div class="flex1 flex_row_left" style="background: #1C2328; margin-right: 20px;">
                            <div class="fitloss-lable" style="margin-left: 20px;"> {{i18n('dialogfitlosszhiyingjiage')}} </div>
                            <el-input-number v-model="fitPrice" size="mini" 
                            :precision="fitlosswave" :step="fitlossPricetick" :min="0" :max="1000000"
                            :controls="false"
                            style="margin-left: 20px; background-color: #1C2328; width: 100%; margin-right: 20px;" 
                            @change="fitPriceChange"
                            :placeholder="i18n('dialogfitlossjiage')"></el-input-number>
                        </div>
                        <div class="flex1 flex_row_left" style="background: #1C2328;">
                            <div class="fitloss-lable" style="margin-left: 20px;"> {{i18n('dialogfitlosszhisunjiage')}} </div>
                            <el-input-number v-model="lossPrice" size="mini" 
                            :precision="fitlosswave" :step="fitlossPricetick" :min="0" :max="1000000"
                            :controls="false"
                            style="margin-left: 20px; background-color: #1C2328; width: 100%; margin-right: 20px;" 
                            @change="lossPriceChange"
                            :placeholder="i18n('dialogfitlossjiage')"></el-input-number>
                        </div>
                    </div>
                    <div class="flex_row_left fitrow fontSize16">
                        <div class="color_white cumpzy flex1" style="margin-left: 20px;"> {{i18n('dialogfitlossyuqishouyi')}}:<span> {{ fitassess === undefined ? '--' : fitassess }} </span></div>
                        <div class="color_white cumpzs flex1" style="margin-left: 20px;"> {{i18n('dialogfitlossyuqikuisun')}}:<span> {{ lossassess === undefined ? '--' : lossassess }} </span></div>
                    </div>

                    <div class="tip" style="margin-left: 20px;"> {{i18n('dialogfitlosstishi1')}} <span> {{ fitlossPricetick }} </span> {{i18n('dialogfitlosstishi2')}} </div>
                    <div class="flex_row_center">
                        <div class="fitlossbtn" @click="fitlossbtnclick"> {{i18n('dialogfitlossqueding')}} </div>
                    </div>
                    
                </div>
            </div>
        </el-dialog>
        
        <!-- 增加云条件单 -->
        <el-dialog :title="i18n('dialogconditionzengjiayuntiaojiandan')" :visible.sync="dialogconditionorder" width="40%"> 
            <div class="flex_row_center">
                <div style="width: 600px;">
                    <div class="flex1 flex_row_left" style="background: #1C2328; height: 42px; width: 100%;">
                        <div style="margin-left: 20px; width: 20%;"> {{i18n('dialogconditiontiaojianleixing')}} </div>
                        <el-radio-group v-model="conditiontype" style="margin-left: 20px;" size="medium">
                            <el-radio :label="0"> {{i18n('dialogconditionjiagetiaojiandan')}} </el-radio>
                            <el-radio :label="1"> {{i18n('dialogconditionshijiantiaojiandan')}} </el-radio>
                        </el-radio-group>
                    </div>
                    <div v-show="conditiontype === 0">
                        <div class="flex1 flex_row_left" style="background: #1C2328; height: 42px; width: 100%; margin-top: 10px;">
                            <div style="margin-left: 20px; width: 20%;"> {{i18n('dialogconditionjiageleixing')}} </div>
                            <el-radio-group v-model="conditionpriceType" style="margin-left: 20px;" size="medium">
                                <el-radio :label="0"> {{i18n('dialogconditionzuixinjia')}} </el-radio>
                                <el-radio :label="1"> {{i18n('dialogconditionByijia')}} </el-radio>
                                <el-radio :label="2"> {{i18n('dialogconditionSyijia')}} </el-radio>
                            </el-radio-group>
                        </div>
                        <div class="flex1 flex_row_left" style="background: #1C2328; height: 42px; width: 100%; margin-top: 10px;">
                            <div style="margin-left: 20px; width: 20%;"> {{i18n('dialogconditiontiaojianshezhi')}} </div>
                            <el-radio-group v-model="conditioncompare" style="margin-left: 20px;" size="medium">
                                <el-radio :label="0"> &#62;&#61; </el-radio>
                                <el-radio :label="1"> &#60;&#61; </el-radio>
                            </el-radio-group>
                        </div>
                        <div class="flex1 flex_row_left" style="background: #1C2328; height: 42px; width: 100%; margin-top: 10px;">
                            <div style="margin-left: 20px; width: 20%;"> {{i18n('dialogconditionjiageshezhi')}} </div>
                            <el-input-number v-model="conditionendPrice" size="mini" 
                                :precision="conditionprecision" :step="conditionstep" 
                                style="margin-left: 10px; background-color: #1C2328; width: 70%;" 
                                :placeholder="i18n('dialogconditionqingshurujiage')"></el-input-number>
                        </div>
                    </div>
                    <div v-show="conditiontype === 1">
                        <div class="flex1 flex_row_left" style="background: #1C2328; height: 42px; width: 100%; margin-top: 10px;">
                            <div style="margin-left: 20px; width: 20%;"> {{i18n('dialogconditionshijianshezhi')}} </div>
                            <el-date-picker
                                v-model="conditionendTime"
                                type="datetime"
                                style="margin-left: 10px; width: 70%;" 
                                class="datepickerSelect"
                                popper-class="datepickerPopper"
                                placeholder="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                        </div>
                    </div>
                    <div class="flex1 flex_row_left" style="background: #1C2328; height: 42px; width: 100%; margin-top: 10px;">
                        <div style="margin-left: 20px; width: 20%;"> {{i18n('dialogconditionjiaoyileixing')}} </div>
                        <el-radio-group v-model="conditiondirectionoffset" style="margin-left: 20px;" size="medium">
                            <div>
                                <el-radio :label="0"> {{i18n('dialogconditionkaiduo')}} </el-radio>
                                <el-radio :label="2"> {{i18n('dialogconditionkaikong')}} </el-radio>
                            </div>
                            <div>
                                <el-radio :label="3"> {{i18n('dialogconditionpingduo')}} </el-radio>
                                <el-radio :label="1"> {{i18n('dialogconditionpingkong')}} </el-radio>
                            </div>
                        </el-radio-group>
                    </div>

                    <div class="flex1 flex_row_left" style="background: #1C2328; height: 42px; width: 100%; margin-top: 10px;">
                        <div style="margin-left: 20px; width: 20%;"> {{i18n('dialogconditiondingdanshuliang')}} </div>
                        <el-input-number v-model="conditionvolume" size="mini" 
                            :precision="8" :step="0.00000001" :min="0.00000001" 
                            style="margin-left: 10px; background-color: #1C2328; width: 70%;" 
                            :placeholder="i18n('dialogconditionqingshurudingdanshuliang')"></el-input-number>
                    </div>
                    <div class="flex1 flex_row_left" style="background: #1C2328; height: 42px; width: 100%; margin-top: 10px;">
                        <div style="margin-left: 20px; width: 20%;"> {{i18n('dialogconditiondingdanshixiao')}} </div>
                        <div style="margin-left: 10px; color: #eee;"> {{i18n('dialogconditionyongjiuyouxia')}} </div>
                    </div>
                    <div class="flex_row_center">
                        <div class="pfexbtn" @click="conditionconfirmbtnClick"> {{i18n('dialogconditiontijiao')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>    
        
        <!-- 全部设置止盈止损 -->
        <el-dialog :title="i18n('dialogAutoProfitLosszhiyingzhisun')" :visible.sync="dialogAutoProfitLoss" width="30%"> 
            <div class="flex_row_center">
                <div>
                    <div class="flex_row_left">
                        <div style="width: 110px; text-align: center;"> {{i18n('dialogAutoProfitLossmingcheng')}} </div>
                        <div style="width: 165px; text-align: center;"> {{i18n('dialogAutoProfitLosszhiyingdian')}} </div>
                        <div style="width: 165px; text-align: center;"> {{i18n('dialogAutoProfitLosszhisundian')}} </div>
                    </div>
     
                    <div>
                        <div class="flex_row_left" v-for="(item, index) in AutoProfitLossList" :key="index" 
                            style="margin-top: 5px; background: #1C2328; height: 35px;">
                            <div style="width: 20%; margin-left: 10px;"> {{ item.contractCode }} </div>
                            <el-input-number v-model="item.inputprofit" size="mini" 
                                :precision="item.precision" :step="item.wave" :min="0" 
                                style="margin-left: 10px; background-color: #1C2328; width: 35%;" 
                                :placeholder="i18n('dialogAutoProfitLosszhiyingdian')"></el-input-number>
                            <el-input-number v-model="item.inputloss" size="mini" 
                                :precision="item.precision" :step="item.wave" :min="0" 
                                style="margin-left: 10px; background-color: #1C2328; width: 35%;" 
                                :placeholder="i18n('dialogAutoProfitLosszhisundian')"></el-input-number>
                        </div>
                    </div>

                    <div class="flex_row_between">
                        <div class="pfexbtn reset" @click="AutoProfitLossReset"> {{i18n('dialogAutoProfitLosschongzhi')}} </div>
                        <div class="pfexbtn" style="margin-left: 20px;" @click="AutoProfitLossconfirm"> {{i18n('dialogAutoProfitLosstijiao')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>           
        
        <!-- 平仓 -->
        <el-dialog :title="i18n('dialogCloseOrderpingcang')" :visible.sync="dialogCloseOrder" width="30%"> 
            <div class="flex_row_center fontSize14">
                <div style="width: 450px;">
                    <div class="flex_row_left"  style="width: 100%; height: 40px; background: #1C2328;">
                        <div style="width:25%; margin-left: 15px; font-size: 14px;"> {{i18n('dialogCloseOrderweituoleixing')}} </div>
                        <el-select v-model="CloseOrderpriceType" :placeholder="i18n('dialogCloseOrderqingxuanze')"
                        size="mini" 
                        @change="CloseOrderpriceTypeChange"
                        style="width: 50%; font-size: 14px;"
                        class="select_trade" popper-class="selectpopper_trade">
                            <el-option :label="i18n('dialogCloseOrdershijia')" value="1"></el-option>
                            <!-- <el-option :label="i18n('dialogCloseOrderxianjia')" value="0"></el-option> -->
                        </el-select>
                    </div>
                    <div class="flex_row_left" style="width: 100%; height: 40px; background: #1C2328; margin-top: 15px;">
                        <div style="width:25%; margin-left: 15px; font-size: 14px;"> {{i18n('dialogCloseOrderjiage')}} </div>
                        <el-input-number v-model="closeorderPrice" size="mini" 
                            :disabled="CloseOrderpriceType === '1'? true:false"
                            :precision="CloseOrderprecision" :step="CloseOrderstep" 
                            style="background-color: #1C2328; width: 50%;" 
                            :placeholder="CloseOrderpriceType === '1'? i18n('dialogCloseOrdershijia') : i18n('dialogCloseOrderqingshurujiage')"></el-input-number>
                    </div>
                    <!-- <div class="flex_row_left" style="width: 100%; height: 40px; background: #1C2328; margin-top: 15px;">
                        <div style="width:25%; margin-left: 15px; font-size: 14px;"> {{i18n('dialogCloseOrdershuliang')}} </div>
                        <el-input-number v-model="closeorderNum" size="mini" 
                            :precision="8" :step="0.00000001" :min="0" :max="closeordercanCloseNum" 
                            style="background-color: #1C2328; width: 50%;" 
                            :placeholder="i18n('dialogCloseOrderqingshurushuliang')"></el-input-number>
                        <div style="margin-left:10px;"> {{i18n('dialogCloseOrderzhang')}} </div>
                    </div> -->
                    <div class="equal1" style="margin-top: 15px;"> {{i18n('dialogCloseOrderkepingshuliang')}} {{ closeordercanCloseNum }} {{i18n('dialogCloseOrderzhang')}} </div>
                    <div class="flex_row_center">
                        <div class="pingcangbtn" @click="pingcangbtnclick"> {{i18n('dialogCloseOrderqueding')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import { newPriceFormat, classUpOrDown, moneyFormat } from '@/utils/filters.js'
import { teadeWSReq } from '@/utils/packedTrade'
import { baseMethods } from '@/utils/methods.js'
import klineChat from '@/components/common/klineChat'

export default {
    data() {
        return {
            hqlist: this.$store.state.hqlist,
            AutoProfitLossList: this.$store.state.AutoProfitLossList,
            leverList: this.$store.state.leverList,
            userWallet: this.$store.state.userWallet,
            tradeFee: this.$store.state.tradeFee,
            // holdOrderList: this.$store.state.holdOrderList, // 持仓列表
            activeObj: {}, // 当前选中合约对象
            activeContract: '',
            activepfex: 1,
            orderTabName: 'first',
            activeprecision: 0,                 //最小波动小数位
            activestep: 1,                      //最小波动
            activemin: 0,                       //品种最低价
            activemax: 9999999,                 //品种最高价
  
            selectHoldorder: undefined,         //选中的持仓单
            selectHoldorderRow: undefined,      //选中的持仓单对象
            OpenOrClose: 'Open',
            dialogleverRate: false,
            dialogtransfer: false,
            dialogfitloss: false,
            dialogCloseOrder: false,
            dialogconditionorder: false,
            dialogAutoProfitLoss: false,
            buttonleverRate: 1,
            priceType: '1',
            orderPrice: undefined,              //下单价格
            orderNum: undefined,                //下单数量
            USDTNum: undefined,                  //下单金额
            slidervalue: 0,                     //滑块值
            marks: {
                0: '0%',
                25: '25%',
                50: '50%',
                75: '75%',
                100: '100%'
            },
            USDTNumDisable: false,
            slidervalueDisable: false,
            canorderNum: 0,                     //可下单数量
            canOpenNum: 0,                      //可开仓数量
            canCloseNum: 0,                     //可平仓数量
            opendeposit: 0,                     //开仓保证金
            buyliquidation: 0,                 //做多强平价格
            sellliquidation: 0,                //做空强平价格
            transferfromvalue: this.$t("trade")['dialogtransferjichuzhanghu'],
            transfertovalue: this.$t("trade")['dialogtransferheyuezhanghu'],
            transdirection: 0,                  //0 法币往合约账户划转
            transferNum: undefined,
            fitlossHoldorderid: undefined,
            fitlossContractcode: undefined,     //止损合约代码
            fitlossdirection: undefined,        //止损方向
            fitlossdirectionvalue: undefined,   //止损方向值
            fitlosscostOpen: undefined,         //止损开仓成本
            fitlossvolume: undefined,           //止损数量
            fitlossnewprice: undefined,         //止损最新价格
            fitlosswave: undefined,
            fitlossPricetick: undefined,
            fitassess: undefined,
            lossassess: undefined,
            fithysize: undefined,
            progressWidth4: 0,
            progressWidth3: 0,
            progressWidth2: 0,
            progressWidth1: 0,
            progressWidth0: 0,
            progressupWidth0: 0,
            progressupWidth1: 0,
            progressupWidth2: 0,
            progressupWidth3: 0,
            progressupWidth4: 0,
            fitDotnum: undefined,
            lossDotnum: undefined,
            fitPrice: undefined,
            lossPrice: undefined,
            editfitDotnum: false,
            editlossDotnum: false,
            editfitPrice: false,
            editlossPrice: false,
            CloseOrderpriceType: '1',           //平仓对话框价格类型1市价0限价
            closeorderPrice: undefined,         //平仓对话框价格
            CloseOrderprecision: 0,             //平仓对话框价格小数位
            CloseOrderstep: 1,                  //平仓对话框价格最小波动
            closeorderNum: undefined,           //平仓对话框平仓数量
            closeordercanCloseNum: 0,           //平仓对话框可平仓数量
            conditiontype: 0,                   //条件单类型
            conditionsymbol: undefined,         //条件单合约品种
            conditionContractcode: undefined,   //条件单合约编号
            conditionpriceType: 0,              //条件单价格类型(0:最新价;1:买一价;2:卖一价;)
            conditioncompare: 0,                //条件单比较符号(0:>=;1:<=;)
            conditiondirectionoffset: 0,        //条件单买卖开平方向(0:开多;1:平空;2:开空;3:平多;)
            conditionendPrice: undefined,       //条件单结束价格
            conditionendTime: undefined,        //条件单结束时间
            conditionvolume: undefined,         //条件单数量
            conditionEdit: false,               //条件单是编辑还是添加
            conditionID: undefined,             //条件单id
            conditionprecision: 0,              //条件单价格输入框小数位
            conditionstep: 1,                   //条件单价格输入框最小波动

        }
    },
    components:{
        klineChat
    },
    watch: {
        'activeObj.fiveHqList': {
            handler (newVal, oldVal) {
                if (newVal) {
                    if (newVal.selltotal && newVal.selltotal > 0) {
                        if (newVal.salevol4 && newVal.salevol4 > 0)
                            this.progressWidth4 = newVal.salevol4 / newVal.selltotal * 100;
                        if (newVal.salevol3 && newVal.salevol3 > 0)
                            this.progressWidth3 = newVal.salevol3 / newVal.selltotal * 100;
                        if (newVal.salevol2 && newVal.salevol2 > 0)
                            this.progressWidth2 = newVal.salevol2 / newVal.selltotal * 100;
                        if (newVal.salevol1 && newVal.salevol1 > 0)
                            this.progressWidth1 = newVal.salevol1 / newVal.selltotal * 100;
                        if (newVal.salevol0 && newVal.salevol0 > 0)
                            this.progressWidth0 = newVal.salevol0 / newVal.selltotal * 100;
                    }
                
                    if (newVal.buytotal && newVal.buytotal > 0) {
                        if (newVal.buyvol0 && newVal.buyvol0 > 0) 
                            this.progressupWidth0 = newVal.buyvol0 / newVal.buytotal * 100;
                        if (newVal.buyvol1 && newVal.buyvol1 > 0) 
                            this.progressupWidth1 = newVal.buyvol1 / newVal.buytotal * 100;
                        if (newVal.buyvol2 && newVal.buyvol2 > 0) 
                            this.progressupWidth2 = newVal.buyvol2 / newVal.buytotal * 100;
                        if (newVal.buyvol3 && newVal.buyvol3 > 0) 
                            this.progressupWidth3 = newVal.buyvol3 / newVal.buytotal * 100;
                        if (newVal.buyvol4 && newVal.buyvol4 > 0) 
                            this.progressupWidth4 = newVal.buyvol4 / newVal.buytotal * 100;
                    }   
                }
            },
            deep: true,
            immediate: true,
        },
        
        'activeObj.price': {
            handler (newVal, oldVal) {
                if (newVal) {
                    if (this.$store.state.loginStatus && this.userWallet && this.userWallet.marginAvailable) {
                        var size = this.activeObj.val;
                        var newprice = baseMethods.accAdd(newVal, baseMethods.accMul(this.activeObj.wave, 150));
                        var canKnum = baseMethods.accDiv(this.userWallet.marginAvailable, baseMethods.accDiv(baseMethods.accMul(newprice, size), this.activepfex));
                        this.canOpenNum = canKnum;
                        //计算强平价格
                        var num = this.orderNum;
                        if (num === undefined || num === null || num === '' || num === 0)
                            num = 1;
                        var newprice = newVal;
                        var inprice = this.orderPrice;
                        if (inprice === undefined || inprice === null || inprice === '') {
                            inprice = 0;
                        }
                        var ycjj = 0; //预成交价
                        if (this.priceType === '0') { //限价，max（最新价，委托价）
                            if (newprice >= inprice) {
                                ycjj = newprice;
                            } else {
                                ycjj = inprice;
                            }
                        } else {
                            ycjj = newprice;
                        }
                        var marginBill = baseMethods.accDiv(baseMethods.accMul(baseMethods.accMul(ycjj, num), size), this.activepfex);
                        this.opendeposit = marginBill;

                        // 手续费=预成交价*张数*面值*费率	8位小数	费率接口里有，如果没有按照0.0005计算
                        // 强平保留资金=保证金*0.5		8位小数
                        // 强平时浮动盈亏=账户权益-手续费*2-强平保留资金	最小波动
                        // 强平亏损点数=强平时浮动盈亏/(张数*面值）	最小波动
                        // 强平预估价格：
                        // 多单：预成交价-强平亏损点数		最小波动
                        // 空单：预成交价+强平亏损点数		最小波动
                        if (this.$store.state.loginStatus && this.userWallet ) {
                            var fee = baseMethods.accMul(baseMethods.accMul(baseMethods.accMul(baseMethods.accMul(ycjj, num), this.tradeFee.open), size), 2);
                            var qpsfdyk = baseMethods.accSub(baseMethods.accSub(this.userWallet.marginBalance, fee), baseMethods.accDiv(marginBill, 2));
                            var qpksds = baseMethods.accDiv(baseMethods.accDiv(qpsfdyk, num), size);
                            var qppriceb = baseMethods.accSub(ycjj, qpksds);
                            var qpprices = baseMethods.accAdd(ycjj, qpksds);
                            if (qppriceb >= 0) {
                                this.buyliquidation = qppriceb;    
                            } else {
                                this.buyliquidation = 0;    
                            }
                                
                            if (qpprices >= 0) {
                                this.sellliquidation = qpprices;   
                            } else {
                                this.sellliquidation = 0;   
                            }           
                        } else {
                            this.buyliquidation = 0;                //做多强平价格
                            this.sellliquidation = 0;               //做空强平价格
                        }
                    } else {
                        this.canOpenNum = 0;
                    }
                }
            },
            deep: true,
            immediate: true,
        },
        '$i18n.locale'(newValue, oldValue) {
            teadeWSReq.getHoldOrder();
            teadeWSReq.getEntrust();
            teadeWSReq.getOrderList();
        }
    },
    computed: {
        holdOrderList() {
            return this.$store.state.holdOrderList;
        },
        EntrustorderList() {
            return this.$store.state.EntrustorderList;
        },
        DealOrderList() {
            return this.$store.state.DealOrderList;
        },
        ConditionOrderList() {
            return this.$store.state.ConditionOrderList;
        },
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("trade")[name];
            }
		},
    },
    mounted() {
        
        if(this.$store.state.hqlist.length > 0){
            if (this.$route.params.codename !== undefined){
                this.activeObj = this.hqlist.find(item => item.code === this.$route.params.codename);
                this.activeContract = this.$route.params.codename;
            }else{
                this.activeObj = sessionStorage.getItem('activeContract')?this.hqlist.find(item => item.code === sessionStorage.getItem('activeContract')):this.hqlist[0]
                this.activeContract = sessionStorage.getItem('activeContract')?sessionStorage.getItem('activeContract'):this.hqlist[0].code
            }
            this.changeactiveContract(this.activeContract);
        }else{
            this.$root.$on('QuotesContractList', (msg) => {
                let defaultContract = '';
                if (this.$store.state.hqlist.length > 0)
                    defaultContract = this.$store.state.hqlist[0].code;
                let Contract = sessionStorage.getItem('activeContract')? sessionStorage.getItem('activeContract'):defaultContract;
                
                if (Contract !== undefined) {
                    this.activeContract = Contract;
                    this.activeObj = this.hqlist.find(item => item.code === Contract);
                    this.changeactiveContract(this.activeContract);
                }
            });
        }
        this.$root.$on('TS2CLeverRateListRes', (msg) => {
        //    this.activepfex = this.LevelrateByContractcode(this.activeContract);
        });
        this.$root.$on('TS2CModifyLeverRateRes', (msg) => {
            if (msg.ModifyRes) {
                this.$message({
                    message: this.$t('trade')['jsMessageResgaiheyuegangganxiugaichenggong'],
                    type: 'success'
                });
                this.activepfex = this.LevelrateByContractcode(this.activeContract);
                // console.log(this.activeContract, this.activepfex);   
                // console.log(this.leverList);
                //重新计算可开数量
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageResgaiheuyegangganxiugaishibai'],
                    type: 'warning'
                });
            };
        });

        this.$root.$on('TS2COpenRes', (msg) => {
            if (msg.Resultcode == 0) {
                this.USDTNum = undefined;
                this.slidervalue = 0;
                this.$message({
                    message: this.$t('trade')['jsMessageReskaicangchenggong'],
                    type: 'success'
                });
            } else {
                var resmessage = '';
                if (msg.Resultcode === 1)
                    resmessage = this.$t('trade')['jsMessageReskaicang1'];  //禁止开仓
                if (msg.Resultcode === 2)
                    resmessage = this.$t('trade')['jsMessageReskaicang2'];  //合约不存在
                if (msg.Resultcode === 3)
                    resmessage = this.$t('trade')['jsMessageReskaicang3'];  //开仓数量超过上限
                if (msg.Resultcode === 4)
                    resmessage = this.$t('trade')['jsMessageReskaicang4'];  //价格为0
                if (msg.Resultcode === 5)
                    resmessage = this.$t('trade')['jsMessageReskaicang5'];  //开仓保证金不足
                if (msg.Resultcode === 6)
                    resmessage = this.$t('trade')['zxkcje']+this.$store.state.minAmount+'USDT';  //开仓保证金不足
                this.$message({
                    message: this.$t('trade')['jsMessageReskaicangshibai'] + resmessage,
                    type: 'error'
                });
            }
        });

        this.$root.$on('TS2CCloseRes', (msg) => {
            if (msg.Resultcode == 0) {
                this.$message({
                    message: this.$t('trade')['jsMessageRespingcangchenggong'],
                    type: 'success'
                });
            } else {
                var resmessage = '';
                if (msg.Resultcode === 1)
                    resmessage = this.$t('trade')['jsMessageRespingcang1'];  //合约不存在
                if (msg.Resultcode === 2)
                    resmessage = this.$t('trade')['jsMessageRespingcang2'];  //持仓单不存在
                if (msg.Resultcode === 3)
                    resmessage = this.$t('trade')['jsMessageRespingcang3'];  //可平量不足
                this.$message({
                    message: this.$t('trade')['jsMessageRespingcangshibai'] + resmessage,
                    type: 'success'
                });
            }

            if (this.dialogCloseOrder)
                this.dialogCloseOrder = false;
        });

        this.$root.$on('TS2CTransferCapitalRes', (msg) => {
            if (this.dialogtransfer) {
                if (msg.Resultcode == 0) {
                    this.$message({
                        message: this.$t('trade')['jsMessageReszijinhuazhuanchenggong'],
                        type: 'success'
                    });
                    // this.getMyLegalCurrencyAccountInfo();
                    this.$store.dispatch('getMyLegalCurrencyAccountInfo');
                    this.transferNum = undefined;
                    this.dialogtransfer = false;
                } else {
                    this.$message({
                        message: this.$t('trade')['jsMessageReszijinhuazhuanshibai'] ,
                        type: 'success'
                    });
                }
            }  
        });

        // this.$root.$on('QI2QSMarketDepthRes', (msg) => {
        //     if (msg.Contractcode === this.activeContract) {
        //         if (msg.fiveHqList.selltotal && msg.fiveHqList.selltotal > 0) {
        //             if (msg.fiveHqList.salevol4 && msg.fiveHqList.salevol4 > 0)
        //                 this.progressWidth4 = msg.fiveHqList.salevol4 / msg.fiveHqList.selltotal * 100;
        //             if (msg.fiveHqList.salevol3 && msg.fiveHqList.salevol3 > 0)
        //                 this.progressWidth3 = msg.fiveHqList.salevol3 / msg.fiveHqList.selltotal * 100;
        //             if (msg.fiveHqList.salevol2 && msg.fiveHqList.salevol2 > 0)
        //                 this.progressWidth2 = msg.fiveHqList.salevol2 / msg.fiveHqList.selltotal * 100;
        //             if (msg.fiveHqList.salevol1 && msg.fiveHqList.salevol1 > 0)
        //                 this.progressWidth1 = msg.fiveHqList.salevol1 / msg.fiveHqList.selltotal * 100;
        //             if (msg.fiveHqList.salevol0 && msg.fiveHqList.salevol0 > 0)
        //                 this.progressWidth0 = msg.fiveHqList.salevol0 / msg.fiveHqList.selltotal * 100;
        //         }
            
        //         if (msg.fiveHqList.buytotal && msg.fiveHqList.buytotal > 0) {
        //             if (msg.fiveHqList.buyvol0 && msg.fiveHqList.buyvol0 > 0) 
        //                 this.progressupWidth0 = msg.fiveHqList.buyvol0 / msg.fiveHqList.buytotal * 100;
        //             if (msg.fiveHqList.buyvol1 && msg.fiveHqList.buyvol1 > 0) 
        //                 this.progressupWidth1 = msg.fiveHqList.buyvol1 / msg.fiveHqList.buytotal * 100;
        //             if (msg.fiveHqList.buyvol2 && msg.fiveHqList.buyvol2 > 0) 
        //                 this.progressupWidth2 = msg.fiveHqList.buyvol2 / msg.fiveHqList.buytotal * 100;
        //             if (msg.fiveHqList.buyvol3 && msg.fiveHqList.buyvol3 > 0) 
        //                 this.progressupWidth3 = msg.fiveHqList.buyvol3 / msg.fiveHqList.buytotal * 100;
        //             if (msg.fiveHqList.buyvol4 && msg.fiveHqList.buyvol4 > 0) 
        //                 this.progressupWidth4 = msg.fiveHqList.buyvol4 / msg.fiveHqList.buytotal * 100;
        //         }   
        //     }
        // });

        this.$root.$on('TS2CSetProfitLossRes', (msg) => {
            if (msg.Resultcode == 0) {
                this.$message({
                    message: this.$t('trade')['jsMessageReschicangdanzhiyingzhisunshezhichenggong'],
                    type: 'success'
                });
                this.fitDotnum = undefined;
                this.lossDotnum = undefined;
                this.fitPrice = undefined;
                this.lossPrice = undefined;
                this.dialogfitloss = false;
            } else {
                var resmessage = '';
                if (msg.Resultcode === 1)
                    resmessage = this.$t('trade')['jsMessageReszhiyingzhisundianshushezhicuowu'];  //止盈止损点数设置错误
                if (msg.Resultcode === 2)
                    resmessage = this.$t('trade')['jsMessageReschicangdanbucunzai'];  //持仓单不存在
                this.$message({
                    message: this.$t('trade')['jsMessageReschicangdanzhiyingzhisunshezhishibai'] + resmessage,
                    type: 'success'
                });
            }
        });

        this.$root.$on('TS2CCancelRes', (msg) => {
            if (msg.Resultcode == 0) {
                this.$message({
                    message: this.$t('trade')['jsMessageResweituodanchedanchenggong'],
                    type: 'success'
                });
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageResweituodanchedanshibai'] ,  //委托单单不存在或状态无法撤单  如果后续有需要再调整提示信息
                    type: 'success'
                });
            }
        });

        this.$root.$on('TS2CSetConditionOrderRes', (msg) => {
            if (msg.Resultcode == 0) {
                this.$message({
                    message: this.$t('trade')['jsMessageRestiaojiandantianjiachenggong'],
                    type: 'success'
                });
            } else {
                var resmessage = '';
                if (msg.Resultcode === 1)
                    resmessage = this.$t('trade')['jsMessageResheyuebucunzai'];
                if (msg.Resultcode === 2)
                    resmessage = this.$t('trade')['jsMessageResjiageshezhicuowu'];
                if (msg.Resultcode === 3)
                    resmessage = this.$t('trade')['jsMessageResshijianshezhicuowu'];
                if (msg.Resultcode === 4)
                    resmessage = this.$t('trade')['jsMessageResneibucuowu'];
                this.$message({
                    message: this.$t('trade')['jsMessageRestiaojiandantianjiashibai'] + resmessage,
                    type: 'success'
                });
            }

            this.dialogconditionorder = false;
        });
        
        this.$root.$on('TS2CSetAutoProfitLossListRes', (msg) => {
            this.$message({
                message: this.$t('trade')['jsMessageReszhiyingzhisunshezhichenggong'],  //设置成功! 全部复位!
                type: 'success'
            });
            this.dialogAutoProfitLoss = false;
        });
        setTimeout(()=>{
            console.log(this.holdOrderList)
        },1000)
    },
    beforeDestroy() {
        this.$root.$off('QuotesContractList');
        this.$root.$off('TS2CModifyLeverRateRes');
        this.$root.$off('TS2COpenRes');
        this.$root.$off('TS2CCloseRes');
        this.$root.$off('TS2CTransferCapitalRes');
        this.$root.$off('QI2QSMarketDepthRes');
        this.$root.$off('TS2CSetProfitLossRes');
        this.$root.$off('TS2CCancelRes');
        this.$root.$off('TS2CSetConditionOrderRes');
        this.$root.$off('TS2CSetAutoProfitLossListRes');
        this.$root.$off('TS2CLeverRateListRes');
    },
    filters: {
        contractCode: function (contract) {
            let attr;
            // 对contract进行初步校验，确保其不为null、undefined或空字符串
            if (typeof contract === "string" && contract !== "") {
                // 使用try-catch结构以处理可能的运行时异常
                try {
                    attr = contract.split("-");
                    // 检查split结果是否为空数组，防止contract不包含'-'的情况
                    if (attr.length > 0) {
                        return attr[0]; // 安全地返回第一部分字符串
                    } else {
                        // 如果attr为空数组，根据需求可以抛出异常或者返回空字符串
                        throw new Error("Contract string does not contain a '-' character.");
                    }
                } catch (error) {
                    // 根据实际应用需求，这里可以记录日志、抛出异常或者进行其他错误处理
                    console.error("Error processing the contract string:", error);
                    // 抛出异常或者返回默认值，取决于应用逻辑需要
                    throw error;
                }
            } else {
                // 对于无效的contract输入，根据应用需求进行处理
                console.warn("Invalid contract string:", contract);
                // 可以抛出异常，或者返回空字符串/默认值，取决于应用逻辑
                throw new Error("Invalid contract string input.");
            }
        },
        filter_newPriceFormat: function (price, n) {
            if(price && price!='--'){
                return newPriceFormat(price, n);
            }else{
                return '--'
            }
           
        },
        filter_classUpOrDown: function (nprice, openprice, useType) {
            
            return classUpOrDown(nprice, openprice, useType);
            
        },
        filter_moneyFormat: function (x, n) {
            return moneyFormat(x, n);
        },
        timeFormat: function (time, type) {
            if (time && time > 0) {
                if (type === 'ymdhms') {
                    var needdate = new Date(time * 1000);
                } else {
                    var needdate = new Date(time);
                }
                var Y = needdate.getFullYear() + '.';
                var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '.';
                var D = needdate.getDate() + ' ';
                var h = (needdate.getHours() < 10 ? '0' + needdate.getHours() : needdate.getHours()) + ':';
                var m = (needdate.getMinutes() < 10 ? '0' + needdate.getMinutes() : needdate.getMinutes()) + ':';
                var s = (needdate.getSeconds() < 10 ? '0' + needdate.getSeconds() : needdate.getSeconds());
                //      年 月 日 时 分 秒
                if (type === 'ymdhms') {
                    return Y + M + D + h + m + s;
                } else {
                    return h + m + s;
                }
            } else {
                return '--';
            }
        },
	},
    methods: {
        dropdown_rateClass(item) {
            // 确保item.price和item.open都是数字类型
            const price = Number(item.price);
            const open = Number(item.open);

            // 为了提高代码的健壮性，添加对price和open非数字的判断
            if (isNaN(price) || isNaN(open)) {
                // console.warn('item.price或item.open不是数字类型');
                return ['color_up','option-span-long']; // 默认类名
            }

            // 假设classUpOrDown是一个已定义的方法，返回相应的类名
            // 此处将过滤器逻辑集成到方法中，提高了代码的可读性和可维护性
            return [
                classUpOrDown(price, open, 'color'),
                'option-span-long'
            ];
        },
        dropdown_priceClass(item) {
            // 确保item.price和item.open都是数字类型
            const price = Number(item.price);
            const open = Number(item.open);

            // 为了提高代码的健壮性，添加对price和open非数字的判断
            if (isNaN(price) || isNaN(open)) {
                // console.warn('item.price或item.open不是数字类型');
                return ['color_up','option-span-short']; // 默认类名
            }

            // 假设classUpOrDown是一个已定义的方法，返回相应的类名
            // 此处将过滤器逻辑集成到方法中，提高了代码的可读性和可维护性
            return [
                classUpOrDown(price, open, 'color'),
                'option-span-short'
            ];
        },
        dropdown_imgClass(item) {
            // 确保item.price和item.open都是数字类型
            const price = Number(item.price);
            const open = Number(item.open);

            // 为了提高代码的健壮性，添加对price和open非数字的判断
            if (isNaN(price) || isNaN(open)) {
                // console.warn('item.price或item.open不是数字类型');
                return 'color_non'; // 默认类名
            }

            // 假设classUpOrDown是一个已定义的方法，返回相应的类名
            // 此处将过滤器逻辑集成到方法中，提高了代码的可读性和可维护性
            return classUpOrDown(price, open, 'color');
        },
        dropdown_itemClass(item) {
            if (item.code === this.activeContract)
                return ['dropdownList', 'activeContract']
            else
                return 'dropdownList';
        },
        nowPrice1_class(obj) {
            if (obj && obj.price && obj.open) {
                if (obj.price > obj.open) {
                    return ['actPrice', 'color_green'];
                } else if (obj.price < obj.open) {
                    return ['actPrice', 'color_red'];
                } else {
                    return 'actPrice';
                }
            } else {
                return 'actPrice';
            }
        },  
        nowPrice2_class(obj) {
            if (obj && obj.price && obj.open) {
                if (obj.price > obj.open) {
                    return ['nowprice', 'color_green'];
                } else if (obj.price < obj.open) {
                    return ['nowprice', 'color_red'];
                } else {
                    return 'nowprice';
                }
            } else {
                return 'nowprice';
            }
        },  
        nowRate2_class(obj) {
            if (obj && obj.price && obj.open) {
                if (obj.price > obj.open) {
                    return ['uplv', 'color_green'];
                } else if (obj.price < obj.open) {
                    return ['uplv', 'color_red'];
                } else {
                    return 'uplv';
                }
            } else {
                return 'uplv';
            }
        },
        eldropdownCommand(command) {
            //选中合约
            this.activeContract = command;
            this.activeObj = this.hqlist.find(item => item.code === command);
            sessionStorage.setItem('activeContract', command);
            this.changeactiveContract(this.activeContract);
            this.selectHoldorder = undefined;
            this.canCloseNum = 0;
            this.selectHoldorderRow = undefined;
            //进入开仓界面
            this.OpenOrClose =  'Open';
        },
        orderTabClick() {
            this.selectHoldorder = undefined;
            this.canCloseNum = 0;
            this.selectHoldorderRow = undefined;
            //进入开仓界面
            this.OpenOrClose =  'Open';
        },
        HoldorderListClick(row) {
            this.selectHoldorder = row.id;
            this.canCloseNum = row.available;
            this.selectHoldorderRow = row;
            //点击持仓单切换合约，在此处理
            this.activeContract = row.Contractcode;
            this.activeObj = this.hqlist.find(item => item.code === row.Contractcode);
            sessionStorage.setItem('activeContract', row.Contractcode);
            this.changeactiveContract(this.activeContract);
            //进入平仓界面
            this.OpenOrClose =  'Close';
        },
        HoldorderListStyle(obj) {
            if (this.selectHoldorder && this.selectHoldorder === obj.row.id) {
                return {
                    background:'#192126', 
                    'border-color':'#20282E'
                }
            } else {
                return {
                    background:'#161c20', 
                    'border-color':'#20282E'
                }
            }
        },
        holdprofitloss(row) {
            if (this.$store.state.loginStatus) {
                this.fitlossHoldorderid = row.id; 
                this.fitlossContractcode = row.Contractcode;
                this.fitlossdirection = row.direction === 0 ? this.$t('trade')['dialogfitlossduo'] : this.$t('trade')['dialogfitlosskong'];
                this.fitlossdirectionvalue = row.direction;
                this.fitlosscostOpen = row.Costopen;
                this.fitlossvolume = row.volume;
                this.fitlosswave = row.wave;
                this.fitlossPricetick = row.pricetick;
                this.fithysize = row.hysize;
                if (row.profitPrice === 0) {
                    this.fitPrice = undefined;
                    this.fitDotnum = undefined;
                    this.fitassess = undefined;
                } else {
                    this.fitPrice = row.profitPrice;
                    //计算出止盈点数
                    //计算预期收益
                    if (row.direction === 0) {
                        var pronum = baseMethods.accSub(row.profitPrice, row.Costopen);
                        var proyg = baseMethods.accMul(baseMethods.accMul(baseMethods.accSub(row.profitPrice, row.Costopen), row.volume), row.hysize);
                    } else {
                        var pronum = baseMethods.accSub(row.Costopen, row.profitPrice);
                        var proyg = baseMethods.accMul(baseMethods.accMul(baseMethods.accSub(row.Costopen, row.profitPrice), row.volume), row.hysize);
                    }
                    this.fitDotnum = pronum;
                    this.fitassess = proyg;
                }   
                    
                if (row.lossPrice === 0) {
                    this.lossPrice = undefined;
                    this.lossDotnum = undefined;
                    this.lossassess = undefined;    
                } else {
                    this.lossPrice = row.lossPrice;
                    
                    //计算出止损点数
                    //计算预期亏损
                    if (row.direction === 0) {
                        var lossnum = baseMethods.accSub(row.Costopen, row.lossPrice);
                        var losyg = baseMethods.accMul(baseMethods.accMul(baseMethods.accSub(row.Costopen, row.lossPrice), row.volume), row.hysize);
                    } else {
                        var lossnum = baseMethods.accSub(row.lossPrice, row.Costopen);
                        var losyg = baseMethods.accMul(baseMethods.accMul(baseMethods.accSub(row.lossPrice, row.Costopen), row.volume), row.hysize);
                    }
                    this.lossDotnum = lossnum;
                    this.lossassess = losyg;
                }   
                    
                var obj = this.hqlist.find(item => item.code === row.Contractcode);
                if (obj) {
                    this.fitlossnewprice = obj.price;
                }
                
                this.dialogfitloss = true;
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        holdclose(row) {
            //持仓单平仓
            if (this.$store.state.loginStatus) {
                this.CloseOrderpriceType = '1';           //平仓对话框价格类型1市价0限价
                this.closeorderPrice = undefined;         //平仓对话框价格
                this.CloseOrderprecision = row.wave;      //平仓对话框价格小数位
                this.CloseOrderstep = row.pricetick;      //平仓对话框价格最小波动
                this.closeorderNum = row.available;          //平仓对话框平仓数量
                this.closeordercanCloseNum= row.available;   //平仓对话框可平仓数量
                this.selectHoldorderRow = row;

                this.dialogCloseOrder = true;
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        EntrustorderCancle(row) {
            //委托单撤单操作
            if (this.$store.state.loginStatus) {
                this.$confirm(this.$t('trade')['jsconfirmquedingchedanma'], this.$t('trade')['jsconfirmtishi'], {
                    confirmButtonText: this.$t('trade')['jsconfirmqueding'],
                    cancelButtonText: this.$t('trade')['jsconfirmquxiao'],
                    type: 'warning'
                }).then(() => {
                    var idArray = [];
                    idArray[0] = row.id;
                    teadeWSReq.trade_back_methods(idArray);
                }).catch(() => {
                    
                });
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }  
        },
        ConditionOrderchange(row) {
            //条件单修改操作
            if (this.$store.state.loginStatus) {
                this.conditionEdit = true;
                this.conditionID = row.id;
                this.conditionsymbol = row.symbol;
                this.conditionContractcode = row.Contractcode;
                this.conditionpriceType = row.priceType;
                this.conditioncompare = row.compare;
                if (row.offset === 0 && row.direction === 0) {
                    this.conditiondirectionoffset = 0;
                } else if (row.offset ===  1 && row.direction === 1) {
                    this.conditiondirectionoffset = 1;
                } else if (row.offset === 0 && row.direction === 1) {
                    this.conditiondirectionoffset = 2;
                } else if (row.offset === 1 && row.direction === 0) {
                    this.conditiondirectionoffset = 3;
                }
                this.conditionvolume = row.volume;
                this.conditionendPrice = row.Endprice;
                const date = new Date();
                date.setTime(row.Endtime * 1000);
                this.conditionendTime = date;
                this.conditiontype = row.type;
                var obj = this.hqlist.find(item => item.code === row.Contractcode);
                if (obj) {
                    this.conditionprecision = obj.wave.toString().split('.').pop().length;
                    this.conditionstep = obj.wave;
                } else {
                    this.conditionprecision = 0;
                    this.conditionstep = 1;
                }
                
                this.dialogconditionorder = true;
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        onditionOrderRun(row) {
            if (this.$store.state.loginStatus) {
                teadeWSReq.starCondition(row.id);
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            } 
        },
        onditionOrderpause(row) {
            if (this.$store.state.loginStatus) {
                teadeWSReq.stopCondition(row.id);
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        ConditionOrderdelete(row) {
            if (this.$store.state.loginStatus) {
                teadeWSReq.delCondition(row.id);
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }    
        },
        ConditionOrderNow(row) {
            if (this.$store.state.loginStatus) {
                teadeWSReq.orderNow(row.id);
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }    
        },
        LevelrateByContractcode(Contractcode) {
            var obj = this.$store.state.leverList.find(item => item.code === Contractcode) ;
            if (obj)
            {
                return obj.leverate; 
            } else {
                return 20;
            }
        },
        userleverRateDialogShow(activepfex, Contractcode) {
            //打卡杠杆倍率设置
            let showdialog = true;
            //判断是否登录
            if (this.$store.state.loginStatus) {
                //判断该合约是否有持仓，有持仓或委托不可修改杠杆
                var hold = 0;
                this.$store.state.holdList.forEach(item => {
                    if (item.getContractcode() === Contractcode) {
                        hold++;
                        showdialog = false;
                    }
                });
                if (hold > 0) {
                    this.$message({
                        message: this.$t('trade')['jsMessagegaiheyueyouchicangbukexiugaiganggan'],
                        type: 'warning'
                    });
                }

                var Entrust = 0;
                this.$store.state.EntrustList.forEach(item => {
                    if (item.getContractcode() === Contractcode && item.getStatus() === 2) {
                        Entrust++ ;
                        showdialog = false;
                    }
                });
                if (Entrust > 0) {
                    this.$message({
                            message: this.$t('trade')['jsMessagegaiheyueyouweituobukexiugaiganggan'],
                            type: 'warning'
                        });
                }

                if (showdialog) {
                    this.buttonleverRate = activepfex;
                    this.dialogleverRate = true;
                }
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        transferDialogShow() {
            if (this.$store.state.loginStatus) {
                this.dialogtransfer = true;
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                })
            } 
        },
        buttonleverRateClick(leverRate) {
            this.buttonleverRate = leverRate;
        },
        changeLeverRate(leverRate, Contractcode) {
            if (this.$store.state.loginStatus) {
                teadeWSReq.setLever(Contractcode, leverRate);
                this.dialogleverRate = false;
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                })
            };
        },
        priceTypeChange(value) {
            // 切换委托类型把价格输入框清空
            this.orderPrice = undefined;
        },
        blurorderPrice(value) {
            //如果用户输入的价格不是最小波动倍数，强制格式化成最小波动倍数
            // if (this.openPrice && this.priceType === '0') {
            //     if (this.openPrice < this.minPrice) {
            //         this.openPrice = this.minPrice;
            //     } else if (this.openPrice > this.maxPrice) {
            //         this.openPrice = this.maxPrice;
            //     } 
            // }
        },
        changeactiveContract(Contractcode) {
              //合约设置倍率
            //   this.activepfex = this.LevelrateByContractcode(this.activeContract);
            if (this.activeObj) {
                this.activestep = this.activeObj.wave;              //最小波动
                var len = this.activeObj.wave.toString().split('.').pop().length;
                this.activeprecision = len;
                // console.log(this.activeprecision);
                if (this.activeObj.high > 0) {
                    this.activemin = this.activeObj.high * 0.8;    //品种最低价 80%
                } else {
                    this.activemin = 0;
                }
                
                if (this.activeObj.low > 0) {
                    this.activemax = this.activeObj.low * 1.2;     //品种最高价 120%
                } else {
                    this.activemax = 9999999;
                } 
            } else {
                this.activestep = 1;
                this.activeprecision = 0;
                this.activemin = 0;
                this.activemax = 9999999;
            }
            this.priceType = '1';
            this.orderPrice = undefined;
            this.orderNum = undefined;
            
            //如果登录，计算可开数量
            // this.calccanOpenNum();
            // this.calcliquidationPrice();
        },
        setAutoProfitLoss() {
            //自动止盈止损设置
            if (this.$store.state.loginStatus) {
                this.AutoProfitLossList.forEach(item => {
                    item.inputprofit = item.profit;
                    item.inputloss = item.loss;
                });
                this.dialogAutoProfitLoss = true;
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                })
            }       
        },
        openLong() {
            //开多
            if (this.$store.state.loginStatus && this.activeContract && this.activeContract !== '') {
                //判断是否登录
                if (this.priceType === '1') {  //市价下单
                    if (this.USDTNum !== undefined && this.USDTNum > 0) {
                        var code = this.activeContract;
                        //计算开仓数量
                        var num = baseMethods.accDiv(this.USDTNum, this.activeObj.price); 
                        teadeWSReq.trade_methods(code, num.toFixed(8), 0, 0, 1);
                    } else {
                        this.$message({
                            message: this.$t('trade')['jsMessageqingshuruzhengquedeshuliang'],
                            type: 'warning'
                        });
                    }
                } else {
                    if (this.orderPrice !== undefined && this.orderPrice > 0) {
                        if (this.USDTNum !== undefined && this.USDTNum > 0) {
                            var code = this.activeContract;
                            var num = baseMethods.accDiv(this.USDTNum, this.orderPrice);
                            teadeWSReq.trade_methods(code, num.toFixed(8), this.orderPrice, 0, 0);
                        } else {
                            this.$message({
                                message: this.$t('trade')['jsMessageqingshuruzhengquedeshuliang'],
                                type: 'warning'
                            });
                        }
                    } else {
                        this.$message({
                            message: this.$t('trade')['jsMessageqingshuruzhengquedejiage'],
                            type: 'warning'
                        });
                    }
                }
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                })
            }
        },
        openShort() {
            //开空
            if (this.$store.state.loginStatus && this.activeContract && this.activeContract !== '') {
                //判断是否登录
                if (this.priceType === '1') {  //市价下单
                    if (this.USDTNum !== undefined && this.USDTNum > 0) {
                        var code = this.activeContract;
                        //计算开仓数量
                        var num = baseMethods.accDiv(this.USDTNum, this.activeObj.price);
                        teadeWSReq.trade_methods(code, num.toFixed(8), 0, 1, 1);
                    } else {
                        this.$message({
                            message: this.$t('trade')['jsMessageqingshuruzhengquedeshuliang'],
                            type: 'warning'
                        });
                    }
                } else {
                    if (this.orderPrice !== undefined && this.orderPrice > 0) {
                        if (this.USDTNum !== undefined && this.USDTNum > 0) {
                            var code = this.activeContract;
                            var num = baseMethods.accDiv(this.USDTNum, this.orderPrice);
                            teadeWSReq.trade_methods(code, num.toFixed(8), this.orderPrice, 1, 0);
                        } else {
                            this.$message({
                                message: this.$t('trade')['jsMessageqingshuruzhengquedeshuliang'],
                                type: 'warning'
                            });
                        }
                    } else {
                        this.$message({
                            message: this.$t('trade')['jsMessageqingshuruzhengquedejiage'],
                            type: 'warning'
                        });
                    }
                }
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        Closeposition() {
            //平仓
            if (this.$store.state.loginStatus) {
                if (this.selectHoldorder && this.selectHoldorderRow){
                    if (this.selectHoldorderRow.direction == 0) {
                        var dire = 1;
                    } else {
                        var dire = 0;
                    }
             
                    if (this.priceType === '1') {  //市价下单
                        var code = this.selectHoldorderRow.Contractcode;
                        var strategyID = this.selectHoldorderRow.strategyID;
                        var traderUserID = this.selectHoldorderRow.traderUserID;   
                        var volume = this.selectHoldorderRow.volume;
                        teadeWSReq.trade_pc_methods_list(strategyID, code, volume, 0, dire, 1, traderUserID);
                    } else {
                        if (this.orderPrice !== undefined && this.orderPrice > 0) {
                            var code = this.selectHoldorderRow.Contractcode;
                            var strategyID = this.selectHoldorderRow.strategyID;
                            var traderUserID = this.selectHoldorderRow.traderUserID;   
                            var volume = this.selectHoldorderRow.volume;
                            teadeWSReq.trade_pc_methods_list(strategyID, code, volume, this.orderPrice, dire, 0, traderUserID);
                        } else {
                            this.$message({
                                message: this.$t('trade')['jsMessageqingshuruzhengquedejiage'],
                                type: 'warning'
                            });
                        }
                    }
                } else {
                    this.$message({
                        message: this.$t('trade')['jsMessageqingxianxuanzechicang'],
                        type: 'warning'
                    });
                }
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        calccanOpenNum() {
            // 计算可开仓数量
            // 可开张数： 可用担保资金/[(最新价+最小波动*150)*面值/杠杆];
            if (this.activeObj && this.activeObj.price && this.activeObj.price > 0 && this.$store.state.loginStatus && this.userWallet && this.userWallet.marginAvailable) {
                var size = this.activeObj.val;
                var newprice = baseMethods.accAdd(this.activeObj.price, baseMethods.accMul(this.activeObj.wave, 150));
                var canKnum = baseMethods.accDiv(this.userWallet.marginAvailable, baseMethods.accDiv(baseMethods.accMul(newprice, size), this.activepfex));
                this.canOpenNum = canKnum;
                //计算强平价格
            } else {
                this.canOpenNum = 0;
            }
        },
        calcliquidationPrice() {
            if (this.activeObj && this.activeObj.price && this.activeObj.price > 0) {
                var size = this.activeObj.val;
                var num = this.orderNum;
                if (num === undefined || num === null || num === '' || num === 0)
                    num = 1;
                var newprice = this.activeObj.price;
                var inprice = this.orderPrice;
                if (inprice === undefined || inprice === null || inprice === '') {
                    inprice = 0;
                }
                var ycjj = 0; //预成交价
                if (this.priceType === '0') { //限价，max（最新价，委托价）
                    if (newprice >= inprice) {
                        ycjj = newprice;
                    } else {
                        ycjj = inprice;
                    }
                } else {
                    ycjj = newprice;
                }
                var marginBill = baseMethods.accDiv(baseMethods.accMul(baseMethods.accMul(ycjj, num), size), this.activepfex);
                this.opendeposit = marginBill;

                // 手续费=预成交价*张数*面值*费率	8位小数	费率接口里有，如果没有按照0.0005计算
                // 强平保留资金=保证金*0.5		8位小数
                // 强平时浮动盈亏=账户权益-手续费*2-强平保留资金	最小波动
                // 强平亏损点数=强平时浮动盈亏/(张数*面值）	最小波动
                // 强平预估价格：
                // 多单：预成交价-强平亏损点数		最小波动
                // 空单：预成交价+强平亏损点数		最小波动
                if (this.$store.state.loginStatus && this.userWallet ) {
                    var fee = baseMethods.accMul(baseMethods.accMul(baseMethods.accMul(baseMethods.accMul(ycjj, num), this.tradeFee.open), size), 2);
                    var qpsfdyk = baseMethods.accSub(baseMethods.accSub(this.userWallet.marginBalance, fee), baseMethods.accDiv(marginBill, 2));
                    var qpksds = baseMethods.accDiv(baseMethods.accDiv(qpsfdyk, num), size);
                    var qppriceb = baseMethods.accSub(ycjj, qpksds);
                    var qpprices = baseMethods.accAdd(ycjj, qpksds);
                    if (qppriceb >= 0) {
                        this.buyliquidation = qppriceb;    
                    } else {
                        this.buyliquidation = 0;    
                    }
                        
                    if (qpprices >= 0) {
                        this.sellliquidation = qpprices;   
                    } else {
                        this.sellliquidation = 0;   
                    }           
                } else {
                    this.buyliquidation = 0;                //做多强平价格
                    this.sellliquidation = 0;               //做空强平价格
                }
            } else {
                this.opendeposit = 0;

                this.buyliquidation = 0;                //做多强平价格
                this.sellliquidation = 0;               //做空强平价格
            }  
        },
        OpenClosetabClick() {
            this.priceType = '1';
            this.orderPrice = undefined;
            this.orderNum = undefined;
        },
        transfericonClick() {
            var temp = this.transfertovalue;
            this.transfertovalue = this.transferfromvalue;
            this.transferfromvalue = temp;
            if (this.transdirection === 0) {
                this.transdirection = 1;
            } else {
                this.transdirection = 0;
            }
            this.transferNum = undefined;
        },
        transferAllclick() {
            if (this.transdirection === 0) {
                this.transferNum = this.userWallet.LegalCurrency;
            } else {
                this.transferNum = this.userWallet.marginAvailable;
            }
        },
        transferbtnclick() {
            if (this.transferNum && this.transferNum > 0) {
                teadeWSReq.exchangeCurrency(this.transdirection, this.transferNum);
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingshuruhuazhuanshuliang'],
                    type: 'warning'
                });
            }
        },
        fitlossbtnclick() {
            //设置持仓单止盈止损
            var profit = 0;
            var loss = 0;
            if (this.fitDotnum !== undefined)
                profit =  this.fitDotnum;
            if (this.lossDotnum !== undefined)
                loss =  this.lossDotnum;

            teadeWSReq.holdOrderSetproless(this.fitlossHoldorderid, profit, loss);

            this.fitDotnum = undefined;
            this.lossDotnum = undefined;
            this.fitPrice = undefined;
            this.lossPrice = undefined;
            this.dialogfitloss = false;
        },
        fitDotnumChange() {
            if (!this.editfitPrice) {
                this.editfitDotnum = true;
                if (this.fitDotnum && this.fitDotnum > 0) {
                    if (this.fitlossdirectionvalue === 0) {
                        var tocost = baseMethods.accAdd(this.fitlosscostOpen, this.fitDotnum);
                        var profit = baseMethods.accMul(baseMethods.accMul(baseMethods.accSub(tocost, this.fitlosscostOpen), this.fitlossvolume), this.fithysize);
                    } else {
                        var tocost = baseMethods.accSub(this.fitlosscostOpen, this.fitDotnum);
                        if (tocost >= 0) {
                            var profit = baseMethods.accMul(baseMethods.accMul(baseMethods.accSub(this.fitlosscostOpen, tocost), this.fitlossvolume), this.fithysize);
                        } else {
                            this.fitDotnum = undefined;
                            var profit = undefined;
                        }
                    }
                    this.fitPrice = tocost;
                    this.fitassess = profit;
                } else {
                    this.fitPrice = 0;
                    this.fitassess = undefined;
                }
                this.editfitDotnum = false;
            }
        },
        lossDotnumChange() {
            //计算止损价格
            if (!this.editlossPrice) {
                this.editlossDotnum = true;
                if (this.lossDotnum && this.lossDotnum > 0) {
                    if (this.fitlossdirectionvalue === 0) {
                        var tocost = baseMethods.accSub(this.fitlosscostOpen, this.lossDotnum);
                        if (tocost >= 0) {
                            var profit = baseMethods.accMul(baseMethods.accMul(baseMethods.accSub(this.fitlosscostOpen, tocost), this.fitlossvolume), this.fithysize);
                        } else {
                            this.lossDotnum = undefined;
                            var profit = undefined;
                        }
                    } else {
                        var tocost = baseMethods.accAdd(this.fitlosscostOpen, this.lossDotnum);
                        var profit = baseMethods.accMul(baseMethods.accMul(baseMethods.accSub(tocost, this.fitlosscostOpen), this.fitlossvolume), this.fithysize);
                    }
                    this.lossPrice = tocost;
                    this.lossassess = profit;
                } else {
                    this.lossPrice = 0;
                    this.lossassess = undefined;
                }
                this.editlossDotnum = false;
            }
        },
        fitPriceChange() {
            if (!this.editfitDotnum) {
                this.editfitPrice = true;
                if (this.fitPrice && this.fitPrice > 0 && this.fitlossPricetick > 0) {
                    var num = 0;
                    if (this.fitlossdirectionvalue === 0) {
                        num = baseMethods.accSub(this.fitPrice, this.fitlosscostOpen); 
                    } else {
                        num = baseMethods.accSub(this.fitlosscostOpen, this.fitPrice); 
                    }
                    if (num > 0) {
                        var profit = baseMethods.accMul(baseMethods.accMul(num, this.fitlossvolume), this.fithysize);
                    }
                    this.fitDotnum = num;
                    this.fitassess = profit;
                } else {
                    this.fitDotnum = undefined;
                    this.fitassess = undefined;
                }

                if (this.fitlossdirectionvalue === 0) {
                    if (this.fitPrice && this.fitPrice < this.fitlosscostOpen) {
                        this.fitDotnum = undefined;
                        this.fitassess = undefined;
                    }
                } else {
                    if (this.fitPrice && this.fitPrice > this.fitlosscostOpen) {
                        this.fitDotnum = undefined;
                        this.fitassess = undefined;
                    }
                }  

                this.editfitPrice = false;
            }
        },
        lossPriceChange() {
            //计算止损点数
            if (!this.editlossDotnum) {
                this.editlossPrice = true;
                if (this.lossPrice && this.lossPrice > 0 && this.fitlossPricetick > 0) {
                    var num = 0;
                    if (this.fitlossdirectionvalue === 0) {
                        num = baseMethods.accSub(this.fitlosscostOpen, this.lossPrice); 
                    } else {
                        num = baseMethods.accSub(this.lossPrice, this.fitlosscostOpen); 
                    }
                    console.log(this.lossPrice, this.fitlosscostOpen, num);

                    if (num > 0) {
                        var profit = baseMethods.accMul(baseMethods.accMul(num, this.fitlossvolume), this.fithysize);
                    }
                    this.lossDotnum = Math.round(num);
                    this.lossassess = profit;
                } else {
                    this.lossDotnum = undefined;
                    this.lossassess = undefined;
                }

                if (this.fitlossdirectionvalue === 0) {
                    if (this.lossPrice && this.lossPrice > this.fitlosscostOpen) {
                        this.lossDotnum = undefined;
                        this.lossassess = undefined;
                    }
                } else {
                    if (this.lossPrice && this.lossPrice < this.fitlosscostOpen) {
                        this.lossDotnum = undefined;
                        this.lossassess = undefined;
                    }
                }
                
                this.editlossPrice = false;
            }
        },
        CloseOrderpriceTypeChange() {
            this.closeorderPrice = undefined;
        },
        pingcangbtnclick() {
            if (this.$store.state.loginStatus) {
                if (this.selectHoldorder && this.selectHoldorderRow){
                    if (this.selectHoldorderRow.direction == 0) {
                        var dire = 1;
                    } else {
                        var dire = 0;
                    }

                    if (this.CloseOrderpriceType === '1') {  //市价下单
                        var code = this.selectHoldorderRow.Contractcode;
                        var strategyID = this.selectHoldorderRow.strategyID;
                        var traderUserID = this.selectHoldorderRow.traderUserID;  
                        var volume = this.selectHoldorderRow.volume; 
                        teadeWSReq.trade_pc_methods_list(strategyID, code, volume, 0, dire, 1, traderUserID);
                    } else {
                        if (this.closeorderPrice !== undefined && this.closeorderPrice > 0) {
                            var code = this.selectHoldorderRow.Contractcode;
                            var strategyID = this.selectHoldorderRow.strategyID;
                            var traderUserID = this.selectHoldorderRow.traderUserID;   
                            var volume = this.selectHoldorderRow.volume; 
                            teadeWSReq.trade_pc_methods_list(strategyID, code, volume, this.closeorderPrice, dire, 0, traderUserID);
                        } else {
                            this.$message({
                                message: this.$t('trade')['jsMessageqingshuruzhengquedejiage'],
                                type: 'warning'
                            });
                        }
                    }
                } else {
                    this.$message({
                        message: this.$t('trade')['jsMessageqingxianxuanzechicang'],
                        type: 'warning'
                    });
                }
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        conditionOrderAddClick() {
            if (this.$store.state.loginStatus) {
                if (this.activeObj && this.activeObj.symbol !== undefined && this.activeObj.code !== undefined) {
                    this.conditionsymbol = this.activeObj.symbol;
                    this.conditionContractcode = this.activeObj.code;
                    this.conditionprecision = this.activeprecision;
                    this.conditionstep = this.activestep;
                    const date = new Date();
                    this.conditionendTime = date;
                    this.conditionEdit = false;
                    this.dialogconditionorder = true;
                } else {
                    this.$message({
                        message: this.$t('trade')['jsMessageqingxianxuanzeheyue'],
                        type: 'warning'
                    });
                }
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        conditionOrderPauseAll() {
            if (this.$store.state.loginStatus) {
                // console.log('暂停全部条件单');
                teadeWSReq.stopCondition(0);
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        conditionOrderRunAll() {
            if (this.$store.state.loginStatus) {
                teadeWSReq.starCondition(0);
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        conditionOrderDelAll() {
            if (this.$store.state.loginStatus) {
                teadeWSReq.delCondition(0);
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        conditionconfirmbtnClick() {
            //条件单添加
            if (this.conditiontype === 0) {
                //价格条件单
                if (this.conditionendPrice !== undefined && this.conditionendPrice > 0) {
                    if (this.conditionvolume !== undefined && this.conditionvolume > 0) {
                        var direction = 0;
                        var offset = 0;
                        if (this.conditiondirectionoffset === 0) {
                            direction = 0;
                            offset = 0;    
                        } else if (this.conditiondirectionoffset === 1) {
                            direction = 1;
                            offset = 1;   
                        } else if (this.conditiondirectionoffset === 2) {    
                            direction = 1;
                            offset = 0;  
                        } else if (this.conditiondirectionoffset === 3) {
                            direction = 0;
                            offset = 1;  
                        }
                        if (this.conditionEdit) {
                            teadeWSReq.changeCondition(this.conditionID, this.conditionsymbol, this.conditionContractcode, this.conditiontype, this.conditionpriceType, 
                                this.conditioncompare, this.conditionendPrice, 0, offset, direction, this.conditionvolume) ;
                        } else {
                            teadeWSReq.addCondition(this.conditionsymbol, this.conditionContractcode, this.conditiontype, this.conditionpriceType, 
                                this.conditioncompare, this.conditionendPrice, 0, offset, direction, this.conditionvolume) ;
                        }
                    } else {
                        this.$message({
                            message: this.$t('trade')['jsMessageqingshurutiaojiandanshuliang'],
                            type: 'warning'
                        });
                    }
                } else {
                    this.$message({
                        message: this.$t('trade')['jsMessageqingshurutiaojiandandejiage'],
                        type: 'warning'
                    });
                }
            } else {
                //时间条件单
                if (this.conditionvolume !== undefined && this.conditionvolume > 0) {
                    if (this.conditionendTime !== undefined && this.conditionendTime > 0) {
                        var direction = 0;
                        var offset = 0;
                        if (this.conditiondirectionoffset === 0) {
                            direction = 0;
                            offset = 0;    
                        } else if (this.conditiondirectionoffset === 1) {
                            direction = 1;
                            offset = 1;
                        } else if (this.conditiondirectionoffset === 2) {    
                            direction = 1;
                            offset = 0;  
                        } else if (this.conditiondirectionoffset === 3) {
                            direction = 0;
                            offset = 1;  
                        }

                        var date = new Date(this.conditionendTime);
                        var time1 = date.getTime() / 1000;
                        if (this.conditionEdit) {
                            teadeWSReq.changeCondition(this.conditionID, this.conditionsymbol, this.conditionContractcode, this.conditiontype, 0, 0, 0, 
                                time1, offset, direction, this.conditionvolume) ;
                        } else {
                            teadeWSReq.addCondition(this.conditionsymbol, this.conditionContractcode, this.conditiontype, 0, 0, 0, 
                                time1, offset, direction, this.conditionvolume ) ;
                        }
                    } else {
                        this.$message({
                            message: this.$t('trade')['jsMessageqingshurutiaojiandanzhixingshijian'],
                            type: 'warning'
                        });
                    }
                } else {
                    this.$message({
                        message: this.$t('trade')['jsMessageqingshurutiaojiandanshuliang'],
                        type: 'warning'
                    });
                }
            }
        },
        AutoProfitLossReset() {
            this.AutoProfitLossList.forEach(item => {
                item.inputprofit = 0;
                item.inputloss = 0;
            });
        },
        AutoProfitLossconfirm() {
            if (this.$store.state.loginStatus) {
                var arr = [];
                this.AutoProfitLossList.forEach(item => {
                    arr.push({
                        contractCode: item.contractCode,
                        Profit: item.inputprofit,
                        Loss: item.inputloss
                    });
                });
                teadeWSReq.editHypzData(arr);
            } else {
                this.$message({
                    message: this.$t('trade')['jsMessageqingxiandenglu'],
                    type: 'warning'
                });
            }
        },
        rechargeClick() {
            this.$router.push("/rechargeLayout");
        },
        withdrawClick() {
            this.$router.push("/withdrawLayout");
        },
        sliderChange() {
            if (!this.slidervalueDisable) {
                var number = baseMethods.accMul(this.userWallet.marginAvailable, baseMethods.accDiv(this.slidervalue, 100));
                this.USDTNumDisable = true;
                this.USDTNum = Math.floor(number);
                this.USDTNumDisable = false;
            }
        },
        USDTNumInput(value) {
            const key = this.$refs.USDNumber.displayValue;
            this.USDTNumDisable = true;
			this.USDTNum = key;
            this.USDTNumDisable = false;
			if (value != undefined) {
				this.$refs.USDNumber.clearValidate();
			}

            if (!this.USDTNumDisable) {
                if (this.USDTNum !== undefined && this.USDTNum > 0) {
                    if (this.userWallet.marginAvailable * 0.25 > this.USDTNum) {
                        this.slidervalueDisable = true;
                        this.slidervalue = 0;
                        this.slidervalueDisable = false;
                    }
                    if (this.userWallet.marginAvailable * 0.25 < this.USDTNum) {
                        this.slidervalueDisable = true;
                        this.slidervalue = 25;
                        this.slidervalueDisable = false;
                    }
                    if (this.userWallet.marginAvailable * 0.5 < this.USDTNum) {
                        this.slidervalueDisable = true;
                        this.slidervalue = 50;
                        this.slidervalueDisable = false;
                    }
                    if (this.userWallet.marginAvailable * 0.75 < this.USDTNum)
                    {
                        this.slidervalueDisable = true;
                        this.slidervalue = 75;
                        this.slidervalueDisable = false;
                    }
                        
                } else {
                    this.slidervalueDisable = true;
                    this.slidervalue = 0;
                    this.slidervalueDisable = false;
                }
            }
        },
        USDTNumChange() {
            if (!this.USDTNumDisable) {
                if (this.USDTNum !== undefined && this.USDTNum > 0) {
                    if (this.userWallet.marginAvailable * 0.25 > this.USDTNum) {
                        this.slidervalueDisable = true;
                        this.slidervalue = 0;
                        this.slidervalueDisable = false;
                    }
                    if (this.userWallet.marginAvailable * 0.25 < this.USDTNum) {
                        this.slidervalueDisable = true;
                        this.slidervalue = 25;
                        this.slidervalueDisable = false;
                    }
                    if (this.userWallet.marginAvailable * 0.5 < this.USDTNum) {
                        this.slidervalueDisable = true;
                        this.slidervalue = 50;
                        this.slidervalueDisable = false;
                    }
                    if (this.userWallet.marginAvailable * 0.75 < this.USDTNum)
                    {
                        this.slidervalueDisable = true;
                        this.slidervalue = 75;
                        this.slidervalueDisable = false;
                    }
                        
                } else {
                    this.slidervalueDisable = true;
                    this.slidervalue = 0;
                    this.slidervalueDisable = false;
                }
            }
        },
    },
}
</script>
<style scoped src="@/css/contractTradeLayout.css">

</style> 
<style scoped>
/* 下拉框 */
.selectpopper_trade.el-select-dropdown{
    border: none !important;
    background-color: #202930 !important;
}

.select_trade.el-select .el-input__inner{
    border: none;
    color:#eee;
    border-color: #202930;
    background-color: #202930;
}

/* 聚焦时的样式 */
.select_trade.el-select .el-input.is-focus .el-input__inner{
    background-color: #202930;
    color:#00D3E9;
}
/* 下拉框选中 */
.selectpopper_trade .el-select-dropdown__item{
    font-size: 12px;

}
.el-dialog{
    background-color: #1C2328 !important;
}
.selectpopper_trade .el-select-dropdown__item.selected{
    background-color: #1e7ac5 !important;
}

.el-input-number .el-input__inner{
    border: none;
    color:#eee;
    background-color: #1C2328;
}

::v-deep .el-dialog {
    background: #1C2328 !important;
}

::v-deep .el-dialog__title {
    color: white !important;
}


/* 日历样式 */
.datepickerPopper.el-picker-panel {
    background: #1C2328 !important;
    color: #ffffff;
    border: 1px solid #293f6f !important;
}

.datepickerPopper .el-date-picker__time-header {
    border-bottom: 1px solid #293f6f !important;
}

.datepickerPopper .el-date-picker__header--bordered {
    border-bottom: 1px solid #293f6f !important;
}

.datepickerPopper .el-picker-panel__footer {
    border-top: 1px solid #293f6f !important;
}

.datepickerPopper.el-picker-panel .el-date-range-picker__time-header {
    border-bottom: 1px solid #93bee7 !important;
}

.datepickerPopper .el-picker-panel__icon-btn {
    width: 8px;
    height: 10px;
    color: #5a81fd !important;
}

.datepickerPopper.el-picker-panel .el-input__inner {
    background-color: #1C2328;
    border: 1px solid #293f6f;
    color: #e8fdff;
}

.datepickerPopper.el-picker-panel .el-input__inner:focus,
.datepickerPopper.el-picker-panel .el-input__inner:hover {
    border: 1px solid #293f6f;
}

.datepickerPopper.el-picker-panel .el-date-range-picker__content.is-left,
.datepickerPopper.el-picker-panel .el-date-range-picker__content.is-right {
    border-right: 1px solid #93bee7 !important;
    background-size: 100% 100%;
}

.datepickerPopper.el-picker-panel .el-date-range-picker__header {
    color: #75ebf2;
}

.datepickerPopper.el-picker-panel .el-date-table th {
    color: #c3e3ec;
}

.datepickerPopper.el-picker-panel .el-date-table td.available {
    width: 14px;
    height: 14px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #6ce6cb;
}

.datepickerPopper.el-picker-panel .el-date-table td.available:hover {
    color: #ffffff;
}

.datepickerPopper .el-date-table td.in-range div,
.datepickerPopper .el-date-table td.in-range div:hover,
.datepickerPopper .el-date-table.is-week-mode .el-date-table__row.current div,
.datepickerPopper .el-date-table.is-week-mode .el-date-table__row:hover div {
    background-color: #1C2328 !important;
}

.datepickerPopper .el-time-panel {
    background-color: #1b3e6f !important;
    border: 1px solid #93bee7 !important;
}

.datepickerPopper .el-time-spinner__item {
    color: #c3e3ec !important;
}

.datepickerPopper .el-time-spinner__item:hover:not(.disabled):not(.active) {
    background: #4c77b3 !important;
}

.datepickerPopper .el-time-panel__btn {
    color: #adcfff !important;
}

.datepickerPopper .el-picker-panel__footer {
    background-color: #1C2328 !important;
}

.datepickerPopper.el-picker-panel .el-button {
    width: 36px;
    height: 18px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #eee;
    background: #0059FF !important;
}

.datepickerPopper.el-picker-panel .el-button:hover {
    color: #eee;
}

.datepickerPopper .el-icon-d-arrow-left {
    color: #1475e3 !important;
}

.datepickerPopper .el-button.is-disabled,
.datepickerPopper .el-button.is-disabled:focus,
.datepickerPopper .el-button.is-disabled:hover {
    color: #1475e3 !important;
    margin-left: -20px !important;
}

.datepickerPopper .el-button + .el-button {
    width: 92px;
    height: 40px;
    background-size: 115% 130% !important;
    border: 1px solid #1475e3 !important;
    border-radius: 4px !important;
}

.datepickerPopper .el-button {
    width: 92px;
    height: 40px;
    border: 1px solid #1475e3;
    border-radius: 4px;
}

.datepickerPopper .el-button--text {
    display: none;
    width: 92px;
    height: 40px;
    border: 1px solid #1475e3;
    border-radius: 4px;
}

.datepickerPopper .el-button.is-disabled.is-plain,
.datepickerPopper .el-button.is-disabled.is-plain:focus,
.datepickerPopper .el-button.is-disabled.is-plain:hover {
    width: 92px;
    height: 40px;
    /* background: url(./images/圆角矩形.png) center center !important; */
    background-size: 115% 130% !important;
    border: 1px solid #1475e3 !important;
    border-radius: 4px !important;
}

.datepickerPopper .el-button--text,
.datepickerPopper .el-button--text.is-disabled,
.datepickerPopper .el-button--text.is-disabled:focus,
.datepickerPopper .el-button--text.is-disabled:hover,
.datepickerPopper .el-button--text:active {
    display: none !important;
}

.datepickerPopper .el-date-table th {
    border-bottom: 1px solid #293f6f !important;
}
/* 时间筛选 */

.datepickerPopper .el-time-panel__content::after,
.datepickerPopper .el-time-panel__content::before {
    border-bottom: 1px solid #293f6f !important;
    border-top: 1px solid #293f6f !important;
}

.datepickerPopper .el-time-panel {
    background-color: #1C2328 !important;
    border: 1px solid #293f6f !important;
}

.datepickerPopper .el-time-panel__btn.confirm {
    background-color: #0059FF !important;
    color: #eee !important;
    border-radius: 4px;
}

.datepickerPopper .el-time-panel__btn.cancel {
    background-color: #0059FF !important;
    color: #eee !important;
    border-radius: 4px;
}

.datepickerPopper .el-time-panel__footer {
    border-top: none !important;
}

.datepickerPopper .el-time-panel {
    border: none;
    border-top: 2px solid #293f6f !important;
}

.datepickerSelect .el-input__inner {
    background: #1C2328 !important;
    border: none;
    color: #eee;
}

.datepickerPopper .el-date-picker__header-label,
.datepickerPopper .el-picker-panel__icon-btn,
.datepickerPopper .el-date-table th {
    color: #eee !important;
}


</style>

<style scoped>


.contract-trade-layout {
    min-width: 1200px;
    background: #101417;
    color: #ffffff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dropdownList {
    background-color: #161C20;
    color: white;
}

.dropdownList:hover {
    background-color: #111111 !important;
}

.activeContract {
    background-color: #111111;
}

.option-span {
    display: inline-block;
    padding-right: 10px;
    width: 40%;
}

.option-span-short {
    display: inline-block;
    padding-right: 10px;
    width: 30%;
}

.option-span-long {
    display: inline-block;
}

.bg_up{
    background: #088F23;
}

.bg_down{
    background: #1763B6;
}

.color_up{
    color: #088F23;
}

.color_down{
    color: #1763B6;
}

.color_border_up{
    color: #088F23;
    border-color: #088F23;
}

.color_border_down{
    color: #A21616;
    border-color: #A21616;
}

::v-deep .el-tabs__content {
    overflow: visible;
}  

::v-deep .el-tabs__item {
    color: white;
    
}

::v-deep .el-tabs__item.is-active {
    color: #FCD535;
    font-weight: 600;
}

::v-deep .el-icon-arrow-left {
    color: white;
}

::v-deep .el-icon-arrow-right {
    color: white;
}

::v-deep .el-tabs__nav-wrap::after {
    height: 0;
}

::v-deep .el-tabs__active-bar {
    background-color: #FCD535;
}

.dataViewTable >>> .el-table__row>td{ 
    /* border: none;  */
    border-color: #20282E;
}

/* 去掉最下面的那一条线 */
.dataViewTable >>> .el-table::before {
    /* height: 0px; */
    background-color: #20282E;
}

.dataViewTable >>> .el-table tbody tr:hover>td {
    background-color: transparent !important;
}

.el-input-number.is-disabled /deep/ .el-input__inner{
    border: none;
    color:#eee;
    /* border-color: #202930; */
    background-color: #1C2328;
}

::v-deep .el-input-number__decrease{
    border: none;
    color: #eee;
    background-color: #202930;
}

::v-deep .el-input-number__increase{
    border: none;
    color: #eee;
    background-color: #202930;
}

::v-deep .el-input-number__decrease.is-disabled{
    color: #4b4a4a;
}

::v-deep .el-input-number__increase.is-disabled{
    color: #4b4a4a;
}

::v-deep .el-input-number.is-disabled .el-input-number__decrease{
    color: #4b4a4a;
}

::v-deep .el-input-number.is-disabled .el-input-number__increase{
    color: #4b4a4a;
}

.inputarea_slider {
    width: calc(100% - 40px);
    margin: 0 10px 12px 10px;
    height: 50px;
    background: #1C2328;
    border: 1px solid #1C2328;
    border-radius: 2px;
    /* line-height: 40px; */
    white-space: nowrap;
    font-size: 10px;
    padding: 0 10px;
  }

::v-deep .inputarea_slider .el-slider__button {
    width: 10px;
    height: 10px;
}

::v-deep .inputarea_slider .el-slider__marks-text {
    font-size: 10px;
}
.zuoduo.el-button:hover {
    background: #4f7ad8 !important; /* 鼠标悬停时的边框颜色 */
    color: white  !important;
  }
.zuokong.el-button:hover {
    background: #d84f4f !important; /* 鼠标悬停时的边框颜色 */
    color: white  !important;
  }
</style>

