import Vue from 'vue'
import VueRouter from 'vue-router'
import BasicLayout from '../components/common/BasicLayout'
import homeLayout from '../views/homeLayout'
import LoginLayout from '../views/LoginLayout'
import contractTradeLayout from '../views/contractTradeLayout.vue'
import assetsLayout from '../views/assetsLayout.vue'
import withdrawLayout from '../views/withdrawLayout.vue'
import rechargeLayout from '../views/rechargeLayout.vue'
import basicsAccountRecordLayout from '../views/basicsAccountRecordLayout.vue'
import contractAccountRecordLayout from '../views/contractAccountRecordLayout.vue'
import contractTransactionRecordLayout from '../views/contractTransactionRecordLayout.vue'
import SecurityCenterLayout from '../views/SecurityCenterLayout.vue'
import verificationLayout from '../views/verificationLayout.vue'
import inviteLayout from '../views/inviteLayout.vue'
import treasure from '../views/treasure.vue'
import subscription from '../views/subscription.vue'
import registerLayout from '../views/registerLayout.vue'
import subDetails from '../views/subDetails.vue'
import registration from '../views/registration.vue'
import commisson from '../views/commisson.vue'

import store from '../store'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '',
        component: BasicLayout,
        meta: {
            requireAuth: true
        },
        children: [
            {
                path: '',
                name: 'homeLayout',
                component: homeLayout,
            },
            {
                path: 'LoginLayout',
                name: 'LoginLayout',
                component: LoginLayout,
            },
            {
                path: 'contractTradeLayout',
                name: 'contractTradeLayout',
                component: contractTradeLayout,
            },
            {
                path: 'assetsLayout',
                name: 'assetsLayout',
                component: assetsLayout,
            },
            {
                path: 'withdrawLayout',
                name: 'withdrawLayout',
                component: withdrawLayout,
            },
            {
                path: 'rechargeLayout',
                name: 'rechargeLayout',
                component: rechargeLayout,
            },
            {
                path: 'basicsAccountRecordLayout',
                name: 'basicsAccountRecordLayout',
                component: basicsAccountRecordLayout,
            },
            {
                path: 'contractAccountRecordLayout',
                name: 'contractAccountRecordLayout',
                component: contractAccountRecordLayout,
            },
            {
                path: 'contractTransactionRecordLayout',
                name: 'contractTransactionRecordLayout',
                component: contractTransactionRecordLayout,
            },
            {
                path: 'SecurityCenterLayout',
                name: 'SecurityCenterLayout',
                component: SecurityCenterLayout,
            },
            {
                path: 'verificationLayout',
                name: 'verificationLayout',
                component: verificationLayout,
            },
            {
                path: 'inviteLayout',
                name: 'inviteLayout',
                component: inviteLayout,
            },
            {
                path: 'treasure',
                name: 'treasure',
                component: treasure,
            },
            {
                path: 'registerLayout',
                name: 'registerLayout',
                component: registerLayout,
            },
            {
                path: 'subscription',
                name: 'subscription',
                component: subscription,
            },
            {
                path: 'subDetails',
                name: 'subDetails',
                component: subDetails,
            },
            {
                path: 'commisson',
                name: 'commisson',
                component: commisson,
            },
            {
                path: 'registration',
                name: 'registration',
                component: registration,
            },
        ]
    }
]

const router = new VueRouter({
    routes,
    mode:'history'
})
router.beforeEach(async(to, from, next) => {
    if(store.state.loginStatus){
        next();
    }else{
        if(to.name == "homeLayout" || to.name == "LoginLayout" || to.name == "contractTradeLayout" || to.name == "registerLayout" || to.name == "registration"){
            next();
        }else{
            if(to.path != 'LoginLayout' ) {
                return next({
                    path:'LoginLayout',
                    NAVTYPE:'push',
                    
                });
            }
        }
    }
})

 
export default router
