export default {
    public:{
        zhd:"أفضل",
        qsjyh:"المستخدمين في جميع أنحاء العالم",
        gtdxz:'خيار مشترك',
        szhbjyytz:'فرص وتحديات العملات الرقمية',
        xsddcf:'فرص الثروة والتحديات في العصر الجديد',
        yzsjypt:"منصة تداول وقفة واحدة",
        mskljyzh:'افتح حساب تداول وابدأ التداول الآن',
        jyhg:"الامتثال التجاري",
    },

    header: {
    hello: 'مرحبا بالعالم!',
    changeLanguage: 'تبديل اللغة',
    Login: 'تسجيل الدخول',
    Register: 'يسجل',
    Logout: 'تسجيل الخروج',
    shouye: 'الصفحة الأولى',
    bibijiaoyisuo: 'تداول العملات المشفرة',
    heyuejiaoyisuo: 'تداول العقود',
    licaichanpin: 'تجمع التعدين',
    rengou: 'الاشتراك',
    ruanjianxiazai: 'تنزيل البرامج',
    zhongwen: 'الصينية',
    yingwen: 'في',
    zichan: 'أصول',
    dingdan: 'طلب',
    heyuedingdan: 'أمر العقد',
    bibidingdan: 'طلب العملة',
    anquanzhongxin: 'مركز الأمن',
    fanyongyaoqing: 'دعوة اللجنة',
    tuichudenglu: 'تسجيل الخروج',
    gupiaojiaoyi: 'تداول الأسهم',
    waihuijiaoyi: 'تداول العملات الأجنبية',
    qiquanjiaoyi:'تداول الخيارات',
    gupiaodingdan: 'ترتيب الأسهم',
    waihuidingdan: 'أوامر الفوركس',
    qiquandingdan: 'ترتيب الخيارات',
    jiaoyidingdan:'أمر التداول',
    daolizhongxin:'مركز الوكالة',
    huodong:'نشاط',
    },
    home: {
    huanyinglaidao: 'مرحبا بكم في',
    pingtaimingcheng: 'هنا',
    mengxiangqihang: 'أبحرت الأحلام',
    yijiankaiqishuzihualvcheng: 'ابدأ رحلتك الرقمية بنقرة واحدة',
    liaojiegengduo: 'يتعلم أكثر',
    chongzhi: 'أعلى حتى',
    jiaoyi: 'تجارة',
    remenbang: 'قائمة شعبية',
    zhangfubang: 'قائمة الرابح',
    diefubang: 'قائمة الخاسرين',
    chengjiaoliang: 'مقدار',
    zanwushuju: 'لا توجد بيانات حتى الآن',
    jiayidui: 'زوج التداول',
    zuixinjiage: 'أحدث الأسعار',
    zhangdie24h: '24 ساعة صعودا وهبوطا',
    caozuo: 'تعمل',
    jiaoyi: 'تجارة',
    gengduo: 'أكثر',
    suishisuidikaiqijiaoyi: 'ابدأ التداول في أي وقت وفي أي مكان',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'سواء كان التطبيق أو الويب، يمكنك بدء معاملتك بسرعة',
    saomaxiazaiApp: 'قم بمسح رمز الاستجابة السريعة لتنزيل التطبيق',
    iosheAndroid: 'دائرة الرقابة الداخلية وأندرويد',
    ninkexinrenqieanquandejiamibijiaoyisuo: 'تبادل العملات المشفرة الموثوق به والآمن',
    tishi1: 'شهادة الاحتياط',
    xiangqing1: 'ونعد بالاحتفاظ بجميع أصول المستخدم على الأقل بنسبة 1:1',
    tishi2: 'أفضل أسعار المعاملات',
    xiangqing2: 'أسعار معاملات تفضيلية، وحقوق تنافسية لكبار الشخصيات، والتمتع بأفضل الخدمات جودة',
    tishi3: 'الأمان الذي يمكنك الاعتماد عليه',
    xiangqing3: 'إن إجراءاتنا الأمنية المتطورة وأموال SAFU تحمي أصولك الرقمية من جميع المخاطر',
    tishi4: 'دعم خدمة العملاء 24*7 ساعات',
    xiangqing4: '24*7 ساعات، وضع التشغيل بدوام كامل، قم بالإجابة على استفساراتك ذات الصلة في أقرب وقت ممكن',
    tishi5: 'مستقرة وآمنة',
    xiangqing5: 'توفر البورصة الرائدة في العالم حماية أمنية على مستوى البنك لأصولك',
    tishi6: 'التعليمات',
    xiangqing6: 'راجع الأسئلة الشائعة للحصول على وصف تفصيلي لميزات محددة',
    fanhui:"يعود",
    shishihangqing:'اقتباسات في الوقت الحقيقي',
    },
    footer: {
    bangzhuzhongxin: 'مركز المساعدة',
    dianhuazixun: 'استشارة هاتفية',
    dianhuahegongzuoshijian: '1670-9756 (10:00~19:00 في أيام الأسبوع/مغلق في عطلات نهاية الأسبوع والعطلات الرسمية)',
    liaotianzixun: 'استشارة عبر الدردشة 1:1',
    keketuozixun: 'استشارات كوكوتوك',
    zaixianbangzhu: 'مساعدة عبر الإنترنت',
    h24kaifang: '(مفتوح 24 ساعة)',
    lixianbangzhu: 'مساعدة دون اتصال',
    gongsidizhi: '1F، قمة يونجسان بورجيو، 69 هانجانج-جيل، يونجسان-جو، سيول',
    gongzuoshijian: '(10:00 ~ 18:00 في أيام الأسبوع / مغلق في عطلات نهاية الأسبوع والعطلات الرسمية)',
    shangshizixun: 'التشاور حول القائمة',
    hezuozixun: 'مشاورة التعاون',
    gongsi: 'شركة',
    lianxiwomwn: 'اتصل بنا',
    zhidao: 'مرشد',
    yonghuzhichi: 'دعم المستخدم',
    zhanghuzhinan: 'دليل الحساب-الإيداع/السحب',
    ruanjianxiazai: 'تنزيل البرامج',
    zhengce: 'سياسة',
    fuwuyuyinsitiaokuan: 'شروط الخدمة والخصوصية',
    },
    login: {
    yonghudenglu: 'تسجيل دخول المستخدم',
    huanyingdenglu: 'مرحبا بكم في تسجيل الدخول',
    xiangmumingcheng: 'بي سي إف إكس',
    guanfangwangzhan: 'الموقع الرسمي',
    youxiang: 'بريد',
    qingshuruyouxiang: 'الرجاء إدخال البريد الإلكتروني الخاص بك',
    mima: 'كلمة المرور',
    qingshurumima: 'الرجاء إدخال كلمة المرور',
    wangjimima: 'نسيت كلمة المرور؟',
    denglu: 'تسجيل الدخول',
    haimeiyouzhanghao: 'ليس لديك حساب حتى الآن؟ يذهب',
    zhuce: 'يسجل',
    dengluchenggong: 'تم تسجيل الدخول بنجاح',
    denglushibai: 'فشل تسجيل الدخول',
    qingshuruyonghuminghemima: 'الرجاء إدخال اسم المستخدم وكلمة المرور',
    mimachangdubunengxiaoyuliuwei: 'لا يمكن أن يقل طول كلمة المرور عن 6 أحرف',
    youxiangrenzheng: 'التحقق من البريد الإلكتروني',
    youxiangdizhi: 'عنوان البريد الإلكتروني',
    qingshuruyouxiangdizhi: 'الرجاء إدخال عنوان البريد الإلكتروني',
    tuxingyanzhengma: 'رمز التحقق الرسومي',
    qingshurutuxingyanzhengma: 'الرجاء إدخال رمز التحقق الرسومي',
    yanzhengma: 'رمز التحقق',
    qingshuruyouxiangyanzhengma: 'الرجاء إدخال رمز التحقق من البريد الإلكتروني',
    huoquyouxiangyanzhengma:'رمز التحقق',
    queding: 'بالتأكيد',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'فشل الحصول على رمز التحقق الرسومي، يرجى الطلب مرة أخرى',
    qingxianwanchengyouxiangrenzheng: 'يرجى إكمال التحقق من البريد الإلكتروني أولاً',
    huoquyouxiangyanzhengmashibai: 'فشل في الحصول على رمز التحقق من البريد الإلكتروني،',
    houchongxinfasong: 'إعادة الإرسال بعد S',
    youxiangyanzhengchenggong: 'تم التحقق من البريد الإلكتروني بنجاح',
    yanzhengmacuowu: 'خطأ في رمز التحقق',
    xiugaidenglumima: 'تغيير كلمة المرور لتسجيل الدخول',
    xinmima: 'كلمة المرور الجديدة',
    quedingxinmima: 'تأكيد كلمة المرور الجديدة',
    qingshuruxinmima: 'الرجاء إدخال كلمة المرور الجديدة',
    qingzaicishuruxinmima: 'الرجاء إدخال كلمة المرور الجديدة مرة أخرى',
    liangcimimashurubuyizhi: 'إدخالا كلمة المرور غير متناسقين',
    mimaxiugaichenggong: 'تم تغيير كلمة المرور بنجاح',
    xiugaimimashibai: 'فشل في تغيير كلمة المرور',
    },
    trade: {
    hangqingheyue: 'عقد',
    hangqingzuixinjia: 'أحدث الأسعار',
    hangqingzhangdiefu: 'زيادة أو نقصان',
    hangqingzuigao24h: '24 ساعة أعلى',
    hangqingzuidi24h: '24 ساعة كحد أدنى',
    hangqingchengjiaoliang24h:'24 حجم أدنى ',
    hangqingchengjiabishu: 'عدد المعاملات',
    zhanghuquanyi: 'حقوق الحساب',
    zhanghukeyongshouyi: 'الفوائد المتاحة',
    hangqingweituoliebiao: 'قائمة الطلبات',
    hangqingjiage: 'سعر',
    hangqingshuliang: 'كمية',
    hangqingzuixinchengjiao: 'أحدث الصفقة',
    hangqingjiage1: 'سعر',
    hangqingshuliang1: 'كمية',
    hangqingshijian: 'وقت',
    dingdanchiyoucangwei: 'شغل منصبا',
    dingdandangqianweituo: 'النظام الحالي',
    dingdanchengjiaojilu: 'سجل المعاملات',
    dingdantiaojiandan: 'أمر مشروط',
    chicangheyue: 'عقد',
    chicangkaicangjunjia: 'سعر الموقف',
    chicangfudongyingkui: 'الربح والخسارة',
    chicanggangganbeishu: 'تَأثِير',
    chicangchicangliang: 'المنصب (المناصب)',
    chicangduokong: 'طويل/قصير',
    chicangduo: 'كثير',
    chicangkong: 'باطل',
    chicangzhiyingjiage: 'سعر جني الأرباح',
    chicangzhisunjiage: 'سعر وقف الخسارة',
    chicangqiangpingyugujiage: 'سعر التصفية المقدر',
    chicangcaozuo: 'تعمل',
    chicangzhiyingzhisun: 'جني الربح ووقف الخسارة',
    chicangpingcang: 'موقف قريب',
    chicangshouxufei: 'رسوم المناولة',
    weituoheyue: 'عقد',
    weituoshuliang: 'كمية الطلب',
    weituojiage: 'سعر العمولة',
    weituofangxiang: 'اتجاه',
    weituogangganbeishu: 'الاستفادة المتعددة',
    weituochengjiaoshuliang: 'كمية الصفقة',
    weituoshouxufei: 'رسوم المناولة',
    weituochengjiaojunjia: 'متوسط ​​السعر',
    weituoshouyi: 'دخل',
    weituozhuangtai: 'ولاية',
    weituochengjiaoshijian: 'وقت الصفقة',
    weituocaozuo: 'تعمل',
    weituochedan: 'إلغاء الطلب',
    weituotijiaoshibai: 'فشل التقديم',
    weituozhilingtijiao: 'تقديم التعليمات',
    weituoyitijiao: 'مُقَدَّم',
    weituobufenchengjiao: 'صفقة جزئية',
    weituobufenchengjiaoyichedan: 'تم إلغاء بعض الطلبات',
    weituowanquanchengjiao: 'صفقة كاملة',
    weituowanquanchedan: 'إلغاء الطلب بالكامل',
    weituochedanzhong: 'إلغاء الطلب',
    weituokaiduo: 'مفتوح لفترة طويلة',
    weituopingkong: 'السماء المسطحة',
    weituokaikong: 'فتح قصيرة',
    weituopingduo: 'بيندو',
    chengjiaoheyue: 'عقد',
    chengjiaofangxiang: 'اتجاه',
    chengjiaoshuliang: 'كمية الصفقة',
    chengjiaojiage: 'سعر الصفقة',
    chengjiaoshijian: 'وقت الصفقة',
    chengjiaopingcangyingkui: 'الربح والخسارة',
    chengjiaoshouxufei: 'رسوم المعاملة',
    chengjiaokaiduo: 'مفتوح لفترة طويلة',
    chengjiaopingkong: 'السماء المسطحة',
    chengjiaokaikong: 'فتح قصيرة',
    chengjiaopingduo: 'بيندو',
    tiaojianzengjiatiaojian: 'أضف الشروط',
    tiaojianzantingquanbu: 'وقفة كل شيء',
    tiaojianqidongquanbu: 'ابدأ الكل',
    tiaojianshanchuquanbu: 'احذف الكل',
    tiaojianheyue: 'عقد',
    tiaojianweituoshuliang: 'كمية الطلب',
    tiaojianfangxiang: 'اتجاه',
    tiaojiantiaojianleixing: 'نوع الشرط',
    tiaojianjiageleixing: 'نوع السعر',
    tiaojianbijiaofuhao: 'رمز المقارنة',
    tiaojiantiaojian: 'حالة',
    tiaojianzhuangtai: 'ولاية',
    tiaojianchuangjianshijian: 'وقت الخلق',
    tiaojiancaozuo: 'تعمل',
    tiaojianxiugai: 'يراجع',
    tiaojianqidong: '.بدء',
    tiaojianzanting: 'يوقف',
    tiaojianshanchu: 'يمسح',
    tiaojianlijixiadan: 'اطلب الآن',
    tiaojiankaiduo: 'مفتوح لفترة طويلة',
    tiaojianpingkong: 'السماء المسطحة',
    tiaojiankaikong: 'فتح قصيرة',
    tiaojianpingduo: 'بيندو',
    tiaojianjiagetiaojian: 'شروط السعر',
    tiaojianshijiantiaojian: 'حالة الوقت',
    tiaojianzuixinjia: 'أحدث الأسعار',
    tiaojianByijia: 'شراء سعر واحد',
    tiaojianSyijia: 'بيع بسعر واحد',
    tiaojianzanting: 'يوقف',
    tiaojianyunxing: 'يجري',
    tiaojianyiwancheng: 'مكتمل',
    tiaojianshibai: 'يفشل',
    tiaojianyishanchu: 'تم الحذف',
    tiaojianshoudongchufawancheng: 'اكتمل المشغل اليدوي',
    tiaojianshoudongchufashibai: 'فشل المشغل اليدوي',
    xiadankaicang: 'افتح مركزا',
    xiadanpingcang: 'موقف قريب',
    xiadanweituoleixing: 'يكتب',
    xiadanqingxuanze: 'الرجاء التحديد',
    xiadanshijia: 'سعر السوق',
    xiadanxianjia: 'حد السعر',
    xiadanjiage: 'سعر',
    xiadanqingshurujiage: 'الرجاء إدخال السعر',
    xiadanshuliang: 'كمية',
    xiadanqingshurushuliang: 'الرجاء إدخال الكمية',
    xiadanzhang: 'يفتح',
    xiadankekaishuliang: 'الكمية المتاحة',
    xiadanqingxianxuanzechicangdan: 'الرجاء تحديد ترتيب الموقف أولا',
    xiadankepingshuliang: 'الكمية التي يمكن تسويتها',
    xiadansuoxubaozhengjin: 'الهامش المطلوب:',
    xiadanshijiazhiyingzhisunyushe: 'سعر السوق يأخذ الربح ويوقف الخسارة مسبقا',
    xiadanyujiqiangpingjiage: 'سعر التصفية التقديري:',
    xiadanqiangpingjiagetishi: 'يتم حساب سعر التصفية المتوقع ديناميكيًا بناءً على إجمالي الأصول. هذا السعر مرجعي فقط ولن يستخدم كتصفية نهائية.',
    xiadanzuoduo: 'اذهب لفترة طويلة',
    xiadanzuokong: 'قصير',
    xiadanpingcang: 'موقف قريب',
    zhanghuheyuezichan: 'أصول العقد',
    zhanghuchongbi: 'إيداع العملات المعدنية',
    zhanghutibi: 'سحب العملات المعدنية',
    zhanghuhuazhuan: 'تحويل',
    zhanghuzhanghuquanyi: 'حقوق الحساب',
    zhanghufudongyingkui: 'الربح والخسارة العائمة',
    zhanghucangweibaozhengjin: 'هامش الموقف',
    zhanghudongjiebaozhengjin: 'تجميد الهامش',
    zhanghukeyongquanyi: 'الفوائد المتاحة',
    zhanghubaozhengjinshiyonglv: 'معدل استخدام الهامش',
    hangqingheyuexinxi: 'معلومات العقد',
    hangqingheyuemingcheng: 'اسم العقد',
    hangqingjijiahuobi: 'عملة الاقتباس',
    hangqingheyuedaxiao: 'حجم العقد',
    hangqingzuixiaojiagebodong: 'الحد الأدنى لتقلبات الأسعار',
    dialogleverRateganggantiaozheng: 'تعديل الرافعة المالية',
    dialogleverRatequeding: 'بالتأكيد',
    dialogtransferzichanhuazhuan: 'نقل الأصول',
    dialogtransferbizhong: 'عملة',
    dialogtransfercong: 'من',
    dialogtransferdao: 'يصل',
    dialogtransfershuliang: 'كمية',
    dialogtransferqingshuruhuazhuanshuliang: 'الرجاء إدخال مبلغ التحويل',
    dialogtransferquanbu: 'الجميع',
    dialogtransferkeyong: 'متاح',
    dialogtransferhuazhuan: 'تحويل',
    dialogtransferjichuzhanghu: 'الحساب الأساسي',
    dialogtransferheyuezhanghu: 'حساب العقد',
    dialogfitlosszhiyingzhisun: 'جني الربح ووقف الخسارة',
    dialogfitlossheyu: 'عقد',
    dialogfitlossduokong: 'طويل/قصير',
    dialogfitlosschichangjunjia: 'متوسط ​​سعر الموقف',
    dialogfitlossshuliang: 'كمية',
    dialogfitlosszuixinjia: 'أحدث الأسعار',
    dialogfitlosszhiyingdianshu: 'خذ نقاط الربح',
    dialogfitlosszhisundianshu: 'نقاط وقف الخسارة',
    dialogfitlossdianshu: 'نتيجة',
    dialogfitlosszhiyingjiage: 'سعر جني الأرباح',
    dialogfitlosszhisunjiage: 'سعر وقف الخسارة',
    dialogfitlossjiage: 'سعر',
    dialogfitlossyuqishouyi: 'العودة المتوقعة',
    dialogfitlossyuqikuisun: 'الخسارة المتوقعة',
    dialogfitlosstishi1: 'نصيحة: الحد الأدنى لتقلب العقد الحالي هو',
    dialogfitlosstishi2: 'يرجى ضبط السعر على 0 لإلغاء جني الأرباح وإيقاف الخسارة.',
    dialogfitlossqueding: 'بالتأكيد',
    dialogfitlossduo: 'كثير',
    dialogfitlosskong: 'باطل',
    dialogconditionzengjiayuntiaojiandan: 'إضافة ورقة حالة السحابة',
    dialogconditiontiaojianleixing: 'نوع الشرط:',
    dialogconditionjiagetiaojiandan: 'ورقة شروط الأسعار',
    dialogconditionshijiantiaojiandan: 'ورقة حالة الوقت',
    dialogconditionjiageleixing: 'نوع السعر:',
    dialogconditionzuixinjia: 'أحدث الأسعار',
    dialogconditionByijia: 'شراء سعر واحد',
    dialogconditionSyijia: 'بيع بسعر واحد',
    dialogconditiontiaojianshezhi: 'إعدادات الحالة:',
    dialogconditionjiageshezhi: 'إعدادات الأسعار:',
    dialogconditionqingshurujiage: 'الرجاء إدخال السعر',
    dialogconditionshijianshezhi: 'إعداد الوقت:',
    dialogconditionjiaoyileixing: 'نوع المعاملة:',
    dialogconditionkaiduo: 'مفتوح لفترة طويلة',
    dialogconditionpingkong: 'السماء المسطحة',
    dialogconditionkaikong: 'فتح قصيرة',
    dialogconditionpingduo: 'بيندو',
    dialogconditiondingdanshuliang: 'كمية الطلب:',
    dialogconditionqingshurudingdanshuliang: 'الرجاء إدخال كمية الطلب',
    dialogconditiondingdanshixiao: 'وقت الطلب:',
    dialogconditionyongjiuyouxia: 'صالحة بشكل دائم',
    dialogconditiontijiao: 'يُقدِّم',
    dialogAutoProfitLosszhiyingzhisun: 'جني الربح ووقف الخسارة',
    dialogAutoProfitLossmingcheng: 'اسم',
    dialogAutoProfitLosszhiyingdian: 'نقطة جني الأرباح',
    dialogAutoProfitLosszhisundian: 'نقطة وقف الخسارة',
    dialogAutoProfitLosschongzhi: 'إعادة ضبط',
    dialogAutoProfitLoss: 'يُقدِّم',
    dialogCloseOrderpingcang: 'موقف قريب',
    dialogCloseOrderweituoleixing: 'نوع المندوب',
    dialogCloseOrderqingxuanze: 'الرجاء التحديد',
    dialogCloseOrdershijia: 'سعر السوق',
    dialogCloseOrderxianjia: 'حد السعر',
    dialogCloseOrderjiage: 'سعر',
    dialogCloseOrderqingshurujiage: 'الرجاء إدخال السعر',
    dialogCloseOrdershuliang: 'كمية',
    dialogCloseOrderqingshurushuliang: 'الرجاء إدخال الكمية',
    dialogCloseOrderzhang: 'يفتح',
    dialogCloseOrderkepingshuliang: 'الكمية التي يمكن تسويتها',
    dialogCloseOrderqueding: 'بالتأكيد',
    jsMessageResgaiheyuegangganxiugaichenggong: 'تم تعديل الرافعة المالية للعقد بنجاح',
    jsMessageResgaiheuyegangganxiugaishibai: 'فشل تعديل الرافعة المالية للعقد',
    jsMessageReskaicangchenggong: 'تم فتح المركز بنجاح',
    jsMessageReskaicangshibai: 'فشل فتح المركز:',
    jsMessageReskaicang1: 'فتح المركز محظور',
    jsMessageReskaicang2: 'العقد غير موجود',
    jsMessageReskaicang3: 'عدد المراكز المفتوحة يتجاوز الحد الأعلى',
    jsMessageReskaicang4: 'السعر 0',
    jsMessageReskaicang5: 'هامش غير كاف لفتح الصفقة',
    jsMessageRespingcangchenggong: 'تم إغلاق المركز بنجاح',
    jsMessageRespingcangshibai: 'فشل في إغلاق الموقف:',
    jsMessageRespingcang1: 'العقد غير موجود',
    jsMessageRespingcang2: 'إيصال المنصب غير موجود',
    jsMessageRespingcang3: 'كمية غير كافية',
    jsMessageReszijinhuazhuanchenggong: 'تم تحويل الأموال بنجاح',
    jsMessageReszijinhuazhuanshibai: 'فشل تحويل الأموال',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'تم تعيين أمر إيقاف الربح وإيقاف الخسارة بنجاح',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'فشلت إعدادات إيقاف الربح وإيقاف الخسارة لأوامر المراكز:',
    jsMessageResweituodanchedanchenggong: 'تم إلغاء الطلب بنجاح',
    jsMessageResweituodanchedanshibai: 'فشل إلغاء الطلب',
    jsMessageRestiaojiandantianjiachenggong: 'تمت إضافة الأمر المشروط بنجاح',
    jsMessageResheyuebucunzai: 'العقد غير موجود',
    jsMessageResjiageshezhicuowu: 'خطأ في تحديد السعر',
    jsMessageResshijianshezhicuowu: 'خطأ في ضبط الوقت',
    jsMessageResneibucuowu: 'خطأ داخلي',
    jsMessageRestiaojiandantianjiashibai: 'فشلت إضافة أمر مشروط:',
    jsMessageReszhiyingzhisunshezhichenggong: 'تم ضبط جني الأرباح وإيقاف الخسارة بنجاح',
    jsconfirmquedingchedanma: 'هل أنت متأكد أنك تريد إلغاء الطلب؟',
    jsconfirmtishi: 'تَلمِيح',
    jsconfirmqueding: 'بالتأكيد',
    jsconfirmquxiao: 'يلغي',
    jsMessageqingxiandenglu: 'الرجاء تسجيل الدخول أولا!',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'يحتوي هذا العقد على مراكز مفتوحة ولا يمكن تعديل الرافعة المالية.',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'هذا العقد له تفويض ولا يمكن تعديل الرافعة المالية.',
    jsMessageqingshuruzhengquedeshuliang: 'الرجاء إدخال الكمية الصحيحة!',
    jsMessageqingshuruzhengquedejiage: 'الرجاء إدخال السعر الصحيح!',
    jsMessageqingxianxuanzechicang: 'الرجاء تحديد الموقف أولا!',
    jsMessageqingshuruhuazhuanshuliang: 'الرجاء إدخال مبلغ التحويل!',
    jsMessageqingxianxuanzeheyue: 'الرجاء تحديد العقد أولا!',
    jsMessageqingshurutiaojiandanshuliang: 'الرجاء إدخال كمية الطلب المشروطة!',
    jsMessageqingshurutiaojiandandejiage: 'الرجاء إدخال سعر الأمر المشروط!',
    jsMessageqingshurutiaojiandanzhixingshijian: 'الرجاء إدخال وقت تنفيذ الأمر المشروط!',
    emptytext: 'لا توجد بيانات حتى الآن',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'تم تعيين نقاط جني الأرباح ووقف الخسارة بشكل غير صحيح',
    jsMessageReschicangdanbucunzai: 'إيصال المنصب غير موجود',
    zxkcje:'الحد الأدنى لمبلغ الافتتاح',
    },
    commisson:{
    dlzx:'مركز الوكالة',
    sjzl:'نظرة عامة على البيانات',
    quanbu:"الجميع",
    jinri:'اليوم',
    zuori:'أمس',
    ri:'يوم',
    wdfy:'خصم بلدي',
    wdsjfy:'خصم المستوى الثالث الخاص بي',
    dengji:'درجة',
    yqrs:'عدد الأشخاص المدعوين',
    tdrs:'حجم الفريق',
    yqxq:'تفاصيل الدعوة',
    yhm:"اسم المستخدم",
    cjsj:'وقت الخلق',
    fyxq:'تفاصيل الخصم',
    zyj:'العمولة الإجمالية',
    dryj:'عمولة يوم واحد',
    fyje:'مبلغ الخصم',
    
    },
    assets: {
    zongzichan: 'إجمالي الأصول',
    chongbi: 'إيداع العملات المعدنية',
    tibi: 'سحب العملات المعدنية',
    huazhuan: 'تحويل',
    fabizhanghu: 'الحساب الأساسي',
    heyuezhanghu: 'حساب العقد',
    jiayiliushui: 'تدفق المعاملات',
    dialogzichanhuazhuan: 'نقل الأصول',
    cong: 'من',
    dao: 'يصل',
    shuliang: 'كمية',
    qingshuruhuazhuanshuliang: 'الرجاء إدخال مبلغ التحويل',
    quanbu: 'الجميع',
    keyong: 'متاح',
    huanzhuan: 'تحويل',
    zijinhuazhuanchenggong: 'تم تحويل الأموال بنجاح',
    zijinhuazhuanshibai: 'فشل تحويل الأموال',
    bibizhanghu: 'حساب العملة',
    qiquanzhanghu:'حساب الخيارات',
    zczl:'نظرة عامة على الأصول',
    zzczh:'خصم إجمالي الأصول',
    ztzc:"الأصول في العبور",
    djzc:'تجميد الأصول',
    zyzc:'الأصول المرهونة',
    cwzc:'أصول الموقف',
    jyzh:'حساب التداول',
    bizhong:'عملة',
    dongjie:'تجميد',
    lirun:"ربح",
    hyzh:"حساب العقد",
    },
    basicsAccountRecord: {
    fanhui: 'يعود',
    fabizhanghuliushui: 'تدفق حساب التداول',
    fabijiaoyiliushuileixing: 'نوع تدفق المعاملات',
    qingxuanze: 'الرجاء التحديد',
    chaxunriqi: 'تاريخ الاستعلام',
    liushuileixing: 'نوع خط الأنابيب',
    shijian: 'وقت',
    biandongjine: 'تغيير المبلغ',
    yue: 'توازن',
    quanbu: 'الجميع',
    Sellbi: 'بيع العملات المعدنية',
    Buybi: 'شراء العملات المعدنية',
    tibi: 'سحب العملات المعدنية',
    chongbi: 'إيداع العملات المعدنية',
    fabizhuanheyue: 'عقد النقل الأساسي',
    heyuezhuanfabi: 'العقد على الأساس',
    zhuanzhang: 'تحويل',
    fabihuazhuandaoheyue: 'التحويل الأساسي للعقد',
    heyuehuazhuandaofabi: 'نقل العقد إلى القاعدة',
    goumaijijin: 'شراء تجمع التعدين',
    jijinshuhui: 'استرداد تجمع التعدين',
    jijinshouyi: 'دخل استرداد مجمع التعدين',
    fabizhuanqihuo: 'التحويل الأساسي إلى العقود الآجلة',
    qihuozhuanfabi: 'أساس نقل العقود الآجلة',
    fabizhuanganggu: 'التحويل الأساسي إلى أسهم هونج كونج',
    gangguzhuanfabi: 'أساسيات نقل الأسهم في هونغ كونغ',
    fabizhuanbibi: 'عملة التحويل الأساسية',
    bibizhuanfabi: 'أساسيات العملة لتحويل العملات',
    goumaiSGCB: 'شراء الطرح الأولي للعملة',
    chushouSGCB: 'بيع الطرح الأولي للعملة',
    huoqushibai: 'فشل الحصول عليها',
    zanwushuju: 'لا توجد بيانات حتى الآن',
    },
    contractAccountRecord: {
    fanhui: 'يعود',
    heyuezhanghuliushui: 'تدفق حساب العقد',
    heyuejiaoyiliushuileixing: 'نوع تدفق معاملة العقد',
    qingxuanze: 'الرجاء التحديد',
    chaxunriqi: 'تاريخ الاستعلام',
    liushuileixing: 'نوع خط الأنابيب',
    shijian: 'وقت',
    biandongjine: 'تغيير المبلغ',
    yue: 'توازن',
    quanbu: 'الجميع',
    kaicangshouxufei: 'رسوم الافتتاح',
    pingcangshouxufei: 'رسوم الإغلاق',
    pingcangyingkui: 'موقف إغلاق الربح والخسارة',
    fabizhuanheyue: 'عقد النقل الأساسي',
    heyuezhuanfabi: 'العقد على الأساس',
    zhuanzhang: 'تحويل',
    kaicangyongjin: 'لجنة الافتتاح',
    pingcangyongjin: 'عمولة الختام',
    huoqushibai: 'فشل الحصول عليها',
    zanwushuju: 'لا توجد بيانات حتى الآن',
    },
    recharge: {
    zanwushuju: 'لا توجد بيانات حتى الآن',
    shijian: 'وقت',
    leixing: 'يكتب',
    shuliang: 'كمية',
    zhuangtai: 'ولاية',
    caozuo: 'تعمل',
    chakanxiangqing: 'تحقق من التفاصيل',
    huoqushibai: 'فشل الحصول عليها',
    dialogtitle: 'تفاصيل',
    chongbi: 'إيداع العملات المعدنية',
    fanhui: 'يعود',
    lianmingcheng: 'اسم السلسلة',
    fuzhilianjie: 'انسخ العنوان',
    tishixinxi1: '• يرجى عدم إيداع أي أصول غير TRC_USDT أو ERC_USDT إلى العنوان أعلاه، وإلا فلن يتم استرداد الأصول',
    tishixinxi2: '• بعد إعادة الشحن إلى العنوان أعلاه، يلزم تأكيد عقدة الشبكة بالكامل، وسيتم استلام الحساب بعد تأكيد الشبكة مرة واحدة، ويمكن سحب العملة بعد تأكيدين للشبكة.',
    tishixinxi3: '• الحد الأدنى لمبلغ الإيداع: 50 دولارًا أمريكيًا، لن يتم إضافة الودائع التي تقل عن الحد الأدنى للمبلغ ولا يمكن استردادها',
    tishixinxi4: '• لن يتغير عنوان إعادة الشحن الخاص بك بشكل متكرر ويمكن إعادة شحنه بشكل متكرر، وفي حالة حدوث أي تغيير، سنبذل قصارى جهدنا لإعلامك من خلال إعلانات الموقع الإلكتروني أو رسائل البريد الإلكتروني',
    tishixinxi5: '• يرجى التأكد من أن جهاز الكمبيوتر والمتصفح الخاص بك آمنان لمنع التلاعب بالمعلومات أو تسريبها.',
    putongchongbi: 'الوديعة العادية',
    jiaoyichenggong: 'الصفقة ناجحة',
    jiaoyishibai: 'فشلت المعاملة',
    OMNIweihuzhong: 'سلسلة OMNI قيد الصيانة، يرجى تحديد TRC20',
    },
    withdraw: {
    fanhui: 'يعود',
    zanwushuju: 'لا توجد بيانات حتى الآن',
    tibi: 'سحب العملات المعدنية',
    huoqushibai: 'فشل الحصول عليها',
    OMNIweihuzhong: 'سلسلة OMNI قيد الصيانة، يرجى تحديد TRC20',
    shijian: 'وقت',
    leixing: 'يكتب',
    shuliang: 'كمية',
    zhuangtai: 'ولاية',
    caozuo: 'تعمل',
    chakanxiangqing: 'تحقق من التفاصيل',
    putongtibi: 'الانسحاب العادي',
    dialogtitle: 'تفاصيل',
    dialogtitlewithdraw: 'سحب العملات المعدنية',
    houchongxinfasong: 'إعادة الإرسال بعد S',
    huoquyouxiangyanzhengma:'رمز التحقق',
    lianmingcheng: 'اسم السلسلة',
    tibidizhi: 'عنوان السحب',
    qingshurutibidizhi: 'الرجاء إدخال عنوان السحب',
    tibishuliang: 'كمية العملات المسحوبة',
    shouxufei: 'رسوم المناولة',
    qingshurutibishuliang: 'الرجاء إدخال مبلغ السحب',
    quanbu: 'الجميع',
    keyong: 'متاح',
    daozhangshuliang: 'وصول الكمية',
    zuixiaotibishuliang: 'الحد الأدنى لمبلغ السحب هو:',
    tishixinxi: 'من أجل ضمان أمان الأموال، عندما تتغير سياسة أمان حسابك أو يتم تعديل كلمة المرور الخاصة بك، سنقوم بإجراء مراجعة يدوية للسحب. يرجى التحلي بالصبر والانتظار حتى يتصل بك الموظفون عبر الهاتف أو البريد الإلكتروني. يرجى التأكد من أن جهاز الكمبيوتر والمتصفح الخاص بك آمنان لمنع التلاعب بالبيانات أو تسريبها.',
    tuxingyanzhengma: 'رمز التحقق الرسومي',
    qingshurutuxingyanzhengma: 'الرجاء إدخال رمز التحقق الرسومي',
    youxiangyanzhengma: 'رمز التحقق من البريد الإلكتروني',
    queding: 'بالتأكيد',
    tijiaozhong: 'تقديم',
    yijujue: 'مرفوض',
    yitongguo: 'اجتاز',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'فشل الحصول على رمز التحقق الرسومي، يرجى الطلب مرة أخرى',
    huoquyouxiangyanzhengmashibai: 'فشل في الحصول على رمز التحقق من البريد الإلكتروني،',
    qingxianwanchengyouxiangrenzheng: 'يرجى إكمال التحقق من البريد الإلكتروني أولاً',
    huoquyouxiangshibaiqingchongxinqingqiu: 'فشل الحصول على عنوان البريد الإلكتروني، يرجى الطلب مرة أخرى',
    qingshuruyouxiangyanzhengma: 'الرجاء إدخال رمز التحقق من البريد الإلكتروني',
    youxiangyanzhengmacuowu: 'رمز التحقق من البريد الإلكتروني خاطئ،',
    qxsmrz:'يرجى التحقق من اسمك الحقيقي أولا',
    tishi:'تَلمِيح',
    queding:'بالتأكيد',
    },
    contractTransactionRecord: {
    fanhui: 'يعود',
    zanwushuju: 'لا توجد بيانات حتى الآن',
    heyuedingdan: 'أمر العقد',
    qingxuanze: 'الرجاء التحديد',
    quanbu: 'الجميع',
    kaiduo: 'مفتوح لفترة طويلة',
    pingkong: 'السماء المسطحة',
    kaikong: 'فتح قصيرة',
    pingduo: 'بيندو',
    xianjiaweituo: 'أمر الحد',
    shijiaweituo: 'نظام السوق',
    suoyouzhuangtai: 'كل الوضع',
    tijiaoshibai: 'فشل التقديم',
    zhunbeitijiao: 'على استعداد لتقديم',
    yitijiao: 'مُقَدَّم',
    bufentijiao: 'صفقة جزئية',
    bufentijiaoyichedan: 'تم إلغاء بعض الطلبات',
    quanbuchengjiao: 'كل الحركات المالية',
    yichedan: 'تم إلغاء الطلب',
    huoqushibai: 'فشل الحصول عليها',
    heyuedaima: 'رمز العقد',
    ganggan: 'رافعة',
    xiadanshijian: 'وقت الطلب',
    chengjiaoshijian: 'وقت الصفقة',
    fangxiang: 'اتجاه',
    baojialeixing: 'نوع الاقتباس',
    weituoliang: 'مبلغ العمولة (قطعة)',
    weituojia: 'سعر الأمر (USDT)',
    chengjiaoliang: 'حجم التداول (التذاكر)',
    chengjiaojunjia: 'متوسط ​​سعر المعاملة (USDT)',
    yingkui: 'الربح والخسارة (USDT)',
    shouxufei: 'رسوم المناولة (USDT)',
    zhuangtai: 'ولاية',
    gupiaodingdan: 'ترتيب الأسهم',
    waihuidingdan: 'أوامر الفوركس',
    gupiaodaima: 'رمز المخزون',
    waihuidaima: 'كود الفوركس',
    },
    kLine: {
    zhibiao: 'فِهرِس',
    shezhi: 'يثبت',
    quanping: 'ملء الشاشة',
    tuichuquanping: 'الخروج من وضع ملء الشاشة',
    jishuzhibiao: 'المؤشرات الفنية',
    futuzhibiao: 'مؤشر الصورة الفرعية',
    zhutuzhibiao: 'مؤشر الرسم البياني الرئيسي',
    huifumoren: 'استعادة الافتراضي',
    lazhutuleixing: 'أنواع الشمعدان',
    qingxuanze: 'الرجاء التحديد',
    quanshixin: 'كلها صلبة',
    quankongxin: 'جوفاء تماما',
    zhangkongxin: 'ارتفاع جوفاء',
    diekongxin: 'تقع جوفاء',
    mianjitu: 'مخطط المنطقة',
    jiagezhouleixing: 'نوع محور السعر',
    xianxingzhou: 'محور الخط',
    baifenbizhou: 'محور النسبة المئوية',
    duishuzhou: 'المحور اللوغاريتمي',
    zuigaojiaxianshi: 'عرض أعلى سعر',
    zuidijiaxianshi: 'عرض أقل الأسعار',
    zuixinjiaxianshi: 'عرض أحدث الأسعار',
    zhibiaozuixinzhixianshi: 'يتم عرض أحدث قيمة للمؤشر',
    daozhizuobiao: "الإحداثيات المقلوبة",
    wanggexianxianshi: 'عرض خط الشبكة',
    hfcg:'تم الاسترداد بنجاح'
    },
    SecurityCenter: {
    fanhui: 'يعود',
    anquanzhongxin: 'مركز الأمن',
    qingshurunicheng: 'الرجاء إدخال اللقب',
    nichengxiugaishibai: 'فشل تعديل اللقب',
    huoquyonghuxinxishibai: 'فشل في الحصول على معلومات المستخدم',
    xiugaidenglumima: 'تغيير كلمة المرور لتسجيل الدخول',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'فشل الحصول على رمز التحقق الرسومي، يرجى الطلب مرة أخرى',
    qingshurujiumima: 'الرجاء إدخال كلمة المرور القديمة',
    qingshuruxinmima: 'الرجاء إدخال كلمة المرور الجديدة',
    qingzaicishuruxinmima: 'الرجاء إدخال كلمة المرور الجديدة مرة أخرى',
    qingshurutuxingyanzhengma: 'الرجاء إدخال رمز التحقق الرسومي',
    qingshuruyanzhengma: 'الرجاء إدخال رمز التحقق',
    huoquzhuceshoujihaoshibai: 'فشل الحصول على رقم الجوال المسجل',
    qingxianwanchengshoujirenzheng: 'يرجى إكمال مصادقة الهاتف المحمول أولاً',
    huoqushoujiyanzhengmashibai: 'فشل في الحصول على رمز التحقق من الهاتف المحمول',
    huoqushoujiyanzhengma: 'الحصول على رمز التحقق من الهاتف المحمول',
    houchongxinfasong: 'إعادة الإرسال بعد S',
    mimacuowu: 'كلمة مرور خاطئة',
    liangcixinmimashurubuyizhiqingjiancha: 'كلمتا المرور الجديدتين اللتين تم إدخالهما غير متطابقتين، يرجى التحقق',
    xinmimaheyuanmimabunengyiyang: 'لا يمكن أن تكون كلمة المرور الجديدة هي نفس كلمة المرور الأصلية',
    qingshurushoujiyanzhengma: 'الرجاء إدخال رمز التحقق للهاتف المحمول',
    yanzhengmacuowu: 'خطأ في رمز التحقق',
    denglumimaxiugaichenggong: 'تم تغيير كلمة المرور لتسجيل الدخول بنجاح',
    denglumimaxiugaishibai: 'فشل تعديل كلمة المرور لتسجيل الدخول',
    xiugaizijinmima: 'تغيير كلمة مرور الصندوق',
    qingshuruzijinmima: 'الرجاء إدخال كلمة المرور للصندوق',
    qingzaicishuruzijinmima: 'الرجاء إدخال كلمة مرور الصندوق مرة أخرى',
    liangcizijinmimabuyizhiqingjiancha: 'كلمتا مرور الصندوقين غير متناسقتين، يرجى التحقق',
    shezhizijinmimachenggong: 'تم ضبط كلمة مرور الصندوق بنجاح',
    shezhizijinmimashibai: 'فشل في تعيين كلمة مرور الصندوق',
    xiugaizijinmimachenggong: 'تم تعديل كلمة مرور الصندوق بنجاح',
    xiugaizijinmimashibai: 'فشل في تغيير كلمة مرور الصندوق',
    youxiangrenzheng: 'مصادقة البريد الإلكتروني',
    huoquyouxiangyanzhengma: 'رمز التحقق',
    qingshuruyouxiangdizhi: 'الرجاء إدخال عنوان البريد الإلكتروني',
    huoquyouxiangyanzhengmashibai: 'فشل في الحصول على رمز التحقق من البريد الإلكتروني',
    qingshuruyouxiangyanzhengma: 'الرجاء إدخال رمز التحقق من البريد الإلكتروني',
    youxiangyanzhengchenggong: 'تم التحقق من البريد الإلكتروني بنجاح',
    weirenzheng: 'غير معتمد',
    queding: 'بالتأكيد',
    anquanshezhiguanli: 'إدارة إعدادات الأمان',
    denglumima: 'كلمة مرور تسجيل الدخول',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'من خلال تعيين كلمة مرور تسجيل الدخول، ستتمكن من تسجيل الدخول مباشرة باستخدام كلمة مرور حسابك',
    xiugai: 'يراجع',
    zijinmima: 'كلمة مرور الصندوق',
    yongyubaohuzijinanquan: 'تستخدم لحماية سلامة الأموال',
    shezhi: 'يثبت',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'يستخدم للتحقق الأمني ​​عند تسجيل الدخول وسحب العملات المعدنية واسترجاع كلمات المرور وتعديل إعدادات الأمان.',
    yirenzheng: 'معتمد',
    renzheng: 'شهادة',
    shimingrenzheng: 'مصادقة الاسم الحقيقي',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'يستخدم للمصادقة الأمنية عند شراء وبيع وسحب وإيداع ونقل العملات المعدنية.',
    daishenhe: 'في انتظار المراجعة',
    yibohui: 'مرفوض',
    yuandenglumima: 'كلمة المرور الأصلية لتسجيل الدخول',
    xinmima: 'كلمة المرور الجديدة',
    quedingxinmima: 'تأكيد كلمة المرور الجديدة',
    tuxingyanzhengma: 'رمز التحقق الرسومي',
    yanzhengma: 'رمز التحقق',
    zijinmima: 'كلمة مرور الصندوق',
    querenzijinmima: 'تأكيد كلمة مرور الصندوق',
    youxiangdizhi: 'عنوان البريد الإلكتروني',
    qingxianwanchengyouxiangrenzheng: 'يرجى إكمال التحقق من البريد الإلكتروني أولاً',
    xiugaitouxiangchenggong: 'تم تعديل الصورة الرمزية بنجاح',
    xiugaitouxiangshibai: 'فشل تعديل الصورة الرمزية',
    shangchuantouxiangzhinengshijpggeshi: 'يمكن تحميل الصور الرمزية بتنسيق JPG فقط!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'لا يمكن أن يتجاوز حجم الصورة الرمزية التي تم تحميلها 2 ميغابايت!',
    },
    verification: {
    fanhui: 'يعود',
    shimingrenzheng: 'مصادقة الاسم الحقيقي',
    qingshuruxingming: 'الرجاء إدخال الاسم',
    qingshuruzhengjianhaoma: 'الرجاء إدخال رقم الهوية الخاص بك',
    huoquyonghuxinxishibai: 'فشل في الحصول على معلومات المستخدم',
    wenjiangeshicuowu: 'خطأ في تنسيق الملف',
    wenjianbunengdayu5M: 'لا يمكن أن يكون حجم الملف أكبر من 5M',
    qingtianxiexingming: 'يرجى ملء اسمك',
    qingtianxiezhengjianhaoma: 'الرجاء تعبئة رقم الهوية',
    qingshangchuanzhengjianzhengmian: 'يرجى تحميل الجانب الأمامي من بطاقة الهوية الخاصة بك',
    qingshangchuanzhengjianfanmian: 'يرجى تحميل الجانب الخلفي من بطاقة الهوية الخاصة بك',
    tijiaochenggong: 'تم التقديم بنجاح',
    kaiqishenfenrenzheng: 'قم بتشغيل مصادقة الهوية',
    bohuiyuanyin: 'سبب الرفض',
    qingtianxiejiashizhizhaogongminkahuzhao: 'يرجى ملء معلومات رخصة القيادة / بطاقة المواطن / جواز السفر الخاصة بك',
    xingming: 'اسم',
    zhengjianhaoma: 'رقم الهوية',
    qingshangchuanjiashizhizhaogongminkahuzhao: 'يرجى تحميل معلومات رخصة القيادة/بطاقة المواطن/جواز السفر',
    shangchuanzhengjianzhengmian: 'قم بتحميل الجزء الأمامي من بطاقة الهوية',
    huotuozhuafangru: 'أو السحب والإفلات',
    shangchuanzhengjianfanmian: 'قم بتحميل الجانب الخلفي من الشهادة',
    tijiao: 'يُقدِّم',
    },
    invite: {
    yaoqinghaoyoudejiangli: 'قم بدعوة الأصدقاء واحصل على المكافآت',
    yongjiukede: 'متاح بشكل دائم',
    jiaoyifanyong: 'خصم التداول',
    fasongyaoqinggeihaoyou: 'إرسال دعوة إلى الأصدقاء',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'قم بدعوة الأصدقاء للتسجيل في BCFX من خلال مشاركة الرابط',
    haoyouzhuce: 'تسجيل صديق',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'يقبل الصديق الدعوة ويكمل التسجيل ويجري المعاملات',
    huodexiangyingbilijiangli: 'احصل على المكافآت النسبية المقابلة',
    qingsonghuodejiaoyishouxufeijianglifuli: 'احصل بسهولة على فوائد مكافأة رسوم المعاملات',
    wodezhuanshufenxiangfangshi: 'طريقتي الحصرية للمشاركة',
    yaoqinglianjie: 'رابط الدعوة',
    jiazaizhong: 'تحميل...',
    fuzhilianjie: 'نسخ الوصلة',
    yaoqingma: 'رمز الدعوة',
    fuzhiyaoqingma: 'انسخ رمز الدعوة',
    wodeyaoqing: 'دعوتي',
    yaoqingrenshu: 'عدد الأشخاص المدعوين',
    huodejiangli: 'احصل على المكافأة',
    huodongxize: 'تفاصيل الحدث',
    tips1: 'العلاقة بين مستخدمي المنصة هي ثلاثة مستويات، مثل: يشكل مستخدمو ABCD ثلاثة مستويات من المستخدمين، وA هو أعلى مستوى!',
    tips2: 'علاقات المصالح بين المستويات',
    tips201: '.',
    tips3: 'المكافأة النقدية: يمكن للعميل "أ" الحصول على 40% من رسوم معاملة عقد العميل "ب" + 20% من رسوم معاملة عقد العميل "ج" + 10% من رسوم معاملة عقد العميل "د"',
    tips4: 'على سبيل المثال: العميل "أ" لديه 10 عملاء من المستوى الأول "ب"، و10 عملاء من المستوى الثاني "ج"، و10 عملاء من المستوى الثالث "د". إذا كان عقد كل عميل يتداول بمبلغ 100000 USDT',
    tips5: 'ثم العائد الذي يمكن أن يحصل عليه العميل "أ".',
    tips6: '100000*1.2%*40%*10+100000*1.2%*20%*10+100000*1.2%*10%*10=8400 USDT مكافأة نقدية، والتي يمكن سحبها في الوقت الحقيقي',
    tips7: 'إذا تم تعديل النشاط، فسوف يخضع لتحديثات منصة BCFX، ويعود حق التفسير النهائي إلى BCFX.',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'فشل الحصول على رمز التحقق الرسومي، يرجى الطلب مرة أخرى',
    qingshuruyouxiang: 'الرجاء إدخال البريد الإلكتروني الخاص بك',
    qingshurutuxingyanzhengma: 'الرجاء إدخال رمز التحقق الرسومي',
    qingshuruyanzhengma: 'الرجاء إدخال رمز التحقق',
    qingshurunindemima: 'الرجاء إدخال كلمة المرور الخاصة بك',
    qingzaicishurunindemima: 'الرجاء إدخال كلمة المرور الخاصة بك مرة أخرى',
    qingshuruzhifumima: 'الرجاء إدخال كلمة مرور الدفع',
    xuantian: 'خياري',
    bitian: 'مطلوب',
    yonghuzhuce: 'تسجيل المستخدم',
    huanyingdenglu: 'مرحبا بكم في تسجيل الدخول',
    guanfangwangzhan: 'الموقع الرسمي',
    xiangmumingcheng: 'بي سي إف إكس',
    youxiang: 'بريد',
    tuxingyanzhengma: 'رمز التحقق الرسومي',
    yanzhengma: 'رمز التحقق',
    huoquyanzhengma: 'رمز التحقق',
    shezhimima: 'تعيين كلمة المرور',
    quedingmima: 'تأكيد كلمة المرور',
    zhifumima: 'كلمة مرور الدفع',
    yaoqingma: 'رمز الدعوة',
    zhuce: 'يسجل',
    yiyouzhanghao: 'لديك حساب بالفعل؟',
    denglu: 'تسجيل الدخول',
    qingshuruyouxiangdizhi: 'الرجاء إدخال عنوان البريد الإلكتروني',
    huoqushibai: 'فشل الحصول عليها',
    houchongxinfasong: 'إعادة الإرسال بعد S',
    liangcishurudemimabuyizhi: 'كلمات المرور التي تم إدخالها مرتين غير متناسقة',
    zhucechenggong: 'تم التسجيل بنجاح',
    zhuceshibai: 'فشل التسجيل',
    },
    treasure:{
    jichubizhong: 'العملة الأساسية',
    shouyibizhong: 'عملة الإيرادات',
    wakuangtianshu: 'أيام التعدين',
    shouyitianshu: 'أيام الكسب',
    suodingtianshu: 'أيام القفل',
    yichanshengshouyi: 'الإيرادات المتولدة',
    yuqishouyi: 'العودة المتوقعة',
    kaishishijian: 'وقت البدء',
    jieshushijian: 'وقت النهاية',
    qingshuruninyaosuodingdebizhongshuliang: 'يرجى إدخال مبلغ USDT بالعملة التي تريد قفلها',
    huoqushibai: 'فشل الحصول عليها',
    meiyitian: 'كل يوم واحد',
    dakai: 'يفتح',
    yuanyuzhoukuangchi: 'تجمع ميتافيرس للتعدين',
    jilu: 'سِجِلّ',
    kuangchixiangqing: 'تفاصيل تجمع التعدين',
    suo: 'قفل',
    yitianchanchu: '1 يوم الإخراج',
    zuixiaosuodingshijian: 'الحد الأدنى لوقت القفل',
    nindeUSDT: 'USDT الخاص بك',
    tian: 'سماء',
    quanbu: 'الجميع',
    suoUSDTbingkaishishengchan: 'قفل USDT وابدأ الإنتاج',
    ninquedingyaosuoUSDTkaishishengchanma: 'هل أنت متأكد أنك تريد قفل USDT وبدء الإنتاج؟',
    tishi: 'تَلمِيح',
    queding: 'بالتأكيد',
    quxiao: 'يلغي',
    kaishishengchan: 'ابدأ الإنتاج!',
    yiquxiao: 'تم الإلغاء',
    },
    subscription:{
    rengouliebiao:'قائمة الاشتراك',
    jijiangdaolai:'قريباً',
    jinxingzhong:'في تَقَدم',
    quanbu:'الجميع',
    keshiyonghuobi:"العملات المتاحة",
    zongshuliang:'الكمية الإجمالية',
    meiyouxianzhi:'لا يوجد حد',
    shengyushuliang:'الكمية المتبقية',
    kaishishijian:'وقت البدء',
    zhuangtai:'ولاية',
    jilu:'سِجِلّ',
    zanwujilu:"لا يوجد سجل حتى الآن",
    leixing:'يكتب',
    shuliang:'كمية',
    goumaijine:'مبلغ الشراء',
    shijian:'وقت',
    goumai:'يشتري',
    duichu:'من العدم',
    jiangli:'جائزة',
    tibi:'سحب العملات المعدنية',
    huidaomulu:'العودة إلى جدول المحتويات',
    dangqianchiyou:'المنعقد حاليا',
    keyong:'متاح',
    keduihuan:'قابلة للاسترداد',
    shishihangqing:'اقتباسات في الوقت الحقيقي',
    yue:'توازن',
    biaoti:'عنوان',
    fashoushuliang:'كمية للبيع',
    jindu:"جدول",
    goumaishuliang:'كمية الشراء',
    ge:'فردي',
    woyiyuedu:'لقد قرأت',
    daibigoumaixieyi:'اتفاقية شراء الرمز المميز',
    qingshurugoumaijine:'الرجاء إدخال مبلغ الشراء',
    shifoyaogoumai:'هل تريد الشراء',
    tishi:'تَلمِيح',
    queding:'بالتأكيد',
    quxiao:'يلغي',
    yjs:'انتهى',
    ywc:'مكتمل',
    sgjg:'سعر الاشتراك',
    jssj:"وقت النهاية",
    zqsl:"الكمية الفائزة",
    zqje:"مبلغ الفوز",
    tian:"سماء",
    shi:'ساعة',
    fen:'نقطة',
    miao:'ثانية',
    shengou:'الاشتراك',
    xmxq:'تفاصيل المشروع',
    },
    }