export default {
    public:{
        zhd:"meilleur",
        qsjyh:"Utilisateurs partout dans le monde",
        gtdxz:'choix commun',
        szhbjyytz:'Opportunités et défis de la monnaie numérique',
        xsddcf:'Opportunités et défis en matière de richesse dans la nouvelle ère',
        yzsjypt:"Plateforme de trading à guichet unique",
        mskljyzh:'Ouvrez un compte de trading et commencez à trader maintenant',
        jyhg:"Conformité commerciale",
    },

    header: {
    hello: 'Bonjour le monde!',
    changeLanguage: 'changer de langue',
    Login: 'Se connecter',
    Register: 'registre',
    Logout: 'Se déconnecter',
    shouye: 'première page',
    bibijiaoyisuo: 'Trading de crypto-monnaie',
    heyuejiaoyisuo: 'négociation de contrats',
    licaichanpin: 'Piscine minière',
    rengou: 'Abonnement',
    ruanjianxiazai: 'Téléchargement de logiciel',
    zhongwen: 'Chinois',
    yingwen: 'Dans',
    zichan: 'actifs',
    dingdan: 'Commande',
    heyuedingdan: 'commande contractuelle',
    bibidingdan: 'Commande de pièces',
    anquanzhongxin: 'Centre de sécurité',
    fanyongyaoqing: 'Invitation à la Commission',
    tuichudenglu: 'Se déconnecter',
    gupiaojiaoyi: 'négociation d\'actions',
    waihuijiaoyi: 'Trading sur le Forex',
    qiquanjiaoyi:'négociation d\'options',
    gupiaodingdan: 'commande de stock',
    waihuidingdan: 'Ordres Forex',
    qiquandingdan: 'ordre d\'options',
    jiaoyidingdan:'ordre de négociation',
    daolizhongxin:'Centre d\'agence',
    huodong:'Activité',
    },
    home: {
    huanyinglaidao: 'bienvenue à',
    pingtaimingcheng: 'ici',
    mengxiangqihang: 'Les rêves mettent les voiles',
    yijiankaiqishuzihualvcheng: 'Commencez votre voyage numérique en un clic',
    liaojiegengduo: 'apprendre encore plus',
    chongzhi: 'recharger',
    jiaoyi: 'commerce',
    remenbang: 'Liste populaire',
    zhangfubang: 'Liste des gagnants',
    diefubang: 'Liste des perdants',
    chengjiaoliang: 'Volume',
    zanwushuju: 'Aucune donnée pour l\'instant',
    jiayidui: 'paire de trading',
    zuixinjiage: 'dernier prix',
    zhangdie24h: '24 heures de montée et de descente',
    caozuo: 'fonctionner',
    jiaoyi: 'commerce',
    gengduo: 'Plus',
    suishisuidikaiqijiaoyi: 'Commencez à trader à tout moment et en tout lieu',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'Qu\'il s\'agisse d\'une application ou d\'un Web, vous pouvez démarrer rapidement votre transaction',
    saomaxiazaiApp: 'Scannez le code QR pour télécharger l\'application',
    iosheAndroid: 'iOS et Android',
    ninkexinrenqieanquandejiamibijiaoyisuo: 'Votre échange de crypto-monnaie fiable et sécurisé',
    tishi1: 'Certificat de réserve',
    xiangqing1: 'Nous promettons de conserver tous les actifs des utilisateurs au moins dans un ratio de 1:1',
    tishi2: 'Meilleurs taux de transactions',
    xiangqing2: 'Tarifs de transaction préférentiels, droits VIP compétitifs et profitez de services de la meilleure qualité',
    tishi3: 'Une sécurité sur laquelle vous pouvez compter',
    xiangqing3: 'Nos mesures de sécurité sophistiquées et nos fonds SAFU protègent vos actifs numériques de tous les risques',
    tishi4: 'Service client 24h/24 et 7j/7',
    xiangqing4: '24 heures sur 24, 7 jours sur 7, mode de fonctionnement à temps plein, répondez à vos demandes pertinentes dans les plus brefs délais',
    tishi5: 'Stable et sûr',
    xiangqing5: 'La première bourse mondiale offre une protection de sécurité de niveau bancaire pour vos actifs',
    tishi6: 'FAQ',
    xiangqing6: 'Consultez la FAQ pour des descriptions détaillées de fonctionnalités spécifiques',
    fanhui:"retour",
    shishihangqing:'Cotations en temps réel',
    },
    footer: {
    bangzhuzhongxin: 'Centre d\'aide',
    dianhuazixun: 'Consultation téléphonique',
    dianhuahegongzuoshijian: '1670-9756 (10h00 ~ 19h00 en semaine/fermé le week-end et les jours fériés)',
    liaotianzixun: 'Consultation par chat 1:1',
    keketuozixun: 'Conseil Cocotok',
    zaixianbangzhu: 'Aide en ligne',
    h24kaifang: '(ouvert 24 heures)',
    lixianbangzhu: 'Aide hors ligne',
    gongsidizhi: '1F, Sommet Yongsan Purgio, 69 Hangang-gil, Yongsan-gu, Séoul',
    gongzuoshijian: '(10h00~18h00 en semaine / fermé le week-end et les jours fériés)',
    shangshizixun: 'Consultation d\'annonce',
    hezuozixun: 'Consultation de coopération',
    gongsi: 'entreprise',
    lianxiwomwn: 'Contactez-nous',
    zhidao: 'guide',
    yonghuzhichi: 'Assistance aux utilisateurs',
    zhanghuzhinan: 'Guide de compte-Dépôt/Retrait',
    ruanjianxiazai: 'Téléchargement de logiciel',
    zhengce: 'politique',
    fuwuyuyinsitiaokuan: 'Conditions d\'utilisation et confidentialité',
    },
    login: {
    yonghudenglu: 'Connexion utilisateur',
    huanyingdenglu: 'Bienvenue pour vous connecter',
    xiangmumingcheng: 'BCFX',
    guanfangwangzhan: 'site officiel',
    youxiang: 'Mail',
    qingshuruyouxiang: 'Veuillez entrer votre email',
    mima: 'mot de passe',
    qingshurumima: 'Veuillez entrer le mot de passe',
    wangjimima: 'oublié le mot de passe ?',
    denglu: 'Se connecter',
    haimeiyouzhanghao: 'Vous n\'avez pas encore de compte ? aller',
    zhuce: 'registre',
    dengluchenggong: 'Connexion réussie',
    denglushibai: 'La connexion a échoué',
    qingshuruyonghuminghemima: 'Veuillez entrer votre nom d\'utilisateur et votre mot de passe',
    mimachangdubunengxiaoyuliuwei: 'La longueur du mot de passe ne peut pas être inférieure à 6 caractères',
    youxiangrenzheng: 'Vérification des e-mails',
    youxiangdizhi: 'Adresse email',
    qingshuruyouxiangdizhi: 'Veuillez entrer l\'adresse e-mail',
    tuxingyanzhengma: 'Code de vérification graphique',
    qingshurutuxingyanzhengma: 'Veuillez saisir le code de vérification graphique',
    yanzhengma: 'Le code de vérification',
    qingshuruyouxiangyanzhengma: 'Veuillez saisir le code de vérification de l\'e-mail',
    huoquyouxiangyanzhengma: 'Obtenir le code de vérification par e-mail',
    queding: 'Bien sûr',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Échec de l\'obtention du code de vérification graphique, veuillez demander à nouveau',
    qingxianwanchengyouxiangrenzheng: 'Veuillez d\'abord effectuer la vérification de l\'e-mail',
    huoquyouxiangyanzhengmashibai: 'Impossible d\'obtenir le code de vérification de l\'e-mail,',
    houchongxinfasong: 'Renvoyer après S',
    youxiangyanzhengchenggong: 'Vérification de l\'e-mail réussie',
    yanzhengmacuowu: 'Erreur de code de vérification',
    xiugaidenglumima: 'Changer le mot de passe de connexion',
    xinmima: 'Nouveau mot de passe',
    quedingxinmima: 'Confirmer le nouveau mot de passe',
    qingshuruxinmima: 'Veuillez entrer un nouveau mot de passe',
    qingzaicishuruxinmima: 'Veuillez saisir à nouveau le nouveau mot de passe',
    liangcimimashurubuyizhi: 'Les deux entrées de mot de passe sont incohérentes',
    mimaxiugaichenggong: 'Mot de passe modifié avec succès',
    xiugaimimashibai: 'Échec de la modification du mot de passe',
    },
    trade: {
    hangqingheyue: 'contracter',
    hangqingzuixinjia: 'dernier prix',
    hangqingzhangdiefu: 'Augmenter ou diminuer',
    hangqingzuigao24h: '24H maximum',
    hangqingzuidi24h: '24H minimum',
    hangqingchengjiaoliang24h: '24H Volume',
    hangqingchengjiabishu: 'Transactions',
    zhanghuquanyi: 'Droits de compte',
    zhanghukeyongshouyi: 'Avantages disponibles',
    hangqingweituoliebiao: 'Liste de commandes',
    hangqingjiage: 'prix',
    hangqingshuliang: 'quantité',
    hangqingzuixinchengjiao: 'dernière transaction',
    hangqingjiage1: 'prix',
    hangqingshuliang1: 'quantité',
    hangqingshijian: 'temps',
    dingdanchiyoucangwei: 'Occuper un poste',
    dingdandangqianweituo: 'Commande en cours',
    dingdanchengjiaojilu: 'Enregistrement des transactions',
    dingdantiaojiandan: 'ordonnance conditionnelle',
    chicangheyue: 'contracter',
    chicangkaicangjunjia: 'Prix ​​de position',
    chicangfudongyingkui: 'Profits et pertes',
    chicanggangganbeishu: 'Effet de levier',
    chicangchicangliang: 'Poste(s)',
    chicangduokong: 'Longue/Courte',
    chicangduo: 'beaucoup',
    chicangkong: 'nul',
    chicangzhiyingjiage: 'Prendre le prix du profit',
    chicangzhisunjiage: 'Prix ​​​​stop loss',
    chicangqiangpingyugujiage: 'Prix ​​de liquidation estimé',
    chicangcaozuo: 'fonctionner',
    chicangzhiyingzhisun: 'Prenez des bénéfices et stoppez les pertes',
    chicangpingcang: 'Fermer',
    chicangshouxufei: 'frais de traitement',
    weituoheyue: 'contracter',
    weituoshuliang: 'Quantité commandée',
    weituojiage: 'Prix ​​de commission',
    weituofangxiang: 'direction',
    weituogangganbeishu: 'Tirer parti de plusieurs',
    weituochengjiaoshuliang: 'Quantité de transaction',
    weituoshouxufei: 'frais de traitement',
    weituochengjiaojunjia: 'Prix ​​moyen',
    weituoshouyi: 'revenu',
    weituozhuangtai: 'État',
    weituochengjiaoshijian: 'Le temps',
    weituocaozuo: 'fonctionner',
    weituochedan: 'Annuler la commande',
    weituotijiaoshibai: 'Échec de la soumission',
    weituozhilingtijiao: 'Soumission des instructions',
    weituoyitijiao: 'Soumis',
    weituobufenchengjiao: 'Accord partiel',
    weituobufenchengjiaoyichedan: 'Certaines commandes ont été annulées',
    weituowanquanchengjiao: 'Accord complet',
    weituowanquanchedan: 'Annuler complètement la commande',
    weituochedanzhong: 'Annulation de la commande',
    weituokaiduo: 'Ouvert longtemps',
    weituopingkong: 'ciel plat',
    weituokaikong: 'Ouvert court',
    weituopingduo: 'Pinduo',
    chengjiaoheyue: 'contracter',
    chengjiaofangxiang: 'direction',
    chengjiaoshuliang: 'Quantité de transaction',
    chengjiaojiage: 'prix de transaction',
    chengjiaoshijian: 'Le temps',
    chengjiaopingcangyingkui: 'Profits et pertes',
    chengjiaoshouxufei: 'Frais de transaction',
    chengjiaokaiduo: 'Ouvert longtemps',
    chengjiaopingkong: 'ciel plat',
    chengjiaokaikong: 'Ouvert court',
    chengjiaopingduo: 'Pinduo',
    tiaojianzengjiatiaojian: 'Ajouter des conditions',
    tiaojianzantingquanbu: 'tout mettre en pause',
    tiaojianqidongquanbu: 'Commencez tout',
    tiaojianshanchuquanbu: 'Supprimer tout',
    tiaojianheyue: 'contracter',
    tiaojianweituoshuliang: 'Quantité commandée',
    tiaojianfangxiang: 'direction',
    tiaojiantiaojianleixing: 'type de condition',
    tiaojianjiageleixing: 'type de prix',
    tiaojianbijiaofuhao: 'Symbole de comparaison',
    tiaojiantiaojian: 'condition',
    tiaojianzhuangtai: 'État',
    tiaojianchuangjianshijian: 'temps de création',
    tiaojiancaozuo: 'fonctionner',
    tiaojianxiugai: 'Réviser',
    tiaojianqidong: 'démarrer',
    tiaojianzanting: 'pause',
    tiaojianshanchu: 'supprimer',
    tiaojianlijixiadan: 'Commandez maintenant',
    tiaojiankaiduo: 'Ouvert longtemps',
    tiaojianpingkong: 'ciel plat',
    tiaojiankaikong: 'Ouvert court',
    tiaojianpingduo: 'Pinduo',
    tiaojianjiagetiaojian: 'conditions de prix',
    tiaojianshijiantiaojian: 'état de temps',
    tiaojianzuixinjia: 'dernier prix',
    tiaojianByijia: 'acheter un prix',
    tiaojianSyijia: 'Vendre un prix',
    tiaojianzanting: 'pause',
    tiaojianyunxing: 'courir',
    tiaojianyiwancheng: 'Complété',
    tiaojianshibai: 'échouer',
    tiaojianyishanchu: 'Supprimé',
    tiaojianshoudongchufawancheng: 'Déclenchement manuel terminé',
    tiaojianshoudongchufashibai: 'Le déclenchement manuel a échoué',
    xiadankaicang: 'Ouvrir',
    xiadanpingcang: 'Fermer la position',
    xiadanweituoleixing: 'taper',
    xiadanqingxuanze: 'Veuillez sélectionner',
    xiadanshijia: 'prix du marché',
    xiadanxianjia: 'limite de prix',
    xiadanjiage: 'prix',
    xiadanqingshurujiage: 'Veuillez entrer le prix',
    xiadanshuliang: 'quantité',
    xiadanqingshurushuliang: 'Veuillez entrer la quantité',
    xiadanzhang: 'ouvrir',
    xiadankekaishuliang: 'Quantité disponible',
    xiadanqingxianxuanzechicangdan: 'Veuillez d\'abord sélectionner l\'ordre des positions',
    xiadankepingshuliang: 'Quantité pouvant être nivelée',
    xiadansuoxubaozhengjin: 'Marge requise :',
    xiadanshijiazhiyingzhisunyushe: 'Prix ​​du marché, prise de profit et stop loss prédéfinis',
    xiadanyujiqiangpingjiage: 'Prix ​​de liquidation estimé :',
    xiadanqiangpingjiagetishi: 'Ce prix de liquidation attendu est calculé dynamiquement sur la base du total des actifs. Ce prix est à titre indicatif seulement et ne servira pas de liquidation définitive.',
    xiadanzuoduo: 'Long',
    xiadanzuokong: 'Court',
    xiadanpingcang: 'Fermer',
    zhanghuheyuezichan: 'Actifs sur contrat',
    zhanghuchongbi: 'Rcharger',
    zhanghutibi: 'Retirer des pièces',
    zhanghuhuazhuan: 'transfert',
    zhanghuzhanghuquanyi: 'Droits de compte',
    zhanghufudongyingkui: 'profits et pertes flottants',
    zhanghucangweibaozhengjin: 'Marge de position',
    zhanghudongjiebaozhengjin: 'Marge de gel',
    zhanghukeyongquanyi: 'Avantages disponibles',
    zhanghubaozhengjinshiyonglv: 'Taux d\'utilisation de la marge',
    hangqingheyuexinxi: 'Informations sur le contrat',
    hangqingheyuemingcheng: 'Nom du contrat',
    hangqingjijiahuobi: 'Devise de cotation',
    hangqingheyuedaxiao: 'Taille du contrat',
    hangqingzuixiaojiagebodong: 'fluctuation minimale des prix',
    dialogleverRateganggantiaozheng: 'Ajustement du levier',
    dialogleverRatequeding: 'Bien sûr',
    dialogtransferzichanhuazhuan: 'Transfert d\'actifs',
    dialogtransferbizhong: 'Devise',
    dialogtransfercong: 'depuis',
    dialogtransferdao: 'arriver',
    dialogtransfershuliang: 'quantité',
    dialogtransferqingshuruhuazhuanshuliang: 'Veuillez saisir le montant du transfert',
    dialogtransferquanbu: 'tous',
    dialogtransferkeyong: 'Disponible',
    dialogtransferhuazhuan: 'transfert',
    dialogtransferjichuzhanghu: 'Compte de base',
    dialogtransferheyuezhanghu: 'Compte de contrat',
    dialogfitlosszhiyingzhisun: 'Prenez des bénéfices et stoppez les pertes',
    dialogfitlossheyu: 'contracter',
    dialogfitlossduokong: 'Longue/Courte',
    dialogfitlosschichangjunjia: 'prix moyen de la position',
    dialogfitlossshuliang: 'quantité',
    dialogfitlosszuixinjia: 'dernier prix',
    dialogfitlosszhiyingdianshu: 'Prenez des points de profit',
    dialogfitlosszhisundianshu: 'Points d\'arrêt des pertes',
    dialogfitlossdianshu: 'score',
    dialogfitlosszhiyingjiage: 'Prendre le prix du profit',
    dialogfitlosszhisunjiage: 'Prix ​​​​stop loss',
    dialogfitlossjiage: 'prix',
    dialogfitlossyuqishouyi: 'retour attendu',
    dialogfitlossyuqikuisun: 'perte attendue',
    dialogfitlosstishi1: 'Astuce : La fluctuation minimale du contrat en cours est de',
    dialogfitlosstishi2: ', veuillez fixer le prix à 0 pour annuler le take profit et stop loss.',
    dialogfitlossqueding: 'Bien sûr',
    dialogfitlossduo: 'beaucoup',
    dialogfitlosskong: 'nul',
    dialogconditionzengjiayuntiaojiandan: 'Ajouter une feuille de conditions nuageuses',
    dialogconditiontiaojianleixing: 'Type d\'état :',
    dialogconditionjiagetiaojiandan: 'Fiche conditions tarifaires',
    dialogconditionshijiantiaojiandan: 'Feuille de conditions de temps',
    dialogconditionjiageleixing: 'Type de prix :',
    dialogconditionzuixinjia: 'dernier prix',
    dialogconditionByijia: 'acheter un prix',
    dialogconditionSyijia: 'Vendre un prix',
    dialogconditiontiaojianshezhi: 'Paramètres des conditions :',
    dialogconditionjiageshezhi: 'Paramètres de prix :',
    dialogconditionqingshurujiage: 'Veuillez entrer le prix',
    dialogconditionshijianshezhi: 'Réglage de l\'heure :',
    dialogconditionjiaoyileixing: 'Type d\'opération :',
    dialogconditionkaiduo: 'Ouvert longtemps',
    dialogconditionpingkong: 'ciel plat',
    dialogconditionkaikong: 'Ouvert court',
    dialogconditionpingduo: 'Pinduo',
    dialogconditiondingdanshuliang: 'Quantité commandée :',
    dialogconditionqingshurudingdanshuliang: 'Veuillez entrer la quantité commandée',
    dialogconditiondingdanshixiao: 'Délai de commande :',
    dialogconditionyongjiuyouxia: 'Valable en permanence',
    dialogconditiontijiao: 'soumettre',
    dialogAutoProfitLosszhiyingzhisun: 'Prenez des bénéfices et stoppez les pertes',
    dialogAutoProfitLossmingcheng: 'nom',
    dialogAutoProfitLosszhiyingdian: 'Prendre des points de profit',
    dialogAutoProfitLosszhisundian: 'Point d\'arrêt des pertes',
    dialogAutoProfitLosschongzhi: 'réinitialiser',
    dialogAutoProfitLoss: 'soumettre',
    dialogCloseOrderpingcang: 'Fermer',
    dialogCloseOrderweituoleixing: 'Type de délégué',
    dialogCloseOrderqingxuanze: 'Veuillez sélectionner',
    dialogCloseOrdershijia: 'prix du marché',
    dialogCloseOrderxianjia: 'limite de prix',
    dialogCloseOrderjiage: 'prix',
    dialogCloseOrderqingshurujiage: 'Veuillez entrer le prix',
    dialogCloseOrdershuliang: 'quantité',
    dialogCloseOrderqingshurushuliang: 'Veuillez entrer la quantité',
    dialogCloseOrderzhang: 'ouvrir',
    dialogCloseOrderkepingshuliang: 'Quantité pouvant être nivelée',
    dialogCloseOrderqueding: 'Bien sûr',
    jsMessageResgaiheyuegangganxiugaichenggong: 'Le levier du contrat a été modifié avec succès',
    jsMessageResgaiheuyegangganxiugaishibai: 'La modification du levier du contrat a échoué',
    jsMessageReskaicangchenggong: 'Poste ouvert avec succès',
    jsMessageReskaicangshibai: 'L\'ouverture du poste a échoué :',
    jsMessageReskaicang1: 'L\'ouverture d\'un poste est interdite',
    jsMessageReskaicang2: 'Le contrat n\'existe pas',
    jsMessageReskaicang3: 'Le nombre de postes ouverts dépasse la limite supérieure',
    jsMessageReskaicang4: 'Le prix est de 0',
    jsMessageReskaicang5: 'Marge insuffisante pour ouvrir une position',
    jsMessageRespingcangchenggong: 'Position clôturée avec succès',
    jsMessageRespingcangshibai: 'Échec de la clôture de la position :',
    jsMessageRespingcang1: 'Le contrat n\'existe pas',
    jsMessageRespingcang2: 'Le récépissé de position n\'existe pas',
    jsMessageRespingcang3: 'Montant insuffisant',
    jsMessageReszijinhuazhuanchenggong: 'Transfert de fonds réussi',
    jsMessageReszijinhuazhuanshibai: 'Le transfert de fonds a échoué',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'Ordre de position stop profit et stop loss définis avec succès',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'Les paramètres stop-profit et stop-loss pour les ordres de position ont échoué :',
    jsMessageResweituodanchedanchenggong: 'Commande annulée avec succès',
    jsMessageResweituodanchedanshibai: 'L\'annulation de la commande a échoué',
    jsMessageRestiaojiandantianjiachenggong: 'Commande conditionnelle ajoutée avec succès',
    jsMessageResheyuebucunzai: 'Le contrat n\'existe pas',
    jsMessageResjiageshezhicuowu: 'Erreur de fixation du prix',
    jsMessageResshijianshezhicuowu: 'Erreur de réglage de l\'heure',
    jsMessageResneibucuowu: 'Erreur interne',
    jsMessageRestiaojiandantianjiashibai: 'Échec de l\'ajout d\'une commande conditionnelle :',
    jsMessageReszhiyingzhisunshezhichenggong: 'Prendre des bénéfices et arrêter les pertes avec succès',
    jsconfirmquedingchedanma: 'Etes-vous sûr de vouloir annuler la commande ?',
    jsconfirmtishi: 'indice',
    jsconfirmqueding: 'Bien sûr',
    jsconfirmquxiao: 'Annuler',
    jsMessageqingxiandenglu: 'Veuillez d\'abord vous connecter !',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'Ce contrat a des positions ouvertes et l\'effet de levier ne peut pas être modifié.',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'Ce contrat a un mandat et l\'effet de levier ne peut être modifié.',
    jsMessageqingshuruzhengquedeshuliang: 'Veuillez entrer la bonne quantité !',
    jsMessageqingshuruzhengquedejiage: 'Veuillez entrer le prix correct !',
    jsMessageqingxianxuanzechicang: 'Veuillez d\'abord sélectionner un poste !',
    jsMessageqingshuruhuazhuanshuliang: 'Veuillez saisir le montant du transfert !',
    jsMessageqingxianxuanzeheyue: 'Veuillez d\'abord sélectionner un contrat !',
    jsMessageqingshurutiaojiandanshuliang: 'Veuillez saisir la quantité de commande conditionnelle !',
    jsMessageqingshurutiaojiandandejiage: 'Veuillez saisir le prix de la commande conditionnelle !',
    jsMessageqingshurutiaojiandanzhixingshijian: 'Veuillez saisir le délai d\'exécution de l\'ordre conditionnel !',
    emptytext: 'Aucune donnée pour l\'instant',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'Les pips de prise de profit et de stop loss sont mal définis',
    jsMessageReschicangdanbucunzai: 'Le récépissé de position n\'existe pas',
    zxkcje:'Montant minimum d\'ouverture',
    },
    commisson:{
    dlzx:'Centre d\'agence',
    sjzl:'Aperçu des données',
    quanbu:"tous",
    jinri:'aujourd\'hui',
    zuori:'hier',
    ri:'jour',
    wdfy:'Ma remise',
    wdsjfy:'Mon rabais de troisième niveau',
    dengji:'grade',
    yqrs:'Nombre de personnes invitées',
    tdrs:'Taille de l\'équipe',
    yqxq:'Détails de l\'invitation',
    yhm:"nom d\'utilisateur",
    cjsj:'temps de création',
    fyxq:'Détails du rabais',
    zyj:'commission totale',
    dryj:'Commission pour un jour',
    fyje:'Montant du rabais',
    
    },
    assets: {
    zongzichan: 'actif total',
    chongbi: 'Recharger',
    tibi: 'Retirer des pièces',
    huazhuan: 'transfert',
    fabizhanghu: 'Compte de base',
    heyuezhanghu: 'Compte de contrat',
    jiayiliushui: 'flux de transactions',
    dialogzichanhuazhuan: 'Transfert d\'actifs',
    cong: 'depuis',
    dao: 'arriver',
    shuliang: 'quantité',
    qingshuruhuazhuanshuliang: 'Veuillez saisir le montant du transfert',
    quanbu: 'tous',
    keyong: 'Disponible',
    huanzhuan: 'transfert',
    zijinhuazhuanchenggong: 'Transfert de fonds réussi',
    zijinhuazhuanshibai: 'Le transfert de fonds a échoué',
    bibizhanghu: 'Compte pièces',
    qiquanzhanghu:'compte d\'options',
    zczl:'Aperçu des actifs',
    zzczh:'Remise sur l\'actif total',
    ztzc:"Actifs en transit",
    djzc:'Geler les avoirs',
    zyzc:'Actifs gagés',
    cwzc:'Actifs de position',
    jyzh:'compte de trading',
    bizhong:'Devise',
    dongjie:'geler',
    lirun:"profit",
    hyzh:"Compte de contrat",
    },
    basicsAccountRecord: {
    fanhui: 'retour',
    fabizhanghuliushui: 'Flux de compte de trading',
    fabijiaoyiliushuileixing: 'Type de flux de transactions',
    qingxuanze: 'Veuillez sélectionner',
    chaxunriqi: 'Date de la requête',
    liushuileixing: 'Type de pipeline',
    shijian: 'temps',
    biandongjine: 'Modifier le montant',
    yue: 'Équilibre',
    quanbu: 'tous',
    Sellbi: 'vendre des pièces',
    Buybi: 'acheter des pièces',
    tibi: 'Retirer des pièces',
    chongbi: 'Recharger',
    fabizhuanheyue: 'Contrat de transfert de base',
    heyuezhuanfabi: 'Contrat à base',
    zhuanzhang: 'transfert',
    fabihuazhuandaoheyue: 'Transfert de base vers le contrat',
    heyuehuazhuandaofabi: 'Transfert de contrat à la base',
    goumaijijin: 'Acheter un pool minier',
    jijinshuhui: 'Rachat du pool minier',
    jijinshouyi: 'Revenu de rachat du pool minier',
    fabizhuanqihuo: 'Conversion de base en contrats à terme',
    qihuozhuanfabi: 'Base de transfert à terme',
    fabizhuanganggu: 'Transfert de base vers les actions de Hong Kong',
    gangguzhuanfabi: 'Bases du transfert d\'actions à Hong Kong',
    fabizhuanbibi: 'Devise de transfert de base',
    bibizhuanfabi: 'Bases du transfert de devise à devise',
    goumaiSGCB: 'Acheter des ICO',
    chushouSGCB: 'Vendre des ICO',
    huoqushibai: 'Échec de l\'obtention',
    zanwushuju: 'Aucune donnée pour l\'instant',
    },
    contractAccountRecord: {
    fanhui: 'retour',
    heyuezhanghuliushui: 'Flux de comptes de contrats',
    heyuejiaoyiliushuileixing: 'Type de flux de transactions de contrat',
    qingxuanze: 'Veuillez sélectionner',
    chaxunriqi: 'Date de la requête',
    liushuileixing: 'Type de pipeline',
    shijian: 'temps',
    biandongjine: 'Modifier le montant',
    yue: 'Équilibre',
    quanbu: 'tous',
    kaicangshouxufei: 'Frais d\'ouverture',
    pingcangshouxufei: 'Frais de clôture',
    pingcangyingkui: 'Résultat de clôture de position',
    fabizhuanheyue: 'Contrat de transfert de base',
    heyuezhuanfabi: 'Contrat à base',
    zhuanzhang: 'transfert',
    kaicangyongjin: 'Commission d\'ouverture',
    pingcangyongjin: 'Commission de clôture',
    huoqushibai: 'Échec de l\'obtention',
    zanwushuju: 'Aucune donnée pour l\'instant',
    },
    recharge: {
    zanwushuju: 'Aucune donnée pour l\'instant',
    shijian: 'temps',
    leixing: 'taper',
    shuliang: 'quantité',
    zhuangtai: 'État',
    caozuo: 'fonctionner',
    chakanxiangqing: 'vérifier les détails',
    huoqushibai: 'Échec de l\'obtention',
    dialogtitle: 'Détails',
    chongbi: 'Recharger',
    fanhui: 'retour',
    lianmingcheng: 'Nom de la chaîne',
    fuzhilianjie: 'Copier l\'adresse',
    tishixinxi1: '• Veuillez ne déposer aucun actif non TRC_USDT ou ERC_USDT à l\'adresse ci-dessus, sinon les actifs ne seront pas récupérés.',
    tishixinxi2: '• Après avoir rechargé à l\'adresse ci-dessus, la confirmation de l\'ensemble du nœud du réseau est requise. Le compte sera reçu après 1 confirmation du réseau et la devise pourra être retirée après 2 confirmations du réseau.',
    tishixinxi3: '• Montant minimum du dépôt : 50 USDT, les dépôts inférieurs au montant minimum ne seront pas crédités et ne pourront pas être remboursés.',
    tishixinxi4: '• Votre adresse de recharge ne changera pas fréquemment et pourra être rechargée à plusieurs reprises ; en cas de changement, nous ferons de notre mieux pour vous informer via des annonces sur le site Web ou par e-mail.',
    tishixinxi5: '• Veuillez vous assurer que votre ordinateur et votre navigateur sont sécurisés pour éviter que les informations ne soient falsifiées ou divulguées.',
    putongchongbi: 'Dépôt ordinaire',
    jiaoyichenggong: 'Transaction réussie',
    jiaoyishibai: 'La transaction a échoué',
    OMNIweihuzhong: 'La chaîne OMNI est en maintenance, veuillez sélectionner TRC20',
    },
    withdraw: {
    fanhui: 'retour',
    zanwushuju: 'Aucune donnée pour l\'instant',
    tibi: 'Retirer des pièces',
    huoqushibai: 'Échec de l\'obtention',
    OMNIweihuzhong: 'La chaîne OMNI est en maintenance, veuillez sélectionner TRC20',
    shijian: 'temps',
    leixing: 'taper',
    shuliang: 'quantité',
    zhuangtai: 'État',
    caozuo: 'fonctionner',
    chakanxiangqing: 'vérifier les détails',
    putongtibi: 'Retrait ordinaire',
    dialogtitle: 'Détails',
    dialogtitlewithdraw: 'Retirer des pièces',
    houchongxinfasong: 'Renvoyer après S',
    huoquyouxiangyanzhengma: 'Obtenir le code de vérification par e-mail',
    lianmingcheng: 'Nom de la chaîne',
    tibidizhi: 'Adresse de retrait',
    qingshurutibidizhi: 'Veuillez saisir l\'adresse de retrait',
    tibishuliang: 'Quantité de pièces retirées',
    shouxufei: 'frais de traitement',
    qingshurutibishuliang: 'Veuillez saisir le montant du retrait',
    quanbu: 'tous',
    keyong: 'Disponible',
    daozhangshuliang: 'Quantité d\'arrivée',
    zuixiaotibishuliang: 'Le montant minimum de retrait est de :',
    tishixinxi: 'Afin de garantir la sécurité des fonds, lorsque la politique de sécurité de votre compte change ou que votre mot de passe est modifié, nous procéderons à un examen manuel du retrait. Veuillez être patient et attendre que le personnel vous contacte par téléphone ou par e-mail. Veuillez vous assurer que votre ordinateur et votre navigateur sont sécurisés pour éviter que les données ne soient falsifiées ou divulguées.',
    tuxingyanzhengma: 'Code de vérification graphique',
    qingshurutuxingyanzhengma: 'Veuillez saisir le code de vérification graphique',
    youxiangyanzhengma: 'Code de vérification de l\'e-mail',
    queding: 'Bien sûr',
    tijiaozhong: 'Soumission',
    yijujue: 'Rejeté',
    yitongguo: 'Passé',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Échec de l\'obtention du code de vérification graphique, veuillez demander à nouveau',
    huoquyouxiangyanzhengmashibai: 'Impossible d\'obtenir le code de vérification de l\'e-mail,',
    qingxianwanchengyouxiangrenzheng: 'Veuillez d\'abord effectuer la vérification de l\'e-mail',
    huoquyouxiangshibaiqingchongxinqingqiu: 'Impossible d\'obtenir l\'adresse e-mail, veuillez demander à nouveau',
    qingshuruyouxiangyanzhengma: 'Veuillez saisir le code de vérification de l\'e-mail',
    youxiangyanzhengmacuowu: 'Le code de vérification de l\'e-mail est erroné,',
    qxsmrz:'Veuillez d\'abord vérifier votre vrai nom',
    tishi:'indice',
    queding:'Bien sûr',
    },
    contractTransactionRecord: {
    fanhui: 'retour',
    zanwushuju: 'Aucune donnée pour l\'instant',
    heyuedingdan: 'commande contractuelle',
    qingxuanze: 'Veuillez sélectionner',
    quanbu: 'tous',
    kaiduo: 'Ouvert longtemps',
    pingkong: 'ciel plat',
    kaikong: 'Ouvert court',
    pingduo: 'Pinduo',
    xianjiaweituo: 'ordre limité',
    shijiaweituo: 'ordre du marché',
    suoyouzhuangtai: 'Tous les statuts',
    tijiaoshibai: 'Échec de la soumission',
    zhunbeitijiao: 'prêt à soumettre',
    yitijiao: 'Soumis',
    bufentijiao: 'Accord partiel',
    bufentijiaoyichedan: 'Certaines commandes ont été annulées',
    quanbuchengjiao: 'Toutes transactions',
    yichedan: 'Commande annulée',
    huoqushibai: 'Échec de l\'obtention',
    heyuedaima: 'Code du contrat',
    ganggan: 'levier',
    xiadanshijian: 'Heure de commande',
    chengjiaoshijian: 'Temps de transaction',
    fangxiang: 'direction',
    baojialeixing: 'Type de devis',
    weituoliang: 'Montant de la commission (pièces)',
    weituojia: 'Prix ​​de la commande (USDT)',
    chengjiaoliang: 'Volume des échanges (billets)',
    chengjiaojunjia: 'Prix ​​de transaction moyen (USDT)',
    yingkui: 'Profits et pertes (USDT)',
    shouxufei: 'Frais de traitement (USDT)',
    zhuangtai: 'État',
    gupiaodingdan: 'commande de stock',
    waihuidingdan: 'Ordres Forex',
    gupiaodaima: 'Code de stock',
    waihuidaima: 'Code Forex',
    },
    kLine: {
    zhibiao: 'indice',
    shezhi: 'installation',
    quanping: 'plein écran',
    tuichuquanping: 'Quitter le plein écran',
    jishuzhibiao: 'Indicateurs techniques',
    futuzhibiao: 'Indicateur de sous-image',
    zhutuzhibiao: 'Indicateur graphique principal',
    huifumoren: 'Restaurer les valeurs par défaut',
    lazhutuleixing: 'Types de chandeliers',
    qingxuanze: 'Veuillez sélectionner',
    quanshixin: 'Tout solide',
    quankongxin: 'Entièrement creux',
    zhangkongxin: 'creux montant',
    diekongxin: 'tomber creux',
    mianjitu: 'graphique en aires',
    jiagezhouleixing: 'Type d\'axe de prix',
    xianxingzhou: 'axe de ligne',
    baifenbizhou: 'Axe de pourcentage',
    duishuzhou: 'Axe logarithmique',
    zuigaojiaxianshi: 'Affichage du prix le plus élevé',
    zuidijiaxianshi: 'Affichage du prix le plus bas',
    zuixinjiaxianshi: 'Dernier affichage des prix',
    zhibiaozuixinzhixianshi: 'La dernière valeur de l\'indicateur est affichée',
    daozhizuobiao: "coordonnées inversées",
    wanggexianxianshi: 'Affichage de la ligne de quadrillage',
    hfcg:'Récupération réussie',
    },
    SecurityCenter: {
    fanhui: 'retour',
    anquanzhongxin: 'Centre de sécurité',
    qingshurunicheng: 'Veuillez entrer un pseudo',
    nichengxiugaishibai: 'La modification du pseudo a échoué',
    huoquyonghuxinxishibai: 'Échec de l\'obtention des informations sur l\'utilisateur',
    xiugaidenglumima: 'Changer le mot de passe de connexion',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Échec de l\'obtention du code de vérification graphique, veuillez demander à nouveau',
    qingshurujiumima: 'Veuillez saisir l\'ancien mot de passe',
    qingshuruxinmima: 'Veuillez entrer un nouveau mot de passe',
    qingzaicishuruxinmima: 'Veuillez saisir à nouveau le nouveau mot de passe',
    qingshurutuxingyanzhengma: 'Veuillez saisir le code de vérification graphique',
    qingshuruyanzhengma: 'Veuillez entrer le code de vérification',
    huoquzhuceshoujihaoshibai: 'Impossible d\'obtenir le numéro de mobile enregistré',
    qingxianwanchengshoujirenzheng: 'Veuillez d\'abord procéder à l\'authentification du téléphone mobile',
    huoqushoujiyanzhengmashibai: 'Échec de l\'obtention du code de vérification du téléphone mobile',
    huoqushoujiyanzhengma: 'Obtenez le code de vérification du téléphone mobile',
    houchongxinfasong: 'Renvoyer après S',
    mimacuowu: 'Mauvais mot de passe',
    liangcixinmimashurubuyizhiqingjiancha: 'Les deux nouveaux mots de passe saisis sont incohérents, merci de vérifier',
    xinmimaheyuanmimabunengyiyang: 'Le nouveau mot de passe ne peut pas être le même que le mot de passe d\'origine',
    qingshurushoujiyanzhengma: 'Veuillez saisir le code de vérification du téléphone mobile',
    yanzhengmacuowu: 'Erreur de code de vérification',
    denglumimaxiugaichenggong: 'Le mot de passe de connexion a été modifié avec succès',
    denglumimaxiugaishibai: 'La modification du mot de passe de connexion a échoué',
    xiugaizijinmima: 'Changer le mot de passe du fonds',
    qingshuruzijinmima: 'Veuillez saisir le mot de passe du fonds',
    qingzaicishuruzijinmima: 'Veuillez saisir à nouveau le mot de passe du fonds',
    liangcizijinmimabuyizhiqingjiancha: 'Les mots de passe des deux fonds sont incohérents, veuillez vérifier',
    shezhizijinmimachenggong: 'Définir le mot de passe du fonds avec succès',
    shezhizijinmimashibai: 'Échec de la définition du mot de passe du fonds',
    xiugaizijinmimachenggong: 'Mot de passe du fonds modifié avec succès',
    xiugaizijinmimashibai: 'Échec de la modification du mot de passe du fonds',
    youxiangrenzheng: 'Authentification par e-mail',
    huoquyouxiangyanzhengma: 'Obtenir le code de vérification par e-mail',
    qingshuruyouxiangdizhi: 'Veuillez entrer l\'adresse e-mail',
    huoquyouxiangyanzhengmashibai: 'Impossible d\'obtenir le code de vérification de l\'e-mail',
    qingshuruyouxiangyanzhengma: 'Veuillez saisir le code de vérification de l\'e-mail',
    youxiangyanzhengchenggong: 'Vérification de l\'e-mail réussie',
    weirenzheng: 'Non certifié',
    queding: 'Bien sûr',
    anquanshezhiguanli: 'Gestion des paramètres de sécurité',
    denglumima: 'Mot de passe de connexion',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'En définissant un mot de passe de connexion, vous pourrez vous connecter directement en utilisant le mot de passe de votre compte',
    xiugai: 'Réviser',
    zijinmima: 'Mot de passe du fonds',
    yongyubaohuzijinanquan: 'Utilisé pour protéger la sécurité des fonds',
    shezhi: 'installation',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'Utilisé pour la vérification de sécurité lors de la connexion, le retrait de pièces, la récupération de mots de passe et la modification des paramètres de sécurité.',
    yirenzheng: 'Agréé',
    renzheng: 'Attestation',
    shimingrenzheng: 'Authentification par nom réel',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'Utilisé pour l\'authentification de sécurité lors de l\'achat, de la vente, du retrait, du dépôt et du transfert de pièces.',
    daishenhe: 'En attente d\'examen',
    yibohui: 'Rejeté',
    yuandenglumima: 'Mot de passe de connexion d\'origine',
    xinmima: 'Nouveau mot de passe',
    quedingxinmima: 'Confirmer le nouveau mot de passe',
    tuxingyanzhengma: 'Code de vérification graphique',
    yanzhengma: 'Le code de vérification',
    zijinmima: 'Mot de passe du fonds',
    querenzijinmima: 'Confirmer le mot de passe du fonds',
    youxiangdizhi: 'Adresse email',
    qingxianwanchengyouxiangrenzheng: 'Veuillez d\'abord effectuer la vérification de l\'e-mail',
    xiugaitouxiangchenggong: 'Avatar modifié avec succès',
    xiugaitouxiangshibai: 'Échec de la modification de l\'avatar',
    shangchuantouxiangzhinengshijpggeshi: 'Les photos d\'avatar ne peuvent être téléchargées qu\'au format JPG !',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'La taille de l’image d’avatar téléchargée ne peut pas dépasser 2 Mo !',
    },
    verification: {
    fanhui: 'retour',
    shimingrenzheng: 'Authentification par nom réel',
    qingshuruxingming: 'Veuillez entrer le nom',
    qingshuruzhengjianhaoma: 'Veuillez entrer votre numéro d\'identification',
    huoquyonghuxinxishibai: 'Échec de l\'obtention des informations sur l\'utilisateur',
    wenjiangeshicuowu: 'Erreur de format de fichier',
    wenjianbunengdayu5M: 'Le fichier ne peut pas dépasser 5 Mo',
    qingtianxiexingming: 'Veuillez remplir votre nom',
    qingtianxiezhengjianhaoma: 'Veuillez remplir le numéro d\'identification',
    qingshangchuanzhengjianzhengmian: 'Veuillez télécharger le recto de votre pièce d\'identité',
    qingshangchuanzhengjianfanmian: 'Veuillez télécharger le verso de votre pièce d\'identité',
    tijiaochenggong: 'Soumission réussie',
    kaiqishenfenrenzheng: 'Activer l\'authentification d\'identité',
    bohuiyuanyin: 'Motif du refus',
    qingtianxiejiashizhizhaogongminkahuzhao: 'Veuillez remplir votre permis de conduire/carte de citoyen/passeport',
    xingming: 'Nom',
    zhengjianhaoma: 'Numéro d\'identification',
    qingshangchuanjiashizhizhaogongminkahuzhao: 'Veuillez télécharger les informations sur votre permis de conduire/carte de citoyen/passeport',
    shangchuanzhengjianzhengmian: 'Téléchargez le recto de la pièce d\'identité',
    huotuozhuafangru: 'Ou faites glisser et déposez',
    shangchuanzhengjianfanmian: 'Téléchargez le verso du certificat',
    tijiao: 'soumettre',
    },
    invite: {
    yaoqinghaoyoudejiangli: 'Invitez des amis et obtenez des récompenses',
    yongjiukede: 'disponible en permanence',
    jiaoyifanyong: 'Remise commerciale',
    fasongyaoqinggeihaoyou: 'Envoyer une invitation à des amis',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'Invitez vos amis à enregistrer BCFX en partageant le lien',
    haoyouzhuce: 'Inscription d\'un ami',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'L\'ami accepte l\'invitation, termine l\'inscription et effectue les transactions',
    huodexiangyingbilijiangli: 'Recevez des récompenses proportionnelles correspondantes',
    qingsonghuodejiaoyishouxufeijianglifuli: 'Bénéficiez facilement des avantages du bonus sur les frais de transaction',
    wodezhuanshufenxiangfangshi: 'Ma façon exclusive de partager',
    yaoqinglianjie: 'Lien d\'invitation',
    jiazaizhong: 'chargement...',
    fuzhilianjie: 'Copier le lien',
    yaoqingma: 'Code d\'invitation',
    fuzhiyaoqingma: 'Copier le code d\'invitation',
    wodeyaoqing: 'mon invitation',
    yaoqingrenshu: 'Nombre de personnes invitées',
    huodejiangli: 'Soyez récompensé',
    huodongxize: 'Détails de l\'événement',
    tips1: 'La relation entre les utilisateurs de la plateforme est à trois niveaux, tels que : les utilisateurs ABCD constituent trois niveaux d\'utilisateurs, et A est le niveau le plus élevé !',
    tips2: 'Relations d’intérêt entre niveaux',
    tips201: '。',
    tips3: 'Récompense en espèces : le client A peut recevoir 40 % des frais de transaction contractuelle du client B + 20 % des frais de transaction contractuelle du client C + 10 % des frais de transaction contractuelle du client D.',
    tips4: 'Par exemple : le client A a 10 clients de premier niveau B, 10 clients de deuxième niveau C et 10 clients de troisième niveau D. Si chaque contrat client négocie 100 000 USDT',
    tips5: 'Alors le retour que le client A peut obtenir',
    tips6: '100000*1,2%*40%*10+100000*1,2%*20%*10+100000*1,2%*10%*10=8400 USDT de récompense en espèces, qui peut être retirée en temps réel',
    tips7: 'Si l\'activité est ajustée, elle sera soumise aux mises à jour de la plateforme BCFX, et le droit final d\'interprétation appartient à BCFX.',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Échec de l\'obtention du code de vérification graphique, veuillez demander à nouveau',
    qingshuruyouxiang: 'Veuillez entrer votre email',
    qingshurutuxingyanzhengma: 'Veuillez saisir le code de vérification graphique',
    qingshuruyanzhengma: 'Veuillez entrer le code de vérification',
    qingshurunindemima: 'Veuillez entrer votre mot de passe',
    qingzaicishurunindemima: 'Veuillez saisir à nouveau votre mot de passe',
    qingshuruzhifumima: 'Veuillez saisir le mot de passe de paiement',
    xuantian: 'Facultatif',
    bitian: 'Requis',
    yonghuzhuce: 'Inscription des utilisateurs',
    huanyingdenglu: 'Bienvenue pour vous connecter',
    guanfangwangzhan: 'site officiel',
    xiangmumingcheng: 'BCFX',
    youxiang: 'Mail',
    tuxingyanzhengma: 'Code de vérification graphique',
    yanzhengma: 'Le code de vérification',
    huoquyanzhengma: 'Le code',
    shezhimima: 'Définir le mot de passe',
    quedingmima: 'Confirmez le mot de passe',
    zhifumima: 'Mot de passe de paiement',
    yaoqingma: 'Code d\'invitation',
    zhuce: 'registre',
    yiyouzhanghao: 'Vous avez déjà un compte ?',
    denglu: 'Se connecter',
    qingshuruyouxiangdizhi: 'Veuillez entrer l\'adresse e-mail',
    huoqushibai: 'Échec de l\'obtention',
    houchongxinfasong: 'Renvoyer après S',
    liangcishurudemimabuyizhi: 'Les mots de passe saisis deux fois sont incohérents',
    zhucechenggong: 'Inscription réussie',
    zhuceshibai: 'L\'inscription a échoué',
    },
    treasure:{
    jichubizhong: 'devise de base',
    shouyibizhong: 'Devise des revenus',
    wakuangtianshu: 'Journées minières',
    shouyitianshu: 'Jours de gains',
    suodingtianshu: 'Jours de fermeture',
    yichanshengshouyi: 'Revenus générés',
    yuqishouyi: 'retour attendu',
    kaishishijian: 'heure de début',
    jieshushijian: 'heure de fin',
    qingshuruninyaosuodingdebizhongshuliang: 'Veuillez saisir le montant en USDT dans la devise que vous souhaitez verrouiller',
    huoqushibai: 'Échec de l\'obtention',
    meiyitian: 'tous les 1 jours',
    dakai: 'Ouvrir',
    yuanyuzhoukuangchi: 'Pool minier métaverse',
    jilu: 'Enregistrer',
    kuangchixiangqing: 'Détails du pool minier',
    suo: 'Verrouillage',
    yitianchanchu: 'sortie 1 jour',
    zuixiaosuodingshijian: 'Temps de verrouillage minimum',
    nindeUSDT: 'Votre USDT',
    tian: 'ciel',
    quanbu: 'tous',
    suoUSDTbingkaishishengchan: 'Verrouillez l\'USDT et démarrez la production',
    ninquedingyaosuoUSDTkaishishengchanma: 'Êtes-vous sûr de vouloir verrouiller l\'USDT et démarrer la production ?',
    tishi: 'indice',
    queding: 'Bien sûr',
    quxiao: 'Annuler',
    kaishishengchan: 'Démarrez la production !',
    yiquxiao: 'Annulé',
    },
    subscription:{
    rengouliebiao:'Liste d\'abonnement',
    jijiangdaolai:'À venir',
    jinxingzhong:'en cours',
    quanbu:'tous',
    keshiyonghuobi:"Devises disponibles",
    zongshuliang:'quantité totale',
    meiyouxianzhi:'pas de limite',
    shengyushuliang:'Quantité restante',
    kaishishijian:'heure de début',
    zhuangtai:'État',
    jilu:'Enregistrer',
    zanwujilu:"Pas encore d\'enregistrement",
    leixing:'taper',
    shuliang:'quantité',
    goumaijine:'Montant de l\'achat',
    shijian:'temps',
    goumai:'Acheter',
    duichu:'Sorti de nulle part',
    jiangli:'prix',
    tibi:'Retirer des pièces',
    huidaomulu:'Retour à la table des matières',
    dangqianchiyou:'actuellement détenu',
    keyong:'Disponible',
    keduihuan:'Rachetable',
    shishihangqing:'Cotations en temps réel',
    yue:'Équilibre',
    biaoti:'titre',
    fashoushuliang:'Quantité à vendre',
    jindu:"calendrier",
    goumaishuliang:'Quantité achetée',
    ge:'individuel',
    woyiyuedu:'j\'ai lu',
    daibigoumaixieyi:'Contrat d\'achat de jetons',
    qingshurugoumaijine:'Veuillez saisir le montant de l\'achat',
    shifoyaogoumai:'Voulez-vous acheter',
    tishi:'indice',
    queding:'Bien sûr',
    quxiao:'Annuler',
    yjs:'terminé',
    ywc:'Complété',
    sgjg:'Prix ​​de l\'abonnement',
    jssj:"heure de fin",
    zqsl:"Quantité gagnante",
    zqje:"Montant gagnant",
    tian:"ciel",
    shi:'heure',
    fen:'indiquer',
    miao:'Deuxième',
    shengou:'Abonnement',
    xmxq:'Détails du projet',
    },
    }
    
    