export default {
    public:{
        zhd:"best",
        qsjyh:"Users all over the world",
        gtdxz:'common choice',
        szhbjyytz:'Digital Currency Opportunities and Challenges',
        xsddcf:'Wealth Opportunities and Challenges in the New Era',
        yzsjypt:"One-stop trading platform",
        mskljyzh:'Open a trading account and start trading now',
        jyhg:"Business compliance",
    },

    header: {
    hello: 'Hello World!',
    changeLanguage: 'Switch language',
    Login: 'Log in',
    Register: 'Register',
    Logout: 'Log out',
    shouye: 'Home',
    bibijiaoyisuo: 'Cryptocurrency trading',
    heyuejiaoyisuo: 'Futures ',
    licaichanpin: 'Mining pool',
    rengou: 'Subscription',
    ruanjianxiazai: 'Software download',
    zhongwen: 'Chinese',
    yingwen: 'In',
    zichan: 'assets',
    dingdan: 'Order',
    heyuedingdan: 'Futures order',
    bibidingdan: 'Coin order',
    anquanzhongxin: 'Security Center',
    fanyongyaoqing: 'Commission invitation',
    tuichudenglu: 'Log out',
    gupiaojiaoyi: 'stock trading',
    waihuijiaoyi: 'Forex trading',
    qiquanjiaoyi:'options trading',
    gupiaodingdan: 'stock order',
    waihuidingdan: 'Forex orders',
    qiquandingdan: 'options order',
    jiaoyidingdan:'trading order',
    daolizhongxin:'Agency Center',
    huodong:'Activity'
    },
    home: {
    huanyinglaidao: 'welcome to',
    pingtaimingcheng: 'here',
    mengxiangqihang: 'Dreams set sail',
    yijiankaiqishuzihualvcheng: 'Start your digital journey with one click',
    liaojiegengduo: 'learn more',
    chongzhi: 'Recharge',
    jiaoyi: 'trade',
    remenbang: 'Popular list',
    zhangfubang: 'Gainer list',
    diefubang: 'Loser list',
    chengjiaoliang: 'Volume',
    zanwushuju: 'No data yet',
    jiayidui: 'trading pair',
    zuixinjiage: 'latest price',
    zhangdie24h: '24 hours rise and fall',
    caozuo: 'operate',
    jiaoyi: 'trade',
    gengduo: 'More',
    suishisuidikaiqijiaoyi: 'Start trading anytime, anywhere',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'Whether it is App or Web, you can quickly start your transaction',
    saomaxiazaiApp: 'Scan the QR code to download the APP',
    iosheAndroid: 'iOS and Android',
    ninkexinrenqieanquandejiamibijiaoyisuo: 'Your trusted and safe cryptocurrency exchange',
    tishi1: 'Reserve Certificate',
    xiangqing1: 'We promise to hold all user assets at least in a ratio of 1:1',
    tishi2: 'Best transaction rates',
    xiangqing2: 'Preferential transaction rates, competitive VIP rights, and enjoy the best quality services',
    tishi3: 'Security you can count on',
    xiangqing3: 'Our sophisticated security measures and SAFU funds protect your digital assets from all risks',
    tishi4: '24*7 hours customer service support',
    xiangqing4: '24*7 hours, full-time operation mode, answer your relevant inquiries as soon as possible',
    tishi5: 'Stable and safe',
    xiangqing5: 'The world\'s leading exchange provides bank-level security protection for your assets',
    tishi6: 'FAQ',
    xiangqing6: 'Check out the FAQ for detailed descriptions of specific features',
    fanhui:"return",
    shishihangqing:'Real-time quotes',
    },
    footer: {
    bangzhuzhongxin: 'Help Center',
    dianhuazixun: 'Telephone consultation',
    dianhuahegongzuoshijian: '1670-9756 (10:00~19:00 on weekdays/closed on weekends and holidays)',
    liaotianzixun: '1:1 chat consultation',
    keketuozixun: 'Cocotok Consulting',
    zaixianbangzhu: 'Online help',
    h24kaifang: '(open 24 hours)',
    lixianbangzhu: 'Offline help',
    gongsidizhi: '1F, Yongsan Purgio Summit, 69 Hangang-gil, Yongsan-gu, Seoul',
    gongzuoshijian: '(10:00~18:00 on weekdays / closed on weekends and holidays)',
    shangshizixun: 'Listing consultation',
    hezuozixun: 'Cooperation consultation',
    gongsi: 'company',
    lianxiwomwn: 'Contact us',
    zhidao: 'guide',
    yonghuzhichi: 'User support',
    zhanghuzhinan: 'Account Guide-Deposit/Withdrawal',
    ruanjianxiazai: 'Software download',
    zhengce: 'policy',
    fuwuyuyinsitiaokuan: 'Terms of Service and Privacy',
    },
    login: {
    yonghudenglu: 'User login',
    huanyingdenglu: 'Welcome to log in',
    xiangmumingcheng: 'BCFX',
    guanfangwangzhan: 'official website',
    youxiang: 'Mail',
    qingshuruyouxiang: 'Please enter your email',
    mima: 'password',
    qingshurumima: 'Please enter password',
    wangjimima: 'forget the password?',
    denglu: 'Log in',
    haimeiyouzhanghao: 'Don’t have an account yet? go',
    zhuce: 'register',
    dengluchenggong: 'Login successful',
    denglushibai: 'Login failed',
    qingshuruyonghuminghemima: 'Please enter username and password',
    mimachangdubunengxiaoyuliuwei: 'The password length cannot be less than 6 characters',
    youxiangrenzheng: 'Email verification',
    youxiangdizhi: 'Email address',
    qingshuruyouxiangdizhi: 'Please enter email address',
    tuxingyanzhengma: 'Graphic verification code',
    qingshurutuxingyanzhengma: 'Please enter the graphic verification code',
    yanzhengma: 'Verification code',
    qingshuruyouxiangyanzhengma: 'Please enter the email verification code',
    huoquyouxiangyanzhengma: 'Get code',
    queding: 'Sure',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Failed to obtain graphic verification code, please request again',
    qingxianwanchengyouxiangrenzheng: 'Please complete email verification first',
    huoquyouxiangyanzhengmashibai: 'Failed to obtain email verification code,',
    houchongxinfasong: 'Resend after S',
    youxiangyanzhengchenggong: 'Email verification successful',
    yanzhengmacuowu: 'Verification code error',
    xiugaidenglumima: 'Change login password',
    xinmima: 'New Password',
    quedingxinmima: 'Confirm new password',
    qingshuruxinmima: 'Please enter new password',
    qingzaicishuruxinmima: 'Please enter new password again',
    liangcimimashurubuyizhi: 'The two password inputs are inconsistent',
    mimaxiugaichenggong: 'Password changed successfully',
    xiugaimimashibai: 'Failed to change password',
    },
    trade: {
    hangqingheyue: 'Futures',
    hangqingzuixinjia: 'latest price',
    hangqingzhangdiefu: 'Increase or decrease',
    hangqingzuigao24h: '24H highest',
    hangqingzuidi24h: '24H minimum',
    hangqingchengjiaoliang24h: '24H trading volume',
    hangqingchengjiabishu: 'Number of transactions',
    zhanghuquanyi: 'Account rights',
    zhanghukeyongshouyi: 'Available benefits',
    hangqingweituoliebiao: 'Order list',
    hangqingjiage: 'Price',
    hangqingshuliang: 'Quantity',
    hangqingzuixinchengjiao: 'latest transaction',
    hangqingjiage1: 'Price',
    hangqingshuliang1: 'Quantity',
    hangqingshijian: 'Time',
    dingdanchiyoucangwei: 'Position',
    dingdandangqianweituo: 'Entrust',
    dingdanchengjiaojilu: 'Transaction order',
    dingdantiaojiandan: 'Conditional order',
    chicangheyue: 'Futures',
    chicangkaicangjunjia: 'Position price',
    chicangfudongyingkui: 'PNL',
    chicanggangganbeishu: 'Leverage',
    chicangchicangliang: 'Position(s)',
    chicangduokong: 'Long/Short',
    chicangduo: 'Long',
    chicangkong: 'Short',
    chicangzhiyingjiage: 'Take profit price',
    chicangzhisunjiage: 'Stop loss price',
    chicangqiangpingyugujiage: 'Estimated liquidation price',
    chicangcaozuo: 'Operate',
    chicangzhiyingzhisun: 'Take profit and stop loss',
    chicangpingcang: 'Close',
    chicangshouxufei: 'Handling fee',
    weituoheyue: 'Futures',
    weituoshuliang: 'Order quantity',
    weituojiage: 'Entrusted price',
    weituofangxiang: 'direction',
    weituogangganbeishu: 'Leverage',
    weituochengjiaoshuliang: 'Transaction quantity',
    weituoshouxufei: 'Handling fee',
    weituochengjiaojunjia: 'Average price',
    weituoshouyi: 'income',
    weituozhuangtai: 'state',
    weituochengjiaoshijian: 'Transaction time',
    weituocaozuo: 'operate',
    weituochedan: 'Cancel order',
    weituotijiaoshibai: 'Submission failed',
    weituozhilingtijiao: 'Instruction submission',
    weituoyitijiao: 'Submitted',
    weituobufenchengjiao: 'Partial deal',
    weituobufenchengjiaoyichedan: 'Some orders have been canceled',
    weituowanquanchengjiao: 'Complete deal',
    weituowanquanchedan: 'Cancel order completely',
    weituochedanzhong: 'Canceling order',
    weituokaiduo: 'Open long',
    weituopingkong: 'Close short',
    weituokaikong: 'Open short',
    weituopingduo: 'Close long',
    chengjiaoheyue: 'Futures',
    chengjiaofangxiang: 'direction',
    chengjiaoshuliang: 'Transaction quantity',
    chengjiaojiage: 'transaction price',
    chengjiaoshijian: 'Transaction time',
    chengjiaopingcangyingkui: 'PNL',
    chengjiaoshouxufei: 'Transaction fee',
    chengjiaokaiduo: 'Open long',
    chengjiaopingkong: 'Close short',
    chengjiaokaikong: 'Open short',
    chengjiaopingduo: 'Close long',
    tiaojianzengjiatiaojian: 'Add conditions',
    tiaojianzantingquanbu: 'pause all',
    tiaojianqidongquanbu: 'Start all',
    tiaojianshanchuquanbu: 'Delete all',
    tiaojianheyue: 'Futures',
    tiaojianweituoshuliang: 'Order quantity',
    tiaojianfangxiang: 'direction',
    tiaojiantiaojianleixing: 'condition type',
    tiaojianjiageleixing: 'price type',
    tiaojianbijiaofuhao: 'Comparison symbol',
    tiaojiantiaojian: 'condition',
    tiaojianzhuangtai: 'state',
    tiaojianchuangjianshijian: 'creation time',
    tiaojiancaozuo: 'operate',
    tiaojianxiugai: 'Revise',
    tiaojianqidong: 'start up',
    tiaojianzanting: 'pause',
    tiaojianshanchu: 'delete',
    tiaojianlijixiadan: 'Order now',
    tiaojiankaiduo: 'Open long',
    tiaojianpingkong: 'Close short',
    tiaojiankaikong: 'Open short',
    tiaojianpingduo: 'Close long',
    tiaojianjiagetiaojian: 'price terms',
    tiaojianshijiantiaojian: 'time condition',
    tiaojianzuixinjia: 'latest price',
    tiaojianByijia: 'buy one price',
    tiaojianSyijia: 'Sell ​​one price',
    tiaojianzanting: 'pause',
    tiaojianyunxing: 'run',
    tiaojianyiwancheng: 'Completed',
    tiaojianshibai: 'fail',
    tiaojianyishanchu: 'Deleted',
    tiaojianshoudongchufawancheng: 'Manual trigger complete',
    tiaojianshoudongchufashibai: 'Manual trigger failed',
    xiadankaicang: 'Open',
    xiadanpingcang: 'Close',
    xiadanweituoleixing: 'Type',
    xiadanqingxuanze: 'Please select',
    xiadanshijia: 'market price',
    xiadanxianjia: 'price limit',
    xiadanjiage: 'price',
    xiadanqingshurujiage: 'Please enter price',
    xiadanshuliang: 'quantity',
    xiadanqingshurushuliang: 'Please enter quantity',
    xiadanzhang: 'Open',
    xiadankekaishuliang: 'Available quantity',
    xiadanqingxianxuanzechicangdan: 'Please select the position order first',
    xiadankepingshuliang: 'Quantity that can be leveled',
    xiadansuoxubaozhengjin: 'Required margin:',
    xiadanshijiazhiyingzhisunyushe: 'Market price take profit and stop loss preset',
    xiadanyujiqiangpingjiage: 'Estimated liquidation price:',
    xiadanqiangpingjiagetishi: 'This expected liquidation price is dynamically calculated based on total assets. This price is for reference only and will not be used as a final liquidation.',
    xiadanzuoduo: 'Long',
    xiadanzuokong: 'Short',
    xiadanpingcang: 'Close',
    zhanghuheyuezichan: 'Futures assets',
    zhanghuchongbi: 'Recharge',
    zhanghutibi: 'Withdraw',
    zhanghuhuazhuan: 'transfer',
    zhanghuzhanghuquanyi: 'Account rights',
    zhanghufudongyingkui: 'PNL',
    zhanghucangweibaozhengjin: 'Position margin',
    zhanghudongjiebaozhengjin: 'Freeze margin',
    zhanghukeyongquanyi: 'Available benefits',
    zhanghubaozhengjinshiyonglv: 'Margin utilization rate',
    hangqingheyuexinxi: 'Futures information',
    hangqingheyuemingcheng: 'Futures name',
    hangqingjijiahuobi: 'Quotation currency',
    hangqingheyuedaxiao: 'Futures size',
    hangqingzuixiaojiagebodong: 'minimum price fluctuation',
    dialogleverRateganggantiaozheng: 'Leverage adjustment',
    dialogleverRatequeding: 'Sure',
    dialogtransferzichanhuazhuan: 'Asset transfer',
    dialogtransferbizhong: 'Currency',
    dialogtransfercong: 'from',
    dialogtransferdao: 'arrive',
    dialogtransfershuliang: 'quantity',
    dialogtransferqingshuruhuazhuanshuliang: 'Please enter the transfer amount',
    dialogtransferquanbu: 'all',
    dialogtransferkeyong: 'Available',
    dialogtransferhuazhuan: 'transfer',
    dialogtransferjichuzhanghu: 'Trading account',
    dialogtransferheyuezhanghu: 'Futures account',
    dialogfitlosszhiyingzhisun: 'Take profit and stop loss',
    dialogfitlossheyu: 'Futures',
    dialogfitlossduokong: 'Long/Short',
    dialogfitlosschichangjunjia: 'average position price',
    dialogfitlossshuliang: 'quantity',
    dialogfitlosszuixinjia: 'latest price',
    dialogfitlosszhiyingdianshu: 'Take profit points',
    dialogfitlosszhisundianshu: 'Stop loss points',
    dialogfitlossdianshu: 'score',
    dialogfitlosszhiyingjiage: 'Take profit price',
    dialogfitlosszhisunjiage: 'Stop loss price',
    dialogfitlossjiage: 'price',
    dialogfitlossyuqishouyi: 'expected return',
    dialogfitlossyuqikuisun: 'expected loss',
    dialogfitlosstishi1: 'Tip: The minimum fluctuation of the current contract is',
    dialogfitlosstishi2: ', please set the price to 0 to cancel take profit and stop loss.',
    dialogfitlossqueding: 'Sure',
    dialogfitlossduo: 'Long',
    dialogfitlosskong: 'Short',
    dialogconditionzengjiayuntiaojiandan: 'Add cloud condition sheet',
    dialogconditiontiaojianleixing: 'Condition type:',
    dialogconditionjiagetiaojiandan: 'Price terms sheet',
    dialogconditionshijiantiaojiandan: 'Time condition sheet',
    dialogconditionjiageleixing: 'Price type:',
    dialogconditionzuixinjia: 'latest price',
    dialogconditionByijia: 'buy one price',
    dialogconditionSyijia: 'Sell ​​one price',
    dialogconditiontiaojianshezhi: 'Condition settings:',
    dialogconditionjiageshezhi: 'Price settings:',
    dialogconditionqingshurujiage: 'Please enter price',
    dialogconditionshijianshezhi: 'Time setting:',
    dialogconditionjiaoyileixing: 'Transaction type:',
    dialogconditionkaiduo: 'Open long',
    dialogconditionpingkong: 'Close short',
    dialogconditionkaikong: 'Open short',
    dialogconditionpingduo: 'Close long',
    dialogconditiondingdanshuliang: 'Order quantity:',
    dialogconditionqingshurudingdanshuliang: 'Please enter the order quantity',
    dialogconditiondingdanshixiao: 'Order time:',
    dialogconditionyongjiuyouxia: 'Valid permanently',
    dialogconditiontijiao: 'submit',
    dialogAutoProfitLosszhiyingzhisun: 'Take profit and stop loss',
    dialogAutoProfitLossmingcheng: 'name',
    dialogAutoProfitLosszhiyingdian: 'Take profit point',
    dialogAutoProfitLosszhisundian: 'Stop loss point',
    dialogAutoProfitLosschongzhi: 'reset',
    dialogAutoProfitLoss: 'submit',
    dialogCloseOrderpingcang: 'Close',
    dialogCloseOrderweituoleixing: 'Delegate type',
    dialogCloseOrderqingxuanze: 'Please select',
    dialogCloseOrdershijia: 'market price',
    dialogCloseOrderxianjia: 'price limit',
    dialogCloseOrderjiage: 'price',
    dialogCloseOrderqingshurujiage: 'Please enter price',
    dialogCloseOrdershuliang: 'quantity',
    dialogCloseOrderqingshurushuliang: 'Please enter quantity',
    dialogCloseOrderzhang: 'open',
    dialogCloseOrderkepingshuliang: 'Quantity that can be leveled',
    dialogCloseOrderqueding: 'Sure',
    jsMessageResgaiheyuegangganxiugaichenggong: 'The contract leverage was modified successfully',
    jsMessageResgaiheuyegangganxiugaishibai: 'The contract leverage modification failed',
    jsMessageReskaicangchenggong: 'Position opened successfully',
    jsMessageReskaicangshibai: 'Position opening failed:',
    jsMessageReskaicang1: 'Opening a position is prohibited',
    jsMessageReskaicang2: 'Contract does not exist',
    jsMessageReskaicang3: 'The number of open positions exceeds the upper limit',
    jsMessageReskaicang4: 'Price is 0',
    jsMessageReskaicang5: 'Insufficient margin for opening a position',
    jsMessageRespingcangchenggong: 'Position closed successfully',
    jsMessageRespingcangshibai: 'Failed to close position:',
    jsMessageRespingcang1: 'Contract does not exist',
    jsMessageRespingcang2: 'The position receipt does not exist',
    jsMessageRespingcang3: 'Insufficient amount',
    jsMessageReszijinhuazhuanchenggong: 'Fund transfer successful',
    jsMessageReszijinhuazhuanshibai: 'Fund transfer failed',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'Position order stop profit and stop loss set successfully',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'The stop-profit and stop-loss settings for position orders failed:',
    jsMessageResweituodanchedanchenggong: 'Order canceled successfully',
    jsMessageResweituodanchedanshibai: 'Order cancellation failed',
    jsMessageRestiaojiandantianjiachenggong: 'Conditional order added successfully',
    jsMessageResheyuebucunzai: 'Contract does not exist',
    jsMessageResjiageshezhicuowu: 'Price setting error',
    jsMessageResshijianshezhicuowu: 'Time setting error',
    jsMessageResneibucuowu: 'Internal error',
    jsMessageRestiaojiandantianjiashibai: 'Failed to add conditional order:',
    jsMessageReszhiyingzhisunshezhichenggong: 'Take profit and stop loss set successfully',
    jsconfirmquedingchedanma: 'Are you sure you want to cancel the order?',
    jsconfirmtishi: 'hint',
    jsconfirmqueding: 'Sure',
    jsconfirmquxiao: 'Cancel',
    jsMessageqingxiandenglu: 'Please log in first!',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'This contract has open positions and the leverage cannot be modified.',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'This contract has a mandate and the leverage cannot be modified.',
    jsMessageqingshuruzhengquedeshuliang: 'Please enter the correct quantity!',
    jsMessageqingshuruzhengquedejiage: 'Please enter the correct price!',
    jsMessageqingxianxuanzechicang: 'Please select a position first!',
    jsMessageqingshuruhuazhuanshuliang: 'Please enter the transfer amount!',
    jsMessageqingxianxuanzeheyue: 'Please select a contract first!',
    jsMessageqingshurutiaojiandanshuliang: 'Please enter the conditional order quantity!',
    jsMessageqingshurutiaojiandandejiage: 'Please enter the price of the conditional order!',
    jsMessageqingshurutiaojiandanzhixingshijian: 'Please enter the conditional order execution time!',
    emptytext: 'No data yet',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'Take profit and stop loss pips are set incorrectly',
    jsMessageReschicangdanbucunzai: 'The position receipt does not exist',
    zxkcje:'Minimum opening amount',
    },
    commisson:{
    dlzx:'Agency Center',
    sjzl:'Data overview',
    quanbu:"all",
    jinri:'today',
    zuori:'yesterday',
    ri:'day',
    wdfy:'My rebate',
    wdsjfy:'My third level rebate',
    dengji:'grade',
    yqrs:'Number of people invited',
    tdrs:'Team size',
    yqxq:'Invitation details',
    yhm:"username",
    cjsj:'creation time',
    fyxq:'Rebate details',
    zyj:'total commission',
    dryj:'Single day commission',
    fyje:'Rebate amount',
    
    },
    assets: {
    zongzichan: 'total assets',
    chongbi: 'Recharge',
    tibi: 'Withdraw',
    huazhuan: 'transfer',
    fabizhanghu: 'Trading account',
    heyuezhanghu: 'Futures account',
    jiayiliushui: 'transaction flow',
    dialogzichanhuazhuan: 'Asset transfer',
    cong: 'from',
    dao: 'arrive',
    shuliang: 'quantity',
    qingshuruhuazhuanshuliang: 'Please enter the transfer amount',
    quanbu: 'all',
    keyong: 'Available',
    huanzhuan: 'transfer',
    zijinhuazhuanchenggong: 'Fund transfer successful',
    zijinhuazhuanshibai: 'Fund transfer failed',
    bibizhanghu: 'Coin account',
    qiquanzhanghu:'options account',
    zczl:'Asset overview',
    zzczh:'Total assets discount',
    ztzc:"Assets in transit",
    djzc:'Freeze assets',
    zyzc:'Pledged assets',
    cwzc:'Position assets',
    jyzh:'trading account',
    bizhong:'Currency',
    dongjie:'freeze',
    lirun:"profit",
    hyzh:"Futures account",
    },
    basicsAccountRecord: {
    fanhui: 'return',
    fabizhanghuliushui: 'Trading account flow',
    fabijiaoyiliushuileixing: 'Transaction flow type',
    qingxuanze: 'Please select',
    chaxunriqi: 'Query date',
    liushuileixing: 'Pipeline type',
    shijian: 'time',
    biandongjine: 'Change amount',
    yue: 'Balance',
    quanbu: 'all',
    Sellbi: 'sell coins',
    Buybi: 'buy coins',
    tibi: 'Withdraw',
    chongbi: 'Recharge',
    fabizhuanheyue: 'Trading transfer Futures',
    heyuezhuanfabi: 'Futures to Trading',
    zhuanzhang: 'transfer',
    fabihuazhuandaoheyue: 'Trading transfer to Futures',
    heyuehuazhuandaofabi: 'Futures transfer to Trading',
    goumaijijin: 'Buy a mining pool',
    jijinshuhui: 'Mining pool redemption',
    jijinshouyi: 'Mining pool redemption income',
    fabizhuanqihuo: 'Trading conversion to Futures',
    qihuozhuanfabi: 'Futures transfer Trading',
    fabizhuanganggu: 'Trading transfer to Hong Kong stocks',
    gangguzhuanfabi: 'Basics of Hong Kong stock transfer',
    fabizhuanbibi: 'Trading transfer currency',
    bibizhuanfabi: 'Basics of currency to currency transfer',
    goumaiSGCB: 'Buy ICO',
    chushouSGCB: 'Selling ICO',
    huoqushibai: 'Failed to obtain',
    zanwushuju: 'No data yet',
    },
    contractAccountRecord: {
    fanhui: 'return',
    heyuezhanghuliushui: 'Trading account flow',
    heyuejiaoyiliushuileixing: 'Trading transaction flow type',
    qingxuanze: 'Please select',
    chaxunriqi: 'Query date',
    liushuileixing: 'Pipeline type',
    shijian: 'time',
    biandongjine: 'Change amount',
    yue: 'Balance',
    quanbu: 'all',
    kaicangshouxufei: 'Opening fee',
    pingcangshouxufei: 'Closing fee',
    pingcangyingkui: 'PNL',
    fabizhuanheyue: 'Trading transfer Trading',
    heyuezhuanfabi: 'Trading to Trading',
    zhuanzhang: 'transfer',
    kaicangyongjin: 'Opening commission',
    pingcangyongjin: 'Closing commission',
    huoqushibai: 'Failed to obtain',
    zanwushuju: 'No data yet',
    },
    recharge: {
    zanwushuju: 'No data yet',
    shijian: 'time',
    leixing: 'type',
    shuliang: 'quantity',
    zhuangtai: 'state',
    caozuo: 'operate',
    chakanxiangqing: 'check the details',
    huoqushibai: 'Failed to obtain',
    dialogtitle: 'Details',
    chongbi: 'Recharge',
    fanhui: 'return',
    lianmingcheng: 'Chain name',
    fuzhilianjie: 'Copy address',
    tishixinxi1: '• Please do not deposit any non-TRC_USDT or ERC_USDT assets to the above address, otherwise the assets will not be retrieved',
    tishixinxi2: '• After you recharge to the above address, the confirmation of the entire network node is required. The account will be received after 1 network confirmation, and the currency can be withdrawn after 2 network confirmations.',
    tishixinxi3: '• Minimum deposit amount: 50USDT, deposits less than the minimum amount will not be credited and cannot be refunded',
    tishixinxi4: '• Your recharge address will not change frequently and can be recharged repeatedly; if there is any change, we will try our best to notify you through website announcements or emails',
    tishixinxi5: '• Please make sure your computer and browser are secure to prevent information from being tampered with or leaked.',
    putongchongbi: 'Ordinary deposit',
    jiaoyichenggong: 'Transaction successful',
    jiaoyishibai: 'Transaction failed',
    OMNIweihuzhong: 'OMNI chain is under maintenance, please select TRC20',
    },
    withdraw: {
    fanhui: 'return',
    zanwushuju: 'No data yet',
    tibi: 'Withdraw coins',
    huoqushibai: 'Failed to obtain',
    OMNIweihuzhong: 'OMNI chain is under maintenance, please select TRC20',
    shijian: 'time',
    leixing: 'type',
    shuliang: 'quantity',
    zhuangtai: 'state',
    caozuo: 'operate',
    chakanxiangqing: 'check the details',
    putongtibi: 'Ordinary withdrawal',
    dialogtitle: 'Details',
    dialogtitlewithdraw: 'Withdraw coins',
    houchongxinfasong: 'Resend after S',
    huoquyouxiangyanzhengma: 'Get code',
    lianmingcheng: 'Chain name',
    tibidizhi: 'Withdrawal address',
    qingshurutibidizhi: 'Please enter the withdrawal address',
    tibishuliang: 'Amount of coins withdrawn',
    shouxufei: 'handling fee',
    qingshurutibishuliang: 'Please enter the withdrawal amount',
    quanbu: 'all',
    keyong: 'Available',
    daozhangshuliang: 'Arrival quantity',
    zuixiaotibishuliang: 'The minimum withdrawal amount is:',
    tishixinxi: 'In order to ensure the security of funds, when your account security policy changes or your password is modified, we will conduct a manual review of the withdrawal. Please be patient and wait for the staff to contact you by phone or email. Please make sure your computer and browser are secure to prevent data from being tampered with or leaked.',
    tuxingyanzhengma: 'Graphic verification code',
    qingshurutuxingyanzhengma: 'Please enter the graphic verification code',
    youxiangyanzhengma: 'Email verification code',
    queding: 'Sure',
    tijiaozhong: 'Submitting',
    yijujue: 'Rejected',
    yitongguo: 'Passed',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Failed to obtain graphic verification code, please request again',
    huoquyouxiangyanzhengmashibai: 'Failed to obtain email verification code,',
    qingxianwanchengyouxiangrenzheng: 'Please complete email verification first',
    huoquyouxiangshibaiqingchongxinqingqiu: 'Failed to obtain email address, please request again',
    qingshuruyouxiangyanzhengma: 'Please enter the email verification code',
    youxiangyanzhengmacuowu: 'Email verification code is wrong,',
    qxsmrz:'Please verify your real name first',
    tishi:'hint',
    queding:'Sure'
    },
    contractTransactionRecord: {
    fanhui: 'return',
    zanwushuju: 'No data yet',
    heyuedingdan: 'contract order',
    qingxuanze: 'Please select',
    quanbu: 'all',
    kaiduo: 'Open long',
    pingkong: 'Close short',
    kaikong: 'Open short',
    pingduo: 'Close long',
    xianjiaweituo: 'limit order',
    shijiaweituo: 'market order',
    suoyouzhuangtai: 'All status',
    tijiaoshibai: 'Submission failed',
    zhunbeitijiao: 'ready to submit',
    yitijiao: 'Submitted',
    bufentijiao: 'Partial deal',
    bufentijiaoyichedan: 'Some orders have been canceled',
    quanbuchengjiao: 'All transactions',
    yichedan: 'Order canceled',
    huoqushibai: 'Failed to obtain',
    heyuedaima: 'Futures code',
    ganggan: 'lever',
    xiadanshijian: 'Order time',
    chengjiaoshijian: 'Transaction time',
    fangxiang: 'direction',
    baojialeixing: 'Quote type',
    weituoliang: 'Commission quantity',
    weituojia: 'Entrusted price (USDT)',
    chengjiaoliang: 'Trading volume ',
    chengjiaojunjia: 'Average transaction price (USDT)',
    yingkui: 'PNL(USDT)',
    shouxufei: 'Handling fee(USDT)',
    zhuangtai: 'state',
    gupiaodingdan: 'stock order',
    waihuidingdan: 'Forex orders',
    gupiaodaima: 'Stock code',
    waihuidaima: 'Forex code',
    },
    kLine: {
    zhibiao: 'index',
    shezhi: 'set up',
    quanping: 'full screen',
    tuichuquanping: 'Exit full screen',
    jishuzhibiao: 'Technical indicators',
    futuzhibiao: 'Sub-picture indicator',
    zhutuzhibiao: 'Main chart indicator',
    huifumoren: 'Restore default',
    lazhutuleixing: 'Candlestick Types',
    qingxuanze: 'Please select',
    quanshixin: 'All solid',
    quankongxin: 'Fully hollow',
    zhangkongxin: 'rising hollow',
    diekongxin: 'fall hollow',
    mianjitu: 'area chart',
    jiagezhouleixing: 'Price axis type',
    xianxingzhou: 'line axis',
    baifenbizhou: 'Percent axis',
    duishuzhou: 'Logarithmic axis',
    zuigaojiaxianshi: 'Highest price display',
    zuidijiaxianshi: 'Lowest price display',
    zuixinjiaxianshi: 'Latest price display',
    zhibiaozuixinzhixianshi: 'The latest value of the indicator is displayed',
    daozhizuobiao: "inverted coordinates",
    wanggexianxianshi: 'Grid line display',
    hfcg:'Recovery successful'
    },
    SecurityCenter: {
    fanhui: 'return',
    anquanzhongxin: 'Security Center',
    qingshurunicheng: 'Please enter a nickname',
    nichengxiugaishibai: 'Nickname modification failed',
    huoquyonghuxinxishibai: 'Failed to obtain user information',
    xiugaidenglumima: 'Change login password',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Failed to obtain graphic verification code, please request again',
    qingshurujiumima: 'Please enter old password',
    qingshuruxinmima: 'Please enter new password',
    qingzaicishuruxinmima: 'Please enter new password again',
    qingshurutuxingyanzhengma: 'Please enter the graphic verification code',
    qingshuruyanzhengma: 'Please enter the verification code',
    huoquzhuceshoujihaoshibai: 'Failed to obtain registered mobile number',
    qingxianwanchengshoujirenzheng: 'Please complete mobile phone authentication first',
    huoqushoujiyanzhengmashibai: 'Failed to obtain mobile phone verification code',
    huoqushoujiyanzhengma: 'Get mobile phone verification code',
    houchongxinfasong: 'Resend after S',
    mimacuowu: 'Wrong password',
    liangcixinmimashurubuyizhiqingjiancha: 'The two new passwords entered are inconsistent, please check',
    xinmimaheyuanmimabunengyiyang: 'The new password cannot be the same as the original password',
    qingshurushoujiyanzhengma: 'Please enter the mobile phone verification code',
    yanzhengmacuowu: 'Verification code error',
    denglumimaxiugaichenggong: 'Login password changed successfully',
    denglumimaxiugaishibai: 'Login password modification failed',
    xiugaizijinmima: 'Change fund password',
    qingshuruzijinmima: 'Please enter the fund password',
    qingzaicishuruzijinmima: 'Please enter the fund password again',
    liangcizijinmimabuyizhiqingjiancha: 'The two fund passwords are inconsistent, please check',
    shezhizijinmimachenggong: 'Set fund password successfully',
    shezhizijinmimashibai: 'Failed to set fund password',
    xiugaizijinmimachenggong: 'Fund password modified successfully',
    xiugaizijinmimashibai: 'Failed to change fund password',
    youxiangrenzheng: 'Email authentication',
    huoquyouxiangyanzhengma: 'Get code',
    qingshuruyouxiangdizhi: 'Please enter email address',
    huoquyouxiangyanzhengmashibai: 'Failed to obtain email verification code',
    qingshuruyouxiangyanzhengma: 'Please enter the email verification code',
    youxiangyanzhengchenggong: 'Email verification successful',
    weirenzheng: 'Not certified',
    queding: 'Sure',
    anquanshezhiguanli: 'Security settings management',
    denglumima: 'Login password',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'By setting a login password, you will be able to log in directly using your account password',
    xiugai: 'Revise',
    zijinmima: 'Fund password',
    yongyubaohuzijinanquan: 'Used to protect the safety of funds',
    shezhi: 'set up',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'Used for security verification when logging in, withdrawing coins, retrieving passwords, and modifying security settings.',
    yirenzheng: 'Certified',
    renzheng: 'Certification',
    shimingrenzheng: 'Real name authentication',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'Used for security authentication when buying, selling, withdrawing, depositing, and transferring coins.',
    daishenhe: 'Pending review',
    yibohui: 'Dismissed',
    yuandenglumima: 'Original login password',
    xinmima: 'New Password',
    quedingxinmima: 'Confirm new password',
    tuxingyanzhengma: 'Graphic verification code',
    yanzhengma: 'Verification code',
    zijinmima: 'Fund password',
    querenzijinmima: 'Confirm fund password',
    youxiangdizhi: 'Email address',
    qingxianwanchengyouxiangrenzheng: 'Please complete email verification first',
    xiugaitouxiangchenggong: 'Modified avatar successfully',
    xiugaitouxiangshibai: 'Failed to modify avatar',
    shangchuantouxiangzhinengshijpggeshi: 'Upload avatar pictures can only be in JPG format!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'The size of the uploaded avatar image cannot exceed 2MB!',
    },
    verification: {
    fanhui: 'return',
    shimingrenzheng: 'Real name authentication',
    qingshuruxingming: 'Please enter name',
    qingshuruzhengjianhaoma: 'Please enter your ID number',
    huoquyonghuxinxishibai: 'Failed to obtain user information',
    wenjiangeshicuowu: 'File format error',
    wenjianbunengdayu5M: 'File cannot be larger than 5M',
    qingtianxiexingming: 'Please fill in your name',
    qingtianxiezhengjianhaoma: 'Please fill in the ID number',
    qingshangchuanzhengjianzhengmian: 'Please upload the front side of your ID',
    qingshangchuanzhengjianfanmian: 'Please upload the reverse side of your ID',
    tijiaochenggong: 'Submission successful',
    kaiqishenfenrenzheng: 'Turn on identity authentication',
    bohuiyuanyin: 'Reason for rejection',
    qingtianxiejiashizhizhaogongminkahuzhao: 'Please fill in your driving license/citizen card/passport information',
    xingming: 'Name',
    zhengjianhaoma: 'ID number',
    qingshangchuanjiashizhizhaogongminkahuzhao: 'Please upload driver\'s license/citizen card/passport information',
    shangchuanzhengjianzhengmian: 'Upload the front of the ID',
    huotuozhuafangru: 'Or drag and drop',
    shangchuanzhengjianfanmian: 'Upload the reverse side of the certificate',
    tijiao: 'submit',
    },
    invite: {
    yaoqinghaoyoudejiangli: 'Invite friends and get rewards',
    yongjiukede: 'permanently available',
    jiaoyifanyong: 'Trading rebate',
    fasongyaoqinggeihaoyou: 'Send invitation to friends',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'Invite friends to register BCFX by sharing the link',
    haoyouzhuce: 'Friend registration',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'The friend accepts the invitation, completes registration and conducts transactions',
    huodexiangyingbilijiangli: 'Receive corresponding proportional rewards',
    qingsonghuodejiaoyishouxufeijianglifuli: 'Easily get transaction fee bonus benefits',
    wodezhuanshufenxiangfangshi: 'My exclusive way to share',
    yaoqinglianjie: 'Invitation link',
    jiazaizhong: 'loading...',
    fuzhilianjie: 'Copy link',
    yaoqingma: 'Invitation code',
    fuzhiyaoqingma: 'Copy invitation code',
    wodeyaoqing: 'my invitation',
    yaoqingrenshu: 'Number of people invited',
    huodejiangli: 'Get rewarded',
    huodongxize: 'Event details',
    tips1: 'The relationship between platform users is three levels, such as: ABCD users constitute three levels of users, and A is the highest level!',
    tips2: 'Interest relationships between levels',
    tips201: '。',
    tips3: 'Cash reward: Client A can receive 40% of Client B’s contract transaction fee + 20% of Client C’s contract transaction fee + 10% of Client D’s contract transaction fee',
    tips4: 'For example: Customer A has 10 first-level customers B, 10 second-level customers C, and 10 third-level customers D. If each customer contract trades 100,000 USDT',
    tips5: 'Then the return that customer A can get',
    tips6: '100000*1.2%*40%*10+100000*1.2%*20%*10+100000*1.2%*10%*10=8400 USDT cash reward, which can be withdrawn in real time',
    tips7: 'If the activity is adjusted, it will be subject to BCFX platform updates, and the final right of interpretation belongs to BCFX.',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Failed to obtain graphic verification code, please request again',
    qingshuruyouxiang: 'Please enter your email',
    qingshurutuxingyanzhengma: 'Please enter the graphic verification code',
    qingshuruyanzhengma: 'Please enter the verification code',
    qingshurunindemima: 'Please enter your password',
    qingzaicishurunindemima: 'Please enter your password again',
    qingshuruzhifumima: 'Please enter payment password',
    xuantian: 'Optional',
    bitian: 'Required',
    yonghuzhuce: 'User registration',
    huanyingdenglu: 'Welcome to log in',
    guanfangwangzhan: 'official website',
    xiangmumingcheng: 'BCFX',
    youxiang: 'Mail',
    tuxingyanzhengma: 'Graphic verification code',
    yanzhengma: 'Verification code',
    huoquyanzhengma: 'Get code',
    shezhimima: 'Set password',
    quedingmima: 'Confirm password',
    zhifumima: 'Payment password',
    yaoqingma: 'Invitation code',
    zhuce: 'register',
    yiyouzhanghao: 'Already have an account? Go',
    denglu: 'Log in',
    qingshuruyouxiangdizhi: 'Please enter email address',
    huoqushibai: 'Failed to obtain',
    houchongxinfasong: 'Resend after S',
    liangcishurudemimabuyizhi: 'The passwords entered twice are inconsistent',
    zhucechenggong: 'Registration successful',
    zhuceshibai: 'Registration failed',
    },
    treasure:{
    jichubizhong: 'base currency',
    shouyibizhong: 'Revenue currency',
    wakuangtianshu: 'Mining days',
    shouyitianshu: 'Earning days',
    suodingtianshu: 'Lock days',
    yichanshengshouyi: 'Revenue generated',
    yuqishouyi: 'expected return',
    kaishishijian: 'start time',
    jieshushijian: 'end time',
    qingshuruninyaosuodingdebizhongshuliang: 'Please enter the amount of USDT in the currency you want to lock',
    huoqushibai: 'Failed to obtain',
    meiyitian: 'every 1 day',
    dakai: 'Open',
    yuanyuzhoukuangchi: 'Metaverse Mining Pool',
    jilu: 'Record',
    kuangchixiangqing: 'Mining pool details',
    suo: 'Lock',
    yitianchanchu: '1 day output',
    zuixiaosuodingshijian: 'Minimum lock time',
    nindeUSDT: 'Your USDT',
    tian: 'sky',
    quanbu: 'all',
    suoUSDTbingkaishishengchan: 'Lock USDT and start production',
    ninquedingyaosuoUSDTkaishishengchanma: 'Are you sure you want to lock USDT and start production?',
    tishi: 'hint',
    queding: 'Sure',
    quxiao: 'Cancel',
    kaishishengchan: 'Start production!',
    yiquxiao: 'Canceled',
    },
    subscription:{
    rengouliebiao:'Subscription list',
    jijiangdaolai:'Coming soon',
    jinxingzhong:'in progress',
    quanbu:'all',
    keshiyonghuobi:"Available currencies",
    zongshuliang:'total quantity',
    meiyouxianzhi:'no limit',
    shengyushuliang:'Remaining quantity',
    kaishishijian:'start time',
    zhuangtai:'state',
    jilu:'Record',
    zanwujilu:"No record yet",
    leixing:'type',
    shuliang:'quantity',
    goumaijine:'Purchase amount',
    shijian:'time',
    goumai:'Buy',
    duichu:'Out of nowhere',
    jiangli:'award',
    tibi:'Withdraw coins',
    huidaomulu:'Return to table of contents',
    dangqianchiyou:'currently held',
    keyong:'Available',
    keduihuan:'Redeemable',
    shishihangqing:'Real-time quotes',
    yue:'Balance',
    biaoti:'title',
    fashoushuliang:'Quantity for sale',
    jindu:"schedule",
    goumaishuliang:'Purchase quantity',
    ge:'indivual',
    woyiyuedu:'I have read',
    daibigoumaixieyi:'Token Purchase Agreement',
    qingshurugoumaijine:'Please enter the purchase amount',
    shifoyaogoumai:'Do you want to buy',
    tishi:'hint',
    queding:'Sure',
    quxiao:'Cancel',
    yjs:'ended',
    ywc:'Completed',
    sgjg:'Subscription price',
    jssj:"end time",
    zqsl:"Winning quantity",
    zqje:"Winning amount",
    tian:"sky",
    shi:'hour',
    fen:'point',
    miao:'Second',
    shengou:'Subscription',
    xmxq:'Project details',
    },
    }
    
    