import ctsTrade from '@/utils/cts_msg_pb.js';
import { baseMethods } from '@/utils/methods.js';
import store from '@/store'
import context from '../main'
import newPriceFormat from './filters'
function calcholdProfit(Contractcode, newprice) {
    var num = 0;
    store.state.holdOrderList.forEach(item => {
        if (item.Contractcode === Contractcode) {
            if (item.direction === 0) {
                item.Profit =baseMethods.accMul(baseMethods.accMul(baseMethods.accSub(newprice, item.Costopen), item.hysize), item.volume) ;
            } else {
                item.Profit = baseMethods.accMul(baseMethods.accMul(baseMethods.accSub(item.Costopen, newprice), item.hysize), item.volume)
            }
        }
        num = num + item.Profit;
    }); 
    store.state.userWallet.calcprofit = num;
};
export function parseData(decPack) {
    // console.log(decPack.msgType);
    switch (decPack.msgType) {
        case 12002:
            var res = proto.cts.QI2QSMarketDepthRes.deserializeBinary(decPack.data);
            var list = res.getQuotepriceqtyList();
            var buytotal = 0;
            var selltotal = 0;
            store.state.hqlist.forEach (item => {
                if (item.code == baseMethods.Utf8ArrayToStr(res.getCodenum_asU8())) {
                    if (item.fivehqtime + 200 < res.getTimestamp()) {
                        item.fivehqtime = res.getTimestamp();
                        obj = item.fiveHqList;

                        list.forEach ((item, index) => {
                            if (index == 0) {
                                obj.buyprice0 = item.getQbidprice();
                                obj.buyvol0 = item.getQbidqty();
                                obj.saleprice0 = item.getQaskprice();
                                obj.salevol0 = item.getQaskqty();
                                buytotal = buytotal + obj.buyvol0;
                                selltotal = selltotal + obj.salevol0;
                            }
                            if (index == 1) {
                                obj.buyprice1 = item.getQbidprice();
                                obj.buyvol1 = item.getQbidqty();
                                obj.saleprice1 = item.getQaskprice();
                                obj.salevol1 = item.getQaskqty();
                                buytotal = buytotal + obj.buyvol1;
                                selltotal = selltotal + obj.salevol1;
                            }
                            if (index == 2) {
                                obj.buyprice2 = item.getQbidprice();
                                obj.buyvol2 = item.getQbidqty();
                                obj.saleprice2 = item.getQaskprice();
                                obj.salevol2 = item.getQaskqty();
                                buytotal = buytotal + obj.buyvol2;
                                selltotal = selltotal + obj.salevol2;
                            }
                            if (index == 3) {
                                obj.buyprice3 = item.getQbidprice();
                                obj.buyvol3 = item.getQbidqty();
                                obj.saleprice3 = item.getQaskprice();
                                obj.salevol3 = item.getQaskqty();
                                buytotal = buytotal + obj.buyvol3;
                                selltotal = selltotal + obj.salevol3;
                            }
                            if (index == 4) {
                                obj.buyprice4 = item.getQbidprice();
                                obj.buyvol4 = item.getQbidqty();
                                obj.saleprice4 = item.getQaskprice();
                                obj.salevol4 = item.getQaskqty();
                                buytotal = buytotal + obj.buyvol4;
                                selltotal = selltotal + obj.salevol4;
                            }
                            

                        });
                        obj.buytotal = buytotal;
                        obj.selltotal = selltotal;
                    } 
                }
            });
            // context.$root.$emit('QI2QSMarketDepthRes', MarketDepth);
            break;
        case 12003:
            var res = proto.cts.QI2QSMarketDetailRes.deserializeBinary(decPack.data);
            store.state.hqlist.forEach (item => {
                if (item.code == baseMethods.Utf8ArrayToStr(res.getCodenum_asU8())) {
                    item.high = res.getHighprice();
                    item.low = res.getLowprice();
                    item.open = res.getOpenprice();
                    item.close = res.getCloseprice();
                    item.dealnum = res.getVolume();
                    item.Totalnum = res.getCount();
                }  
            })
            break;
        case 12004:
            var res = proto.cts.QI2QSTradeDetailRes.deserializeBinary(decPack.data);
            var list = res.getTradedetailList();
            store.state.hqlist.forEach (item => {
                if (item.code == baseMethods.Utf8ArrayToStr(res.getCodenum_asU8())) {
                    if (item.lastpricetime + 800 < list[0].getTimestamp()) {
                        item.price = list[0].getPrice();
                        item.close = list[0].getPrice();
                        item.vol = list[0].getAmount();
                        item.second = list[0].getTimestamp();
                        item.lastpricetime = list[0].getTimestamp();
                    }

                    //每个合约一秒刷新一次K线
                    if (item.lastKline + 800 < list[0].getTimestamp()) {
                        item.lastKline = list[0].getTimestamp() ;
                        var quotesObj = {
                            code: item.code,
                            price: item.price,
                            second: item.second,
                            Totalnum: item.dealnum,
                        };
                        context.$root.$emit('TradeDetailRes', quotesObj);
                    }
                    //每个合约五秒计算一下盈亏
                    if (item.calcProfittime + 10000 < list[0].getTimestamp()) {
                        item.calcProfittime = list[0].getTimestamp() ;
                        calcholdProfit(baseMethods.Utf8ArrayToStr(res.getCodenum_asU8()), list[0].getPrice());
                    }
                }
            })	
            break;
        case 12005:
            //行情接口推送逐笔行情数据到行情服务
            var res = proto.cts.QI2QSTradeBboRes.deserializeBinary(decPack.data);
            var obj = res.getQuotepriceqty();
            store.state.hqlist.forEach (item => {
                if (item.code == baseMethods.Utf8ArrayToStr(res.getCodenum_asU8())) {
                    if (item.TradeBbotime + 200 < res.getTimestamp()) {
                        item.latestdealList.price5 = item.latestdealList.price4;
                        item.latestdealList.num5 = item.latestdealList.num4;
                        item.latestdealList.time5 = item.latestdealList.time4;
                        item.latestdealList.price4 = item.latestdealList.price3;
                        item.latestdealList.num4 = item.latestdealList.num3;
                        item.latestdealList.time4 = item.latestdealList.time3;
                        item.latestdealList.price3 = item.latestdealList.price2;
                        item.latestdealList.num3 = item.latestdealList.num2;
                        item.latestdealList.time3 = item.latestdealList.time2;
                        item.latestdealList.price2 = item.latestdealList.price1;
                        item.latestdealList.num2 = item.latestdealList.num1;
                        item.latestdealList.time2 = item.latestdealList.time1;
                        item.latestdealList.price1 = item.latestdealList.price0;
                        item.latestdealList.num1 = item.latestdealList.num0;
                        item.latestdealList.time1 = item.latestdealList.time0;
                        item.latestdealList.price0 = obj.getQbidprice();
                        item.latestdealList.num0 = obj.getQbidqty();
                        item.latestdealList.time0 = res.getTimestamp();
                        item.TradeBbotime = res.getTimestamp();
                    }
                }
            });
           
            break;
        case 13552:
            var res = proto.cts.QS2CContractListRes.deserializeBinary( decPack.data );
            var list = res.getContractList();
            if (store.state.hqlist.length > 0) { return; }
            list.forEach (item => {
                if (item.getSymbol() != 'FX' && item.getSymbol() != 'US') {
                    store.state.contractList.push(item);
                }
                
                var hy_wave = item.getPricetick()
                var  float_num;//最小波动,最小波动小数位数
                var y = String(hy_wave).indexOf(".") + 1;
                var count = String(hy_wave).length - y;
                if (y > 0) {
                    float_num = count
                } else {
                    float_num = 0
                };

                var AutoProfitLossInfo = {};
                AutoProfitLossInfo.contractCode = item.getContractcode();
                AutoProfitLossInfo.profit = 0;
                AutoProfitLossInfo.loss = 0;
                AutoProfitLossInfo.inputprofit = 0;
                AutoProfitLossInfo.inputloss = 0;
                AutoProfitLossInfo.precision = float_num;
                AutoProfitLossInfo.wave = item.getPricetick();
                store.state.AutoProfitLossList.push(AutoProfitLossInfo);

                var dealList = {};
                dealList.price0 = 0;
                dealList.num0 = 0;
                dealList.time0 = 0;
                dealList.price1 = 0;
                dealList.num1 = 0;
                dealList.time1 = 0;
                dealList.price2 = 0;
                dealList.num2 = 0;
                dealList.time2 = 0;
                dealList.price3 = 0;
                dealList.num3 = 0;
                dealList.time3 = 0;
                dealList.price4 = 0;
                dealList.num4 = 0;
                dealList.time4 = 0;
                dealList.price5 = 0;
                dealList.num5 = 0;
                dealList.time5 = 0;

                var obj = {};
                obj.symbol = item.getSymbol()
                obj.code = item.getContractcode();
                obj.val = item.getContractsize();
                obj.id = item.getId();
                obj.wave = item.getPricetick();
                // obj.sort = item.getSort();
                obj.float_num = float_num;
                obj.price = item.getLastprice();
                obj.high = 0;
                obj.low = 0;
                obj.open = item.getOpenprice();
                obj.close = item.getLastprice();
                obj.rate = 0;
                obj.calcProfittime = 0;
                obj.TradeBbotime = 0;
                obj.lastpricetime = 0;
                obj.fivehqtime = 0;
                obj.lastKline = 0;
                obj.fiveHqList = {
                    buyprice0: 0,
                    buyvol0: 0,
                    saleprice0: 0,
                    salevol0: 0,
                    buyprice1: 0,
                    buyvol1: 0,
                    saleprice1: 0,
                    salevol1: 0,
                    buyprice2: 0,
                    buyvol2: 0,
                    saleprice2: 0,
                    salevol2: 0,
                    buyprice3: 0,
                    buyvol3: 0,
                    saleprice3: 0,
                    salevol3: 0,
                    buyprice4: 0,
                    buyvol4: 0,
                    saleprice4: 0,
                    salevol4: 0,
                    buytotal: 0,
                    selltotal: 0,
                };
                obj.latestdealList = dealList;
                if (item.getSymbol() != 'FX' && item.getSymbol() != 'US') {
                    store.state.hqlist.push(obj);
                }
                
            })
            console.log( store.state.hqlist)
            context.$root.$emit('QuotesContractList', '');
            // console.log(store.state.hqlistWh);
            break;
        default:
            // console.log('unKnown type:' + decPack.msgType);
            break;
    }
}