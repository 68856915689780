<template>
    <div class="middle">
        <div class="ding"></div>
        
        <div style="display: flex;justify-content: space-between;">
            <div style="margin-left:15%;display: flex;align-items: center;">
                <img src="../assets/images/loginBack.png" alt="" style="width:500px;height:500px;">
            </div>
            <div class="user Sign" style="margin-right:15%">
                <div class="user-gang">|</div>
                <h1> {{ i18n('yonghudenglu') }} </h1><br>
                <div class="user-fbex"> 
                    {{ i18n('huanyingdenglu') }} 
                    <span style="color: #F0B90B;"> {{ i18n('xiangmumingcheng') }} </span> 
                    {{ i18n('guanfangwangzhan') }} 
                </div>
                <div class="user-id">
                    {{ i18n('youxiang') }} <br>
                    <el-input 
                        v-model="inputid" 
                        type="text" 
                        size="medium" 
                        class="input-id"
                        :placeholder="i18n('qingshuruyouxiang')"></el-input>
                </div>
                <div class="password">
                    {{ i18n('mima') }} <br>
                    <el-input 
                        v-model="inputpassword" 
                        size="medium" 
                        class="input-password"
                        :placeholder="i18n('qingshurumima')" 
                        show-password 
                        minlength="6" 
                        maxlength="16"></el-input>
                </div>
                <div class="user-forget" @click="userforgetClick"> {{ i18n('wangjimima') }} </div>
                <div class="user-button">
                    <el-button 
                    style="background-color: #FCD535;border:none;color:#333"
                        type="primary" 
                        @click="handleLogin" 
                        ref='loadingBut'
                        :loading=loading> {{ i18n('denglu') }} </el-button>
                        
                </div>
                <div class="user-button" style="margin-top:20px">
                    <el-button 
                    type="warning"
                    plain
                        @click="googleLogin" 
                        > 
                        <div style="display:flex;justify-content: center;align-items: center;">
                            <img src="../assets/images/guge.png" alt="" style='width:20px;height:20px'>
                            <div style="margin-left:20px">Google {{ i18n('denglu') }} </div>
                        </div>
                     </el-button>
                        
                </div>
                <div class="user-account">
                    {{ i18n('haimeiyouzhanghao') }} 
                    <span class="button-register" style="cursor: pointer;" @click="registerClick"> {{ i18n('zhuce') }} </span>
                </div>
            </div>
        </div>
        <el-dialog 
        :title="i18n('youxiangrenzheng')"
        class="dialogyouxiangrenzheng" 
        :visible.sync="dialogyouxiangrenzheng" 
        :close-on-click-modal=false
        :close-on-press-escape=false
        width="500px">    
            <div class="flex_row_center">
                <div class="Change-frame">
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('youxiangdizhi')}} <br>
                        <!-- <input type="tel" class="email" placeholder="请输入邮箱地址" /> -->
                        <el-input 
                            v-model="emailAddr" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="i18n('qingshuruyouxiangdizhi')"></el-input>
                    </div>
                    <!-- <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('tuxingyanzhengma')}} <br>
                        <div class="flex_row_left">
                           
                            <el-input 
                            v-model="imgcode" 
                            style="width: 220px;  margin-top: 12px;"
                            :placeholder="i18n('qingshurutuxingyanzhengma')"></el-input>
                            <img @click="getImgCode" class="authimg" :src="imgsrc" alt="" style="cursor: pointer; margin-left: 20px;">
                        </div>
                    </div> -->
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('yanzhengma')}} <br>
                        <div class="flex_row_left">
                           
                            <el-input 
                            v-model="emailtextcode" 
                            style="width: 220px;  margin-top: 12px;"
                            :placeholder="i18n('qingshuruyouxiangyanzhengma')"></el-input>
                            <div :class="{'getcode': true, 'noclick': !getemailcodecanClick}" data-type="4" @click="getemailcode()"> {{ getemailcodeTitle }} </div>
                        </div>
                    </div>
                    <div class="Change-cancel flex_row_center">
                        <div class="but-determine emailqueding" @click="emailauthsubmit"> {{ i18n('queding')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog :title="i18n('xiugaidenglumima')" 
        class="dialogchangepassword" 
        :visible.sync="dialogchangepassword" 
        :close-on-click-modal=false
        :close-on-press-escape=false
        width="500px">
            <div class="flex_row_center">
                <div class="Change-frame">
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('xinmima')}} <br>
                        <!-- <input class="newpsw" type="password" /> -->
                        <el-input 
                            v-model="newpassword" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="i18n('qingshuruxinmima')"></el-input>
                    </div>
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('quedingxinmima')}} <br>
                        <!-- <input class="surenewpsw" type="password" /> -->
                        <el-input 
                            v-model="newpassword2" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="i18n('qingzaicishuruxinmima')"></el-input>
                    </div>
                    <div class="Change-cancel flex_row_center">
                        <div class="but-determine changePsw" @click="changePswclick"> {{ i18n('queding')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog title="Google" 
        class="dialogchangepassword" 
        :visible.sync="dialogGoogle" 
        :close-on-click-modal=false
        :close-on-press-escape=false
        width="500px">
            <div class="flex_row_center">
                <div class="Change-frame">
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('xinmima')}} <br>
                        <!-- <input class="newpsw" type="password" /> -->
                        <el-input 
                            v-model="regparam.userpassword" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="i18n('qingshuruxinmima')"></el-input>
                    </div>
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('quedingxinmima')}} <br>
                        <!-- <input class="surenewpsw" type="password" /> -->
                        <el-input 
                            v-model="regparam.testpassword" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="i18n('qingzaicishuruxinmima')"></el-input>
                    </div>
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ $t('register')['zhifumima']}} <br>
                        <!-- <input class="surenewpsw" type="password" /> -->
                        <el-input 
                            v-model="regparam.payPassword" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="pl1"></el-input>
                    </div>
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ $t('register')['yaoqingma']}} <br>
                        <!-- <input class="surenewpsw" type="password" /> -->
                        <el-input 
                            v-model="regparam.invitecode" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="pl2"></el-input>
                    </div>
                    <div class="Change-cancel flex_row_center">
                        <div class="but-determine changePsw" @click="googleRegSub()"> {{ i18n('queding')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog title="Google" 
        class="dialogchangepassword" 
        :visible.sync="dialogGoogleTb" 
        :close-on-click-modal=false
        :close-on-press-escape=false
        width="500px">
            <div class="flex_row_center">
                <div class="Change-frame">
                    <div class="Change-input" style="margin-bottom: 20px;"> {{ i18n('mima')}} <br>
                        <!-- <input class="newpsw" type="password" /> -->
                        <el-input 
                            v-model="regparamTb.userpassword" 
                            style="width: 400px; margin-top: 12px;"
                            :placeholder="i18n('qingshurumima')"></el-input>
                    </div>
                    
                    <div class="Change-cancel flex_row_center">
                        <div class="but-determine changePsw" @click="googleTbRegSub()"> {{ i18n('queding')}} </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import config from '@/utils/configs'
const loadingsss = ''
export default {
    data() {
        return {
            loading: false,
            error: '',
            inputid: undefined,
            inputpassword: undefined,
            dialogyouxiangrenzheng: false,
            emailAddr: undefined,
            imgcode: undefined,
            emailtextcode: undefined,
            getemailcodecanClick: true,
            emailcount: 0,
            emailcountDowntimer: null,
            getemailcodeTitle: this.$t('register')['huoquyanzhengma'],
            emailtextcodecid: undefined,
            imgsrc: undefined,
            imgcodeid: undefined,
            dialogchangepassword: false,
            newpassword: undefined,
            newpassword2: undefined,
            account: undefined,
            dialogGoogle:false,
            pl1: this.$t('register')['qingshuruzhifumima'],
            pl2:this.$t('register')['bitian'],
            regparam: {
                userpassword: '',
                payPassword: '',
                invitecode: '',
                useraccount:'',
                testpassword:'',
                useraccount:'',
                googleID:''
            },
            dialogGoogleTb:false,
            regparamTb:{
                useraccount:'',
                googleID:'',
                userpassword:''
            }
        };
    },
    mounted() {
        this.$root.$on('tradelogin', (msg) => {
            this.loading = false;
            if(loadingsss){
                loadingsss.close()
            }
            if (msg.loginStatus) {
                //登录成功提示
                this.$message({
                    message: this.$t("login")['dengluchenggong'],
                    duration:2000,
                    type: 'success'
                });
                sessionStorage.setItem('username', this.$store.state.username);
                sessionStorage.setItem('userpass', this.$store.state.userpass);
                // this.$router.back();
                this.$router.replace({name: 'homeLayout'});
            } else {
                //登录失败提示
                this.$message.error(this.$t("login")['denglushibai']);
            }
        });
        this.paramValue = this.$route.query;
        // this.dialogGoogleTb = true
        if(this.paramValue.code){
            this.getGoogleRz(this.paramValue.code)
        }
        
    },

    computed: {
        i18n() {
            return function (name) {
                return this.$t("login")[name]
            }
        },
    },
    watch:{
        '$i18n.locale'(newValue, oldValue) {
            this.getemailcodeTitle =  this.$t('register')['huoquyanzhengma'],
            this.pl1 = this.$t('register')['qingshuruzhifumima'];
            this.pl2 = this.$t('register')['bitian'];
        }
    },
    methods: {

        getGoogleRz(code){
            loadingsss = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            let params = {
                code: code,
            }
            this.$api.getRegistGoogle(params).then((res)=>{
                
                if(res.data.code == 200) {
                    if(res.data.data.userGoogleType == 0){
                        this.dialogGoogle = true;
                        this.regparam.useraccount = res.data.data.email
                        this.regparam.googleID = res.data.data.googleId
                    }else if(res.data.data.userGoogleType == 1){
                        this.$store.state.username = res.data.data.email;
                        this.$store.state.userpass = res.data.data.googleId;
                        let conn_data = {
                            url: config.tradeAddress,
                            user: res.data.data.email,
                            userpass: res.data.data.googleId
                        }
                        sessionStorage.setItem('loginType', 1);
                        this.$store.dispatch('tradeWS/startWebSocket', conn_data);
                    }else{
                        this.dialogGoogleTb = true;
                        this.regparamTb.useraccount = res.data.data.email
                        this.regparamTb.googleID = res.data.data.googleId
                    }
                } else {
                    loadingsss.close();
                }
                    
            })
        },
        googleTbRegSub(){
            if (this.regparamTb.userpassword.length < 6) {
                this.$message({
                    message: this.$t('login')['mimachangdubunengxiaoyuliuwei'],
                    type: 'warning'
                });
            }else{
                loadingsss = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
           
                this.$api.bindGoogle(this.regparamTb).then((res)=>{
                    loadingsss.close();
                    if(res.data.code == 200) {
                        this.$store.state.username = this.regparamTb.useraccount;
                        this.$store.state.userpass = this.regparamTb.googleID;
                        sessionStorage.setItem('loginType', 1);
                        let conn_data = {
                            url: config.tradeAddress,
                            user: this.regparamTb.useraccount,
                            userpass: this.regparamTb.googleID
                        }
                        this.$store.dispatch('tradeWS/startWebSocket', conn_data);
                    }else{
                        
                    }
                })
            }
            
        },
        googleRegSub(){
            if(this.regparam.userpassword != this.regparam.testpassword){
                this.$message({
                    message: this.$t('login')['liangcimimashurubuyizhi'],
                    type: 'warning'
                });
            }else  if (this.regparam.userpassword.length < 6) {
                this.$message({
                    message: this.$t('login')['mimachangdubunengxiaoyuliuwei'],
                    type: 'warning'
                });
            }else{
                loadingsss = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
           
                this.$api.doRegistGoogle(this.regparam).then((res)=>{
                    if(res.data.code == 200) {
                        this.$store.state.username = this.regparam.useraccount;
                        this.$store.state.userpass = this.regparam.googleID;
                        sessionStorage.setItem('loginType', 1);
                        let conn_data = {
                            url: config.tradeAddress,
                            user: this.regparam.useraccount,
                            userpass: this.regparam.googleID
                        }
                        this.$store.dispatch('tradeWS/startWebSocket', conn_data);
                    }else{
                        loadingsss.close()
                    }
                })
            }
            
        },
        googleLogin(){
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let client_id = '796202150897-qac20b32i682gpdct8o90h7k5dvp1pa9.apps.googleusercontent.com'
            let redirect_uri = 'https://'+ window.location.host+'/LoginLayout'
            window.location.href='https://accounts.google.com/o/oauth2/v2/auth?client_id='+client_id+'&redirect_uri='+redirect_uri+'&response_type=code&scope=email profile'
        },
        handleLogin() {
            //先判断输入是否为空
            if (this.inputid === '' || this.inputpassword === '') {
                this.$message.error(this.$t("login")['qingshuruyonghuminghemima']);
                return;
            }
            //判断密码长度
            if (this.inputpassword.length < 6) {
                this.$message.error(this.$t("login")['mimachangdubunengxiaoyuliuwei']);
                return;
            }

            this.loading = true;
            this.error = '';

            let conn_data = {
                url: config.tradeAddress,
                user: this.inputid,
                userpass: this.inputpassword
            }
            this.$store.state.username = this.inputid;
            this.$store.state.userpass = this.inputpassword;
            this.$store.dispatch('tradeWS/startWebSocket', conn_data);

            var that = this;
        },
        registerClick() {
            this.$router.replace({name: 'registerLayout'});
        },
        async textCodeCheck(textcode, cid) {
            let data = {
                cid: cid,
                textCode: textcode,
            }
            this.$api.textCodeCheck_Register(data).then((res)=>{
                if(res.data.code == 200) {
                    this.account = this.emailAddr;
                    this.emailAddr = undefined;
                    this.imgcode = undefined;
                    this.emailtextcode = undefined;
                    this.dialogyouxiangrenzheng = false;
                    //打开修改密码的第二步
                    this.dialogchangepassword = true;
                    this.$message({
                        message: this.$t('login')['youxiangyanzhengchenggong'],
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: this.$t('login')['yanzhengmacuowu'] + res.data.msg,
                        type: 'warning'
                    });
                }
            })
        },
        emailauthsubmit() {
            if (this.emailtextcode !== undefined && this.emailtextcode !== '') {
                this.textCodeCheck(this.emailtextcode, this.emailtextcodecid);
            } else {
                this.$message({
                    message: this.$t('login')['qingshuruyouxiangyanzhengma'],
                    type: 'warning'
                })
            }
        },
        userforgetClick() {
            this.getImgCode();
            this.dialogyouxiangrenzheng = true;
        },
        async getImgCode() {
            let data = {}
            this.$api.getmakeVerifyBase64(data).then((res)=>{
                if(res.data.code == 200){
                    this.imgsrc = res.data.img_base64;
                    this.imgcodeid = res.data.id;
                } else {
                    this.$message({
                        message: this.$t('login')['huoqutuxingyanzhengmashibaiqingchongxinqingqiu'],
                        type: 'warning'
                    });
                }
            });
        },
        getemailcode() {
            if (this.emailAddr !== undefined && this.emailAddr !== '') {
                // if (this.imgcode !== undefined && this.imgcode !== '') {
                    
                // } else {
                //     this.$message({
                //         message: this.$t('login')['qingshurutuxingyanzhengma'],
                //         type: 'warning'
                //     });
                // }
                this.getEmailTextCode(this.imgcode, 3, this.emailAddr, this.imgcodeid);
            }
             else {
                this.$message({
                    message: this.$t('login')['qingxianwanchengyouxiangrenzheng'],
                    type: 'warning'
                });
            }
        },
        async getEmailTextCode(textcode, codeuse, useremail, imgcodeid) {
            let data = {
                textCode: textcode,
                codeUse: codeuse,
                useremail: useremail,
                id: imgcodeid,
            }
            this.$api.getEmailTextCode_Register(data).then((res)=>{
                if(res.data.code == 200){
                    this.emailtextcodecid = res.data.cid;
                    this.emailcountDown();
                } else {
                    this.$message({
                        message: this.$t('login')['huoquyouxiangyanzhengmashibai'],
                    })
                }
            })
        },
        emailcountDown() {
            this.emailcount = 60;
            clearInterval(this.emailcountDowntimer);
            this.emailcountDowntimer = setInterval(() => {
                this.emailcount--;
                this.getemailcodeTitle = this.emailcount  ;
                this.getemailcodecanClick = false
                if (this.emailcount === 0) {
                    this.emailcount = 60;
                    this.getemailcodecanClick = true;
                    clearInterval(this.emailcountDowntimer);
                    this.getemailcodeTitle = this.$t('register')['huoquyanzhengma'] ;
                }
            }, 1000);
        },
        changePswclick() {
            if (this.newpassword === undefined || this.newpassword === '' || this.newpassword2 === undefined || this.newpassword2 === '') {
                this.$message({
                    message: this.$t('login')['qingshuruxinmima'],
                    type: 'warning'
                });
                return;
            };
            if (this.newpassword !== this.newpassword2) {
                this.$message({
                    message: this.$t('login')['liangcimimashurubuyizhi'],
                    type: 'warning'
                });
                return;
            };
            this.changePassword(this.newpassword, this.account);
        },
        async changePassword(newpassword, account) {
            let data = {
                account: account,
                cid: this.emailtextcodecid,
                textCode: this.imgcode,
                password: newpassword,
                cf_password: newpassword,
            }
            this.$api.resetPwd(data).then((res)=>{
                if(res.data.code == 200){
                    this.$message({
                        message: this.$t('login')['mimaxiugaichenggong'],
                        type: 'success'
                    })
                    this.dialogchangepassword = false;
                } else {
                    this.$message({
                        message: this.$t('login')['xiugaimimashibai'],
                    })
                }
            })
        },
    }, 
};
</script>

<style scoped>
.middle {
    min-width: 686px;
    width: 100%;
    height: 100vh;
    background: white;
    position: relative;
}
/deep/ .el-input__inner {
    border-color: #FAFAFA;
    background: #FAFAFA;
  }
.user {
    width: 364px;
    height: 548px;
    /* margin-top: 25px; */
    padding-left: 32px;
    padding-top: 74px;
    box-shadow: 10px 10px 20px #ccc;
}

.ding {
    min-width: 686px;
    width: 100%;
    height: 130px;
}

.user-gang {
    font-size: 20px;
    color: #F0B90B;
    margin-right: 16px;
    float: left;
}

.user h1 {
    font-size: 20px
}

.user-fbex {
    font-size: 16px;
}

.input-id {
    margin-top: 10px;
    width: 300px;
    height: 44px;
    color: #fff;
    font-size: 16px;
}

.input-password {
    margin-top: 10px;
    width: 300px;
    height: 44px;
    color: #fff;
    font-size: 16px;
}

.user-id {
    margin-top: 35px;

    font-size: 14px;
    position: relative;
}

.password {
    margin-top: 20px;

    font-size: 14px;
    position: relative;
}

.user-forget {
    color: #F0B90B;
    font-size: 14px;
    text-align: right;
    margin-right: 70px;
    line-height: 40px;
    cursor: pointer;
}

.user-button button {
    width: 300px;
    height: 44px;
}

.user-account {
    width: 300px;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}

.user-account span {
    color: #F0B90B;
}

.flex_row_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_col_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flex_row_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex_row_around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex_row_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.getcode {
    background: #FCD535;
    box-shadow: 0px 2px 4px 0px rgb(21 85 73 / 35%);
    line-height: 40px;
    text-align: center;
    margin-top: 12px;
    width: 160px;
    margin-left: 10px;
    cursor: pointer;
}

.but-determine {
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #FCD535;
    width: 126px;
    border-radius: 2px;
    cursor: pointer;
}

</style>