<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import config from '@/utils/configs'

export default {
    name: 'app',
    created() {
        this.$store.dispatch('quotesWS/startWebSocket', config.quotationAddress);

        //自动登录
        let username = sessionStorage.getItem('username');
        let userpassword = sessionStorage.getItem('userpass');
        if (username && userpassword && username !== '' && userpassword !== '') {
            let conn_data = {
                url: config.tradeAddress,
                user: username,
                userpass: userpassword
            }
            this.$store.dispatch('tradeWS/startWebSocket', conn_data);
        }
    },
    beforeDestroy() {
        this.$store.dispatch('quotesWS/close');
        this.$store.dispatch('tradeWS/close');
    }
}
</script>
<style>
.el-button:hover {
    background: #f8d253 !important; /* 鼠标悬停时的边框颜色 */
    color: #333  !important;
  }
  .el-message-box .el-button{
    background: #f8d253 !important; /* 鼠标悬停时的边框颜色 */
    color: #333  !important;
    border: none;
  }
  #app{
    font-family: '微软雅黑';
    letter-spacing: 1px;
  }
   .el-footer{
    padding: 0 !important;
}
</style>