export default {
    public:{
        zhd:"最高",
        qsjyh:"世界中のユーザー",
        gtdxz:'一般的な選択',
        szhbjyytz:'デジタル通貨の機会と課題',
        xsddcf:'新時代の富の機会と課題',
        yzsjypt:"ワンストップ取引プラットフォーム",
        mskljyzh:'取引口座を開設して今すぐ取引を開始してください',
        jyhg:"ビジネスコンプライアンス",
    },

    header: {
    hello: '「こんにちは世界」',
    changeLanguage: '言語を切り替える',
    Login: 'ログイン',
    Register: '登録する',
    Logout: 'ログアウト',
    shouye: 'フロントページ',
    bibijiaoyisuo: '暗号通貨取引',
    heyuejiaoyisuo: '契約取引',
    licaichanpin: 'マイニングプール',
    rengou: 'サブスクリプション',
    ruanjianxiazai: 'ソフトウェアのダウンロード',
    zhongwen: '中国語',
    yingwen: 'で',
    zichan: '資産',
    dingdan: '注文',
    heyuedingdan: '契約注文',
    bibidingdan: 'コイン注文',
    anquanzhongxin: 'セキュリティセンター',
    fanyongyaoqing: '委員会への招待状',
    tuichudenglu: 'ログアウト',
    gupiaojiaoyi: '株式取引',
    waihuijiaoyi: '外国為替取引',
    qiquanjiaoyi:'オプション取引',
    gupiaodingdan: '在庫注文',
    waihuidingdan: '外国為替注文',
    qiquandingdan: 'オプションの順序',
    jiaoyidingdan:'取引注文',
    daolizhongxin:'代理店センター',
    huodong:'活動',
    },
    home: {
    huanyinglaidao: 'ようこそ',
    pingtaimingcheng: 'ここ',
    mengxiangqihang: '夢は船出する',
    yijiankaiqishuzihualvcheng: 'ワンクリックでデジタルの旅を始めましょう',
    liaojiegengduo: 'もっと詳しく知る',
    chongzhi: '補充する',
    jiaoyi: '貿易',
    remenbang: '人気リスト',
    zhangfubang: '獲得者リスト',
    diefubang: '敗者リスト',
    chengjiaoliang: '音量',
    zanwushuju: 'まだデータがありません',
    jiayidui: '取引ペア',
    zuixinjiage: '最新の価格',
    zhangdie24h: '24時間上昇と下降',
    caozuo: '操作する',
    jiaoyi: '貿易',
    gengduo: 'もっと',
    suishisuidikaiqijiaoyi: 'いつでもどこでも取引を始めましょう',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'アプリでもウェブでも、すぐに取引を開始できます',
    saomaxiazaiApp: 'QRコードをスキャンしてAPPをダウンロードしてください',
    iosheAndroid: 'iOSとAndroid',
    ninkexinrenqieanquandejiamibijiaoyisuo: '信頼できる安全な暗号通貨取引所',
    tishi1: 'リザーブ証明書',
    xiangqing1: '当社はすべてのユーザー資産を少なくとも1:1の比率で保有することを約束します。',
    tishi2: '最高の取引レート',
    xiangqing2: '優遇取引レート、競争力のある VIP 権利、最高品質のサービスをお楽しみください',
    tishi3: '信頼できるセキュリティ',
    xiangqing3: '弊社の高度なセキュリティ対策と SAFU ファンドがお客様のデジタル資産をあらゆるリスクから保護します',
    tishi4: '24時間365日のカスタマーサービスサポート',
    xiangqing4: '24時間*7時間、フルタイム稼働モードで、関連するお問い合わせにできるだけ早く回答します',
    tishi5: '安定して安全',
    xiangqing5: '世界有数の取引所は、お客様の資産に銀行レベルのセキュリティ保護を提供します',
    tishi6: 'よくある質問',
    xiangqing6: '特定の機能の詳細な説明については、FAQ を確認してください。',
    fanhui:"戻る",
    shishihangqing:'リアルタイムの相場',
    },
    footer: {
    bangzhuzhongxin: 'ヘルプセンター',
    dianhuazixun: '電話相談',
    dianhuahegongzuoshijian: '1670-9756（平日10:00～19:00／土日祝休み）',
    liaotianzixun: '1:1チャット相談',
    keketuozixun: 'ココトクコンサルティング',
    zaixianbangzhu: 'オンラインヘルプ',
    h24kaifang: '（２４時間営業）',
    lixianbangzhu: 'オフラインヘルプ',
    gongsidizhi: 'ソウル市龍山区漢江ギル69 龍山プルジオサミット1階',
    gongzuoshijian: '(平日10:00～18:00 / 土日祝休み)',
    shangshizixun: '上場相談',
    hezuozixun: '連携相談',
    gongsi: '会社',
    lianxiwomwn: 'お問い合わせ',
    zhidao: 'ガイド',
    yonghuzhichi: 'ユーザーサポート',
    zhanghuzhinan: 'アカウントガイド - 入金/出金',
    ruanjianxiazai: 'ソフトウェアのダウンロード',
    zhengce: 'ポリシー',
    fuwuyuyinsitiaokuan: '利用規約とプライバシー',
    },
    login: {
    yonghudenglu: 'ユーザーログイン',
    huanyingdenglu: 'ログインへようこそ',
    xiangmumingcheng: 'BCFX',
    guanfangwangzhan: '公式ウェブサイト',
    youxiang: '郵便',
    qingshuruyouxiang: 'メールアドレスを入力してください',
    mima: 'パスワード',
    qingshurumima: 'パスワードを入力してください',
    wangjimima: 'パスワードを忘れましたか?',
    denglu: 'ログイン',
    haimeiyouzhanghao: 'まだアカウントをお持ちでない場合は、行く',
    zhuce: '登録する',
    dengluchenggong: 'ログイン成功',
    denglushibai: 'ログインに失敗しました',
    qingshuruyonghuminghemima: 'ユーザー名とパスワードを入力してください',
    mimachangdubunengxiaoyuliuwei: 'パスワードの長さは 6 文字未満にすることはできません',
    youxiangrenzheng: 'メール認証',
    youxiangdizhi: '電子メールアドレス',
    qingshuruyouxiangdizhi: 'メールアドレスを入力してください',
    tuxingyanzhengma: 'グラフィック検証コード',
    qingshurutuxingyanzhengma: 'グラフィック認証コードを入力してください',
    yanzhengma: '検証コード',
    qingshuruyouxiangyanzhengma: 'メール認証コードを入力してください',
    huoquyouxiangyanzhengma: 'メール認証コードを取得する',
    queding: '确定',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'グラフィック確認コードを取得できませんでした。再度リクエストしてください。',
    qingxianwanchengyouxiangrenzheng: 'まずメール認証を完了してください',
    huoquyouxiangyanzhengmashibai: 'メール認証コードの取得に失敗しました。',
    houchongxinfasong: 'S の後に再送信します',
    youxiangyanzhengchenggong: 'メール認証が成功しました',
    yanzhengmacuowu: '認証コードエラー',
    xiugaidenglumima: 'ログインパスワードを変更する',
    xinmima: '新しいパスワード',
    quedingxinmima: '新しいパスワードを確認します',
    qingshuruxinmima: '新しいパスワードを入力してください',
    qingzaicishuruxinmima: '新しいパスワードをもう一度入力してください',
    liangcimimashurubuyizhi: '2 つのパスワード入力が矛盾しています',
    mimaxiugaichenggong: 'パスワードが正常に変更されました',
    xiugaimimashibai: 'パスワードの変更に失敗しました',
    },
    trade: {
    hangqingheyue: '契約',
    hangqingzuixinjia: '最新の価格',
    hangqingzhangdiefu: '増加または減少',
    hangqingzuigao24h: '24時間最高',
    hangqingzuidi24h: '最低24時間',
    hangqingchengjiaoliang24h: '24時間の取引高',
    hangqingchengjiabishu: 'トランザクション数',
    zhanghuquanyi: 'アカウントの権利',
    zhanghukeyongshouyi: '利用可能な特典',
    hangqingweituoliebiao: '注文リスト',
    hangqingjiage: '価格',
    hangqingshuliang: '量',
    hangqingzuixinchengjiao: '最新の取引',
    hangqingjiage1: '価格',
    hangqingshuliang1: '量',
    hangqingshijian: '時間',
    dingdanchiyoucangwei: 'ポジションを保持する',
    dingdandangqianweituo: '現在の注文',
    dingdanchengjiaojilu: '取引記録',
    dingdantiaojiandan: '条件付きの順序',
    chicangheyue: '契約',
    chicangkaicangjunjia: 'ポジション価格',
    chicangfudongyingkui: '利益と損失',
    chicanggangganbeishu: 'てこの作用',
    chicangchicangliang: '役職',
    chicangduokong: 'ロング/ショート',
    chicangduo: '多くの',
    chicangkong: 'ヌル',
    chicangzhiyingjiage: '利食い価格',
    chicangzhisunjiage: 'ストップロス価格',
    chicangqiangpingyugujiage: '推定清算価格',
    chicangcaozuo: '操作する',
    chicangzhiyingzhisun: 'テイクプロフィットとストップロス',
    chicangpingcang: '閉じる',
    chicangshouxufei: '手数料',
    weituoheyue: '契約',
    weituoshuliang: '注文数量',
    weituojiage: '手数料価格',
    weituofangxiang: '方向',
    weituogangganbeishu: '複数を活用する',
    weituochengjiaoshuliang: '取引数量',
    weituoshouxufei: '手数料',
    weituochengjiaojunjia: '平均価格',
    weituoshouyi: '所得',
    weituozhuangtai: '州',
    weituochengjiaoshijian: 'トランザクション時間',
    weituocaozuo: '操作する',
    weituochedan: '注文をキャンセルする',
    weituotijiaoshibai: '送信に失敗しました',
    weituozhilingtijiao: '指示書の提出',
    weituoyitijiao: '提出済み',
    weituobufenchengjiao: '部分的な取引',
    weituobufenchengjiaoyichedan: '一部の注文がキャンセルされました',
    weituowanquanchengjiao: '取引を完了する',
    weituowanquanchedan: '注文を完全にキャンセルする',
    weituochedanzhong: '注文のキャンセル',
    weituokaiduo: '長く開く',
    weituopingkong: '平空',
    weituokaikong: 'オープンショート',
    weituopingduo: 'ピンドゥオ',
    chengjiaoheyue: '契約',
    chengjiaofangxiang: '方向',
    chengjiaoshuliang: '取引数量',
    chengjiaojiage: '取引価格',
    chengjiaoshijian: 'トランザクション時間',
    chengjiaopingcangyingkui: '利益と損失',
    chengjiaoshouxufei: '取引手数料',
    chengjiaokaiduo: '長く開く',
    chengjiaopingkong: '平空',
    chengjiaokaikong: 'オープンショート',
    chengjiaopingduo: 'ピンドゥオ',
    tiaojianzengjiatiaojian: '条件を追加する',
    tiaojianzantingquanbu: 'すべてを一時停止する',
    tiaojianqidongquanbu: 'すべてを開始する',
    tiaojianshanchuquanbu: 'すべて削除',
    tiaojianheyue: '契約',
    tiaojianweituoshuliang: '注文数量',
    tiaojianfangxiang: '方向',
    tiaojiantiaojianleixing: '条件タイプ',
    tiaojianjiageleixing: '価格タイプ',
    tiaojianbijiaofuhao: '比較記号',
    tiaojiantiaojian: '状態',
    tiaojianzhuangtai: '州',
    tiaojianchuangjianshijian: '作成時間',
    tiaojiancaozuo: '操作する',
    tiaojianxiugai: '改訂',
    tiaojianqidong: '起動する',
    tiaojianzanting: '一時停止',
    tiaojianshanchu: '消去',
    tiaojianlijixiadan: '今すぐ注文',
    tiaojiankaiduo: '長く開く',
    tiaojianpingkong: '平空',
    tiaojiankaikong: 'オープンショート',
    tiaojianpingduo: 'ピンドゥオ',
    tiaojianjiagetiaojian: '価格条件',
    tiaojianshijiantiaojian: '時間条件',
    tiaojianzuixinjia: '最新の価格',
    tiaojianByijia: '1つの価格で購入する',
    tiaojianSyijia: 'ワンプライスで販売',
    tiaojianzanting: '一時停止',
    tiaojianyunxing: '走る',
    tiaojianyiwancheng: '完了',
    tiaojianshibai: '失敗',
    tiaojianyishanchu: '削除されました',
    tiaojianshoudongchufawancheng: '手動トリガー完了',
    tiaojianshoudongchufashibai: '手動トリガーに失敗しました',
    xiadankaicang: '開く',
    xiadanpingcang: '閉じる',
    xiadanweituoleixing: 'タイプ',
    xiadanqingxuanze: '選択してください',
    xiadanshijia: '市場価格',
    xiadanxianjia: '価格制限',
    xiadanjiage: '価格',
    xiadanqingshurujiage: '価格を入力してください',
    xiadanshuliang: '量',
    xiadanqingshurushuliang: '数量を入力してください',
    xiadanzhang: '開ける',
    xiadankekaishuliang: '在庫数',
    xiadanqingxianxuanzechicangdan: '最初にポジション注文を選択してください',
    xiadankepingshuliang: 'レベルアップできる数量',
    xiadansuoxubaozhengjin: '必要なマージン:',
    xiadanshijiazhiyingzhisunyushe: '市場価格のテイクプロフィットとストップロスのプリセット',
    xiadanyujiqiangpingjiage: '推定清算価格:',
    xiadanqiangpingjiagetishi: 'この予想清算価格は総資産に基づいて動的に計算されます。この価格は参考価格であり、最終的な清算として使用されるものではありません。',
    xiadanzuoduo: '買騰',
    xiadanzuokong: '買落',
    xiadanpingcang: '閉じる',
    zhanghuheyuezichan: '契約資産',
    zhanghuchongbi: 'コインを入金する',
    zhanghutibi: 'コインを引き出す',
    zhanghuhuazhuan: '移行',
    zhanghuzhanghuquanyi: 'アカウントの権利',
    zhanghufudongyingkui: '変動損益',
    zhanghucangweibaozhengjin: 'ポジションマージン',
    zhanghudongjiebaozhengjin: 'フリーズマージン',
    zhanghukeyongquanyi: '利用可能な特典',
    zhanghubaozhengjinshiyonglv: '証拠金利用率',
    hangqingheyuexinxi: '契約情報',
    hangqingheyuemingcheng: '契約名',
    hangqingjijiahuobi: '見積通貨',
    hangqingheyuedaxiao: '契約規模',
    hangqingzuixiaojiagebodong: '最低価格変動',
    dialogleverRateganggantiaozheng: 'レバレッジ調整',
    dialogleverRatequeding: 'もちろん',
    dialogtransferzichanhuazhuan: '資産譲渡',
    dialogtransferbizhong: '通貨',
    dialogtransfercong: 'から',
    dialogtransferdao: '到着',
    dialogtransfershuliang: '量',
    dialogtransferqingshuruhuazhuanshuliang: '送金額を入力してください',
    dialogtransferquanbu: '全て',
    dialogtransferkeyong: '利用可能',
    dialogtransferhuazhuan: '移行',
    dialogtransferjichuzhanghu: 'ベーシックアカウント',
    dialogtransferheyuezhanghu: '契約口座',
    dialogfitlosszhiyingzhisun: 'テイクプロフィットとストップロス',
    dialogfitlossheyu: '契約',
    dialogfitlossduokong: 'ロング/ショート',
    dialogfitlosschichangjunjia: '平均ポジション価格',
    dialogfitlossshuliang: '量',
    dialogfitlosszuixinjia: '最新の価格',
    dialogfitlosszhiyingdianshu: 'テイクプロフィットポイント',
    dialogfitlosszhisundianshu: 'ストップロスポイント',
    dialogfitlossdianshu: '点数',
    dialogfitlosszhiyingjiage: '利食い価格',
    dialogfitlosszhisunjiage: 'ストップロス価格',
    dialogfitlossjiage: '価格',
    dialogfitlossyuqishouyi: '期待リターン',
    dialogfitlossyuqikuisun: '予想損失',
    dialogfitlosstishi1: 'ヒント: 現在の契約の最小変動は',
    dialogfitlosstishi2: ', テイクプロフィットとストップロスをキャンセルするには、価格を0に設定してください。',
    dialogfitlossqueding: 'もちろん',
    dialogfitlossduo: '多くの',
    dialogfitlosskong: 'ヌル',
    dialogconditionzengjiayuntiaojiandan: '雲状況シートを追加',
    dialogconditiontiaojianleixing: '条件タイプ:',
    dialogconditionjiagetiaojiandan: '価格条件シート',
    dialogconditionshijiantiaojiandan: 'タイムコンディションシート',
    dialogconditionjiageleixing: '価格タイプ:',
    dialogconditionzuixinjia: '最新の価格',
    dialogconditionByijia: '1つの価格で購入する',
    dialogconditionSyijia: 'ワンプライスで販売',
    dialogconditiontiaojianshezhi: '条件設定:',
    dialogconditionjiageshezhi: '価格設定:',
    dialogconditionqingshurujiage: '価格を入力してください',
    dialogconditionshijianshezhi: '時間設定：',
    dialogconditionjiaoyileixing: '取引タイプ:',
    dialogconditionkaiduo: '長く開く',
    dialogconditionpingkong: '平空',
    dialogconditionkaikong: 'オープンショート',
    dialogconditionpingduo: 'ピンドゥオ',
    dialogconditiondingdanshuliang: '注文数量:',
    dialogconditionqingshurudingdanshuliang: '注文数量を入力してください',
    dialogconditiondingdanshixiao: '注文時間:',
    dialogconditionyongjiuyouxia: '永久に有効',
    dialogconditiontijiao: '提出する',
    dialogAutoProfitLosszhiyingzhisun: 'テイクプロフィットとストップロス',
    dialogAutoProfitLossmingcheng: '名前',
    dialogAutoProfitLosszhiyingdian: 'テイクプロフィットポイント',
    dialogAutoProfitLosszhisundian: 'ストップロスポイント',
    dialogAutoProfitLosschongzhi: 'リセット',
    dialogAutoProfitLoss: '提出する',
    dialogCloseOrderpingcang: '閉じる',
    dialogCloseOrderweituoleixing: 'デリゲートタイプ',
    dialogCloseOrderqingxuanze: '選択してください',
    dialogCloseOrdershijia: '市場価格',
    dialogCloseOrderxianjia: '価格制限',
    dialogCloseOrderjiage: '価格',
    dialogCloseOrderqingshurujiage: '価格を入力してください',
    dialogCloseOrdershuliang: '量',
    dialogCloseOrderqingshurushuliang: '数量を入力してください',
    dialogCloseOrderzhang: '開ける',
    dialogCloseOrderkepingshuliang: 'レベルアップできる数量',
    dialogCloseOrderqueding: 'もちろん',
    jsMessageResgaiheyuegangganxiugaichenggong: '契約レバレッジが正常に変更されました',
    jsMessageResgaiheuyegangganxiugaishibai: '契約レバレッジの変更に失敗しました',
    jsMessageReskaicangchenggong: 'ポジションが正常にオープンされました',
    jsMessageReskaicangshibai: 'ポジションのオープンに失敗しました:',
    jsMessageReskaicang1: 'ポジションをオープンすることは禁止されています',
    jsMessageReskaicang2: '契約は存在しません',
    jsMessageReskaicang3: 'オープンポジション数が上限を超えています',
    jsMessageReskaicang4: '価格は0です',
    jsMessageReskaicang5: 'ポジションをオープンするための証拠金が不十分',
    jsMessageRespingcangchenggong: 'ポジションが正常にクローズされました',
    jsMessageRespingcangshibai: 'ポジションを決済できませんでした:',
    jsMessageRespingcang1: '契約は存在しません',
    jsMessageRespingcang2: 'ポジション受信が存在しません',
    jsMessageRespingcang3: '量が足りない',
    jsMessageReszijinhuazhuanchenggong: '資金移動が成功しました',
    jsMessageReszijinhuazhuanshibai: '資金移動に失敗しました',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'ポジション注文のストッププロフィットとストップロスが正常に設定されました',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'ポジション注文のストッププロフィットとストップロスの設定が失敗しました:',
    jsMessageResweituodanchedanchenggong: '注文は正常にキャンセルされました',
    jsMessageResweituodanchedanshibai: '注文のキャンセルに失敗しました',
    jsMessageRestiaojiandantianjiachenggong: '条件付き注文が正常に追加されました',
    jsMessageResheyuebucunzai: '契約は存在しません',
    jsMessageResjiageshezhicuowu: '価格設定エラー',
    jsMessageResshijianshezhicuowu: '時刻設定エラー',
    jsMessageResneibucuowu: '内部エラー',
    jsMessageRestiaojiandantianjiashibai: '条件付き順序を追加できませんでした:',
    jsMessageReszhiyingzhisunshezhichenggong: 'テイクプロフィットとストップロスが正常に設定されました',
    jsconfirmquedingchedanma: '注文をキャンセルしてもよろしいですか?',
    jsconfirmtishi: 'ヒント',
    jsconfirmqueding: 'もちろん',
    jsconfirmquxiao: 'キャンセル',
    jsMessageqingxiandenglu: 'まずはログインしてください！',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'この契約にはオープンポジションがあり、レバレッジを変更することはできません。',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'この契約には義務があり、レバレッジを変更することはできません。',
    jsMessageqingshuruzhengquedeshuliang: '正しい数量を入力してください。',
    jsMessageqingshuruzhengquedejiage: '正しい価格を入力してください。',
    jsMessageqingxianxuanzechicang: 'まずはポジションを選択してください！',
    jsMessageqingshuruhuazhuanshuliang: '送金金額を入力してください！',
    jsMessageqingxianxuanzeheyue: 'まずは契約内容を選択してください！',
    jsMessageqingshurutiaojiandanshuliang: '条件付き注文数量を入力してください。',
    jsMessageqingshurutiaojiandandejiage: '条件付き注文の価格を入力してください。',
    jsMessageqingshurutiaojiandanzhixingshijian: '条件付き注文の実行時間を入力してください。',
    emptytext: 'まだデータがありません',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'テイクプロフィットとストップロスのpipsが正しく設定されていない',
    jsMessageReschicangdanbucunzai: 'ポジション受信が存在しません',
    zxkcje:'最小開口量',
    },
    commisson:{
    dlzx:'代理店センター',
    sjzl:'データの概要',
    quanbu:"全て",
    jinri:'今日',
    zuori:'昨日',
    ri:'日',
    wdfy:'私のリベート',
    wdsjfy:'3 番目のレベルのリベート',
    dengji:'学年',
    yqrs:'招待人数',
    tdrs:'チームの規模',
    yqxq:'招待状の詳細',
    yhm:"ユーザー名",
    cjsj:'作成時間',
    fyxq:'リベートの詳細',
    zyj:'合計手数料',
    dryj:'1 日分のコミッション',
    fyje:'リベート額',
    
    },
    assets: {
    zongzichan: '総資産',
    chongbi: 'コインを入金する',
    tibi: 'コインを引き出す',
    huazhuan: '移行',
    fabizhanghu: 'ベーシックアカウント',
    heyuezhanghu: '契約口座',
    jiayiliushui: '取引の流れ',
    dialogzichanhuazhuan: '資産譲渡',
    cong: 'から',
    dao: '到着',
    shuliang: '量',
    qingshuruhuazhuanshuliang: '送金額を入力してください',
    quanbu: '全て',
    keyong: '利用可能',
    huanzhuan: '移行',
    zijinhuazhuanchenggong: '資金移動が成功しました',
    zijinhuazhuanshibai: '資金移動に失敗しました',
    bibizhanghu: 'コイン口座',
    qiquanzhanghu:'オプション口座',
    zczl:'資産の概要',
    zzczh:'総資産割引',
    ztzc:"輸送中の資産",
    djzc:'資産を凍結する',
    zyzc:'担保資産',
    cwzc:'ポジション資産',
    jyzh:'取引口座',
    bizhong:'通貨',
    dongjie:'フリーズする',
    lirun:"利益",
    hyzh:"契約口座",
    },
    basicsAccountRecord: {
    fanhui: '戻る',
    fabizhanghuliushui: '取引口座の流れ',
    fabijiaoyiliushuileixing: 'トランザクションフロータイプ',
    qingxuanze: '選択してください',
    chaxunriqi: 'クエリの日付',
    liushuileixing: 'パイプラインの種類',
    shijian: '時間',
    biandongjine: '変更金額',
    yue: 'バランス',
    quanbu: '全て',
    Sellbi: 'コインを売る',
    Buybi: 'コインを買う',
    tibi: 'コインを引き出す',
    chongbi: 'コインを入金する',
    fabizhuanheyue: '譲渡基本契約書',
    heyuezhuanfabi: '契約から基本へ',
    zhuanzhang: '移行',
    fabihuazhuandaoheyue: '基本的に契約への移行',
    heyuehuazhuandaofabi: '契約を拠点に転送',
    goumaijijin: 'マイニングプールを購入する',
    jijinshuhui: 'マイニングプールの償還',
    jijinshouyi: 'マイニングプールの償還収入',
    fabizhuanqihuo: '先物への基本的な変換',
    qihuozhuanfabi: '先物振替ベース',
    fabizhuanganggu: '香港株への基本振替',
    gangguzhuanfabi: '香港株式移転の基本',
    fabizhuanbibi: '基本送金通貨',
    bibizhuanfabi: '通貨間送金の基本',
    goumaiSGCB: 'ICOを購入する',
    chushouSGCB: 'ICOの販売',
    huoqushibai: '取得に失敗しました',
    zanwushuju: 'まだデータがありません',
    },
    contractAccountRecord: {
    fanhui: '戻る',
    heyuezhanghuliushui: 'ご契約アカウントの流れ',
    heyuejiaoyiliushuileixing: '契約取引フローの種類',
    qingxuanze: '選択してください',
    chaxunriqi: 'クエリの日付',
    liushuileixing: 'パイプラインの種類',
    shijian: '時間',
    biandongjine: '変更金額',
    yue: 'バランス',
    quanbu: '全て',
    kaicangshouxufei: '出店料',
    pingcangshouxufei: '成約料',
    pingcangyingkui: 'ポジション決済損益',
    fabizhuanheyue: '譲渡基本契約書',
    heyuezhuanfabi: '契約から基本へ',
    zhuanzhang: '移行',
    kaicangyongjin: 'オープニングコミッション',
    pingcangyongjin: '閉会委員会',
    huoqushibai: '取得に失敗しました',
    zanwushuju: 'まだデータがありません',
    },
    recharge: {
    zanwushuju: 'まだデータがありません',
    shijian: '時間',
    leixing: 'タイプ',
    shuliang: '量',
    zhuangtai: '州',
    caozuo: '操作する',
    chakanxiangqing: '詳細を確認する',
    huoqushibai: '取得に失敗しました',
    dialogtitle: '詳細',
    chongbi: 'コインを入金する',
    fanhui: '戻る',
    lianmingcheng: 'チェーン名',
    fuzhilianjie: 'アドレスをコピーする',
    tishixinxi1: '• TRC_USDT または ERC_USDT 以外の資産を上記のアドレスに預けないでください。預けないと、資産は取得されません。',
    tishixinxi2: '• 上記のアドレスにチャージした後、ネットワークノード全体の確認が必要です。1 回のネットワーク確認後にアカウントが受信され、2 回のネットワーク確認後に通貨を引き出すことができます。',
    tishixinxi3: '• 最低入金額: 50USDT、最低金額未満の入金額は入金されず、返金もできません。',
    tishixinxi4: '• リチャージアドレスは頻繁には変更されず、繰り返しリチャージできます。変更があった場合は、ウェブサイトのお知らせまたは電子メールでお知らせするよう最善を尽くします。',
    tishixinxi5: '• 情報の改ざんや漏洩を防ぐため、お使いのコンピュータやブラウザが安全であることをご確認ください。',
    putongchongbi: '普通預金',
    jiaoyichenggong: 'トランザクションが成功しました',
    jiaoyishibai: 'トランザクションが失敗しました',
    OMNIweihuzhong: 'OMNIチェーンはメンテナンス中のためTRC20をお選びください',
    },
    withdraw: {
    fanhui: '戻る',
    zanwushuju: 'まだデータがありません',
    tibi: 'コインを引き出す',
    huoqushibai: '取得に失敗しました',
    OMNIweihuzhong: 'OMNIチェーンはメンテナンス中のためTRC20をお選びください',
    shijian: '時間',
    leixing: 'タイプ',
    shuliang: '量',
    zhuangtai: '州',
    caozuo: '操作する',
    chakanxiangqing: '詳細を確認する',
    putongtibi: '普通引き出し',
    dialogtitle: '詳細',
    dialogtitlewithdraw: 'コインを引き出す',
    houchongxinfasong: 'S の後に再送信します',
    huoquyouxiangyanzhengma: 'メール認証コードを取得する',
    lianmingcheng: 'チェーン名',
    tibidizhi: '出金アドレス',
    qingshurutibidizhi: '出金アドレスを入力してください',
    tibishuliang: '引き出したコインの量',
    shouxufei: '手数料',
    qingshurutibishuliang: '出金額を入力してください',
    quanbu: '全て',
    keyong: '利用可能',
    daozhangshuliang: '入荷数',
    zuixiaotibishuliang: '最低出金額は次のとおりです。',
    tishixinxi: '資金の安全性を確保するため、アカウントのセキュリティ ポリシーが変更された場合、またはパスワードが変更された場合は、出金の審査が手動で行われます。スタッフから電話または電子メールで連絡されるまでお待ちください。データの改ざんや漏洩を防ぐために、コンピューターとブラウザーが安全であることを確認してください。',
    tuxingyanzhengma: 'グラフィック検証コード',
    qingshurutuxingyanzhengma: 'グラフィック認証コードを入力してください',
    youxiangyanzhengma: 'メール認証コード',
    queding: 'もちろん',
    tijiaozhong: '提出する',
    yijujue: '拒否されました',
    yitongguo: '合格した',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'グラフィック確認コードを取得できませんでした。再度リクエストしてください。',
    huoquyouxiangyanzhengmashibai: 'メール認証コードの取得に失敗しました。',
    qingxianwanchengyouxiangrenzheng: 'まずメール認証を完了してください',
    huoquyouxiangshibaiqingchongxinqingqiu: 'メールアドレスの取得に失敗しました。再度リクエストしてください',
    qingshuruyouxiangyanzhengma: 'メール認証コードを入力してください',
    youxiangyanzhengmacuowu: 'メール認証コードが間違っています。',
    qxsmrz:'まず本名を確認してください',
    tishi:'ヒント',
    queding:'もちろん',
    },
    contractTransactionRecord: {
    fanhui: '戻る',
    zanwushuju: 'まだデータがありません',
    heyuedingdan: '契約注文',
    qingxuanze: '選択してください',
    quanbu: '全て',
    kaiduo: '長く開く',
    pingkong: '平空',
    kaikong: 'オープンショート',
    pingduo: 'ピンドゥオ',
    xianjiaweituo: '指値注文',
    shijiaweituo: '成行注文',
    suoyouzhuangtai: 'すべてのステータス',
    tijiaoshibai: '送信に失敗しました',
    zhunbeitijiao: '提出する準備ができています',
    yitijiao: '提出済み',
    bufentijiao: '部分的な取引',
    bufentijiaoyichedan: '一部の注文がキャンセルされました',
    quanbuchengjiao: '全取引',
    yichedan: '注文がキャンセルされました',
    huoqushibai: '取得に失敗しました',
    heyuedaima: '契約コード',
    ganggan: 'レバー',
    xiadanshijian: '注文時間',
    chengjiaoshijian: 'トランザクション時間',
    fangxiang: '方向',
    baojialeixing: '見積タイプ',
    weituoliang: '手数料金額（個）',
    weituojia: '注文価格 (USDT)',
    chengjiaoliang: '取引高（チケット）',
    chengjiaojunjia: '平均取引価格 (USDT)',
    yingkui: '損益(USDT)',
    shouxufei: '手数料(USDT)',
    zhuangtai: '州',
    gupiaodingdan: '在庫注文',
    waihuidingdan: '外国為替注文',
    gupiaodaima: '証券コード',
    waihuidaima: '外国為替コード',
    },
    kLine: {
    zhibiao: '索引',
    shezhi: '設定',
    quanping: '全画面表示',
    tuichuquanping: '全画面表示を終了する',
    jishuzhibiao: 'テクニカル指標',
    futuzhibiao: 'サブピクチャーインジケーター',
    zhutuzhibiao: 'メインチャートインジケーター',
    huifumoren: 'デフォルトに戻す',
    lazhutuleixing: 'ローソク足の種類',
    qingxuanze: '選択してください',
    quanshixin: 'すべて固体',
    quankongxin: '完全中空',
    zhangkongxin: '上昇する空洞',
    diekongxin: '中空に落ちる',
    mianjitu: '面グラフ',
    jiagezhouleixing: '価格軸の種類',
    xianxingzhou: '線軸',
    baifenbizhou: 'パーセント軸',
    duishuzhou: '対数軸',
    zuigaojiaxianshi: '最高値表示',
    zuidijiaxianshi: '最安値表示',
    zuixinjiaxianshi: '最新の価格表示',
    zhibiaozuixinzhixianshi: 'インジケーターの最新値が表示されます',
    daozhizuobiao: "反転座標",
    wanggexianxianshi: 'グリッド線表示',
    hfcg:'回復成功',
    },
    SecurityCenter: {
    fanhui: '戻る',
    anquanzhongxin: 'セキュリティセンター',
    qingshurunicheng: 'ニックネームを入力してください',
    nichengxiugaishibai: 'ニックネームの変更に失敗しました',
    huoquyonghuxinxishibai: 'ユーザー情報の取得に失敗しました',
    xiugaidenglumima: 'ログインパスワードを変更する',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'グラフィック確認コードを取得できませんでした。再度リクエストしてください。',
    qingshurujiumima: '古いパスワードを入力してください',
    qingshuruxinmima: '新しいパスワードを入力してください',
    qingzaicishuruxinmima: '新しいパスワードをもう一度入力してください',
    qingshurutuxingyanzhengma: 'グラフィック認証コードを入力してください',
    qingshuruyanzhengma: '確認コードを入力してください',
    huoquzhuceshoujihaoshibai: '登録された携帯電話番号の取得に失敗しました',
    qingxianwanchengshoujirenzheng: '最初に携帯電話認証を完了してください',
    huoqushoujiyanzhengmashibai: '携帯電話認証コードの取得に失敗しました',
    huoqushoujiyanzhengma: '携帯電話認証コードを取得する',
    houchongxinfasong: 'S の後に再送信します',
    mimacuowu: 'パスワードが間違っています',
    liangcixinmimashurubuyizhiqingjiancha: '入力した 2 つの新しいパスワードは矛盾しています。確認してください。',
    xinmimaheyuanmimabunengyiyang: '新しいパスワードは元のパスワードと同じであってはなりません',
    qingshurushoujiyanzhengma: '携帯電話の認証コードを入力してください',
    yanzhengmacuowu: '認証コードエラー',
    denglumimaxiugaichenggong: 'ログインパスワードが正常に変更されました',
    denglumimaxiugaishibai: 'ログインパスワードの変更に失敗しました',
    xiugaizijinmima: 'ファンドのパスワードを変更する',
    qingshuruzijinmima: 'ファンドのパスワードを入力してください',
    qingzaicishuruzijinmima: 'ファンドのパスワードをもう一度入力してください',
    liangcizijinmimabuyizhiqingjiancha: '2 つのファンドのパスワードが一致していません。確認してください。',
    shezhizijinmimachenggong: 'ファンドのパスワードを正常に設定しました',
    shezhizijinmimashibai: 'ファンドのパスワードを設定できませんでした',
    xiugaizijinmimachenggong: 'ファンドのパスワードが正常に変更されました',
    xiugaizijinmimashibai: 'ファンドのパスワード変更に失敗しました',
    youxiangrenzheng: 'メール認証',
    huoquyouxiangyanzhengma: 'メール認証コードを取得する',
    qingshuruyouxiangdizhi: 'メールアドレスを入力してください',
    huoquyouxiangyanzhengmashibai: 'メール認証コードの取得に失敗しました',
    qingshuruyouxiangyanzhengma: 'メール認証コードを入力してください',
    youxiangyanzhengchenggong: 'メール認証が成功しました',
    weirenzheng: '認定されていません',
    queding: 'もちろん',
    anquanshezhiguanli: 'セキュリティ設定の管理',
    denglumima: 'ログインパスワード',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'ログインパスワードを設定すると、アカウントのパスワードを使用して直接ログインできるようになります。',
    xiugai: '改訂',
    zijinmima: '基金のパスワード',
    yongyubaohuzijinanquan: '資金の安全を保護するために使用されます',
    shezhi: '設定',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'ログイン、コインの出金、パスワードの取得、セキュリティ設定の変更時のセキュリティ検証に使用されます。',
    yirenzheng: '認定済み',
    renzheng: '認証',
    shimingrenzheng: '実名認証',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'コインの売買、出金、預け入れ、送金時のセキュリティ認証に使用されます。',
    daishenhe: '審査待ち',
    yibohui: '解雇された',
    yuandenglumima: '元のログインパスワード',
    xinmima: '新しいパスワード',
    quedingxinmima: '新しいパスワードを確認します',
    tuxingyanzhengma: 'グラフィック検証コード',
    yanzhengma: '検証コード',
    zijinmima: '基金のパスワード',
    querenzijinmima: 'ファンドのパスワードを確認する',
    youxiangdizhi: '電子メールアドレス',
    qingxianwanchengyouxiangrenzheng: 'まずメール認証を完了してください',
    xiugaitouxiangchenggong: 'アバターが正常に変更されました',
    xiugaitouxiangshibai: 'アバターの変更に失敗しました',
    shangchuantouxiangzhinengshijpggeshi: 'アバター写真をアップロードできるのは JPG 形式のみです。',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'アップロードするアバター画像のサイズは 2MB を超えることはできません。',
    },
    verification: {
    fanhui: '戻る',
    shimingrenzheng: '実名認証',
    qingshuruxingming: '名前を入力してください',
    qingshuruzhengjianhaoma: 'ID番号を入力してください',
    huoquyonghuxinxishibai: 'ユーザー情報の取得に失敗しました',
    wenjiangeshicuowu: 'ファイル形式エラー',
    wenjianbunengdayu5M: 'ファイルは 5M を超えることはできません',
    qingtianxiexingming: 'お名前をご記入ください',
    qingtianxiezhengjianhaoma: 'ID番号を入力してください',
    qingshangchuanzhengjianzhengmian: '身分証明書の表面をアップロードしてください',
    qingshangchuanzhengjianfanmian: '身分証明書の裏面をアップロードしてください',
    tijiaochenggong: '送信成功',
    kaiqishenfenrenzheng: 'ID認証をオンにする',
    bohuiyuanyin: '拒否理由',
    qingtianxiejiashizhizhaogongminkahuzhao: '運転免許証/住民票/パスポート情報をご記入ください',
    xingming: '名前',
    zhengjianhaoma: 'ID番号',
    qingshangchuanjiashizhizhaogongminkahuzhao: '運転免許証/住民カード/パスポート情報をアップロードしてください',
    shangchuanzhengjianzhengmian: '身分証明書の前面をアップロードします',
    huotuozhuafangru: 'またはドラッグアンドドロップ',
    shangchuanzhengjianfanmian: '証明書の裏面をアップロードする',
    tijiao: '提出する',
    },
    invite: {
    yaoqinghaoyoudejiangli: '友達を招待して報酬を獲得しましょう',
    yongjiukede: '永久に利用可能',
    jiaoyifanyong: '取引リベート',
    fasongyaoqinggeihaoyou: '友達に招待状を送信する',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'リンクを共有して友達を招待して BCFX に登録してください',
    haoyouzhuce: '友達登録',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: '友人は招待を受け入れ、登録を完了し、取引を実行します。',
    huodexiangyingbilijiangli: '対応する比例報酬を受け取る',
    qingsonghuodejiaoyishouxufeijianglifuli: '取引手数料ボーナス特典を簡単に獲得',
    wodezhuanshufenxiangfangshi: '私だけの共有方法',
    yaoqinglianjie: '招待リンク',
    jiazaizhong: '読み込み中...',
    fuzhilianjie: 'リンクをコピー',
    yaoqingma: '招待コード',
    fuzhiyaoqingma: '招待コードをコピーする',
    wodeyaoqing: '私の招待状',
    yaoqingrenshu: '招待人数',
    huodejiangli: '報酬を得る',
    huodongxize: 'イベント詳細',
    tips1: 'プラットフォーム ユーザー間の関係は 3 レベルです。たとえば、ABCD ユーザーは 3 つのレベルのユーザーを構成し、A が最高レベルです。',
    tips2: 'レベル間の利害関係',
    tips201: '。',
    tips3: '現金報酬: 顧客 A は顧客 B の契約取引手数料の 40% + 顧客 C の契約取引手数料の 20% + 顧客 D の契約取引手数料の 10% を受け取ることができます',
    tips4: '例: 顧客 A には、10 人の第 1 レベルの顧客 B、10 人の第 2 レベルの顧客 C、および 10 人の第 3 レベルの顧客 D がいます。各顧客の契約が 100,000 USDT で取引されているとします。',
    tips5: 'すると顧客Aが得られるリターンは',
    tips6: '100000*1.2%*40%*10+100000*1.2%*20%*10+100000*1.2%*10%*10=8400 USDT 現金報酬、リアルタイムで引き出し可能',
    tips7: 'アクティビティが調整された場合、BCFX プラットフォームのアップデートの対象となり、最終的な解釈権は BCFX に帰属します。',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'グラフィック確認コードを取得できませんでした。再度リクエストしてください。',
    qingshuruyouxiang: 'メールアドレスを入力してください',
    qingshurutuxingyanzhengma: 'グラフィック認証コードを入力してください',
    qingshuruyanzhengma: '確認コードを入力してください',
    qingshurunindemima: 'パスワードを入力してください',
    qingzaicishurunindemima: 'パスワードをもう一度入力してください',
    qingshuruzhifumima: '支払いパスワードを入力してください',
    xuantian: 'オプション',
    bitian: '必須',
    yonghuzhuce: 'ユーザー登録',
    huanyingdenglu: 'ログインへようこそ',
    guanfangwangzhan: '公式ウェブサイト',
    xiangmumingcheng: 'BCFX',
    youxiang: '郵便',
    tuxingyanzhengma: 'グラフィック検証コード',
    yanzhengma: '検証コード',
    huoquyanzhengma: '検証コード',
    shezhimima: 'パスワードを設定する',
    quedingmima: 'パスワードを認証する',
    zhifumima: '支払いパスワード',
    yaoqingma: '招待コード',
    zhuce: '登録する',
    yiyouzhanghao: 'すでにアカウントをお持ちですか?',
    denglu: 'ログイン',
    qingshuruyouxiangdizhi: 'メールアドレスを入力してください',
    huoqushibai: '取得に失敗しました',
    houchongxinfasong: 'S の後に再送信します',
    liangcishurudemimabuyizhi: '2回入力したパスワードは一致しません',
    zhucechenggong: '登録が成功しました',
    zhuceshibai: '登録に失敗しました',
    },
    treasure:{
    jichubizhong: '基本通貨',
    shouyibizhong: '収益通貨',
    wakuangtianshu: '採掘日',
    shouyitianshu: '稼げる日数',
    suodingtianshu: 'ロック日',
    yichanshengshouyi: '生み出される収益',
    yuqishouyi: '期待リターン',
    kaishishijian: '開始時間',
    jieshushijian: '終了時間',
    qingshuruninyaosuodingdebizhongshuliang: 'ロックしたい通貨のUSDTの金額を入力してください',
    huoqushibai: '取得に失敗しました',
    meiyitian: '1日ごと',
    dakai: '開ける',
    yuanyuzhoukuangchi: 'メタバース マイニング プール',
    jilu: '記録',
    kuangchixiangqing: 'マイニングプールの詳細',
    suo: 'ロック',
    yitianchanchu: '1日分の出力',
    zuixiaosuodingshijian: '最小ロック時間',
    nindeUSDT: 'あなたの USDT',
    tian: '空',
    quanbu: '全て',
    suoUSDTbingkaishishengchan: 'USDTをロックして生産を開始する',
    ninquedingyaosuoUSDTkaishishengchanma: 'USDTをロックして実稼働を開始してもよろしいですか?',
    tishi: 'ヒント',
    queding: 'もちろん',
    quxiao: 'キャンセル',
    kaishishengchan: '生産開始！',
    yiquxiao: 'キャンセル',
    },
    subscription:{
    rengouliebiao:'購読リスト',
    jijiangdaolai:'近日公開',
    jinxingzhong:'進行中',
    quanbu:'全て',
    keshiyonghuobi:"利用可能な通貨",
    zongshuliang:'総量',
    meiyouxianzhi:'制限なし',
    shengyushuliang:'残量',
    kaishishijian:'開始時間',
    zhuangtai:'州',
    jilu:'記録',
    zanwujilu:"まだ記録がありません",
    leixing:'タイプ',
    shuliang:'量',
    goumaijine:'購入金額',
    shijian:'時間',
    goumai:'買う',
    duichu:'兑出',
    jiangli:'賞',
    tibi:'コインを引き出す',
    huidaomulu:'目次に戻る',
    dangqianchiyou:'現在開催中の',
    keyong:'利用可能',
    keduihuan:'引き換え可能',
    shishihangqing:'リアルタイムの相場',
    yue:'バランス',
    biaoti:'タイトル',
    fashoushuliang:'販売数量',
    jindu:"スケジュール",
    goumaishuliang:'購入数量',
    ge:'個人の',
    woyiyuedu:'読みました',
    daibigoumaixieyi:'トークン購入契約書',
    qingshurugoumaijine:'購入金額を入力してください',
    shifoyaogoumai:'購入しますか?',
    tishi:'ヒント',
    queding:'もちろん',
    quxiao:'キャンセル',
    yjs:'終わった',
    ywc:'完了',
    sgjg:'定期購入価格',
    jssj:"終了時間",
    zqsl:"当選数量",
    zqje:"当選金額",
    tian:"空",
    shi:'時間',
    fen:'ポイント',
    miao:'2番',
    shengou:'サブスクリプション',
    xmxq:'プロジェクトの詳細',
    },
    }