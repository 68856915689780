export default {
    public:{
        zhd:"بهترین",
        qsjyh:"کاربران در سراسر جهان",
        gtdxz:'انتخاب مشترک',
        szhbjyytz:'فرصت ها و چالش های ارز دیجیتال',
        xsddcf:'فرصت ها و چالش های ثروت در عصر جدید',
        yzsjypt:"پلت فرم معاملاتی یک مرحله ای",
        mskljyzh:'یک حساب معاملاتی باز کنید و همین الان معامله را شروع کنید',
        jyhg:"انطباق تجاری",
    },

    header: {
    hello: 'سلام دنیا!',
    changeLanguage: 'تغییر زبان',
    Login: 'وارد شوید',
    Register: 'ثبت نام کنید',
    Logout: 'از سیستم خارج شوید',
    shouye: 'صفحه اول',
    bibijiaoyisuo: 'تجارت ارزهای دیجیتال',
    heyuejiaoyisuo: 'معاملات قراردادی',
    licaichanpin: 'استخر معدن',
    rengou: 'اشتراک',
    ruanjianxiazai: 'دانلود نرم افزار',
    zhongwen: 'چینی',
    yingwen: 'در',
    zichan: 'دارایی ها',
    dingdan: 'سفارش دهید',
    heyuedingdan: 'سفارش قرارداد',
    bibidingdan: 'سفارش سکه',
    anquanzhongxin: 'مرکز امنیتی',
    fanyongyaoqing: 'دعوت از کمیسیون',
    tuichudenglu: 'از سیستم خارج شوید',
    gupiaojiaoyi: 'معاملات سهام',
    waihuijiaoyi: 'تجارت فارکس',
    qiquanjiaoyi:'معاملات گزینه ها',
    gupiaodingdan: 'سفارش سهام',
    waihuidingdan: 'سفارشات فارکس',
    qiquandingdan: 'ترتیب گزینه ها',
    jiaoyidingdan:'سفارش معاملات',
    daolizhongxin:'مرکز نمایندگی',
    huodong:'فعالیت'
    },
    home: {
    huanyinglaidao: 'خوش آمدید به',
    pingtaimingcheng: 'اینجا',
    mengxiangqihang: 'رویاها به راه افتادند',
    yijiankaiqishuzihualvcheng: 'سفر دیجیتال خود را با یک کلیک شروع کنید',
    liaojiegengduo: 'بیشتر بیاموزید',
    chongzhi: 'شارژ کردن',
    jiaoyi: 'تجارت',
    remenbang: 'لیست محبوب',
    zhangfubang: 'لیست گینر',
    diefubang: 'لیست بازنده ها',
    chengjiaoliang: 'حجم',
    zanwushuju: 'هنوز هیچ داده ای وجود ندارد',
    jiayidui: 'جفت معاملاتی',
    zuixinjiage: 'آخرین قیمت',
    zhangdie24h: '24 ساعت صعود و سقوط',
    caozuo: 'عمل کنند',
    jiaoyi: 'تجارت',
    gengduo: 'بیشتر',
    suishisuidikaiqijiaoyi: 'تجارت را در هر زمان و هر مکان شروع کنید',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'چه برنامه یا وب باشد، می توانید به سرعت تراکنش خود را شروع کنید',
    saomaxiazaiApp: 'برای دانلود برنامه، کد QR را اسکن کنید',
    iosheAndroid: 'iOS و اندروید',
    ninkexinrenqieanquandejiamibijiaoyisuo: 'صرافی مطمئن و امن ارز دیجیتال شما',
    tishi1: 'گواهی رزرو',
    xiangqing1: 'ما قول می دهیم که تمام دارایی های کاربر را حداقل در نسبت 1:1 نگهداری کنیم',
    tishi2: 'بهترین نرخ تراکنش ها',
    xiangqing2: 'نرخ تراکنش ترجیحی، حقوق VIP رقابتی و برخورداری از بهترین کیفیت خدمات',
    tishi3: 'امنیت که می توانید روی آن حساب کنید',
    xiangqing3: 'اقدامات امنیتی پیچیده ما و بودجه SAFU از دارایی های دیجیتال شما در برابر همه خطرات محافظت می کند',
    tishi4: 'پشتیبانی 24*7 ساعته خدمات مشتری',
    xiangqing4: '24*7 ساعت، حالت کار تمام وقت، به سوالات مربوطه خود در اسرع وقت پاسخ دهید',
    tishi5: 'پایدار و ایمن',
    xiangqing5: 'صرافی پیشرو در جهان حفاظت امنیتی در سطح بانک را برای دارایی های شما فراهم می کند',
    tishi6: 'سوالات متداول',
    xiangqing6: 'برای توضیح دقیق ویژگی‌های خاص، سؤالات متداول را بررسی کنید',
    fanhui:"بازگشت",
    shishihangqing:'نقل قول های زمان واقعی',
    },
    footer: {
    bangzhuzhongxin: 'مرکز راهنمایی',
    dianhuazixun: 'مشاوره تلفنی',
    dianhuahegongzuoshijian: '1670-9756 (10:00 تا 19:00 در روزهای هفته / تعطیل در آخر هفته ها و تعطیلات)',
    liaotianzixun: 'مشاوره چت 1:1',
    keketuozixun: 'مشاوره کوکوتوک',
    zaixianbangzhu: 'کمک آنلاین',
    h24kaifang: '(24 ساعت باز است)',
    lixianbangzhu: 'کمک آفلاین',
    gongsidizhi: '1F، Yongsan Purgio Summit، 69 Hangang-gil، Yongsan-gu، سئول',
    gongzuoshijian: '(10:00 تا 18:00 در روزهای هفته / تعطیل در تعطیلات آخر هفته و تعطیلات)',
    shangshizixun: 'مشاوره لیست',
    hezuozixun: 'مشاوره همکاری',
    gongsi: 'شرکت',
    lianxiwomwn: 'با ما تماس بگیرید',
    zhidao: 'راهنمای',
    yonghuzhichi: 'پشتیبانی کاربر',
    zhanghuzhinan: 'راهنمای حساب - واریز / برداشت',
    ruanjianxiazai: 'دانلود نرم افزار',
    zhengce: 'سیاست',
    fuwuyuyinsitiaokuan: 'شرایط خدمات و حریم خصوصی',
    },
    login: {
    yonghudenglu: 'ورود کاربر',
    huanyingdenglu: 'به ورود خوش آمدید',
    xiangmumingcheng: 'BCFX',
    guanfangwangzhan: 'وب سایت رسمی',
    youxiang: 'ایمیل',
    qingshuruyouxiang: 'لطفا ایمیل خود را وارد کنید',
    mima: 'رمز عبور',
    qingshurumima: 'لطفا رمز عبور را وارد کنید',
    wangjimima: 'رمز عبور را فراموش کرده اید؟',
    denglu: 'وارد شوید',
    haimeiyouzhanghao: 'هنوز حساب کاربری ندارید؟ برو',
    zhuce: 'ثبت نام کنید',
    dengluchenggong: 'ورود با موفقیت',
    denglushibai: 'ورود ناموفق بود',
    qingshuruyonghuminghemima: 'لطفا نام کاربری و رمز عبور را وارد کنید',
    mimachangdubunengxiaoyuliuwei: 'طول رمز عبور نمی تواند کمتر از 6 کاراکتر باشد',
    youxiangrenzheng: 'تایید ایمیل',
    youxiangdizhi: 'آدرس ایمیل',
    qingshuruyouxiangdizhi: 'لطفا آدرس ایمیل را وارد کنید',
    tuxingyanzhengma: 'کد تصدیق گرافیکی',
    qingshurutuxingyanzhengma: 'لطفا کد تصدیق گرافیکی را وارد کنید',
    yanzhengma: 'کد تایید',
    qingshuruyouxiangyanzhengma: 'لطفا کد تایید ایمیل را وارد کنید',
    huoquyouxiangyanzhengma: 'کد تایید ایمیل را دریافت کنید',
    queding: 'مطمئنا',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'کد تأیید گرافیکی دریافت نشد، لطفاً دوباره درخواست کنید',
    qingxianwanchengyouxiangrenzheng: 'لطفاً ابتدا تأیید ایمیل را تکمیل کنید',
    huoquyouxiangyanzhengmashibai: 'دریافت کد تأیید ایمیل ناموفق بود،',
    houchongxinfasong: 'ارسال مجدد پس از S',
    youxiangyanzhengchenggong: 'تأیید ایمیل با موفقیت انجام شد',
    yanzhengmacuowu: 'خطای کد تأیید',
    xiugaidenglumima: 'رمز ورود را تغییر دهید',
    xinmima: 'رمز عبور جدید',
    quedingxinmima: 'رمز عبور جدید را تایید کنید',
    qingshuruxinmima: 'لطفا رمز عبور جدید را وارد کنید',
    qingzaicishuruxinmima: 'لطفا رمز جدید را دوباره وارد کنید',
    liangcimimashurubuyizhi: 'دو ورودی رمز عبور متناقض هستند',
    mimaxiugaichenggong: 'رمز عبور با موفقیت تغییر کرد',
    xiugaimimashibai: 'تغییر رمز عبور انجام نشد',
    },
    trade: {
    hangqingheyue: 'قرارداد',
    hangqingzuixinjia: 'آخرین قیمت',
    hangqingzhangdiefu: 'افزایش یا کاهش',
    hangqingzuigao24h: '24 ساعت بالاترین',
    hangqingzuidi24h: 'حداقل 24 ساعت',
    hangqingchengjiaoliang24h: 'حجم معاملات 24 ساعته',
    hangqingchengjiabishu: 'تعداد معاملات',
    zhanghuquanyi: 'حقوق حساب',
    zhanghukeyongshouyi: 'مزایای موجود',
    hangqingweituoliebiao: 'لیست سفارش',
    hangqingjiage: 'قیمت',
    hangqingshuliang: 'مقدار',
    hangqingzuixinchengjiao: 'آخرین معامله',
    hangqingjiage1: 'قیمت',
    hangqingshuliang1: 'مقدار',
    hangqingshijian: 'زمان',
    dingdanchiyoucangwei: 'موقعیتی را حفظ کنید',
    dingdandangqianweituo: 'سفارش فعلی',
    dingdanchengjiaojilu: 'سابقه معامله',
    dingdantiaojiandan: 'دستور مشروط',
    chicangheyue: 'قرارداد',
    chicangkaicangjunjia: 'قیمت موقعیت',
    chicangfudongyingkui: 'سود و زیان',
    chicanggangganbeishu: 'اهرم',
    chicangchicangliang: 'موقعیت(ها)',
    chicangduokong: 'بلند/کوتاه',
    chicangduo: 'بسیاری',
    chicangkong: 'تهی',
    chicangzhiyingjiage: 'قیمت سود بگیرید',
    chicangzhisunjiage: 'قیمت توقف ضرر',
    chicangqiangpingyugujiage: 'قیمت انحلال تخمینی',
    chicangcaozuo: 'عمل کنند',
    chicangzhiyingzhisun: 'سود بگیرید و ضرر کنید',
    chicangpingcang: 'بسته',
    chicangshouxufei: 'هزینه جابجایی',
    weituoheyue: 'قرارداد',
    weituoshuliang: 'مقدار سفارش',
    weituojiage: 'قیمت کمیسیون',
    weituofangxiang: 'جهت',
    weituogangganbeishu: 'اهرم چندگانه',
    weituochengjiaoshuliang: 'مقدار معامله',
    weituoshouxufei: 'هزینه جابجایی',
    weituochengjiaojunjia: 'قیمت متوسط',
    weituoshouyi: 'درآمد',
    weituozhuangtai: 'دولت',
    weituochengjiaoshijian: 'زمان معامله',
    weituocaozuo: 'عمل کنند',
    weituochedan: 'لغو سفارش',
    weituotijiaoshibai: 'ارسال ناموفق بود',
    weituozhilingtijiao: 'ارسال دستورالعمل',
    weituoyitijiao: 'ارسال شد',
    weituobufenchengjiao: 'معامله جزئی',
    weituobufenchengjiaoyichedan: 'برخی از سفارشات لغو شده است',
    weituowanquanchengjiao: 'معامله کامل',
    weituowanquanchedan: 'سفارش را به طور کامل لغو کنید',
    weituochedanzhong: 'در حال لغو سفارش',
    weituokaiduo: 'طولانی باز کنید',
    weituopingkong: 'آسمان صاف',
    weituokaikong: 'کوتاه باز کنید',
    weituopingduo: 'پیندو',
    chengjiaoheyue: 'قرارداد',
    chengjiaofangxiang: 'جهت',
    chengjiaoshuliang: 'مقدار معامله',
    chengjiaojiage: 'قیمت معامله',
    chengjiaoshijian: 'زمان معامله',
    chengjiaopingcangyingkui: 'سود و زیان',
    chengjiaoshouxufei: 'کارمزد تراکنش',
    chengjiaokaiduo: 'طولانی باز کنید',
    chengjiaopingkong: 'آسمان صاف',
    chengjiaokaikong: 'کوتاه باز کنید',
    chengjiaopingduo: 'پیندو',
    tiaojianzengjiatiaojian: 'شرایط را اضافه کنید',
    tiaojianzantingquanbu: 'مکث همه',
    tiaojianqidongquanbu: 'همه چیز را شروع کنید',
    tiaojianshanchuquanbu: 'همه را حذف کنید',
    tiaojianheyue: 'قرارداد',
    tiaojianweituoshuliang: 'مقدار سفارش',
    tiaojianfangxiang: 'جهت',
    tiaojiantiaojianleixing: 'نوع شرط',
    tiaojianjiageleixing: 'نوع قیمت',
    tiaojianbijiaofuhao: 'نماد مقایسه',
    tiaojiantiaojian: 'وضعیت',
    tiaojianzhuangtai: 'دولت',
    tiaojianchuangjianshijian: 'زمان ایجاد',
    tiaojiancaozuo: 'عمل کنند',
    tiaojianxiugai: 'تجدید نظر کنید',
    tiaojianqidong: 'راه اندازی',
    tiaojianzanting: 'مکث',
    tiaojianshanchu: 'حذف کنید',
    tiaojianlijixiadan: 'هم اکنون سفارش دهید',
    tiaojiankaiduo: 'طولانی باز کنید',
    tiaojianpingkong: 'آسمان صاف',
    tiaojiankaikong: 'کوتاه باز کنید',
    tiaojianpingduo: 'پیندو',
    tiaojianjiagetiaojian: 'شرایط قیمت',
    tiaojianshijiantiaojian: 'شرایط زمانی',
    tiaojianzuixinjia: 'آخرین قیمت',
    tiaojianByijia: 'یک قیمت بخر',
    tiaojianSyijia: 'فروش یک قیمت',
    tiaojianzanting: 'مکث',
    tiaojianyunxing: 'اجرا کنید',
    tiaojianyiwancheng: 'تکمیل شد',
    tiaojianshibai: 'شکست بخورد',
    tiaojianyishanchu: 'حذف شد',
    tiaojianshoudongchufawancheng: 'ماشه دستی کامل شد',
    tiaojianshoudongchufashibai: 'ماشه دستی ناموفق بود',
    xiadankaicang: 'باز',
    xiadanpingcang: 'بسته',
    xiadanweituoleixing: 'نوع',
    xiadanqingxuanze: 'لطفا انتخاب کنید',
    xiadanshijia: 'قیمت بازار',
    xiadanxianjia: 'محدودیت قیمت',
    xiadanjiage: 'قیمت',
    xiadanqingshurujiage: 'لطفا قیمت را وارد کنید',
    xiadanshuliang: 'مقدار',
    xiadanqingshurushuliang: 'لطفا مقدار را وارد کنید',
    xiadanzhang: 'باز کردن',
    xiadankekaishuliang: 'مقدار موجود',
    xiadanqingxianxuanzechicangdan: 'لطفاً ابتدا ترتیب موقعیت را انتخاب کنید',
    xiadankepingshuliang: 'مقدار قابل تراز کردن',
    xiadansuoxubaozhengjin: 'حاشیه مورد نیاز:',
    xiadanshijiazhiyingzhisunyushe: 'قیمت بازار از پیش تعیین شده سود و توقف ضرر دارد',
    xiadanyujiqiangpingjiage: 'قیمت تخمینی انحلال:',
    xiadanqiangpingjiagetishi: 'این قیمت انحلال مورد انتظار به صورت پویا بر اساس کل دارایی ها محاسبه می شود. این قیمت فقط برای مرجع است و به عنوان انحلال نهایی استفاده نخواهد شد.',
    xiadanzuoduo: 'طولانی برو',
    xiadanzuokong: 'کوتاه',
    xiadanpingcang: 'بسته',
    zhanghuheyuezichan: 'دارایی های قرارداد',
    zhanghuchongbi: 'سکه ها را واریز کنید',
    zhanghutibi: 'سکه ها را برداشت کنید',
    zhanghuhuazhuan: 'انتقال',
    zhanghuzhanghuquanyi: 'حقوق حساب',
    zhanghufudongyingkui: 'سود و زیان شناور',
    zhanghucangweibaozhengjin: 'حاشیه موقعیت',
    zhanghudongjiebaozhengjin: 'حاشیه انجماد',
    zhanghukeyongquanyi: 'مزایای موجود',
    zhanghubaozhengjinshiyonglv: 'نرخ بهره برداری حاشیه',
    hangqingheyuexinxi: 'اطلاعات قرارداد',
    hangqingheyuemingcheng: 'نام قرارداد',
    hangqingjijiahuobi: 'ارز مظنه',
    hangqingheyuedaxiao: 'اندازه قرارداد',
    hangqingzuixiaojiagebodong: 'حداقل نوسان قیمت',
    dialogleverRateganggantiaozheng: 'تعدیل اهرم',
    dialogleverRatequeding: 'مطمئنا',
    dialogtransferzichanhuazhuan: 'انتقال دارایی',
    dialogtransferbizhong: 'ارز',
    dialogtransfercong: 'از',
    dialogtransferdao: 'رسیدن',
    dialogtransfershuliang: 'مقدار',
    dialogtransferqingshuruhuazhuanshuliang: 'لطفا مبلغ انتقال را وارد کنید',
    dialogtransferquanbu: 'همه',
    dialogtransferkeyong: 'موجود است',
    dialogtransferhuazhuan: 'انتقال',
    dialogtransferjichuzhanghu: 'حساب پایه',
    dialogtransferheyuezhanghu: 'حساب قرارداد',
    dialogfitlosszhiyingzhisun: 'سود بگیرید و ضرر کنید',
    dialogfitlossheyu: 'قرارداد',
    dialogfitlossduokong: 'بلند/کوتاه',
    dialogfitlosschichangjunjia: 'میانگین قیمت موقعیت',
    dialogfitlossshuliang: 'مقدار',
    dialogfitlosszuixinjia: 'آخرین قیمت',
    dialogfitlosszhiyingdianshu: 'امتیاز سود بگیرید',
    dialogfitlosszhisundianshu: 'امتیازات را متوقف کنید',
    dialogfitlossdianshu: 'نمره',
    dialogfitlosszhiyingjiage: 'قیمت سود بگیرید',
    dialogfitlosszhisunjiage: 'قیمت توقف ضرر',
    dialogfitlossjiage: 'قیمت',
    dialogfitlossyuqishouyi: 'بازده مورد انتظار',
    dialogfitlossyuqikuisun: 'ضرر مورد انتظار',
    dialogfitlosstishi1: 'نکته: حداقل نوسان قرارداد فعلی است',
    dialogfitlosstishi2: '، لطفاً قیمت را روی 0 تنظیم کنید تا سود را لغو کنید و ضرر را متوقف کنید.',
    dialogfitlossqueding: 'مطمئنا',
    dialogfitlossduo: 'بسیاری',
    dialogfitlosskong: 'تهی',
    dialogconditionzengjiayuntiaojiandan: 'برگه شرایط ابری را اضافه کنید',
    dialogconditiontiaojianleixing: 'نوع شرایط:',
    dialogconditionjiagetiaojiandan: 'برگه شرایط قیمت',
    dialogconditionshijiantiaojiandan: 'برگه شرایط زمانی',
    dialogconditionjiageleixing: 'نوع قیمت:',
    dialogconditionzuixinjia: 'آخرین قیمت',
    dialogconditionByijia: 'یک قیمت بخر',
    dialogconditionSyijia: 'فروش یک قیمت',
    dialogconditiontiaojianshezhi: 'تنظیمات شرایط:',
    dialogconditionjiageshezhi: 'تنظیمات قیمت:',
    dialogconditionqingshurujiage: 'لطفا قیمت را وارد کنید',
    dialogconditionshijianshezhi: 'تنظیم زمان:',
    dialogconditionjiaoyileixing: 'نوع معامله:',
    dialogconditionkaiduo: 'طولانی باز کنید',
    dialogconditionpingkong: 'آسمان صاف',
    dialogconditionkaikong: 'کوتاه باز کنید',
    dialogconditionpingduo: 'پیندو',
    dialogconditiondingdanshuliang: 'مقدار سفارش:',
    dialogconditionqingshurudingdanshuliang: 'لطفا مقدار سفارش را وارد کنید',
    dialogconditiondingdanshixiao: 'زمان سفارش:',
    dialogconditionyongjiuyouxia: 'اعتبار دائمی',
    dialogconditiontijiao: 'ارسال کنید',
    dialogAutoProfitLosszhiyingzhisun: 'سود بگیرید و ضرر کنید',
    dialogAutoProfitLossmingcheng: 'نام',
    dialogAutoProfitLosszhiyingdian: 'امتیاز سود بگیرید',
    dialogAutoProfitLosszhisundian: 'نقطه توقف ضرر',
    dialogAutoProfitLosschongzhi: 'تنظیم مجدد',
    dialogAutoProfitLoss: 'ارسال کنید',
    dialogCloseOrderpingcang: 'بسته',
    dialogCloseOrderweituoleixing: 'نوع نماینده',
    dialogCloseOrderqingxuanze: 'لطفا انتخاب کنید',
    dialogCloseOrdershijia: 'قیمت بازار',
    dialogCloseOrderxianjia: 'محدودیت قیمت',
    dialogCloseOrderjiage: 'قیمت',
    dialogCloseOrderqingshurujiage: 'لطفا قیمت را وارد کنید',
    dialogCloseOrdershuliang: 'مقدار',
    dialogCloseOrderqingshurushuliang: 'لطفا مقدار را وارد کنید',
    dialogCloseOrderzhang: 'باز کردن',
    dialogCloseOrderkepingshuliang: 'مقدار قابل تراز کردن',
    dialogCloseOrderqueding: 'مطمئنا',
    jsMessageResgaiheyuegangganxiugaichenggong: 'اهرم قرارداد با موفقیت اصلاح شد',
    jsMessageResgaiheuyegangganxiugaishibai: 'اصلاح اهرم قرارداد ناموفق بود',
    jsMessageReskaicangchenggong: 'موقعیت با موفقیت باز شد',
    jsMessageReskaicangshibai: 'باز شدن موقعیت ناموفق بود:',
    jsMessageReskaicang1: 'گشایش پوزیشن ممنوع',
    jsMessageReskaicang2: 'قرارداد وجود ندارد',
    jsMessageReskaicang3: 'تعداد پوزیشن های باز از حد بالایی بیشتر است',
    jsMessageReskaicang4: 'قیمت 0',
    jsMessageReskaicang5: 'حاشیه ناکافی برای باز کردن موقعیت',
    jsMessageRespingcangchenggong: 'موقعیت با موفقیت بسته شد',
    jsMessageRespingcangshibai: 'موقعیت بسته نشد:',
    jsMessageRespingcang1: 'قرارداد وجود ندارد',
    jsMessageRespingcang2: 'رسید موقعیت وجود ندارد',
    jsMessageRespingcang3: 'مقدار ناکافی',
    jsMessageReszijinhuazhuanchenggong: 'انتقال وجه با موفقیت انجام شد',
    jsMessageReszijinhuazhuanshibai: 'انتقال وجه انجام نشد',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'تعیین موقعیت دستور توقف سود و ضرر و زیان با موفقیت تنظیم شد',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'تنظیمات توقف سود و توقف ضرر برای سفارشات موقعیت ناموفق بود:',
    jsMessageResweituodanchedanchenggong: 'سفارش با موفقیت لغو شد',
    jsMessageResweituodanchedanshibai: 'لغو سفارش انجام نشد',
    jsMessageRestiaojiandantianjiachenggong: 'سفارش مشروط با موفقیت اضافه شد',
    jsMessageResheyuebucunzai: 'قرارداد وجود ندارد',
    jsMessageResjiageshezhicuowu: 'خطای تعیین قیمت',
    jsMessageResshijianshezhicuowu: 'خطای تنظیم زمان',
    jsMessageResneibucuowu: 'خطای داخلی',
    jsMessageRestiaojiandantianjiashibai: 'سفارش مشروط اضافه نشد:',
    jsMessageReszhiyingzhisunshezhichenggong: 'مجموعه سود و توقف ضرر را با موفقیت دریافت کنید',
    jsconfirmquedingchedanma: 'آیا مطمئن هستید که می خواهید سفارش را لغو کنید؟',
    jsconfirmtishi: 'اشاره',
    jsconfirmqueding: 'مطمئنا',
    jsconfirmquxiao: 'لغو کنید',
    jsMessageqingxiandenglu: 'لطفا ابتدا وارد شوید!',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'این قرارداد دارای موقعیت های باز است و اهرم قابل تغییر نیست.',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'این قرارداد دارای دستور است و اهرم قابل تغییر نیست.',
    jsMessageqingshuruzhengquedeshuliang: 'لطفا مقدار صحیح را وارد کنید!',
    jsMessageqingshuruzhengquedejiage: 'لطفا قیمت صحیح را وارد کنید',
    jsMessageqingxianxuanzechicang: 'لطفا ابتدا یک موقعیت را انتخاب کنید!',
    jsMessageqingshuruhuazhuanshuliang: 'لطفا مبلغ انتقال را وارد کنید!',
    jsMessageqingxianxuanzeheyue: 'لطفا ابتدا یک قرارداد را انتخاب کنید!',
    jsMessageqingshurutiaojiandanshuliang: 'لطفا مقدار سفارش مشروط را وارد کنید!',
    jsMessageqingshurutiaojiandandejiage: 'لطفا قیمت سفارش مشروط را وارد کنید!',
    jsMessageqingshurutiaojiandanzhixingshijian: 'لطفا زمان اجرای سفارش مشروط را وارد کنید!',
    emptytext: 'هنوز هیچ داده ای وجود ندارد',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'پیپ های سود و توقف ضرر تنظیم نشده اند',
    jsMessageReschicangdanbucunzai: 'رسید موقعیت وجود ندارد',
    zxkcje:'حداقل مقدار باز شدن',
    },
    commisson:{
    dlzx:'مرکز نمایندگی',
    sjzl:'مروری بر داده ها',
    quanbu:"همه",
    jinri:'امروز',
    zuori:'دیروز',
    ri:'روز',
    wdfy:'تخفیف من',
    wdsjfy:'تخفیف سطح سوم من',
    dengji:'درجه',
    yqrs:'تعداد افراد دعوت شده',
    tdrs:'اندازه تیم',
    yqxq:'جزئیات دعوتنامه',
    yhm:"نام کاربری",
    cjsj:'زمان ایجاد',
    fyxq:'جزئیات تخفیف',
    zyj:'کل کمیسیون',
    dryj:'کمیسیون یک روزه',
    fyje:'مبلغ تخفیف',
    
    },
    assets: {
    zongzichan: 'کل دارایی ها',
    chongbi: 'سکه ها را واریز کنید',
    tibi: 'سکه ها را برداشت کنید',
    huazhuan: 'انتقال',
    fabizhanghu: 'حساب پایه',
    heyuezhanghu: 'حساب قرارداد',
    jiayiliushui: 'جریان معامله',
    dialogzichanhuazhuan: 'انتقال دارایی',
    cong: 'از',
    dao: 'رسیدن',
    shuliang: 'مقدار',
    qingshuruhuazhuanshuliang: 'لطفا مبلغ انتقال را وارد کنید',
    quanbu: 'همه',
    keyong: 'موجود است',
    huanzhuan: 'انتقال',
    zijinhuazhuanchenggong: 'انتقال وجه با موفقیت انجام شد',
    zijinhuazhuanshibai: 'انتقال وجه انجام نشد',
    bibizhanghu: 'حساب سکه',
    qiquanzhanghu:'حساب گزینه ها',
    zczl:'نمای کلی دارایی',
    zzczh:'تخفیف کل دارایی ها',
    ztzc:"دارایی های در حال انتقال",
    djzc:'مسدود کردن دارایی ها',
    zyzc:'دارایی های تعهد شده',
    cwzc:'موقعیت دارایی ها',
    jyzh:'حساب معاملاتی',
    bizhong:'ارز',
    dongjie:'منجمد کردن',
    lirun:"سود",
    hyzh:"حساب قرارداد",
    },
    basicsAccountRecord: {
    fanhui: 'بازگشت',
    fabizhanghuliushui: 'جریان حساب معاملاتی',
    fabijiaoyiliushuileixing: 'نوع جریان تراکنش',
    qingxuanze: 'لطفا انتخاب کنید',
    chaxunriqi: 'تاریخ پرس و جو',
    liushuileixing: 'نوع خط لوله',
    shijian: 'زمان',
    biandongjine: 'مقدار را تغییر دهید',
    yue: 'تعادل',
    quanbu: 'همه',
    Sellbi: 'فروش سکه',
    Buybi: 'خرید سکه',
    tibi: 'سکه ها را برداشت کنید',
    chongbi: 'سکه ها را واریز کنید',
    fabizhuanheyue: 'قرارداد انتقال پایه',
    heyuezhuanfabi: 'قرارداد به پایه',
    zhuanzhang: 'انتقال',
    fabihuazhuandaoheyue: 'انتقال اولیه به قرارداد',
    heyuehuazhuandaofabi: 'انتقال قرارداد به پایگاه',
    goumaijijin: 'یک استخر معدن بخرید',
    jijinshuhui: 'بازخرید استخر معدن',
    jijinshouyi: 'درآمد بازخرید استخر معدن',
    fabizhuanqihuo: 'تبدیل پایه به معاملات آتی',
    qihuozhuanfabi: 'اساس نقل و انتقالات آتی',
    fabizhuanganggu: 'انتقال اولیه به سهام هنگ کنگ',
    gangguzhuanfabi: 'مبانی انتقال سهام هنگ کنگ',
    fabizhuanbibi: 'ارز انتقال پایه',
    bibizhuanfabi: 'مبانی انتقال ارز به ارز',
    goumaiSGCB: 'خرید ICO',
    chushouSGCB: 'فروش ICO',
    huoqushibai: 'به دست نیامد',
    zanwushuju: 'هنوز هیچ داده ای وجود ندارد',
    },
    contractAccountRecord: {
    fanhui: 'بازگشت',
    heyuezhanghuliushui: 'جریان حساب قرارداد',
    heyuejiaoyiliushuileixing: 'نوع جریان معامله قرارداد',
    qingxuanze: 'لطفا انتخاب کنید',
    chaxunriqi: 'تاریخ پرس و جو',
    liushuileixing: 'نوع خط لوله',
    shijian: 'زمان',
    biandongjine: 'مقدار را تغییر دهید',
    yue: 'تعادل',
    quanbu: 'همه',
    kaicangshouxufei: 'هزینه افتتاحیه',
    pingcangshouxufei: 'هزینه بسته شدن',
    pingcangyingkui: 'سود و زیان بسته شدن موقعیت',
    fabizhuanheyue: 'قرارداد انتقال پایه',
    heyuezhuanfabi: 'قرارداد به پایه',
    zhuanzhang: 'انتقال',
    kaicangyongjin: 'کمیسیون افتتاحیه',
    pingcangyongjin: 'کمیسیون اختتامیه',
    huoqushibai: 'به دست نیامد',
    zanwushuju: 'هنوز هیچ داده ای وجود ندارد',
    },
    recharge: {
    zanwushuju: 'هنوز هیچ داده ای وجود ندارد',
    shijian: 'زمان',
    leixing: 'نوع',
    shuliang: 'مقدار',
    zhuangtai: 'دولت',
    caozuo: 'عمل کنند',
    chakanxiangqing: 'جزئیات را بررسی کنید',
    huoqushibai: 'به دست نیامد',
    dialogtitle: 'جزئیات',
    chongbi: 'سکه ها را واریز کنید',
    fanhui: 'بازگشت',
    lianmingcheng: 'نام زنجیر',
    fuzhilianjie: 'آدرس را کپی کنید',
    tishixinxi1: '• لطفاً هیچ گونه دارایی غیر TRC_USDT یا ERC_USDT را به آدرس فوق واریز نکنید، در غیر این صورت دارایی ها بازیابی نمی شوند.',
    tishixinxi2: '• پس از شارژ مجدد به آدرس فوق، تایید کل گره شبکه مورد نیاز است.',
    tishixinxi3: '• حداقل مبلغ سپرده: 50 USDT، سپرده های کمتر از حداقل مبلغ اعتبار داده نمی شود و قابل استرداد نمی باشد.',
    tishixinxi4: '• آدرس شارژ شما به طور مکرر تغییر نمی کند و می توان آن را به طور مکرر شارژ کرد، در صورت وجود هر گونه تغییر، ما تمام تلاش خود را می کنیم تا از طریق اطلاعیه های وب سایت یا ایمیل به شما اطلاع دهیم',
    tishixinxi5: '• لطفاً مطمئن شوید که رایانه و مرورگر شما ایمن هستند تا از دستکاری یا درز اطلاعات جلوگیری شود.',
    putongchongbi: 'سپرده معمولی',
    jiaoyichenggong: 'معامله با موفقیت انجام شد',
    jiaoyishibai: 'معامله ناموفق بود',
    OMNIweihuzhong: 'زنجیره OMNI تحت تعمیر است، لطفاً TRC20 را انتخاب کنید',
    },
    withdraw: {
    fanhui: 'بازگشت',
    zanwushuju: 'هنوز هیچ داده ای وجود ندارد',
    tibi: 'سکه ها را برداشت کنید',
    huoqushibai: 'به دست نیامد',
    OMNIweihuzhong: 'زنجیره OMNI تحت تعمیر است، لطفاً TRC20 را انتخاب کنید',
    shijian: 'زمان',
    leixing: 'نوع',
    shuliang: 'مقدار',
    zhuangtai: 'دولت',
    caozuo: 'عمل کنند',
    chakanxiangqing: 'جزئیات را بررسی کنید',
    putongtibi: 'برداشت معمولی',
    dialogtitle: 'جزئیات',
    dialogtitlewithdraw: 'سکه ها را برداشت کنید',
    houchongxinfasong: 'ارسال مجدد پس از S',
    huoquyouxiangyanzhengma: 'کد تایید ایمیل را دریافت کنید',
    lianmingcheng: 'نام زنجیر',
    tibidizhi: 'آدرس برداشت',
    qingshurutibidizhi: 'لطفا آدرس برداشت را وارد کنید',
    tibishuliang: 'مقدار سکه های برداشت شده',
    shouxufei: 'هزینه جابجایی',
    qingshurutibishuliang: 'لطفا مبلغ برداشت را وارد کنید',
    quanbu: 'همه',
    keyong: 'موجود است',
    daozhangshuliang: 'مقدار ورود',
    zuixiaotibishuliang: 'حداقل مبلغ برداشت عبارت است از:',
    tishixinxi: 'به منظور اطمینان از امنیت وجوه، هنگامی که خط مشی امنیتی حساب شما تغییر می کند یا رمز عبور شما تغییر می کند، ما صبور باشید و منتظر بمانید تا کارکنان از طریق تلفن یا ایمیل با شما تماس بگیرند. لطفاً مطمئن شوید که رایانه و مرورگر شما ایمن هستند تا از دستکاری یا لو رفتن داده ها جلوگیری شود.',
    tuxingyanzhengma: 'کد تصدیق گرافیکی',
    qingshurutuxingyanzhengma: 'لطفا کد تصدیق گرافیکی را وارد کنید',
    youxiangyanzhengma: 'کد تایید ایمیل',
    queding: 'مطمئنا',
    tijiaozhong: 'در حال ارائه',
    yijujue: 'رد شد',
    yitongguo: 'گذشت',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'کد تأیید گرافیکی دریافت نشد، لطفاً دوباره درخواست کنید',
    huoquyouxiangyanzhengmashibai: 'دریافت کد تأیید ایمیل ناموفق بود،',
    qingxianwanchengyouxiangrenzheng: 'لطفاً ابتدا تأیید ایمیل را تکمیل کنید',
    huoquyouxiangshibaiqingchongxinqingqiu: 'آدرس ایمیل دریافت نشد، لطفاً دوباره درخواست کنید',
    qingshuruyouxiangyanzhengma: 'لطفا کد تایید ایمیل را وارد کنید',
    youxiangyanzhengmacuowu: 'کد تایید ایمیل اشتباه است،',
    qxsmrz:'لطفا ابتدا نام واقعی خود را تأیید کنید',
    tishi:'اشاره',
    queding:'مطمئنا'
    },
    contractTransactionRecord: {
    fanhui: 'بازگشت',
    zanwushuju: 'هنوز هیچ داده ای وجود ندارد',
    heyuedingdan: 'سفارش قرارداد',
    qingxuanze: 'لطفا انتخاب کنید',
    quanbu: 'همه',
    kaiduo: 'طولانی باز کنید',
    pingkong: 'آسمان صاف',
    kaikong: 'کوتاه باز کنید',
    pingduo: 'پیندو',
    xianjiaweituo: 'سفارش محدود',
    shijiaweituo: 'سفارش بازار',
    suoyouzhuangtai: 'همه وضعیت',
    tijiaoshibai: 'ارسال ناموفق بود',
    zhunbeitijiao: 'آماده ارسال',
    yitijiao: 'ارسال شد',
    bufentijiao: 'معامله جزئی',
    bufentijiaoyichedan: 'برخی از سفارشات لغو شده است',
    quanbuchengjiao: 'کلیه معاملات',
    yichedan: 'سفارش لغو شد',
    huoqushibai: 'به دست نیامد',
    heyuedaima: 'کد قرارداد',
    ganggan: 'اهرم',
    xiadanshijian: 'زمان سفارش',
    chengjiaoshijian: 'زمان معامله',
    fangxiang: 'جهت',
    baojialeixing: 'نوع نقل قول',
    weituoliang: 'مقدار کمیسیون (قطعه)',
    weituojia: 'قیمت سفارش (USDT)',
    chengjiaoliang: 'حجم معاملات (بلیت)',
    chengjiaojunjia: 'میانگین قیمت تراکنش (USDT)',
    yingkui: 'سود و زیان (USDT)',
    shouxufei: 'هزینه جابجایی (USDT)',
    zhuangtai: 'دولت',
    gupiaodingdan: 'سفارش سهام',
    waihuidingdan: 'سفارشات فارکس',
    gupiaodaima: 'کد سهام',
    waihuidaima: 'کد فارکس',
    },
    kLine: {
    zhibiao: 'شاخص',
    shezhi: 'راه اندازی کرد',
    quanping: 'تمام صفحه',
    tuichuquanping: 'خروج از تمام صفحه',
    jishuzhibiao: 'شاخص های فنی',
    futuzhibiao: 'نشانگر تصویر فرعی',
    zhutuzhibiao: 'نشانگر نمودار اصلی',
    huifumoren: 'بازیابی پیش فرض',
    lazhutuleixing: 'انواع شمعدان',
    qingxuanze: 'لطفا انتخاب کنید',
    quanshixin: 'همه جامد',
    quankongxin: 'کاملا توخالی',
    zhangkongxin: 'توخالی در حال افزایش',
    diekongxin: 'توخالی افتادن',
    mianjitu: 'نمودار مساحتی',
    jiagezhouleixing: 'نوع محور قیمت',
    xianxingzhou: 'محور خط',
    baifenbizhou: 'محور درصد',
    duishuzhou: 'محور لگاریتمی',
    zuigaojiaxianshi: 'نمایش بالاترین قیمت',
    zuidijiaxianshi: 'نمایش کمترین قیمت',
    zuixinjiaxianshi: 'نمایش آخرین قیمت',
    zhibiaozuixinzhixianshi: 'آخرین مقدار نشانگر نمایش داده می شود',
    daozhizuobiao: "مختصات معکوس",
    wanggexianxianshi: 'نمایش خط شبکه',
    hfcg:'بازیابی با موفقیت انجام شد',
    },
    SecurityCenter: {
    fanhui: 'بازگشت',
    anquanzhongxin: 'مرکز امنیتی',
    qingshurunicheng: 'لطفا یک نام مستعار وارد کنید',
    nichengxiugaishibai: 'اصلاح نام مستعار انجام نشد',
    huoquyonghuxinxishibai: 'اطلاعات کاربر به دست نیامد',
    xiugaidenglumima: 'رمز ورود را تغییر دهید',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'کد تأیید گرافیکی دریافت نشد، لطفاً دوباره درخواست کنید',
    qingshurujiumima: 'لطفا رمز عبور قدیمی را وارد کنید',
    qingshuruxinmima: 'لطفا رمز عبور جدید را وارد کنید',
    qingzaicishuruxinmima: 'لطفا رمز جدید را دوباره وارد کنید',
    qingshurutuxingyanzhengma: 'لطفا کد تصدیق گرافیکی را وارد کنید',
    qingshuruyanzhengma: 'لطفا کد تایید را وارد کنید',
    huoquzhuceshoujihaoshibai: 'شماره موبایل ثبت شده بدست نیامد',
    qingxianwanchengshoujirenzheng: 'لطفاً ابتدا احراز هویت تلفن همراه را کامل کنید',
    huoqushoujiyanzhengmashibai: 'کد تأیید تلفن همراه دریافت نشد',
    huoqushoujiyanzhengma: 'دریافت کد تایید تلفن همراه',
    houchongxinfasong: 'ارسال مجدد پس از S',
    mimacuowu: 'رمز عبور اشتباه',
    liangcixinmimashurubuyizhiqingjiancha: 'دو کلمه عبور جدید وارد شده ناهماهنگ هستند، لطفا بررسی کنید',
    xinmimaheyuanmimabunengyiyang: 'رمز عبور جدید نمی تواند با رمز اصلی یکسان باشد',
    qingshurushoujiyanzhengma: 'لطفا کد تایید تلفن همراه را وارد کنید',
    yanzhengmacuowu: 'خطای کد تأیید',
    denglumimaxiugaichenggong: 'رمز ورود با موفقیت تغییر کرد',
    denglumimaxiugaishibai: 'تغییر رمز ورود به سیستم انجام نشد',
    xiugaizijinmima: 'رمز عبور صندوق را تغییر دهید',
    qingshuruzijinmima: 'لطفا رمز صندوق را وارد کنید',
    qingzaicishuruzijinmima: 'لطفا رمز صندوق را دوباره وارد کنید',
    liangcizijinmimabuyizhiqingjiancha: 'دو رمز عبور صندوق متناقض هستند، لطفا بررسی کنید',
    shezhizijinmimachenggong: 'رمز عبور صندوق با موفقیت تنظیم شد',
    shezhizijinmimashibai: 'رمز عبور صندوق تنظیم نشد',
    xiugaizijinmimachenggong: 'رمز عبور صندوق با موفقیت اصلاح شد',
    xiugaizijinmimashibai: 'رمز عبور صندوق تغییر نکرد',
    youxiangrenzheng: 'احراز هویت ایمیل',
    huoquyouxiangyanzhengma: 'کد تایید ایمیل را دریافت کنید',
    qingshuruyouxiangdizhi: 'لطفا آدرس ایمیل را وارد کنید',
    huoquyouxiangyanzhengmashibai: 'کد تأیید ایمیل دریافت نشد',
    qingshuruyouxiangyanzhengma: 'لطفا کد تایید ایمیل را وارد کنید',
    youxiangyanzhengchenggong: 'تأیید ایمیل با موفقیت انجام شد',
    weirenzheng: 'گواهی نشده است',
    queding: 'مطمئنا',
    anquanshezhiguanli: 'مدیریت تنظیمات امنیتی',
    denglumima: 'رمز ورود',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'با تعیین رمز ورود، می توانید مستقیماً با استفاده از رمز عبور حساب کاربری خود وارد شوید',
    xiugai: 'تجدید نظر کنید',
    zijinmima: 'رمز عبور صندوق',
    yongyubaohuzijinanquan: 'برای محافظت از ایمنی وجوه استفاده می شود',
    shezhi: 'راه اندازی کرد',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'برای تأیید امنیتی هنگام ورود به سیستم، برداشت سکه، بازیابی رمزهای عبور و تغییر تنظیمات امنیتی استفاده می شود.',
    yirenzheng: 'گواهی شده',
    renzheng: 'گواهینامه',
    shimingrenzheng: 'احراز هویت نام واقعی',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'برای احراز هویت امنیتی هنگام خرید، فروش، برداشت، سپرده گذاری و انتقال سکه استفاده می شود.',
    daishenhe: 'در انتظار بررسی',
    yibohui: 'اخراج شد',
    yuandenglumima: 'رمز ورود اصلی',
    xinmima: 'رمز عبور جدید',
    quedingxinmima: 'رمز عبور جدید را تایید کنید',
    tuxingyanzhengma: 'کد تصدیق گرافیکی',
    yanzhengma: 'کد تایید',
    zijinmima: 'رمز عبور صندوق',
    querenzijinmima: 'رمز عبور صندوق را تأیید کنید',
    youxiangdizhi: 'آدرس ایمیل',
    qingxianwanchengyouxiangrenzheng: 'لطفاً ابتدا تأیید ایمیل را تکمیل کنید',
    xiugaitouxiangchenggong: 'چهره‌نما با موفقیت اصلاح شد',
    xiugaitouxiangshibai: 'تغییر چهره‌نما ناموفق بود',
    shangchuantouxiangzhinengshijpggeshi: 'آپلود تصاویر آواتار فقط می تواند در فرمت JPG باشد!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'اندازه تصویر آواتار آپلود شده نمی تواند بیش از 2 مگابایت باشد!',
    },
    verification: {
    fanhui: 'بازگشت',
    shimingrenzheng: 'احراز هویت نام واقعی',
    qingshuruxingming: 'لطفا نام را وارد کنید',
    qingshuruzhengjianhaoma: 'لطفا شماره شناسایی خود را وارد کنید',
    huoquyonghuxinxishibai: 'اطلاعات کاربر به دست نیامد',
    wenjiangeshicuowu: 'خطای فرمت فایل',
    wenjianbunengdayu5M: 'فایل نمی تواند بزرگتر از 5M باشد',
    qingtianxiexingming: 'لطفا نام خود را پر کنید',
    qingtianxiezhengjianhaoma: 'لطفا شماره شناسنامه را وارد کنید',
    qingshangchuanzhengjianzhengmian: 'لطفا قسمت جلوی شناسنامه خود را آپلود کنید',
    qingshangchuanzhengjianfanmian: 'لطفا سمت عقب شناسنامه خود را آپلود کنید',
    tijiaochenggong: 'ارسال با موفقیت انجام شد',
    kaiqishenfenrenzheng: 'احراز هویت را روشن کنید',
    bohuiyuanyin: 'دلیل رد',
    qingtianxiejiashizhizhaogongminkahuzhao: 'لطفا اطلاعات گواهینامه رانندگی/کارت شهروندی/گذرنامه خود را وارد کنید',
    xingming: 'نام',
    zhengjianhaoma: 'شماره شناسنامه',
    qingshangchuanjiashizhizhaogongminkahuzhao: 'لطفا اطلاعات گواهینامه رانندگی/کارت شهروندی/گذرنامه را آپلود کنید',
    shangchuanzhengjianzhengmian: 'جلوی شناسه را آپلود کنید',
    huotuozhuafangru: 'یا بکشید و رها کنید',
    shangchuanzhengjianfanmian: 'سمت عقب گواهی را آپلود کنید',
    tijiao: 'ارسال کنید',
    },
    invite: {
    yaoqinghaoyoudejiangli: 'دوستان را دعوت کنید و جایزه بگیرید',
    yongjiukede: 'به طور دائم در دسترس است',
    jiaoyifanyong: 'تخفیف معاملاتی',
    fasongyaoqinggeihaoyou: 'ارسال دعوت نامه برای دوستان',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'با اشتراک گذاری لینک، دوستان را برای ثبت نام BCFX دعوت کنید',
    haoyouzhuce: 'ثبت نام دوست',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'دوست دعوت نامه را می پذیرد، ثبت نام را تکمیل می کند و معاملات را انجام می دهد',
    huodexiangyingbilijiangli: 'پاداش های متناسب را دریافت کنید',
    qingsonghuodejiaoyishouxufeijianglifuli: 'به راحتی مزایای پاداش کارمزد تراکنش را دریافت کنید',
    wodezhuanshufenxiangfangshi: 'راه انحصاری من برای اشتراک گذاری',
    yaoqinglianjie: 'لینک دعوت',
    jiazaizhong: 'در حال بارگذاری...',
    fuzhilianjie: 'لینک را کپی کنید',
    yaoqingma: 'کد دعوت',
    fuzhiyaoqingma: 'کد دعوت را کپی کنید',
    wodeyaoqing: 'دعوت من',
    yaoqingrenshu: 'تعداد افراد دعوت شده',
    huodejiangli: 'پاداش بگیرید',
    huodongxize: 'جزئیات رویداد',
    tips1: 'ارتباط بین کاربران پلت فرم سه سطح است، مانند: کاربران ABCD سه سطح از کاربران را تشکیل می دهند و A بالاترین سطح است!',
    tips2: 'روابط علاقه بین سطوح',
    tips201: '.',
    tips3: 'پاداش نقدی: مشتری A می تواند 40٪ از کارمزد معامله قرارداد مشتری B + 20٪ از کارمزد معامله قرارداد مشتری C + 10٪ از کارمزد معامله قرارداد مشتری D را دریافت کند.',
    tips4: 'به عنوان مثال: مشتری A دارای 10 مشتری سطح اول B، 10 مشتری سطح دوم C، و 10 مشتری سطح سوم D. اگر قرارداد هر مشتری 100000 USDT معامله کند.',
    tips5: 'سپس بازگشتی که مشتری A می تواند دریافت کند',
    tips6: '100000*1.2%*40%*10+100000*1.2%*20%*10+100000*1.2%*10%*10=8400 USDT پاداش نقدی، که در زمان واقعی قابل برداشت است',
    tips7: 'اگر فعالیت تنظیم شود، مشمول به‌روزرسانی‌های پلتفرم BCFX می‌شود و حق نهایی تفسیر متعلق به BCFX است.',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'کد تأیید گرافیکی دریافت نشد، لطفاً دوباره درخواست کنید',
    qingshuruyouxiang: 'لطفا ایمیل خود را وارد کنید',
    qingshurutuxingyanzhengma: 'لطفا کد تصدیق گرافیکی را وارد کنید',
    qingshuruyanzhengma: 'لطفا کد تایید را وارد کنید',
    qingshurunindemima: 'لطفا رمز عبور خود را وارد کنید',
    qingzaicishurunindemima: 'لطفا رمز عبور خود را دوباره وارد کنید',
    qingshuruzhifumima: 'لطفا رمز پرداخت را وارد کنید',
    xuantian: 'اختیاری',
    bitian: 'مورد نیاز',
    yonghuzhuce: 'ثبت نام کاربر',
    huanyingdenglu: 'به ورود خوش آمدید',
    guanfangwangzhan: 'وب سایت رسمی',
    xiangmumingcheng: 'BCFX',
    youxiang: 'ایمیل',
    tuxingyanzhengma: 'کد تصدیق گرافیکی',
    yanzhengma: 'کد تایید',
    huoquyanzhengma:'کد تایید',
    shezhimima: 'رمز عبور را تنظیم کنید',
    quedingmima: 'رمز عبور را تایید کنید',
    zhifumima: 'رمز پرداخت',
    yaoqingma: 'کد دعوت',
    zhuce: 'ثبت نام کنید',
    yiyouzhanghao: 'از قبل یک حساب کاربری دارید؟',
    denglu: 'وارد شوید',
    qingshuruyouxiangdizhi: 'لطفا آدرس ایمیل را وارد کنید',
    huoqushibai: 'به دست نیامد',
    houchongxinfasong: 'ارسال مجدد پس از S',
    liangcishurudemimabuyizhi: 'پسوردهای وارد شده دو بار متناقض هستند',
    zhucechenggong: 'ثبت نام با موفقیت انجام شد',
    zhuceshibai: 'ثبت نام انجام نشد',
    },
    treasure:{
    jichubizhong: 'ارز پایه',
    shouyibizhong: 'ارز درآمدی',
    wakuangtianshu: 'روزهای معدن',
    shouyitianshu: 'روزهای کسب درآمد',
    suodingtianshu: 'روزهای قفل',
    yichanshengshouyi: 'درآمد ایجاد شده',
    yuqishouyi: 'بازده مورد انتظار',
    kaishishijian: 'زمان شروع',
    jieshushijian: 'زمان پایان',
    qingshuruninyaosuodingdebizhongshuliang: 'لطفاً مقدار USDT را به ارزی که می خواهید قفل کنید وارد کنید',
    huoqushibai: 'به دست نیامد',
    meiyitian: 'هر 1 روز',
    dakai: 'باز کنید',
    yuanyuzhoukuangchi: 'استخر معدن Metaverse',
    jilu: 'ضبط کنید',
    kuangchixiangqing: 'جزئیات استخر معدن',
    suo: 'قفل',
    yitianchanchu: 'خروجی 1 روزه',
    zuixiaosuodingshijian: 'حداقل زمان قفل',
    nindeUSDT: 'USDT شما',
    tian: 'آسمان',
    quanbu: 'همه',
    suoUSDTbingkaishishengchan: 'USDT را قفل کنید و تولید را شروع کنید',
    ninquedingyaosuoUSDTkaishishengchanma: 'آیا مطمئن هستید که می خواهید USDT را قفل کنید و تولید را شروع کنید؟',
    tishi: 'اشاره',
    queding: 'مطمئنا',
    quxiao: 'لغو کنید',
    kaishishengchan: 'شروع به تولید!',
    yiquxiao: 'لغو شد',
    },
    subscription:{
    rengouliebiao:'لیست اشتراک',
    jijiangdaolai:'به زودی',
    jinxingzhong:'در حال انجام است',
    quanbu:'همه',
    keshiyonghuobi:"ارزهای موجود",
    zongshuliang:'مقدار کل',
    meiyouxianzhi:'بدون محدودیت',
    shengyushuliang:'مقدار باقی مانده',
    kaishishijian:'زمان شروع',
    zhuangtai:'دولت',
    jilu:'ضبط کنید',
    zanwujilu:"هنوز رکوردی وجود ندارد",
    leixing:'نوع',
    shuliang:'مقدار',
    goumaijine:'مبلغ خرید',
    shijian:'زمان',
    goumai:'خرید کنید',
    duichu:'از هیچ جا',
    jiangli:'جایزه',
    tibi:'سکه ها را برداشت کنید',
    huidaomulu:'بازگشت به فهرست مطالب',
    dangqianchiyou:'در حال حاضر برگزار می شود',
    keyong:'موجود است',
    keduihuan:'قابل بازخرید',
    shishihangqing:'نقل قول های زمان واقعی',
    yue:'تعادل',
    biaoti:'عنوان',
    fashoushuliang:'مقدار برای فروش',
    jindu:"برنامه",
    goumaishuliang:'مقدار خرید',
    ge:'فردی',
    woyiyuedu:'خوانده ام',
    daibigoumaixieyi:'قرارداد خرید توکن',
    qingshurugoumaijine:'لطفا مبلغ خرید را وارد کنید',
    shifoyaogoumai:'آیا می خواهید بخرید',
    tishi:'اشاره',
    queding:'مطمئنا',
    quxiao:'لغو کنید',
    yjs:'پایان یافت',
    ywc:'تکمیل شد',
    sgjg:'قیمت اشتراک',
    jssj:"زمان پایان",
    zqsl:"مقدار برنده",
    zqje:"مبلغ برنده",
    tian:"آسمان",
    shi:'ساعت',
    fen:'نقطه',
    miao:'دوم',
    shengou:'اشتراک',
    xmxq:'جزئیات پروژه',
    },
    }
    
    