<template>
    <div>
        
        <div style="width: 1200px;margin:0 auto">
            <div class="flex_row_left" style="height: 50px; background-color: #eee;">
                <el-page-header 
                    @back="goBack" 
                    :content="i18n('fanhui')"
                    :title="i18n('shishihangqing')"
                    style="margin-left: 10px;">
                </el-page-header>   
            </div>
            <div class="selcon container" style="width:100%;margin-top:20px">
                <el-tabs 
                    class="home-tabs"
                    v-model="activeName" 
                    @tab-click="tabClick">
                    <el-tab-pane :label="i18n('remenbang')" name="first"></el-tab-pane>
                    <el-tab-pane :label="i18n('zhangfubang')" name="second"></el-tab-pane>
                    <el-tab-pane :label="i18n('diefubang')" name="third"></el-tab-pane>
                    <el-tab-pane :label="i18n('chengjiaoliang')" name="fourth"></el-tab-pane>
                </el-tabs>
    
                <el-table
                    :data="eltableData"
                    stripe
                    type='index'
                    style="width: 100%;"
                    class="home-table"
                    :empty-text="i18n('zanwushuju')"
                    :cell-style="{'border-color':'#fff'}"
                    :header-cell-style="{background:'#fff', padding: '0', height:'45px', 'border-color':'#fff', color: '#000'}"
                    :row-style="{background:'#fff'}">
                    <el-table-column
                        :label="i18n('jiayidui')"
                        min-width="200">
                        <template slot-scope="scope">
                            <div style="display: flex; align-items: center;">
                                <el-image
                                    style="width: 30px; height: 30px"
                                    :src="apiAd+'/codelogo/'+contractCodeUsdt(scope.row.code)+'-USDT.png'"></el-image>  
                                <div style="color: #000; margin-left: 20px;"> {{ scope.row.code }} </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="i18n('zuixinjiage')"
                        min-width="100">
                        <template slot-scope="scope">
                            <span style="color: #000;"> 
                                {{ scope.row.price  | newPriceFormat(scope.row.float_num)}} </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="i18n('zhangdie24h')"
                        min-width="100">
                        <template slot-scope="scope">
                            <span :class="scope.row.price > scope.row.open ? 'color_up': 'color_down'"> 
                                {{ scope.row.price | filter_classUpOrDown(scope.row.open,'rate') }} </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="i18n('caozuo')"
                        align="right"
                        min-width="80">
                        <template slot-scope="scope">
                            <div style="flex:1; text-align: right;">
                                <el-button
                                    size="mini"
                                    type="primary"
                                    style="background-color: #FCD535;border:none;color:#333;width: 68px;"
                                    @click="handleTrade(scope.row.code)"> {{ i18n('jiaoyi') }} </el-button> 
                            </div>
                            
                        </template>
                    </el-table-column>
                </el-table>
    
                
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import config from '@/utils/configs'
import { newPriceFormat, classUpOrDown, moneyFormat } from '@/utils/filters.js'
  export default {
    data() {
        return {
            hqlist: this.$store.state.hqlist,
            activeName: 'first',
            eltableData:[],
            apiAd: config.apiAddress,
        }
    },
    filters: {
        contractCode: function (contract) {
            var attr = contract.split("-");
            return attr[0];
        },
        newPriceFormat: function (price, n) {
            return newPriceFormat(price, n);
        },
        classUpOrDown: function (nprice, openprice, useType) {
            return classUpOrDown(nprice, openprice, useType);
        },
        filter_classUpOrDown: function (nprice, openprice, useType) {
            return classUpOrDown(nprice, openprice, useType);
        },
    },
    computed: {
        
        i18n() {
            return function (name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("home")[name]
            }
        },
    },
    methods:{
        tabClick(tab) {
            this.pusheltableData(tab.name) ;
        },
        goBack() {
            this.$router.go(-1);
        },
        handleTrade(codename) {
            this.$router.push({ name: 'contractTradeLayout', params: { codename: codename } });
        },
        contractCodeUsdt (contract) {
            var attr=contract.split("-");
            return  attr[0]; 
        },
        pusheltableData(dataType) {
            switch (dataType) {
                case 'first':
                    this.eltableData = [];
                    var list = [...this.$store.state.hqlist];
                    list.sort((a, b) => a.sort - b.sort);
                    this.eltableData = [...list]
                    break;
                case 'second':
                this.eltableData = [];
                    var list = [...this.$store.state.hqlist];
                    list.forEach (item => {
                        if (item.price !== 0 && item.open !== 0) {
                            var ncPrice = item.price - item.open;
                            var priceRange = ncPrice / item.open;
                            item.rate = moneyFormat(priceRange * 100, 2);
                        }
                    });
                    list.sort((a, b) => b.rate - a.rate);
                    this.eltableData = [...list]
                    break;
                case 'third':
                    this.eltableData = [];
                    var list = [...this.$store.state.hqlist];
                    list.forEach (item => {
                        if (item.price !== 0 && item.open !== 0) {
                            var ncPrice = item.price - item.open;
                            var priceRange = ncPrice / item.open;
                            item.rate = moneyFormat(priceRange * 100, 2);
                        }
                    });
                    list.sort((a, b) => a.rate - b.rate);
                    this.eltableData = [...list]
                    break;
                case 'fourth':
                    this.eltableData = [];
                    var list = [...this.$store.state.hqlist];
                    list.sort((a, b) => a.dealnum - b.dealnum);
                    this.eltableData = [...list]
                    break;
            }
        },
    },
    mounted() {
        this.$root.$on('QuotesContractList', (msg) => {
            this.pusheltableData('first') ;
            console.log(his.$store.state.hqlist)  
        });

        if (this.$store.state.hqlist.length > 0) {
            this.pusheltableData('first') ; 
                     
        };
    },
  }
  </script>
  
  <style scoped lang="scss">
  .flex_row_left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .color_up{
        color: rgb(23, 99, 182);
    }

    .color_down{
        color: rgb(225, 35, 67);
    }

  </style>
  