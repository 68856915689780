<template>
  <div class="container">
    <el-container>
      <!--侧栏-->
      <el-container>
        <!--头部-->
        <el-header>
          <header-layout></header-layout>
        </el-header>
        <!--内容-->
        <router-view></router-view>
        <el-footer v-if='!isTrade'>
          <footer-layout></footer-layout>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import HeaderLayout from "./HeaderLayout.vue";
import footerLayout from "./footerLayout.vue";
export default {
  name: "BasicLayout",
  components: {
    HeaderLayout,
    footerLayout
  },
  computed: {
    // 使用计算属性来判断路由
    isTrade() {
      return this.$route.path === '/contractTradeLayout';
    }
  },
  data() {
     return{
      
     }
  },
  created() {
  }
}
</script>

<style scoped>
</style>
