<template>
    <div style="font-size:15px">
        <!-- 列表部分 -->
        <div class="treasure" >
            <div class="head-part">
                <div style="display: flex; width:1200px;margin:0 auto">
                    <div class="head-top">
                        <div class="title font_bold" style="align-items: flex-start;color:white"> {{i18n('dlzx')}} </div>
                        <!-- <img style="margin-top: 40px; margin-right: 32px;" :src="require('@/assets/images/Mine.png')" alt=""> -->
                    </div>
                </div>
            </div> 

            <div style="display: flex; justify-content: center;">
                <div style="width: 1200px;margin:0 auto">
                    <div class="mhrader" style="display:flex">
                        <img src="@/assets/images/comm1.png" alt="" style="width:30px;height:30px">
                        <div style="margin-left: 10px;margin-top:5px">{{ i18n('sjzl') }}</div>
                    </div>
                    <div style="overflow: hidden;">
                    
                        <div class="right_sw" style="">
                            <div class="right_sw_li" @click="selectType('')" :class="!selType?'right_hover':''" style="">{{ i18n('quanbu') }}</div>
                            <div class="right_sw_li" @click="selectType(1)" :class="selType==1?'right_hover':''" >{{ i18n('jinri') }}</div>
                            <div class="right_sw_li" @click="selectType(2)" :class="selType==2?'right_hover':''"  >{{ i18n('zuori') }}</div>
                            <div class="right_sw_li" @click="selectType(3)" :class="selType==3?'right_hover':''"  >7{{ i18n('ri') }}</div>
                            <div class="right_sw_li" @click="selectType(4)" :class="selType==4?'right_hover':''"  >30{{ i18n('ri') }}</div>
                        </div>
                        <div style="margin-top:20px">2024-04-15 12:00-2024-08-07 22:00</div>
                    </div>
                    <div style="width:100%;background:#FAFAFA;margin-top:30px;border-bottom:1px solid #EEEEEE">
                       <div style="padding:30px;">
                            <div style="color:#777777">{{ i18n('wdfy') }}</div>
                            <div style="margin-top:15px;">
                                <span style="font-size:22px;font-weight:600">{{reteDetails.totalCommission}} </span>
                                <span> USDT</span>
                            </div>
                       </div>
                    </div>
                    <div style="width:100%;background:#FAFAFA;margin-top:30px;">
                        <div style="padding:30px;display:flex;justify-content: space-between;">
                            <div>
                                <div style="color:#777777">{{ i18n('wdsjfy') }}</div>
                                <div style="margin-top:15px;" v-if="reteList.length>0">{{ i18n('dengji') }}1：{{parseFloat(reteList[0])*100}}%</div>
                                <div style="margin-top:15px;" v-if="reteList.length>0">{{ i18n('dengji') }}2：{{parseFloat(reteList[1])*100}}%</div>
                                <div style="margin-top:15px;" v-if="reteList.length>0">{{ i18n('dengji') }}3：{{parseFloat(reteList[2])*100}}%</div>
                            </div>
                            <div>
                                <div style="color:#777777">{{ i18n('\yqrs') }}</div>
                                <div style="margin-top:15px;">
                                    <span style="font-size:22px;font-weight:600">{{reteDetails.effectiveNum}}</span>
                                </div>
                           </div>
                            <div>
                                <div style="color:#777777">{{ i18n('tdrs') }}</div>
                                <div style="margin-top:15px;">
                                    <span style="font-size:22px;font-weight:600">{{reteDetails.allInviteNum}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mhrader" style="display:flex">
                        <img src="@/assets/images/comm2.png" alt="" style="width:30px;height:30px">
                        <div style="margin-left: 10px;margin-top:5px">{{ i18n('yqxq') }}</div>
                    </div>
                    <div class="outer_tabs_tit" style="">
                        <div :class="levelType==''?'outer_tabs_tit_act':''" @click="selLevel(0)">{{ i18n('quanbu') }}</div>
                        <div :class="levelType==1?'outer_tabs_tit_act':''" @click="selLevel(1)">LV.1 <span style="margin-left:3px"> ({{commissionDetails.firstTeamNum}}/{{commissionDetails.firstEffectiveTeamNum}})</span></div>
                        <div :class="levelType==2?'outer_tabs_tit_act':''" @click="selLevel(2)">LV.2 <span style="margin-left:3px"> ({{commissionDetails.secondTeamNum}}/{{commissionDetails.secondEffectiveTeamNum}})</span></div>
                        <div :class="levelType==3?'outer_tabs_tit_act':''" @click="selLevel(3)">LV.3 <span style="margin-left:3px"> ({{commissionDetails.thirdTeamNum}}/{{commissionDetails.thirdEffectiveTeamNum}})</span></div>
                    </div>
                    <div class="outer_tabs" >
                        
                        <div class="orderlist" style="width: 100%; border: 1px solid #ddd;">
                            <el-table
                                :data="lists"
                                style="width: 100%;"
                                :empty-text="i18n('zanwushuju')"
                                :header-cell-style="{background:'#F9F9F8', padding: '0', height:'45px'}"
                                :row-style="{background:'#F9F9F8'}">
                                <el-table-column
                                    :label="i18n('yhm')"
                                    min-width="100">
                                    <template slot-scope="scope">
                                        <span style="color: #000;"> 
                                            {{scope.row.account}} </span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    :label="i18n('cjsj')"
                                    min-width="100">
                                    <template slot-scope="scope">
                                        <span style="color: #000;"> 
                                            {{timeFormat(scope.row.createTime,'rrr') }} </span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    :label="i18n('dengji')"
                                    min-width="100">
                                    <template slot-scope="scope">
                                        <span> {{ scope.row.level }}</span>
                                    </template>
                                </el-table-column>
                                
                            </el-table>

                            <div class="block">
                                <el-pagination
                                    background
                                    hide-on-single-page
                                    :page-size=page_size
                                    :pager-count="11"
                                    layout="prev, pager, next"
                                    :current-page.sync=page
                                    @current-change="handleCurrentChange"
                                    class="flex_row_right"
                                    :total=totalCount>
                                </el-pagination>
                            </div>
                        </div>          
                    </div>
                    <div class="mhrader" style="display:flex">
                        <img src="@/assets/images/comm3.png" alt="" style="width:30px;height:30px">
                        <div style="margin-left: 10px;margin-top:5px">{{ i18n('fyxq') }}</div>
                    </div>
                    <div style="width:100%;background:#FAFAFA;margin-top:30px;">
                        <div style="padding:30px;display:flex;justify-content: space-between;">
                            <div>
                                <div style="color:#777777">{{ i18n('zyj') }}</div>
                                <div style="margin-top:15px;">
                                    <span style="font-size:22px;font-weight:600">{{bonusRecordsDetails.allBonus | moneyFormat(4)}}</span>
                                </div>
                           </div>
                            <div>
                                <div style="color:#777777">{{ i18n('dryj') }}</div>
                                <div style="margin-top:15px;">
                                    <span style="font-size:22px;font-weight:600">{{bonusRecordsDetails.todayBonus | moneyFormat(4)}}</span>
                                </div>
                           </div>
                            <div>
                                <div style="color:#777777">{{ i18n('dengji') }}1</div>
                                <div style="margin-top:15px;">
                                    <span style="font-size:22px;font-weight:600">{{bonusRecordsDetails.firstBonus | moneyFormat(4)}}</span>
                                </div>
                            </div>
                            <div>
                                <div style="color:#777777">{{ i18n('dengji') }}2</div>
                                <div style="margin-top:15px;">
                                    <span style="font-size:22px;font-weight:600">{{bonusRecordsDetails.secondBonus | moneyFormat(4)}}</span>
                                </div>
                            </div>
                            <div>
                                <div style="color:#777777">{{ i18n('dengji') }}3</div>
                                <div style="margin-top:15px;">
                                    <span style="font-size:22px;font-weight:600">{{bonusRecordsDetails.thirdBonus | moneyFormat(4)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="outer_tabs" >
                        
                        <div class="orderlist" style="width: 100%; border: 1px solid #ddd;margin-top:20px">
                            <el-table
                                :data="listsBonus"
                                style="width: 100%;"
                                :empty-text="i18n('zanwushuju')"
                                :header-cell-style="{background:'#F9F9F8', padding: '0', height:'45px'}"
                                :row-style="{background:'#F9F9F8'}">
                                <el-table-column
                                    :label="i18n('yhm')"
                                    min-width="100">
                                    <template slot-scope="scope">
                                        <span style="color: #000;"> 
                                            {{scope.row.account}} </span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                :label="i18n('cjsj')"
                                    min-width="100">
                                    <template slot-scope="scope">
                                        <span style="color: #000;"> 
                                            {{scope.row.time.split(' ')[0] }} </span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                :label="i18n('dengji')"
                                    min-width="100">
                                    <template slot-scope="scope">
                                        <span> {{ scope.row.UserLevel }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                :label="i18n('fyje')"
                                    min-width="100">
                                    <template slot-scope="scope">
                                        <span> {{ scope.row.changeNum }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <div class="block">
                                <el-pagination
                                    background
                                    hide-on-single-page
                                    :page-size=page_size
                                    :pager-count="11"
                                    layout="prev, pager, next"
                                    :current-page.sync=page
                                    @current-change="handleCurrentChange"
                                    class="flex_row_right"
                                    :total=totalCount>
                                </el-pagination>
                            </div>
                        </div>          
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    data() {
        return {
            totalCount:0,
            page:1,
            page_size:10,
            recordListData:[],
            selType:'',
            levelType:'',
            toDaytamp:'',
            endDaytamp:'',
            reteList:[],
            reteDetails:{},
            paramsYq: {
                page: 1,
                page_size:50,
                selectLevel:0
            }, //请求的参数
            commissionDetails:{},
            lists:[],
            bonusRecordsDetails:{},
            listsBonus:[],
            params: {
                page: 1,
                page_size:50,
            }, 
        };
    },
    mounted() {
        
        this.$root.$on('tradelogin', (message) => {
           this.getInvitesData();
           this.getTotalCommission();
           this.getBonusRecords()
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getInvitesData()
            this.getTotalCommission()
            this.getBonusRecords()
        };
    },
    beforeDestroy() {
        
    },
    filters:{
        moneyFormat: function (x, n) {
            var f = parseFloat(x);
            var basenum=1;
            for (var i = 0; i < n; i++) {
                basenum=basenum+"0"
            }
            basenum=basenum*1;
            if (isNaN(f)) {
                return false;
            }
            f = Math.floor(x * basenum) / basenum;
            var s = f.toString();
            var rs = s.indexOf(".");
            if (rs < 0) {
                rs = s.length;
                s += ".";
            }
            while (s.length <= rs + n) {
                s += "0";
            }
            return s;
        },
    },
    methods: {
        timeFormat(time,type) {
            if (time && time > 0) {
                if(type){
                    var needdate = new Date(time*1000);
                }else{
                    var needdate = new Date(time);
                }
               
                var Y = needdate.getFullYear() + '-';
                var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '-';
                var D = needdate.getDate();
               
                //      年 月 日 时 分 秒
                return Y + M + D;
            } else {
                return '--';
            }
        },
        handleCurrentChange(){

        },
        getInvitesData(){
            let data = {
					beginTime:this.toDaytamp,
					endTime:this.endDaytamp
				}
            this.$api.invitesData(data).then((res)=>{
                this.reteList = res.data.reteList.split(',');
				this.reteDetails = res.data.data
            })
        },
        getTotalCommission(){
            let data = this.paramsYq
            this.$api.totalCommission(data).then((res)=>{
                this.commissionDetails = res.data;
				this.lists = res.data.data.list
            })
        },
        getBonusRecords(){
            let data = this.params
            this.$api.bonusRecords(data).then((res)=>{
                this.bonusRecordsDetails = res.data
                this.listsBonus = res.data.data.list
            })
        },
        selectType(val){
            this.selType = val
            if(val == 1){
                this.toDaytamp = this.timeFormat(new Date().getTime()-86400000);
                this.endDaytamp = this.timeFormat(new Date().getTime());
            }else if(val == 2){
                this.toDaytamp = this.timeFormat(new Date().getTime()-(86400000*2));
                this.endDaytamp = this.timeFormat(new Date().getTime()-86400000);
            }else if(val == 3){
                this.toDaytamp = this.timeFormat(new Date().getTime()-(86400000*7));
                this.endDaytamp = this.timeFormat(new Date().getTime());
            }else if(val == 4){
                this.toDaytamp = this.timeFormat(new Date().getTime()-(86400000*30));
                this.endDaytamp = this.timeFormat(new Date().getTime());
            }else{
                this.toDaytamp = '';
                this.endDaytamp = ''
            }
            this.getInvitesData()
        },
        selLevel(val){
            this.levelType = val;
            this.paramsYq.selectLevel = val;
            this.lists = []
            this.getTotalCommission()
			
        }
        
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("commisson")[name];
            }
		},
    },
}
</script>

<style scoped>
.outer_tabs{
    width: 100%;
}
.outer_tabs .el-tabs__nav-wrap::after {
	display: none !important;
}

.outer_tabs .el-tabs__item {
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #929292 !important;
}

.outer_tabs .el-tabs__item.is-active {
	color: #000 !important;
}

.outer_tabs .el-tabs__active-bar {
	background: none !important;
	height: 2px !important;
}

.outer_tabs .el-tabs__nav-scroll {
	margin-bottom: 0;
}


.outer_tabs .el-tabs__item {
  padding: 0 10px !important;
  margin-right: 16px;
  color: #000 !important;
}

.outer_tabs .el-tabs__item.is-active {
  background: #F9F9F9;
}
.mhrader{
    font-size: 20px;
    margin: 40px 0;
}
.right_sw{
    display: flex;
}
.right_sw div{
    width: 100px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
}
.right_hover{
    background-color: #F3F3F3;
}
.outer_tabs_tit{
    width:100%;
    display:flex;
    line-height:40px;
    background:#FAFAFA;
    border: 1px solid #FAFAFA;
    border-bottom: none;
}
.outer_tabs_tit div{
    padding: 0 20px;
    margin-left:20px;
}
.outer_tabs_tit_act{
    color: #FCD535;
    border-bottom: 2px solid #FCD535;
}
.head-part{
    background: url('@/assets/images/commin.png') no-repeat no-repeat;
    background-size: 100% 100%;
    height: 50vh;
    width: 100%;
    color:white;
    display: flex;
    align-items: center;
}
</style>

<style scoped src="@/css/treasure.css">

</style> 