<template>
  <footer>
    <div class="bottom_foottop ">
        <div style="margin-top: 60px;">
            <div class="fz-40 fw-500 text-c" style="line-height: 58px;font-size:40px;font-weight:500">{{$t("public")['yzsjypt']}}</div>
            <div class="fz-24 text-c" style="line-height: 58px;font-size:24px">{{$t("public")['mskljyzh']}}</div>
            <div class="flex_col_center" style="display:flex;justify-content:center;">
                <el-button 
                @click="goPage('trade')"
                type="primary"
                class="fz-18"
                style="background-color: white;border:none;color:#333;margin-top:20px;border-radius:20px;padding: 10px 40px"
                > {{$t("home")['jiaoyi']}} </el-button>
            </div>
        </div>
    </div>
    <div class="foot1" style=" max-width: 1200px;margin:0 auto">
        <div class="foot1_left">
            <img src="@/assets/logo2x.png" alt="" style="width:160px;height:55px">
        </div>
        <div class="foot1_right">
            <ul>
                <li>
                    <p>  {{i18n('gongsi')}} </p>
                    <a :href="url" target="_blank">  {{i18n('lianxiwomwn')}} </a>
                   
                </li>
                <li>
                    <p>  {{i18n('zhidao')}} </p>
                    <!-- <a href="https://help.coin-one.one/listClass.html?id=6&column=%E6%96%B0%E6%89%8B%E6%8C%87%E5%8D%97" target="_blank"> {{i18n('yonghuzhichi')}} </a>
                    <a href="https://help.coin-one.one/listClass.html?id=6&column=%E6%96%B0%E6%89%8B%E6%8C%87%E5%8D%97" target="_blank"> {{i18n('zhanghuzhinan')}} </a> -->
                    <a href="www.blockchainflex.com">  {{i18n('ruanjianxiazai')}} </a>
                </li>
                <li>
                    <p>  {{i18n('zhengce')}} </p>
                    <a href="https://app.blockchainflex.com/static/images/msb_registration.pdf" target="_blank">  {{$t("public")['jyhg']}} </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="foot2">
        <p>Copyright ©  BCFX, Inc. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footerLayout",
  data() {
    return {
      url: '',
      inviteCode:sessionStorage.getItem("uid")
    }
  },
  methods: {
      handleImageError() {
        // 为了增加鲁棒性，这里使用this.$el.src而不是直接修改属性
        // 适用于Vue的编译器语法，确保在Vue的环境中正确处理DOM元素
        this.$el.src = '../../assets/error.png';
      },
      gopagse() {
        this.$router.push({name: 'registration'}, () => {});
      },
      getChat(){
        if(localStorage.getItem("lang") == 'en'){
				this.url = 'https://chat.blockchainflex.com?language=en&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'tc'){
				this.url = 'https://chat.blockchainflex.com?language=tw&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'hi'){
				this.url = 'https://chat.blockchainflex.com?language=hi&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'jp'){
				this.url = 'https://chat.blockchainflex.com?language=ja&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'pt'){
				this.url = 'https://chat.blockchainflex.com?language=pt&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'es'){
				this.url = 'https://chat.blockchainflex.com?language=es&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'ru'){
				this.url = 'https://chat.blockchainflex.com?language=ru&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'ko'){
				this.url = 'https://chat.blockchainflex.com?language=ko&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'fr'){
				this.url = 'https://chat.blockchainflex.com?language=fr&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'de'){
				this.url = 'https://chat.blockchainflex.com?language=de&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'ar'){
				this.url = 'https://chat.blockchainflex.com?language=ar&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'tr'){
				this.url = 'https://chat.blockchainflex.com?language=tr&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'fa'){
				this.url = 'https://chat.blockchainflex.com?language=fa&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'pl'){
				this.url = 'https://chat.blockchainflex.com&language=pl&metadata={"name":"'+this.inviteCode+'"}'
			}else if(localStorage.getItem("lang") == 'zh'){
				this.url = 'https://chat.blockchainflex.com?language=tw&metadata={"name":"'+this.inviteCode+'"}'
			}else{
				this.url = 'https://chat.blockchainflex.com?language=en&metadata={"name":"'+this.inviteCode+'"}'
			}
            console.log(this.url)
      }
  },
  mounted() {
    console.log(localStorage.getItem("lang"))
    this.getChat()
  },
  computed: {
    i18n() {
      return function(name) {
        return this.$t("footer")[name]
      }
	},
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-footer{
    padding: 0;
}
  footer{
       
        width: 100%;
        margin: 0 auto;
        padding: 148px 0px 48px 0;
        font-size: 14px;
    }
    footer i{
        font-style: normal;
    }
    .bottom_foottop .el-button:hover {
        background: #c4c2bb !important; /* 鼠标悬停时的边框颜色 */
        color: #333  !important;
      }
      .foot1{
        padding-top: 30px;
      }
  .foot1_left,
    .foot1_right{
        width: 50%;
        float: left;
        padding-bottom: 30px;
    }
    .foot1_left>div{
        margin-bottom: 12px
    }
    .fl1{
        color: #484d55;
        font-size: 14px;
        font-weight: 700;
    }
    .fl2{
        color: #79818f;
        font-size: 12px;
        font-weight: 700;
    }
    .fl2 i{
        margin: 0 0 0 8px;
        text-align: left;
        color: #aeb3bb;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
    }
    .fl3 span{
        font-weight: 700;
        color: #79818f;
        font-size: 12px;
        margin-right:10px;
        position: relative;
    }
    .fl3 span:after{
        content:"";
        width: 1px;
        height: 10px;
        background-color: #aeb3bb;
        position: absolute;
        right: -7px;
        top: 4px;
    }
    .fl3 span:last-child:after{
        display: none;
    }
    .fl3 i{
        color: #aeb3bb;
        font-size: 12px;
    }
    .fl4{
        display: flex;
        align-items: baseline;
        flex-direction: row;
        padding-top: 10px;
    }
    .fl4 span{
        color: #79818f;
        font-size: 12px;
        font-weight: 700;
    }
    .fl4 p{
            margin: 0 0 0 8px;
    text-align: left;
    color: #aeb3bb;
    font-size: 12px
    }
    .fl5{
            margin: 16px 0 0;
        font-size: 12px;
        color: #aeb3bb;
    }
    .fl6{
        font-weight: 700;
        color: #aeb3bb;
    }
    .fl6 span{
        font-weight: 700;
        color: #79818f;
        font-size: 12px;
        margin-right:10px;
        position: relative;
    }
    .fl6 span:after{
        content:"";
        width: 1px;
        height: 10px;
        background-color: #aeb3bb;
        position: absolute;
        right: -7px;
        top: 4px;
    }
    .fl6 span:last-child:after{
        display: none;
    }
    .foot1_right{
        display: flex;
    }
    .foot1_right ul{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .foot1_right li{
        min-width: 165px;
        padding: 0 35px 0 0;
    }
    .foot1_right li p{
        font-size: 16px;
        color: #484d55;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .foot1_right li a{
        display: block;
        font-size: 13px;
        word-break: break-word;
        color: #79818f;
        margin-bottom: 8px;
    }
    .foot2{
        clear: both;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-top: 1px solid #e4e5e8;
        padding-top: 12px;
        color: #aeb3bb;
    font-size: 12px;
    }
    .bottom_foottop{
        width: 100%;
        height: 350px;
        background: url('@/assets/images/bottom.png') no-repeat no-repeat;
        background-size: 100% 100%;
        color: white;
        display: flex;
        justify-content: center;
    }
    
</style>
